import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faChevronDown, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'ph-app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  
  constructor(
    public translate: TranslateService,
    private authenticationService: AuthenticationService
  ) {}
  searchtype = new FormControl();
  timeFormControl = new FormControl();
  searchTypeList: string[] = ['Personnummer', 'Remissnr', 'Remitent', 'Datum', 'Läkare'];
  public faGlobe = faGlobe;
  public faChevronDown = faChevronDown;

  ngOnInit() {
    this.selectedLang = this.translate.getDefaultLang();
  }

  public isExpanded: boolean = false;
  public toggle() {
    this.isExpanded = !this.isExpanded;
  }

  public selectedLang: string;
  public changeLanguage() {
    if (this.translate.currentLang != this.selectedLang)
      this.translate.use(this.selectedLang);
  }
  logout() {
    this.authenticationService.logout();
  }
}
