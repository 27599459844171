import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DataItemGroup } from '@model/data-item-group';
import { DataItem } from '@model/data-item';
import { RefValidatorService } from '@core/services/ref-validator.service';
import { DataPatientRemissUndersokning } from '@model/data-patientremissundersokning';
import { Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataPatient } from '@model/data-patient';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-input-page-vers2',
  templateUrl: './input-snurra-vers2.component.html',
  styleUrls: ['./input-snurra-vers2.component.scss']
})
export class InputSnurraVers2Component implements OnInit {

  constructor(private fb: FormBuilder, 
    private http: HttpClient, 
    private ref: ChangeDetectorRef,
    private refValidator: RefValidatorService
  ) { }

  public echoForm: FormGroup;
  public changeTimer: ReturnType<typeof setTimeout>;
  public inputDescription;

  public dataItemGroups: DataItemGroup[];
  public activeItemGroup: DataItemGroup;
  public activeItem: DataItem;
  @Input() dataDevice: DataPatientRemissUndersokning;
  @Input() dataPatient: DataPatient;
  public dataFromDevice = new BehaviorSubject<DataPatientRemissUndersokning[]>(null);
  ngOnInit() {
    console.log('Patient: ' + this.dataPatient.patientId);
    this.dataFromDevice.next(null);
    let form = { patientId: [this.dataDevice.patientId] };

    this.echoForm = this.fb.group(form);

    //Timer för att spara aktuell inmatning
    this.echoForm.valueChanges.subscribe(val => {
      if (this.changeTimer != null)
        clearTimeout(this.changeTimer);

      this.changeTimer = setTimeout(() => {
        this.onChange(val);
        this.changeTimer = null;
      }, 3000);
    });

    this.dataFromDevice.subscribe((data) => {
      this.inputDescription = data as [];
      this.inputDescription.flatMap(a => a.items).map(n => {
        if (n.visible == undefined)
          n.visible = () => true;
        else {
          let s = n.visible as string;
          n.visible = () => eval(s.replace(/{{([^}]*)}}/g, 'this.echoForm.value.$1'));
        }
        let ctl: FormControl = new FormControl('',[this.refValidator.validator(n.ref)]);
        this.echoForm.addControl(n.variable, ctl);
      });

      this.dataItemGroups = this.inputDescription as DataItemGroup[];
      this.activeItemGroup = this.dataItemGroups[0];
      this.activeItem = this.activeItemGroup.items[0];

      this.ref.markForCheck();
    });
  }

  onChange(val) {
    console.log(this.echoForm.value);
    //this.api.postData(this.echoForm.value).subscribe(() => { console.log(this.echoForm.value); });
  }

  onSubmit(formGroup: FormGroup) {
    //För tillfället: Sätt första input till active igen
    this.activeItemGroup = this.dataItemGroups[0];
    this.activeItem = this.activeItemGroup.items[0];

    this.echoForm = formGroup;
    // TODO: Use EventEmitter with form value
    console.warn(this.echoForm.value);
  }
}
