<div class="modal-header">
  <h5 class="modal-title">{{variableName ? 'Redigera ' + variableName : 'Ny variabel'}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
</div>
<div class="d-flex justify-content-center" *ngIf="isLoading == true">
  <i>Laddar information</i>
  <ph-loading-spinner></ph-loading-spinner>
</div>
<form [formGroup]="saveAddVariableForm" (ngSubmit)="submitForm()" *ngIf="isLoading == false">
  <input type="hidden" formControlName="id">
  <div class="modal-body">
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="sysDescription" class="col-form-label">Systembeskrivning: </label>
      </div>
      <div class="col-lg-8">
        <input class="form-control" formControlName="sysDescription" />
        <div *ngIf="sysDescription.invalid && shortName.touched" class="text-danger font-italic">
          <div *ngIf="sysDescription.errors.required"> Vänligen ange en systembeskrivning </div>
        </div>
        <div *ngIf="sysDescription.invalid && sysDescription.touched" class="text-danger font-italic">
          <div *ngIf="sysDescription.errors.maxlength"> Systembeskrivningen är för långt. </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="name" class="col-form-label">Namn: </label>
      </div>
      <div class="col-lg-8">
        <input class="form-control" formControlName="name" />
        <div *ngIf="name.invalid && name.touched" class="text-danger font-italic">
          <div *ngIf="name.errors.required"> Vänligen ange ett namn </div>
        </div>
        <div *ngIf="name.invalid && name.touched" class="text-danger font-italic">
          <div *ngIf="name.errors.maxlength"> Namnet är för långt. </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="shortName" class="col-form-label">Kortnamn: </label>
      </div>
      <div class="col-lg-8">
        <input class="form-control" formControlName="shortName" />
        <div *ngIf="shortName.invalid && shortName.touched" class="text-danger font-italic">
          <div *ngIf="shortName.errors.required"> Vänligen ange ett kortnamn </div>
        </div>
        <div *ngIf="shortName.invalid && shortName.touched" class="text-danger font-italic">
          <div *ngIf="shortName.errors.maxlength"> Kortnamnet är för långt. </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="short" class="col-form-label">Förkortning: </label>
      </div>
      <div class="col-lg-8">
        <input class="form-control" formControlName="short" />
        <div *ngIf="short.invalid && short.touched" class="text-danger font-italic">
          <div *ngIf="short.errors.required"> Vänligen ange en förkortning </div>
        </div>
        <div *ngIf="short.invalid && short.touched" class="text-danger font-italic">
          <div *ngIf="short.errors.maxlength"> Förkortningen är för lång. </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="description" class="col-form-label">Beskrivning: </label>
      </div>
      <div class="col-lg-8">
        <textarea class="form-control" formControlName="description" rows="3"></textarea>
        <div *ngIf="description.invalid && description.touched" class="text-danger font-italic">
          <div *ngIf="description.errors.required"> Vänligen ange en beskrivning </div>
        </div>
        <div *ngIf="description.invalid && description.touched" class="text-danger font-italic">
          <div *ngIf="description.errors.maxlength"> Beskrivningen är för lång. </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="internalComment" class="col-form-label">Intern kommentar: </label>
      </div>
      <div class="col-lg-8">
        <textarea class="form-control" formControlName="internalComment" rows="3"></textarea>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="description" class="col-form-label">Undersökningsvy grupp: </label>
      </div>
      <div class="col-lg-8" *ngIf="sysEchoGroupsUnd">
        <mat-form-field appearance="fill" class="w-100 border-0 p-0 m-0">
          <mat-label class="bg-white pt-4 px-0 mt-2 mx-0 py-0 mb-0">Välj grupp/vy</mat-label>
          <mat-select formControlName="sysEchoGroupsUnd" class="form-control bg-white pl-3" multiple>
            <!--<mat-select-trigger>
            {{sysEchoGroupsUnd.value ? sysEchoGroupsUnd.value[0] : ''}}
            <span *ngIf="groups.value?.length > 1" class="example-additional-selection">
              (+{{sysEchoGroupsUnd.value.length - 1}} {{sysEchoGroupsUnd.value?.length === 2 ? 'andra' : 'andra'}})
            </span>
          </mat-select-trigger>-->
            <mat-option *ngFor="let group of undersokningsVyGrupp | async" [value]="group.id">{{group.echoGroup}}</mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="sysEchoGroupsUnd.touched && sysEchoGroupsUnd.errors" class="text-danger font-italic">
          <div *ngIf="sysEchoGroupsUnd.errors.required">er
Fältet är obligatoriskt
          /d</div>
          er
          /d
        </div>
      </div>
    </div>

    <!--<div class="mb-3 row">
  <div class="col-4 text-right">
    <label for="description" class="col-form-label">Undersökningsvy grupp: </label>
  </div>
  <div class="col-lg-8">
    <mat-form-field appearance="fill" class="w-100 border-0 bg-white">
      <mat-label class="bg-white pt-4 px-0 mt-2 mx-0 py-0 mb-0">Välj grupp/vy</mat-label>
      <mat-select formArrayName="sysEchoGroupsUnd" class="form-control bg-white pl-3" multiple>-->
    <!--<mat-select-trigger>
    {{sysEchoGroupsUnd.value ? sysEchoGroupsUnd.value[0] : ''}}
    <span *ngIf="groups.value?.length > 1" class="example-additional-selection">
      (+{{sysEchoGroupsUnd.value.length - 1}} {{sysEchoGroupsUnd.value?.length === 2 ? 'andra' : 'andra'}})
    </span>
  </mat-select-trigger>-->
    <!--<mat-option *ngFor="let group of undersokningsVyGrupp | async" [value]="group.id">{{group.echoGroup}}</mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="sysEchoGroupsUnd.touched && sysEchoGroupsUnd.errors" class="text-danger font-italic">
        <div *ngIf="sysEchoGroupsUnd.errors.required">Fältet är obligatoriskt</div>
      </div>
    </div>
  </div>-->
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="sysEchoGroupsAnFys" class="col-form-label">Anatomisk/fysiologisk grupp:</label>
      </div>
      <div class="col-lg-8">
        <select class="form-select" formControlName="sysEchoGroupsAnFys">
          <option value="0">Välj grupp/vy</option>

          <!--<mat-select-trigger>-->
          <option *ngFor="let group of anatomiskFysiologiskVyGrupp | async" [value]="group.id">{{group.echoGroup}}</option>
        </select>
        <div *ngIf="sysEchoGroupsAnFys.touched && sysEchoGroupsAnFys.errors" class="text-danger font-italic">
          <div *ngIf="sysEchoGroupsAnFys.errors.required">Fältet är obligatoriskt</div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="durabilityOfMeasurment" class="col-form-label">Hållbarhet för mätningen av variablen (anges i dagar): </label>
      </div>
      <div class="col-lg-8">
        <input class="form-control" id="durabilityOfMeasurment" formControlName="durabilityOfMeasurment" />
        <div *ngIf="durabilityOfMeasurment.invalid && durabilityOfMeasurment.touched" class="text-danger font-italic">
          <div *ngIf="durabilityOfMeasurment.errors.maxlength"> Värdet får inte vara mer än 3 tecken </div>
          <div *ngIf="durabilityOfMeasurment.errors.minlength"> Värdet får inte vara mindre än 1 tecken </div>
          <div *ngIf="durabilityOfMeasurment.errors.pattern"> Fältet får bara innehålla siffror </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="timeLimitMeasurment" class="col-form-label">Hur länge är variablen jämförbar med tidigare mätningar (anges i år): </label>
      </div>
      <div class="col-lg-8">
        <input class="form-control" formControlName="timeLimitMeasurment" />
        <div *ngIf="timeLimitMeasurment.invalid && timeLimitMeasurment.touched" class="text-danger font-italic">
          <div *ngIf="timeLimitMeasurment.errors.maxlength"> Värdet får inte vara mer än 3 tecken </div>
          <div *ngIf="timeLimitMeasurment.errors.minlength"> Värdet får inte vara mindre än 1 tecken </div>
          <div *ngIf="timeLimitMeasurment.errors.pattern"> Fältet får bara innehålla siffror </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row">
      <div class="col-4 text-right">
        <label for="sysInfEchoValueType" class="col-form-label">Metod för gradering: </label>
      </div>
      <div class="col-8">
        <select formControlName="sysInfEchoValueType" class="form-select" (change)="getInfoSysInfEchoValueType($event.target.value)">
          <option [value]="null">Välj valgrupp</option>
          <option *ngFor="let option of listSysInfEchoValueType | async" [value]="option.id">{{option.description}}</option>
        </select>
      </div>

    </div>
    <div class="mb-3 row">
      <div class="col-8 offset-4">
        <ng-container *ngIf="!(sysInfEchoValueTypeList|async) && (sysInfEchoValueType.value != null) && sysInfEchoValueType.value != '0'">
          <ph-loading-spinner></ph-loading-spinner>
        </ng-container>
        <table class="table table-responsive" *ngIf="(sysInfEchoValueTypeList | async) && sysInfEchoValueType.value != 0 && sysInfEchoValueType.value != 7">
          <thead><tr><th>Aktuella val i gruppen<th></tr></thead>
          <tr *ngFor="let option of sysInfEchoValueTypeList | async"><td>{{option.text}}</td></tr>
        </table>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="sysInfEchoValueType.value == '0'">
      <div class="col-4 text-right">
        <label for="sysInfUnit" class="col-form-label">Enhet: </label>
      </div>
      <div class="col-8">
        <select formControlName="sysInfUnit" class="form-select">
          <option value="null">Välj enhet</option>
          <option *ngFor="let unit of unitList | async" [value]="unit.id">{{unit.unitShort}}</option>
        </select>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="sysInfEchoValueType.value == '0' || sysInfEchoValueType.value == '7'">
      <div class="col-4 text-right">
        <label for="sysInfFormat" class="col-form-label">Format: </label>
      </div>
      <div class="col-8">
        <select formControlName="sysInfFormat" class="form-select">
          <option value="null">Välj format</option>
          <option *ngFor="let format of formatList | async" [value]="format.id">{{format.format}}</option>
        </select>
      </div>
    </div>
    <div *ngIf="sysInfEchoValueType.value == '7'" class="mb-3 row">
      <div class="col-4 text-right">
        <label for="sysInfMeasuremenMethods" class="col-form-label">Mätmetoder: </label>
      </div>
      <div class="col-8">
        <!--<select formControlName="sysInfMeasuremenMethods" class="form-select">
        <option value="null">Välj mätmetod</option>
        <option *ngFor="let metod of sysInfMeasuremenMethods | async" [value]="metod.id">{{metod.name}}</option>
      </select>-->
        <mat-form-field appearance="fill" class="w-100 border-0 p-0 m-0">
          <mat-label class="bg-white pt-4 px-0 mt-2 mx-0 py-0 mb-0">Välj metoder</mat-label>
          <mat-select formControlName="sysInfMeasuremenMethods" class="form-control bg-white pl-3" multiple>
            <mat-option *ngFor="let method of sysInfMeasuremenMethods | async" [value]="method.id" (onSelectionChange)="changeSelectedMethods($event)">{{method.name}}</mat-option>
          </mat-select>
        </mat-form-field>


        <i *ngIf="addedMeasuremenMethods.length > 0"><small>Drag och släpp för att sortera.</small></i>
        ll
        <div cdkDropList class="sort-list col-6" (cdkDropListDropped)="drop($event)" *ngIf="addedMeasuremenMethods.length > 0">
          <div class="sort-box" *ngFor="let metod of addedMeasuremenMethods; let i = index" cdkDrag>
            <div class="row">
              <div class="col-12">
                {{metod.name}} - Sortering: {{setIndex(metod, i)}}
              </div>
              <!--<div class="col-2">-->
              <!--<fa-icon [icon]="faTrash" class="pointer float-end" (click)="removeMethod(metod, i)" [ngbTooltip]="'Ta bort'"></fa-icon>-->
              <!--</div>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="sysInfEchoValueType.value == '0' || sysInfEchoValueType.value == '7'">
      <div class="col-lg-4 offset-lg-4 col-md-12">
        <label for="impossibleValueMin" class="col-form-label">Omöjligt minvärde: </label>
        <input class="form-control" formControlName="impossibleValueMin" />
        <div *ngIf="impossibleValueMin.invalid && impossibleValueMin.touched" class="text-danger font-italic">
          <div *ngIf="impossibleValueMin.errors.maxlength"> Värdet får inte vara mer än 3 tecken </div>
          <div *ngIf="impossibleValueMin.errors.minlength"> Värdet får inte vara mindre än 1 tecken </div>
          <div *ngIf="impossibleValueMin.errors.pattern"> Fältet får bara innehålla siffror </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-12">
        <label for="impossibleValueMax" class="col-form-label">Omöjligt maxvärde: </label>
        <input class="form-control" formControlName="impossibleValueMax" />
        <div *ngIf="impossibleValueMax.invalid && impossibleValueMax.touched" class="text-danger font-italic">
          <div *ngIf="impossibleValueMax.errors.maxlength"> Värdet får inte vara mer än 3 tecken </div>
          <div *ngIf="impossibleValueMax.errors.minlength"> Värdet får inte vara mindre än 1 tecken </div>
          <div *ngIf="impossibleValueMax.errors.pattern"> Fältet får bara innehålla siffror </div>
        </div>
      </div>
    </div>
    <div *ngIf="cVariableId > 0 && cVariable?.sysInfEchoValueTypeId == 0" [ngClass]="graderingJsonData != null && graderingJsonData != '' ? card : ''">
      <div class="card-body">
        <div class="row">
          <div class="col-6" *ngIf="graderingJsonData != null && graderingJsonData != ''">
            <h3>Graderingar</h3>
          </div>
          <div [ngClass]="graderingJsonData != null && graderingJsonData != 'col-6' ? card : 'col-12'">
            <button class="btn btn-link float-end" (click)="openEditGraderingar()">Hantera graderingar</button>
          </div>
        </div>
        <div class="row" *ngIf="graderingJsonData != null && graderingJsonData != ''">
          <div class="col-12">
            <table class="table table-responsive m-3">
              <thead>
                <tr>
                  <th>Kön</th>
                  <th>Formel/värde</th>
                  <th>Graderingstext</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let formel of graderingJsonData?.formler; index as i;">
                  <tr [ngClass]="formel.graderingstyp == 7 ? 'fw-bold' : ''">
                    <td>{{formel.konBeskrivning}}</td>
                    <td>
                      {{formel.startValue}}
                      <span *ngIf="formel.graderingstyp != 1 && formel.graderingstyp != 8 && formel.graderingstyp != 7"> - </span>
                      <span *ngIf="formel.graderingstyp == 1"> > </span>
                      <span *ngIf="formel.graderingstyp == 8"> <</span>
                      {{formel.formel}}
                    </td>
                    <td>{{formel.graderingsText}}</td>
                    <td><span *ngIf="formel.visaEjISvar">(visas inte i svaret)</span></td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">

    <button type="button" class="btn btn-info cancel" (click)="onCancel()">{{'common.close' | translate}}</button>
    <button type="submit" class="btn btn-lg btn-success" [disabled]="processSubmit">{{'common.save' | translate}} <ph-loading-spinner *ngIf="processSubmit" size="x"></ph-loading-spinner></button>

  </div>
</form>
