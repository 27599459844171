import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataPatient } from '@model/data-patient';
import { DataItem } from '@model/data-item';
import { PatientRemiss } from '@model/data-patientremiss';
import { DataPatientRemissUndersokning } from '@model/data-patientremissundersokning';
import { SelectListItem } from '@model/select-list-item';
import { PatientListDto } from '@model/patienter/patient-list-dto';
import { PatientService } from '@core/services/patient.service';
import { OnDestroy } from '@angular/core';

//import { MatExpansionModule } from '@angular/material/expansion';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'overview-patient',
  templateUrl: './overview-patient.component.html',
  styleUrls: ['overview-patient.component.scss'],
})
export class OverviewPatientComponent implements OnInit, OnDestroy {

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private dataService: PatientService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
  ) { }
  public mobiliseringString: string;
  public dataPatient = new BehaviorSubject<PatientListDto[]>(null);
  public diagramItems: DataItem[] = [];
  public dataPatientRemissUndersokning: DataPatientRemissUndersokning[];
  public selectedPatientRemissUndersokning: DataPatientRemissUndersokning;
  public dataRemissWithId: PatientListDto;
  //public dataPatientWithId: ViewRemittance;
  public patientRemiss: PatientRemiss[];
  public selectedPatientRemiss: PatientRemiss;
  public mobiliseringOptions: SelectListItem[] = [];

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let patientId = params.get('patientId');
      this.getMobiliseringOptions();
      this.getData(patientId);
      this.showDiagram(2);
    });
  }
  ngOnDestroy() {
    this.dataPatient.unsubscribe();
  }
  /* Show diagram for DataItem */
  public randomSerieData: any;
  public showDiagram(index: number) {
    this.diagramItems[index] = null; //reset
    this.randomSerieData = [
      [Date.UTC(2021, 0, 1), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 3), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 4), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 5), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 6), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 7), parseFloat((Math.random() * 10).toFixed(1))]
    ];
  }

  //private getData(patientId) {
  //  if (patientId) {
  //    this.http.get('/assets/plan-examination/patienter.json')
  //      .subscribe((data: DataPatient[]) => {
  //        this.dataPatientWithId = data.filter(item => item.patientId == patientId)[0];
  //        this.detector.markForCheck();
  //      });
  //  }
  //}
  private getData(patientId) {
    if (patientId) {
      //this.dataPatient = this.dataService.getPatient(patientId);
      //this.http.get('/assets/plan-examination/patienter.json')
      //  .subscribe((data: DataPatient[]) => {
      //    this.dataPatientWithId = data.filter(item => item.patientId == patientId)[0];
      //    this.dataPatientWithId.remisser.forEach(remiss => this.getMobiliseringString(this.mobiliseringOptions, remiss.mobilisering));
          //this.dataRemissWithId.mobiliseringString = this.getMobiliseringString(this.mobiliseringOptions, this.dataPatientWithId.mobilisering);


          //this.patientRemiss = data.map(patient => {
          //  return patient.map(remiss => {
          //    let patientremiss = new DataPatientRemissUndersokning();
          //    patientremiss.pnr = patient.pnr;
          //    patientremiss.patientId = patient.patientId
          //    patientremiss.remissId = remiss.id;
          //    patientremiss.remisstyp = remiss.remisstyp;
          //    patientremiss.prioritering = remiss.prioritering;
          //    patientremiss.remissdatum = remiss.remissdatum;
          //    patientremiss.remissnr = remiss.remissnr;
          //    patientremiss.remitent = remiss.remitent;
          //    patientremiss.undersokningstypId = remiss.undersokningstypId;
          //    patientremiss.planerad = remiss.planerad;
          //    patientremiss.komplettera = remiss.komplettera;
          //    patientremiss.aterremitterad = remiss.aterremitterad;
          //    patientremiss.vantarKomplettering = remiss.vantarKomplettering;
          //    patientremiss.hasMultipleRemisses = patient.remisser.filter(remiss => !remiss.planerad).length > 1;
          //    patientremiss.status = 1;/*this.getStatus(patientremiss.komplettera, patientremiss.aterremitterad, patientremiss.hasMultipleRemisses, patientremiss.planerad, patientremiss.vantarKomplettering);*/
          //    patientremiss.mobilisering = patient.mobilisering;
          //    return patientremiss;
          //  });
          //}).flat();

          this.detector.markForCheck();
/*        });*/
    }
  }
  private getMobiliseringOptions() {
    this.http.get('/assets/select-listor/mobilisering.json')
      .subscribe((data: SelectListItem[]) => {
        data.forEach((item) => {
          this.mobiliseringOptions.push(new SelectListItem(item.text, item.value));
        });
        this.detector.markForCheck();
      });
  }
  public getStatus(komplettera, aterremitterad, hasMultipleRemisses, planerad, vantarKomplettering): number {
    if (komplettera)
      return 1;
    else if (aterremitterad)
      return 2;
    else if (hasMultipleRemisses)
      return 3;
    else if (planerad)
      return 4;
    else if (vantarKomplettering)
      return 5;
    else
      return 0;
  }
  public getMobiliseringString(mobiliseringOptions, value): string {
    return mobiliseringOptions.filter(mobilisering => mobilisering.value == value)[0].text;
  }
  public hasPlannedExaminations(patient): boolean {
    return patient.remisser.filter(remiss => remiss.planerad && !remiss.undersokt)?.length > 0;
  }
  public hasCompletedExaminations(remisser): boolean {
    return remisser.filter(remiss => remiss.undersokt)?.length > 0;
  }
  public hasOplaneradeRemisser(remisser): boolean {
    return remisser.filter(remiss => !remiss.planerad)?.length > 1;
  }

  /* Show remiss modal*/
  public showRemissModalIsOpen = new BehaviorSubject<boolean>(false);

  public openShowRemissModal(patientRemissData: DataPatientRemissUndersokning) {
    /*this.dataPatientRemissWithId = patientRemissData;*/
    this.showRemissModalIsOpen.next(null);
  }
  public showRemissModalClosed(reason: string) {
    this.showRemissModalIsOpen.next(false);
  }
}
