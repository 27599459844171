<ng-template #content let-modal size="md">
  <div class="modal-header">
    <h5 class="modal-title" id="preview-image-modal"></h5>
    <!--<button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>-->
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body text-center">
    <img [src]="'assets/img/' + imageName + '.png'" class="img-fluid" />
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-outline-danger delete" (click)="removeImage()">
      <fa-icon [icon]="faTrashAlt"></fa-icon>&ensp;{{'pages.overview.images.delete' | translate}}
    </button>
    <button type="button" class="btn btn-sm btn-info" (click)="closeModal()">{{'common.close' | translate}}</button>
  </div>
</ng-template>
