import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { PatientListDto } from '@model/patienter/patient-list-dto';
import { RemittanceDto } from '../../model/remisser/remittance-dto';
import { SelectedMeasuremenMethod } from '../../modules/settings/components/add-echovariable-modal/add-echovariable-modal.component';
import { RegEchoVariantDto } from '../../model/undersokningsvarianter/reg-echo-variant-dto';


@Injectable({
  providedIn: 'root'
})
export class UndersokningService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public getUndersokning(id: number): Observable<PatientListDto> {
    if (id == null)
      return EMPTY;

    return this.http.get<PatientListDto>(this.baseUrl + `api/plan-examination/${id}`);
  }
  // Testbädd nedan
  public getPlannedExamination(id: number): Observable<PatientRemissUndersokning> {
    return this.http.get<PatientRemissUndersokning>(this.baseUrl + `api/plan-examination/plannedexamination/${id}`);
  }
  public savePlannedMedicalExamination(id: number, undersokning: PatientRemissUndersokning): Observable<any> {
    if (id > 0) {
      return this.http.put<PatientRemissUndersokning>(this.baseUrl + `api/plan-examination/${undersokning.plannedexamination.id}`, undersokning);
    }
    return this.http.post<PatientRemissUndersokning>(this.baseUrl + 'api/plan-examination', undersokning);
  }
  public getExaminations(): Observable<ExaminationsListDto[]> {
    return this.http.get<ExaminationsListDto[]>(this.baseUrl + `api/plan-examination/getlist`);
  }
  //Till testen
  public saveTestUlValues(ulValues: Test_UndersokningsvardenDto): Observable<any> {
    if (ulValues.id > 0) {
      return this.http.put<Test_UndersokningsvardenDto>(this.baseUrl + `api/plan-examination/ultestvalues/${ulValues.id}`, ulValues);
    }
    return this.http.post<Test_UndersokningsvardenDto>(this.baseUrl + 'api/plan-examination/ultestvalues', ulValues);
  }
  //Till testen
  public getUlValuesTest(): Observable<Test_UndersokningsvardenDto[]> {
    return this.http.get<Test_UndersokningsvardenDto[]>(this.baseUrl + `api/plan-examination/ultestvalues`);
  }
  public deleteExamination(id: number): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `api/plan-examination/${id}`);
  }
  public deleteUlValuesTest(id: number): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `api/plan-examination/ultestvalues/${id}`);
  }
  
  public testUlValuesNameExists(name: string): Observable<any> {
    return this.http.get<any>(this.baseUrl + `api/plan-examination/ultestvaluesexists/${name}`);
  }
}

//TODO: Lagt detta tillfälligt och sen struktuera om klasserna i backend?
export type RemittanceOnlyDto = {
  id: number;
  patientId: number;
  remittanceClinicId: number;
  remittanceDoctorId: number;
  healthProblems?: string;
  sysInfPriorityId?: number;
  weight?: number;
  height?: number;
  timestamp?: string;
  active: boolean;
  remittanceStatusId: number;
  questionStatment?: string;
  statusComment?: string;
  arrivalDatum?: string;
}

export type PlannedExaminationDto = {
  id: number;
  sysInfMedicalExaminationTypeId?: number;
  remittanceId: number;
  weight?: number;
  length?: number;
  timestamp?: Date;
  active: boolean;
  remittanceClinicId?: number;
  remittanceDoctorId?: number;
  healthProblems?: string;
  sysInfPriorityId?: number;
  remissDatum?: Date;
  questionStatement?: string;
  valdaVarJson?: string;
  uTypDescription?: string;
  otherInformationTexts?: OtherInformationsTexts[] | [];
  variantsIds?: string | "";
  variants?: string[] | [];
  removedVariablesIds?: string | "";
  addedVariablesIds?: string | "";
  removedVariablesDescs?: string[] | [];
  addedVariablesDescs?: string[] | [];
}
export type PatientDto = {
  id: number;
  personalIdentityNumber: string;
  name: string;
  lastname: string;
  ePost?: string;
  phone?: string;
  genderId?: number;
  age?: any;
  address?: string;
  zipcode?: number;
  postOrt?: string;
  languageId?: number;
  weight?: any;
  length?: any;
  dateVl?: string;
  hypersensivityToMedicine?: boolean;
  gender?: string;
  language?: string;
  remittanceList?: RemittanceDto[];
}
export type PatientRemissUndersokning = {
  patient: PatientDto;
  remiss: RemittanceOnlyDto;
  plannedexamination: PlannedExaminationDto;
}
export type ExaminationsListDto = {
  pnr: string;
  created: Date;
  patientFnamn: string;
  patientEnamn: string;
  uTypDescription: string;
}
export type Test_Undersokningsvarden = {
  id: number;
  namn?: string;
  skapadDatum?: Date;
  andradDatum?: Date;
  vardenJson?: string;
}
export type Test_UndersokningsvardenDto = {
  id: number;
  namn?: string;
  skapadDatum?: Date;
  andradDatum?: Date;
  vardenJson: UlResult[];
}
export type UlResult = {
  id: number;
  value?: string;
  variableName?: string | "";
  unit?: string | "";
  measurementMethods?: SelectedMeasuremenMethod[] | [];
}
export class OtherInformationsTexts {
  id: number;
  description: string = "";
}
