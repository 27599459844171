import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'drawable-canvas',
  templateUrl: './drawable-canvas.component.html',
  styleUrls: ['./drawable-canvas.component.scss']
})
export class DrawableCanvasComponent implements AfterViewInit, OnChanges {

  constructor(
    public translate: TranslateService
  ) {}

  @Input() backgroundImageName: string;
  @Input() triggerSaveImage: boolean = false;
  @Output() getImgDataUrl = new EventEmitter<any>();
  
  @ViewChild("drawingCanvas") canvasRef: ElementRef;

  ngAfterViewInit(): void {
    this.initializeCanvas();
  }

  ngOnChanges(): void {
    if (this.triggerSaveImage) this.save();
  }
  
  private ctx: CanvasRenderingContext2D;

  private canvasHeight = 225;
  private canvasWidth = 150;

  private drawingFlag = false;
  private dotFlag = false;
  private previousX = 0;
  private currentX = 0;
  private previousY = 0;
  private currentY = 0;

  private readonly defaultStrokeWidth = 5;
  private readonly defaultErasorWidth = 10;
  private strokeWidth = this.defaultStrokeWidth;
  public color = "black";

  private initializeCanvas() {
    this.ctx  = this.canvasRef.nativeElement.getContext('2d');
    this.canvasRef.nativeElement.style.width = "100%";
    this.canvasHeight = this.canvasRef.nativeElement.height;
    this.canvasWidth = this.canvasRef.nativeElement.width;
    this.setImageAsBackground();
    this.setEventListeners();
  }

  private setEventListeners() {
    let _ = this;
    this.canvasRef.nativeElement.addEventListener("mousemove", function (e) { _.findxy('move', e) }, false);
    this.canvasRef.nativeElement.addEventListener("mousedown", function (e) { _.findxy('down', e) }, false);
    this.canvasRef.nativeElement.addEventListener("mouseup", function (e) { _.findxy('up', e) }, false);
    this.canvasRef.nativeElement.addEventListener("mouseout", function (e) { _.findxy('out', e) }, false);
    this.canvasRef.nativeElement.addEventListener("touchmove", function (e) { _.findxy('move', e) }, false);
    this.canvasRef.nativeElement.addEventListener("touchstart", function (e) { _.findxy('down', e) }, false);
    this.canvasRef.nativeElement.addEventListener("touchend", function (e) { _.findxy('up', e) }, false);
  }

  private setImageAsBackground() {
    if (this.backgroundImageName) {
      let _ = this;
      let image = new Image();
      image.src = "assets/img/" + this.backgroundImageName + ".png";
      image.onload = function() {
        let canvasImgWidth = _.canvasWidth - 20;
        let proportion = canvasImgWidth / image.width;
        _.canvasHeight = proportion * image.height; // canvas height proportional to image
        _.canvasRef.nativeElement.height = _.canvasHeight + 20;
        _.ctx.drawImage(image,10,10,canvasImgWidth,_.canvasHeight);
      };
    }
  }

  private findxy(res, e) {
    switch (res) {
      case 'down':
        this.previousX = this.currentX;
        this.previousY = this.currentY;

        let clickedX = (e.touches ? e.touches["0"].clientX : e.clientX); 
        let clickedY = (e.touches ? e.touches["0"].clientY : e.clientY); 

        let mousePosition = this.getMousePosition(this.canvasRef.nativeElement, clickedX, clickedY);
        this.currentX = mousePosition.x;
        this.currentY = mousePosition.y;

        this.drawingFlag = true;
        this.dotFlag = true;
        if (this.dotFlag) {
          this.ctx.beginPath();
          this.ctx.fillStyle = this.color;
          this.ctx.fillRect(this.currentX, this.currentY, 2, 2);
          this.ctx.closePath();
          this.dotFlag = false;
        }
        break;
      case 'up':
      case 'out':
        this.drawingFlag = false;
        break;
      case 'move':
        if (this.drawingFlag) {
          this.previousX = this.currentX;
          this.previousY = this.currentY;

          let clickedX = (e.touches ? e.touches["0"].clientX : e.clientX); 
          let clickedY = (e.touches ? e.touches["0"].clientY : e.clientY);

          let mousePosition = this.getMousePosition(this.canvasRef.nativeElement, clickedX, clickedY);
          this.currentX = mousePosition.x;
          this.currentY = mousePosition.y;
          
          this.draw();
        }
        break;
    }
  }

  private getMousePosition(canvas, clickedX, clickedY): any {
    var rect = canvas.getBoundingClientRect(), // abs. size of element
        scaleX = canvas.width / rect.width,    // relationship bitmap vs. element for X
        scaleY = canvas.height / rect.height;  // relationship bitmap vs. element for Y
  
    return {
      x: (clickedX - rect.left) * scaleX,   // scale mouse coordinates after they have
      y: (clickedY - rect.top) * scaleY     // been adjusted to be relative to element
    }
  }

  private draw() {
    this.ctx.beginPath();
    this.ctx.moveTo(this.previousX, this.previousY);
    this.ctx.lineTo(this.currentX, this.currentY);
    this.ctx.strokeStyle = this.color;
    this.ctx.lineWidth = this.strokeWidth;
    this.ctx.stroke();
    this.ctx.closePath();
  }

  public setColor(newColor: string) {
    this.color = newColor;
    if (this.color == "white") 
      this.strokeWidth = this.defaultErasorWidth;
    else this.strokeWidth = this.defaultStrokeWidth;
  }
  
  public clear() {
    if (this.backgroundImageName)
      this.setImageAsBackground();
    else
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
  }
    
  public save() {
    let canvasDataURL = this.canvasRef.nativeElement.toDataURL();
    this.getImgDataUrl.emit(canvasDataURL);
  }

}
