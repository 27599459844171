<form [formGroup]="editForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="modal-header">
    <h4 class="modal-title pull-left">
      <ng-container *ngIf="id !== 0; else newForm">Redigera undersökning</ng-container>
      <ng-template #newForm>Ny undersökning</ng-template>
    </h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="onCancel()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center">
      <div class="col-8 offset-2">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="patientNewSearch" id="search" value="search">
          <label class="form-check-label" for="search">
            Sök patient
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="patientNewSearch" id="new" value="new">
          <label class="form-check-label" for="new">
            Ny patient
          </label>
        </div>
      </div>
    </div>
    <div class="row justify-content-center" *ngIf="editForm.value.patientNewSearch == 'search'">
      <label class="col-form-label col-2 text-right" for="search">Sök patient: </label>
      <div class="col-8">

        <ng-select class="col-8 form-control" [items]="patienter | async"
                   bindLabel="personalIdentityNumber"
                   [minTermLength]="0"
                   [loading]="patienterLoading"
                   loadingText="Hämtar patienter..."
                   [clearable]="true"
                   typeToSearchText="Skriv minst 3 tecken av personnummer"
                   [typeahead]="patienterInput"
                   formControlName="selectedPatient" (change)="selectPatient($event)">
          <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
            {{item.personalIdentityNumber}} - {{item.lastname}}, {{item.name}}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{item.personalIdentityNumber}} - {{item.lastName}}, {{item.name}}
          </ng-template>
        </ng-select>
      </div>
    </div>
    <div>
      <div class="row justify-content-center mt-2">
        <label class="col-form-label col-2 text-right" for="pnr">Personnummer: </label>
        <div class="col-8">
          <input type="text" class="form-control form-control-sm" (focusout)="personnummerChangeInput($event.target.value)" formControlName="pnr" [readonly]="editForm.value.patientNewSearch == 'search' && patientId > 0" />
          <div *ngIf="invalidPnr" class="text-danger">
            <div *ngIf="errorPnr">Personnummer saknas</div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <label class="col-form-label col-2 text-right" for="namn">Namn: </label>
        <div class="col-8">
          <input type="text" class="form-control form-control-sm" formControlName="namn" [readonly]="patientId > 0 && editForm.value.patientNewSearch == 'search'" />
          <div *ngIf="invalidNamn" class="text-danger">
            <div *ngIf="errorNamn">Namn saknas</div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <label class="col-form-label col-2 text-right" for="enamn">Efternamn: </label>
        <div class="col-8">
          <input type="text" class="form-control form-control-sm" formControlName="enamn" [readonly]="patientId > 0 && editForm.value.patientNewSearch == 'search'" />
          <div *ngIf="invalidENamn" class="text-danger">
            <div *ngIf="errorENamn">Efternamn saknas</div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <label class="col-form-label col-2 text-right" for="epost">E-post: </label>
        <div class="col-8">
          <input type="text" class="form-control form-control-sm" formControlName="epost" />
          <div *ngIf="invalidEpost" class="text-danger">
            <div *ngIf="errorEpost">E-post saknas</div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <label class="col-form-label col-2 text-right" for="telefon">Telefon: </label>
        <div class="col-8">
          <input type="text" class="form-control form-control-sm" formControlName="telefon" />
          <div *ngIf="invalidTelefon" class="text-danger">
            <div *ngIf="errorTelefon">Telefonnummer saknas</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8 offset-2">
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="kon" id="konM" [value]="2">
          <label class="form-check-label" for="konM">
            Man
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" formControlName="kon" id="konF" [value]="1">
          <label class="form-check-label" for="konF">
            Kvinna
          </label>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <label class="col-form-label col-2 text-right" for="langd">Längd: </label>
      <div class="col-8">
        <input type="text" class="form-control form-control-sm" formControlName="langd" />
        <div *ngIf="invalidLangd" class="text-danger">
          <div *ngIf="errorLangd">Längd saknas</div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <label class="col-form-label col-2 text-right" for="vikt">Vikt: </label>
      <div class="col-8">
        <input type="text" class="form-control form-control-sm" formControlName="vikt" />
        <div *ngIf="invalidVikt" class="text-danger">
          <div *ngIf="errorVikt">"Personnummer saknas</div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <label class="col-form-label col-2 text-right" for="alder">Ålder: </label>
      <div class="col-8">
        <input type="text" class="form-control form-control-sm" formControlName="alder" />
        <div *ngIf="invalidAlder" class="text-danger">
          <div *ngIf="errorAlder">Ålder saknas</div>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <label for="halsoproblem" class="col-form-label col-2 text-right">Hälsoproblem: </label>
      <div class="col-8">
        <textarea class="form-control" id="halsoproblem" rows="3" formControlName="halsoproblem"></textarea>
      </div>
    </div>
    <hr>
    <h4>Undersökning</h4>
    <div class="row justify-content-center">
      <label class="col-form-label col-2 text-right" for="uTyp">Undersökningstyp: </label>
      <div class="col-8">
        <select class="form-select form-control-sm" id="uTyp" formControlName="uTyp" (change)="changeTyp()">
          <option value="0">Välj undersökningstyp</option>
          <option *ngFor="let u of uTyp | async" [ngValue]="u.id">
            {{ u.description }}
          </option>
        </select>
      </div>
    </div>

    <div class="row justify-content-center mt-2" *ngIf="(varianter | async) && (editForm.value.uTyp == 1 || id > 0)">
      <label class="col-form-label col-2 text-right" for="variant">Variant: </label>
      <div class="col-8">
        <mat-form-field appearance="fill" class="w-100 border-0 p-0 m-0">
          <mat-label>Välj undersökningsvarianter</mat-label>
          <mat-select formControlName="variant" multiple (selectionChange)="changeSelectedVariants()">
            <mat-option *ngFor="let v of varianter | async" [value]="v.id">
              {{v.variantName}}
              <fa-icon *ngIf="v.variables.length > 0" [icon]="faInfoCircle" [ngbTooltip]="infoVariant" container="body" tooltipClass="tooltipclass" placement="right"></fa-icon>
              <ng-template #infoVariant>
                <div class="tooltip-inner">
                  <ul *ngFor="let variable of v.variables" class="list-unstyled">
                    <li class="p-0 m-0">Id: {{variable.id}} - {{variable.shortName}}</li>
                  </ul>
                </div>
              </ng-template>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-10">
        <div class="row justify-content-end">
          <div class="col-8 px-5">
            <ph-loading-spinner *ngIf="showVariableTextSpinner && editForm.value.variant > 0" class="justify-content-center d-flex py-2" size="2x"></ph-loading-spinner>
            <i *ngIf="!showVariableTextSpinner">{{showAddedAndRemovedVariables()}}</i>
          </div>
          <div class="col-2">
            <button class="btn btn-sm float-end" type="button" (click)="openCloseAllGroups(openAllGroups = !openAllGroups)">{{openAllGroups ? 'Stäng alla variabelgrupper': 'Öppna alla variabelgrupper'}}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="row bg-light border p-3" *ngIf="(editForm.value.uTyp == 1 || id > 0) && openAllGroups">
      <div class="alert alert-dark" role="alert">
        <fa-icon [icon]="faInfoCircle"></fa-icon> Variabler som saknar graderingsmetod visas inte här.
      </div>
      <!--<div class="row">
        <div class="col-4">
          <small>Sök:</small>
          <input type="text" class="form-control" (ngModelChange)="variableInputChange($event)"
                 placeholder="Sök / Filtrera..." formControlName="searchVariables">
        </div>
      </div>-->
      <ng-container *ngFor="let grupp of variablesByGroupType2">
        <div class="col-lg-3 col-sm-4 pt-2">
          <h5><span class="pointer" [ngbTooltip]="grupp.toggleAll? 'Avmarkera alla': 'Markera alla i gruppen'" (click)="selectAllInGroup(grupp, grupp.toggleAll = !grupp.toggleAll)">{{grupp.echoGroup}} {{countSelectedInGroups(grupp.id)}}</span> <fa-icon class="px-2" [icon]="grupp.open ? faChevronUp : faChevronDown" [ngbTooltip]="grupp.open ? 'Stäng' : 'Öppna'" (click)="grupp.open = !grupp.open"></fa-icon></h5>
          <ng-container *ngIf="grupp.open">
            <ph-loading-spinner *ngIf="!grupp.sysEchoVariables"></ph-loading-spinner>
            <ng-container *ngFor="let variable of grupp.sysEchoVariables">
              <div class="form-check" *ngIf="variable.show != false">
                <input class="form-check-input" type="checkbox" id="var_{{variable.id}}" formControlName="var_{{variable.id}}" (click)="countSelectedInGroups() " />
                <label class="form-check-label" for="var_{{variable.id}}" [ngbTooltip]="variable.description">
                  {{variable.shortName}}
                </label>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </ng-container>
    </div>

    <div class="row justify-content-center mt-2">
      <label class="col-form-label col-2 text-right">Övrigt: </label>
      <div class="col-8">
        <ul class="list-group" *ngFor="let text of otherInformationsTexts; index as i">
          <li class="list-group-item mt-1">
            <div class="row">
              <div class="col-10">
                <input type="text" class="form-control" formControlName="otherText_{{text.id}}" placeholder="Övrig information" aria-label="Övrig information">
              </div>
              <!--Radera inte den första-->
              <div class="col-2 pt-2" *ngIf="!i==0">
                <fa-icon [icon]="faTrash" (click)="removeOtherText(i)" class="pointer" [ngbTooltip]="'Ta bort'"></fa-icon>
              </div>
            </div>
          </li>
        </ul>
        <button class="btn btn-primary mt-2" type="button" [ngbTooltip]="'Lägg till fler'" (click)="addOtherText()"><fa-icon [icon]="faPlus"></fa-icon></button>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Stäng</button>
    <button type="submit" class="btn btn-primary save" [disabled]="!editForm.valid">Spara</button>
    <button *ngIf="fromList" type="button" class="btn btn-primary save" [disabled]="!editForm.valid" (click)="startExamination()">Starta undersökning</button>
  </div>
</form>
