<div class="modal-header">
  <h4 class="modal-title pull-left">
    Undersökningsvärden
    <!--<ng-container *ngIf="id !== 0; else newForm">Redigera undersökning</ng-container>
    <ng-template #newForm>Ny undersökning</ng-template>-->
  </h4>
  <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="onCancel()">
    <span aria-hidden="true" class="visually-hidden">&times;</span>
  </button>
</div>
<form [formGroup]="editForm" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="modal-body">
    <div class="row justify-content-center">
      <label class="col-form-label col-2 text-right" for="namn">Namn: </label>
      <div class="col-8">
        <input type="text" class="form-control form-control-sm" formControlName="namn" />
        <div *ngIf="(namn.touched || submitted) && namn.errors?.required" class="text-danger">Du måste ange ett namn</div>
        <div class="text-danger" *ngIf="(namn.touched || submitted) && namn.errors?.nameExists">
          Namnet finns redan.
      </div>
    </div>
    </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-8 offset-2">
    <table class="table">
      <thead>
        <tr><th>Variabel</th><th>Värde</th><th>Metoder</th></tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let variable of ulValues">
          <tr *ngIf="variable.value">
            <td>{{variable.variableName}}</td>
            <td> {{variable.value}} {{variable.unit}}</td>
            <td><ng-container *ngFor="let metod of variable.measurementMethods">{{metod.name}}: {{metod.value}} {{variable.unit}}</ng-container></td>
          </tr>
         </ng-container>
        </tbody>
    </table>
  </div>
    </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onCancel()">Stäng</button>
    <button type="submit" class="btn btn-primary save" [disabled]="!editForm.valid">Spara</button>
  </div>
</form>
