import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { TranslateService } from '@ngx-translate/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-image-modal',
  templateUrl: './add-image-modal.component.html',
  styleUrls: ['./add-image-modal.component.scss']
})
export class AddImageModalComponent implements OnChanges {

  public imageLib: string[] = ['resurs-1', 'resurs-2', 'resurs-3', 'resurs-4', 'resurs-5', 'resurs-6', 'resurs-7', 'resurs-8', 'resurs-9'];

  constructor(
    public translate: TranslateService,
    private modalService: NgbModal
  ) { }

  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();

  @ViewChild("content") modalHtml;

  public faPlus = faPlus;
  
  public imageToDrawOn: string;
  public saveDrawedImage: boolean = false;

  ngOnChanges(): void {
    if (this.modalIsOpen)
      this.openModal();
  }

  private openModal() {
    this.modalService.open(this.modalHtml, {ariaLabelledBy: 'add-image-modal', size: 'lg'})
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }

  public closeModal() {
    this.imageToDrawOn = null;
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }

  public addImage() {
    this.saveDrawedImage = true;
  }

  public receiveImgDataUrl(imgDataUrl: any) {

    //TODO: Save drawed image to server
    // $.ajax({
    //   type: "POST",
    //   url: "api/",
    //   data: { 
    //      imgBase64: imgDataUrl
    //   }

    //save drawed canvas as file
    fetch(imgDataUrl).then(res => res.blob()).then(blob => { 
      saveAs(blob, this.imageToDrawOn + '-edit');
      this.saveDrawedImage = false;
    });
  }
}
