<ng-container *ngIf="showTable">
  <ng-container *ngFor="let table of tables">
    <!--Visa vanlig tabell-->
    <table class="table" *ngIf="!table.showMultipleVariableValues">
      <thead>
        <tr>
          <ng-container *ngFor="let header of table.headers">
            <th [attr.colspan]="header.colspan">{{header.description}}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let gRow of table.groupedRows">
          <ng-container *ngFor="let row of gRow.rows; let index = index">
            <ng-container *ngIf="row.variables.length > 0">
              <tr>
                <td *ngIf="gRow.description && index === 0" [attr.rowspan]="gRow.totalVariables" [class.verticalTableTd]="gRow.isVertical">{{gRow.description}} <span *ngIf="gRow.showUnitWithNames">{{variables[0].unit}}</span></td>
                <td (click)="showChild(row.id)" *ngIf="row.canOpen" class="pointer" [class.fw-bold]="row.isBold">
                  <fa-icon [icon]="row.showChildren ? faChevronUp : faChevronDown"></fa-icon>
                  {{row.rowDescription != '' ? row.rowDescription : row.variables[0].short}}
                </td>
                <td *ngIf="!row.canOpen">
                  {{row.rowDescription != '' ? row.rowDescription : row.variables[0].short}}
                </td>
                <td *ngIf="!table.showUnitWithNames">{{row.variables[0].unit}}</td>
                <td>
                  <ng-container *ngFor="let variable of row.variables; index as ix">
                    {{variable.currentValue}}{{ix == 0 && row.variableIds.length > 1 ? ' / ' : ''}}
                  </ng-container>
                </td>
                <td></td>
                <td>
                  <!--TODO: Fråga Pekka om jag kan göra såhär eller om det kan bli annan ordning?-->
                  <ng-container *ngFor="let variable of row.variables; index as ix">
                    <ng-container *ngFor="let formel of variable.calculationJson?.formler; index as i">
                      <ng-container *ngIf="variable.calculationJson.intervalltyp == 1 && formel.graderingstyp == 9 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                        {{formel.graderingsTecken}}{{formel.startValue}}{{ix == 0 && row.variableIds.length > 1 ? ' / ' : ''}}
                      </ng-container>
                      <ng-container *ngIf="variable.calculationJson.intervalltyp == 2 && formel.graderingstyp == 6 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                        {{formel.beraknat}}{{ix == 0 && row.variableIds.length > 1 ? ' / ' : ''}}
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </td>
              </tr>
              <!--Fäll ut om den är sammansatt-->
              <!--variable.currentValue != '' && variable.currentValue != null &&-->
              <tr *ngFor="let variable of row.variables">
                <ng-container *ngIf="row.showChildren == true">
                  <td [class.fw-bold]="row.isBold">{{variable.shortName}}</td>
                  <td>{{variable.unit}}</td>
                  <td>{{variable.currentValue}}</td>
                  <td></td>
                  <td>
                    <table>
                      <tbody *ngFor="let formel of variable.calculationJson?.formler; index as i">
                        <tr>
                          <td>
                            <ng-container *ngIf="variable.calculationJson.intervalltyp == 1 && formel.graderingstyp == 9 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                              {{formel.graderingsTecken}}{{formel.startValue}}
                            </ng-container>
                            <ng-container *ngIf="variable.calculationJson.intervalltyp == 2 && formel.graderingstyp == 6 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
                              {{formel.beraknat}}
                            </ng-container>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </ng-container>
              </tr>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>
    <!--Tabell med värden kolumnvis-->
    <table class="table" *ngIf="table.showMultipleVariableValues">
      <thead>
        <tr>
          <ng-container *ngFor="let header of table.headers">
            <th [attr.colspan]="header.colspan">{{header.description}}</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let gRow of table.groupedRows">
              <tr *ngFor="let row of gRow.rows; let index = index">
                <td>{{gRow.description}} <span *ngIf="gRow.showUnitWithNames">({{variables[0].unit}})</span></td>
                  <!--TODO: Fråga Pekka om jag kan göra såhär eller om det kan bli annan ordning?-->
                  <ng-container *ngFor="let variable of row.variables; index as ix">
                    <td>
                      {{variable.currentValue}}
                      <!--<ng-container *ngFor="let formel of variable.calculationJson?.formler; index as i">
    <ng-container *ngIf="variable.calculationJson.intervalltyp == 1 && formel.graderingstyp == 9 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
      {{formel.graderingsTecken}}{{formel.startValue}}{{ix == 0 ? ' / ' : ''}}
    </ng-container>
    <ng-container *ngIf="variable.calculationJson.intervalltyp == 2 && formel.graderingstyp == 6 && (undersokning.patient.genderId == formel.kon || variable.calculationJson.kontyp == 0)">
      {{formel.beraknat}}{{ix == 0 ? ' / ' : ''}}
    </ng-container>
  </ng-container>-->
                    </td>
                  </ng-container>
              </tr>
          </ng-container>
      </tbody>
      <!--<tfooter>
        <tr *ngFor="let footer of table.footer">
          <td [attr.colspan]="footer.colspan">{{footer.description != '' ? footer.description : footer.variable.shortName}} <span *ngIf="footer.variable">{{footer.variable.currentValue}}</span></td>
        </tr>
        </tfooter>-->
    </table>
  </ng-container>
</ng-container>
<button (click)="getData(showTable = !showTable)" class="btn btn-primary">{{showTable ? 'Dölj tabell med resultat' : 'Visa tabell med resultat'}}</button>

