<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title" id="edit-image-modal">Redigera bild</h5>
    <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>-->
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
    <div class="modal-body">
      <draw-on-image [imageName]="imageName"></draw-on-image>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info" (click)="closeModal()">Stäng</button>
    </div>
  </ng-template>
