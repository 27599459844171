import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import {  FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { PatientRemiss } from '@model/data-patientremiss';
import { Remiss } from '@model/data-remiss';
import { RemittanceDto } from '@model/remisser/remittance-dto';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-infoUndersokning-modal',
  templateUrl: './add-infoUndersokning-modal.component.html',
  styleUrls: ['./add-infoUndersokning-modal.component.scss']
})
export class AddInfoUndersokningModalComponent implements OnChanges {

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }

  @Input() patientRemiss: Remiss;
  @Input() patientData: RemittanceDto;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();
  @Output() onSubmit = new EventEmitter<FormGroup>();
  @ViewChild("content") modalHtml;

  public loading = new BehaviorSubject<boolean>(false);
  public saveAddInfoUndersokningForm: FormGroup;
  public formItems: PatientRemiss[] = [];
  submitted = false;
  vikt = new FormControl();
  langd = new FormControl();

  ngOnChanges(): void {
    if (this.modalIsOpen) {
      this.openModal();
      this.setForm();
    }
  }

  /* Modal */
  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'add-infoUndersokning-modal', size: 'lg' })
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }

  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit(this.saveAddInfoUndersokningForm);
  }

  private setForm() {
    this.saveAddInfoUndersokningForm = this.fb.group({
      vikt: ['75', Validators.required],
      langd: ['175', Validators.required]
    });
  }
  public submitForm() {
    //TODO: Save
    console.log(this.saveAddInfoUndersokningForm.value);
    this.submitted = true;
    if (this.saveAddInfoUndersokningForm.valid) {

      this.onSubmit.emit(this.saveAddInfoUndersokningForm);
      this.closeModal();
    }

    this.modalService.dismissAll(); //Sålänge - stäng modal
  }
}
