import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PatientListDto } from '@model/patienter/patient-list-dto';
import { PatientDto } from './undersokning.service';

@Injectable({
  providedIn: 'root'
})
export class PatientService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public getPatientOverview(): Observable<PatientListDto[]> {
    return this.http.get<PatientListDto[]>(this.baseUrl + 'api/patient');
  }
  //TODO: Annan modell för patientvyn?
  public getPatient(id: number): Observable<PatientListDto> {
    if (id == null)
      return EMPTY;

    return this.http.get<PatientListDto>(this.baseUrl + `api/patient/${id}`);
  }
  ////
  getPatienter(filter?: string): Observable<PatientListDto[]> {
    let query = this.baseUrl + 'api/patient';
    if (filter?.length >= 3) {
      query += `?filter=¦${filter}¦`;
    }
    return this.http.get<PatientListDto[]>(query);
  }
}
