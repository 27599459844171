<div class="text-light">
  <div>
    <button class="btn btn-sm btn-primary mt-3" (click)="openShowLakemedelModal()">Nytt läkemedel <i class="fas fa-pills"></i></button>
    <b class="text-light float-right clickable pt-3" *ngIf="remissLakemedel.length > 0" (click)="lakemedelRuta = !lakemedelRuta">
      Läkemedel <span *ngIf="remissLakemedel">({{remissLakemedel.length}})</span>
      <span><fa-icon class="float-right mx-2" [icon]="!lakemedelRuta ? faAngleDown : faAngleUp"></fa-icon></span>
    </b>
  </div>
    <div class="card-body" *ngIf="lakemedelRuta">
      <table class="table text-light">
        <tr>
          <th><span class="badge badge-secondary">Givna</span></th>
          <th></th>
          <th></th>
        </tr>
        <tr *ngFor="let lakemedel of remissLakemedel">
          <ng-container *ngIf="lakemedel.lakemedelStatus == '4'">
            <td class="text-light"><a href="{{lakemedel.linkFass}}" target="_blank" [ngbTooltip]="'Länk till FASS'" class="text-light"><i class="fas fa-info-circle"></i></a> {{lakemedel.lakemedel}} ({{lakemedel.lakemedelStyrka}})</td>
            <td class="text-light">{{lakemedel.lakemedelTidGivet}}</td>
            <td><button class="btn-link btn" [ngbTooltip]="'Redigera'" (click)="openEditShowLakemedelModal(lakemedel)"><i class="fas fa-edit"></i></button></td>
            <td class="text-right"><input type="checkbox"></td>
          </ng-container>
        </tr>
      </table>
      <table class="table w-100">
        <tr>
          <th><span class="badge badge-secondary">Föreslaget</span></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr *ngFor="let lakemedel of remissLakemedel">
          <ng-container *ngIf="lakemedel.lakemedelStatus == '2'">
            <td class="text-light"><a href="{{lakemedel.linkFass}}" target="_blank" [ngbTooltip]="'Länk till FASS'" class="text-light"><i class="fas fa-info-circle"></i></a> {{lakemedel.lakemedel}} ({{lakemedel.lakemedelStyrka}})</td>
            <td class="text-light">{{lakemedel.lakemedelTidGivet}}</td>
            <td><button class="btn-link btn" [ngbTooltip]="'Redigera'" (click)="openEditShowLakemedelModal(lakemedel)"><i class="fas fa-edit"></i></button></td>
            <td class="text-right"><input type="checkbox"></td>
          </ng-container>
        </tr>
      </table>
      <table class="table w-100">
        <tr>
          <th><span class="badge badge-secondary">Ordinerat</span></th>
          <th></th>
          <th></th>
          <th></th>
        </tr>
        <tr *ngFor="let lakemedel of remissLakemedel">
          <ng-container *ngIf="lakemedel.lakemedelStatus == '3'">
            <td class="text-light"><a href="{{lakemedel.linkFass}}" target="_blank" [ngbTooltip]="'Länk till FASS'" class="text-light"><i class="fas fa-info-circle"></i></a> {{lakemedel.lakemedel}} ({{lakemedel.lakemedelStyrka}})</td>
            <td class="text-light">{{lakemedel.lakemedelTidGivet}}</td>
            <td><button class="btn-link btn" [ngbTooltip]="'Redigera'" (click)="openEditShowLakemedelModal(lakemedel)"><i class="fas fa-edit"></i></button></td>
            <td class="text-right"><input type="checkbox"></td>
          </ng-container>
        </tr>
      </table>
      <table class="table w-100">
        <tr>
          <th><span class="badge badge-secondary">Anteckning</span></th>
          <th></th>
        </tr>
        <tr *ngFor="let lakemedel of remissLakemedel">
          <ng-container *ngIf="lakemedel.lakemedelStatus == '1'">
            <td class="text-light">{{lakemedel.lakemedelKommentar}}</td>
            <td></td>
            <td><button class="btn-link btn" [ngbTooltip]="'Redigera'" (click)="openEditShowLakemedelModal(lakemedel)"><i class="fas fa-edit"></i></button></td>
            <td class="text-right"><input type="checkbox"></td>
          </ng-container>
        </tr>
      </table>
      <div class="text-right row text-dark" *ngIf="remissLakemedel.length > 0">
        <div class="col-2 offset-4 my-auto">
          <span [ngbTooltip]="'1. Klicka i checkboxarna för de läkemedel du vill göra åtgärd för. 2. Välj åtgärd i droplisten till höger här. 3. Klicka på knappen Utför'"><i class="fas fa-info-circle"></i></span>
        </div>
        <select class="form-control col-4">
          <option>Välj åtgärd</option>
          <option>Läkemedel givet</option>
          <option>Ordinera läkemedel</option>
          <option>Radera</option>
        </select>
        <div class="col-2 my-auto">
          <button class="btn btn-sm btn-primary">Utför</button>
        </div>
      </div>
    </div>

</div>

<add-lakemedel-modal [newLakemedelToEdit]="newLakemedelToEdit | async"
                     [modalIsOpen]="showLakemedelModalIsOpen | async"
                     (modalClosed)="showLakemedelModalClosed()"
                     (onSubmit)="newLakemedelSubmitted($event)"></add-lakemedel-modal>
