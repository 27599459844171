import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';
import { DataExaminationTypeList } from '@model/data-examination-type-list';
import { ExampleSavedDataItem } from '@model/data-exampleSavedDataItem-type';
import { ExampleItemgroups } from '@model/data-exampleItemgroups';
import { ExampleSavedDataExaminationType } from '@model/data-exampleSavedDataExaminationType-type';
import { OnDestroy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-examinationstypes',
  templateUrl: './examinationstypes.component.html',
  styleUrls: ['./examinationstypes.component.scss']
})
export class ExaminationtypesComponent implements OnInit, OnDestroy {
  public dataItemGroups = new BehaviorSubject<DataItemGroup[]>(null);
  public variableItems: DataItem[] = [];
  public dataExaminationType = new BehaviorSubject<DataExaminationTypeList[]>(null);
  public exampleSavedDataExaminationType = new BehaviorSubject<ExampleSavedDataExaminationType[]>(null);
  public exampleSavedDataItem: ExampleSavedDataItem[] = [];
  public exampleSavedDataItemGroup: ExampleItemgroups[] = [];

  constructor(
    public translate: TranslateService,
    private http: HttpClient
  ) { }
  
  public typeSelected: boolean;
  public createNewExaminationType: boolean;

  ngOnInit(): void {
    this.createNewExaminationType = true;
    this.getDataFromExistingEchoTypes('new');
  }
  ngOnDestroy() {
    this.dataItemGroups.unsubscribe();
    this.dataExaminationType.unsubscribe();
    this.exampleSavedDataExaminationType.unsubscribe();
  }
  public getEchoTypes() {
    this.createNewExaminationType = false;
    this.http.get('/assets/examinations_types_json/echotypes.json')
      .subscribe((data: DataExaminationTypeList[]) => {
        this.dataExaminationType.next(data);
        data.forEach(() => this.variableItems.push(null));
      });
  }

  public getDataFromExistingEchoTypes(url) {
    if (url == "new") {
      this.createNewExaminationType = true;
      this.getData("/assets/examinations_types_json/new.json");
    }
    else {
      this.typeSelected = true;
      this.createNewExaminationType = false;
      this.getData(url);
    }
  }

  private getData(url) {
    this.http.get(url)
      .subscribe((data: ExampleSavedDataExaminationType[]) => {
        this.exampleSavedDataExaminationType.next(data);
        data.forEach(() => this.exampleSavedDataItemGroup.push(null));
        //data.forEach((key: any) => this.exampleSavedDataItemGroup.forEach(() => key.dataItemGroups.push()));
        console.log(data);
        //////names => this.typeName = names;
      });
  }
}
