import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DataItem } from '@model/data-item';

import { DataItemGroup } from '@model/data-item-group';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'input-data-table',
    templateUrl: './input-data-table.component.html',
    styleUrls: ['./input-data-table.component.scss']
})
export class InputDataTableComponent implements OnChanges {
    @Input() formGroup: FormGroup;
    @Input() itemGroup: DataItemGroup;

    @Input() activeItemGroup: DataItemGroup;
    @Output() activeItemGroupChange = new EventEmitter<DataItemGroup>();

    @Input() activeItem: DataItem;
    @Output() activeItemChange = new EventEmitter<DataItem>();

    public isCollapsed: boolean;

    ngOnChanges() {
        if (this.itemGroup && this.activeItemGroup) {
            this.isCollapsed = this.activeItemGroup.group != this.itemGroup.group;
        }
    }
    
    public setActiveItem(item: DataItem): void {
        this.activeItemGroup = this.itemGroup;
        this.activeItemGroupChange.emit(this.itemGroup);
        this.activeItem = item;
        this.activeItemChange.emit(this.activeItem);
    }

    public itemHasErrorValidation(item: DataItem): boolean {
        let validationStatus = this.formGroup.get(item.variable).getError('rangeError');
        return validationStatus == 1 || validationStatus == 2;
    }

    public itemHasWarningValidation(item: DataItem): boolean {
        let validationStatus = this.formGroup.get(item.variable).getError('rangeError');
        return validationStatus == 3 || validationStatus == 4;
    } 

    public itemGroupHasErrorValidation(): boolean {
        let hasErrors: boolean = false;
        let _this = this;
        this.itemGroup.items.forEach((item) => {
            if (_this.itemHasErrorValidation(item)) {
                hasErrors = true;
                return;
            }
        });
        return hasErrors;
    }

    public itemGroupWarningValidation(): boolean {
        let hasWarnings: boolean = false;
        let _this = this;
        this.itemGroup.items.forEach((item) => {
            if (_this.itemHasWarningValidation(item)) {
                hasWarnings = true;
                return;
            }
        });
        return hasWarnings;
    }
}
