import { AfterViewChecked, AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { ViewEchoVariableDto } from '../../../../model/undersokningsvarianter/view-echo-variable-dto';
import { SelectedMeasuremenMethod } from '../../../settings/components/add-echovariable-modal/add-echovariable-modal.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectLista, Typselectlist } from '../../echo-testbadd/echo-testbadd.component';
import { VariablesService } from '../../../../core/services/variabler.service';
import { faBars, faAtom } from "@fortawesome/free-solid-svg-icons";
import { coerceBooleanProperty } from "@angular/cdk/coercion";

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'option-value-examination',
  templateUrl: './option-value-examination.component.html',
  styleUrls: ['./option-value-examination.component.scss']
})
export class OptionValueExaminationComponent implements OnInit {
  constructor(private readonly element: ElementRef
  ) { }
  //Ikoner
  faBars = faBars;
  faAtom = faAtom;
  @Input() variable: ViewEchoVariableDto;
  @Input() currentValue: string;
  @Input() variableId: number;
  @Input() style: number; 
  @Input() valdGrupp: number;
  @Input() showEditVariable: boolean;
  //@Input() gindex: number; 
  //@Input() vindex: number;
  @Input() typeSelectList: SelectLista[];
  @Output() private tabToNextVariable = new EventEmitter<number>();
  @Output() private variableValuesChanged = new EventEmitter<VariableAndValue>();
  //@Output() private onFormGroupChange = new EventEmitter<any>();
  //@Output() private showEdit = new EventEmitter<{ show: boolean, variabelId: number }>();

  @ViewChild("content") modalHtml;
  private _variableForm: FormGroup = new FormGroup({
    valgruppOptionsModal: new FormControl(null),
    var_text: new FormControl(""),
    currentValue: new FormControl("")
  });
  get variableForm(): FormGroup { return this._variableForm; }
  get cValue() { return this._variableForm.get('currentValue'); }
  get valgruppOptionsModal() { return this._variableForm.get('valgruppOptionsModal'); }

  ngOnInit(): void {
    this.variable.currentValue = this.currentValue;
    this.variable.measurementMethods = this.variable.measurementMethods.sort((a, b) => a.sortorder - b.sortorder);
    this.setForm();
    //this.valuesChanged();
  }
  setForm() {
    //this.variableForm.get("currentValue")?.patchValue(this.variable.currentValue);
    //this.variableForm.get("currentValue")?.setValidators([Validators.max(this.variable.impossibleValueMax), Validators.min(this.variable.impossibleValueMin)]);
    this.variableForm.get("valgruppOptionsModal")?.patchValue(this.variable.currentValue);

    //Sätt selectvärde om det är selectlista
    //this.variableForm.get("valgrupp")?.patchValue(this.variable.currentValue);
  }
  variableChangeInput(value: string) {
    //this.variableForm.get("valgruppOptionsModal")?.patchValue(this.currentValue);
    //this.variable.currentValue = this.currentValue;
    //this.showHideEdit(false);
    //this.variableForm.get("currentValue")?.patchValue(this.variable.currentValue);
    this.variableValuesChanged.emit({ variable: this.variable, value: value ?? ""});
    this.tabToNextVariable.emit(this.variableId);

  }
  showHideEdit(show: boolean) {
    this.variableForm.get("valgruppOptionsModal")?.patchValue(this.variable.currentValue);

    console.log("VariabelId" + this.variableId + "Show" + show + "Valuetyp" + this.variable.valueTypeId);
    this.variable.showEdit = show;
    //this.showEdit.emit({ show: show, variabelId: this.variableId });
    //TODO: Fortsätt här med att sätta fokus på input i tabell och lista
  }
  //////////
  ///
  onChangeValgrupp(variable: ViewEchoVariableDto, event: any, style: number) {
    //Om något val är satt
    if (event.target.value != null) {
      variable.currentValue = event.target[event.target.selectedIndex].text.split(' |', 1).toString();
      variable.showEdit = false;
      let splitText = event.target[event.target.selectedIndex].text.split('|');
      splitText[1] = splitText[1].toLowerCase();
      variable.graderingText = variable.shortName + " är " + splitText[1];
    }
  }
  //Välj selectval med tangentbordet (Id). Hur blir det om det är mer än 9 val?
  onKeyPressedOption(event: KeyboardEvent) {
    if (event.keyCode - 97 >= 0 && event.keyCode - 97 <= 2) {
      this.typeSelectList = this.typeSelectList[event.keyCode - 97].value;
    }
  }
}

export class VariableAndValue
{ variable?: ViewEchoVariableDto; value?: string; }
