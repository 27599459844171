import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

import { ExampleSavedDataExaminationType } from '@model/data-exampleSavedDataExaminationType-type';
import { DataExaminationTypeList } from '@model/data-examination-type-list';
import { HttpClient } from '@angular/common/http';
import { RegEchoVariantDto } from '@model/undersokningsvarianter/reg-echo-variant-dto';
import { UndersokningsVariantService } from '@core/services/undersokningsvariant.service';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-examinationtype-modal',
  templateUrl: './add-examinationtype-modal.component.html',
  styleUrls: ['./add-examinationtype-modal.component.scss']
})
export class AddExaminationTypeModalComponent implements OnChanges {
  constructor(
    public translate: TranslateService,
    private dataService: UndersokningsVariantService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private http: HttpClient,
  ) { }
  //Ikoner
  faCalendar = faCalendar;
  public typeSelected: boolean;
  public createNewExaminationType: boolean;
  //@Input() itemGroup: DataExaminationTypeList;
  @Input() dataWithId;
  @Input() modalIsOpen: boolean;
  @Output() savedVariant = new EventEmitter<RegEchoVariantDto>();
  @Output() modalClosed = new EventEmitter<string>();
  //@Output() newExaminationTypeItemGroup = new EventEmitter<FormGroup>();
  @Output() onSubmit = new EventEmitter<string>();
  @ViewChild("content") modalHtml;

  //svarighetsgradList: SelectListItem[] = [new SelectListItem('1', '1'), new SelectListItem('2', '2'), new SelectListItem('3', '3')];

  //public examinationTypeForm: FormGroup;
  public loading = new BehaviorSubject<boolean>(false);
  //public variableItems: DataItem[] = [];
  //examinationTypesList: Observable<Undersökningsvariant[]>;


  public formItems: ExampleSavedDataExaminationType[] = [];
  public event: EventEmitter<any> = new EventEmitter<DataExaminationTypeList[]>();
  //saveNewExaminationType = false;
  submitted = false;
  //showEchoTypes = false;


  ngOnChanges(): void {
    if (this.modalIsOpen) {
      this.openModal();
      this.setForm();
      //  this.getEchoTypes();
    }
  }

  /* Modal */
  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'add-examinationtype-modal', size: 'lg' })
      .result.then((result) => this.modalClosed.emit(result), (reason) => this.modalClosed.emit(reason));
  }

  public newExaminationTypeForm: FormGroup;
  private setForm() {
    this.loading.next(true);
    this.newExaminationTypeForm = new FormGroup({
      variantName: new FormControl('', Validators.required),
      variantDescription: new FormControl('', Validators.required),
      validToDatum: new FormControl(),
      validFromDatum: new FormControl(),
      typeActiveFromDatum: new FormControl(),
      typeActiveToDatum: new FormControl()
    });
    this.newExaminationTypeForm.reset();
    this.loading.next(false);
  }

  get variantName() { return this.newExaminationTypeForm.get('variantName'); }
  get variantDescription() { return this.newExaminationTypeForm.get('variantDescription'); }
  get validFromDatum() { return this.newExaminationTypeForm.get('validFromDatum'); }
  get validToDatum() { return this.newExaminationTypeForm.get('validToDatum'); }
  get typeActiveFromDatum() { return this.newExaminationTypeForm.get('typeActiveFromDatum'); }
  get typeActiveToDatum() { return this.newExaminationTypeForm.get('typeActiveToDatum'); }

  public submitForm() {
    this.submitted = true;
    //TODO: Save
    if (!this.newExaminationTypeForm.valid) {
      //Något felaktigt i formuläret, visa alla felmeddelanden, stäng ej modal
      this.newExaminationTypeForm.markAllAsTouched();
      return;
    }
    let undersokningsvariant: RegEchoVariantDto = {
      variantName: this.newExaminationTypeForm.get("variantName")?.value ?? "",
      variantDescription: this.newExaminationTypeForm.get("variantDescription")?.value ?? "",
      validFrom: this.format(this.newExaminationTypeForm.get("validFromDatum")?.value) ?? null,
      kompetenskrav: 0, //TODO: Hårdkodat
      validTo: this.format(this.newExaminationTypeForm.get("validToDatum")?.value) ?? null,
      valdaVarJson: '',
      active: true
    }
    this.submitted = true;
    if (this.newExaminationTypeForm.valid) {
      this.dataService.saveNyUndersokningsvariant(undersokningsvariant).subscribe({
        next: (result: RegEchoVariantDto) => {
          console.log(result);
          this.savedVariant.emit(result);
          this.modalClosed.emit();
          this.modalService.dismissAll();
        },
        error: (errorResponse: any) => {
        }
      });
    }
  }
  get registerFormControl() {
    return this.newExaminationTypeForm.controls;
  }
  private format(date: NgbDateStruct): Date {
    return date ? new Date(Date.UTC(date.year, date.month - 1, date.day)) : null
  }
}
