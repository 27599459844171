import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataPatient } from '@model/data-patient';
import { PatientRemiss } from '@model/data-patientremiss';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
//import { MatExpansionModule } from '@angular/material/expansion';
//import { Sort } from '@angular/material/sort';
import { ChangeDetectorRef } from '@angular/core';
import { DynamicSortService } from '@core/services/dynamic-sort.service';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { SelectListItem } from '@model/select-list-item';
import { ActivatedRoute } from '@angular/router';
import { DataPatientRemissUndersokning } from '@model/data-patientremissundersokning';
import { Remiss } from '@model/data-remiss';
import { OnDestroy } from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'patient-remittance-list',
  templateUrl: './patient-remittance-list.component.html',
  styleUrls: ['./patient-remittance-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PatientRemittanceListComponent implements OnInit, OnDestroy {

  /*Ta bort om vi inte ska ha exemplet med expanderbar tabell*/
  columnsToDisplay = ['pnr'];
  expandedElement: DataPatient | null;
  status: [];
  sortedData: PatientRemiss[];

  //sortedDataOriginal: PatientRemiss[];

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private dynamicSort: DynamicSortService
  ) { }

  public faCaretDown = faCaretDown;
  public faCaretUp = faCaretUp;
  public dataRemissWithId: Remiss;
  public dataPatientWithId: DataPatient;
  public dataPatient = new BehaviorSubject<DataPatientRemissUndersokning[]>(null);
  remisser: PatientRemiss[];

  //public dataRemissWithId: Remiss;
  //public dataPatientWithId: DataPatient;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let patientId = params.get('patientId');

      this.getData(patientId);
    });
    this.initVisibleTableColumns();
  }
  ngOnDestroy() {
    this.dataPatient.unsubscribe();
  }

  private getData(patientId) {
    this.http.get('/assets/plan-examination/patienter.json')
      .subscribe((data: DataPatient[]) => {
        this.dataPatientWithId = data.filter(item => item.patientId == patientId)[0];
        this.sortedData = data.filter(item => item.patientId == patientId).map(patient => {
          return patient.remisser.map(remiss => {
            let patientremiss = new PatientRemiss();
            patientremiss.pnr = patient.pnr;
            patientremiss.patientId = patient.patientId
            patientremiss.remissId = remiss.id;
            patientremiss.remisstyp = remiss.remisstyp;
            patientremiss.prioritering = remiss.prioritering;
            patientremiss.remissdatum = remiss.remissdatum;
            patientremiss.remissnr = remiss.remissnr;
            patientremiss.remitent = remiss.remitent;
            patientremiss.undersokningstypId = remiss.undersokningstypId;
            patientremiss.planerad = remiss.planerad;
            patientremiss.komplettera = remiss.komplettera;
            patientremiss.aterremitterad = remiss.aterremitterad;
            patientremiss.vantarKomplettering = remiss.vantarKomplettering;
            patientremiss.hasMultipleRemisses = patient.remisser.filter(remiss => !remiss.planerad).length > 1;
            patientremiss.status = this.getStatus(patientremiss.komplettera, patientremiss.aterremitterad, patientremiss.hasMultipleRemisses, patientremiss.planerad, patientremiss.vantarKomplettering);
            patientremiss.mobilisering = patient.mobilisering;
            return patientremiss;
          });
        }).flat();

        this.setOrderBy(this.orderBy, true); //Sort by default

        //patientremisser.forEach(array => {
        //  array.forEach(item => {
        //    this.sortedData.push(item);
        //  })
        //});
        //this.sortedDataOriginal = this.sortedData;

        this.detector.markForCheck();
      });
  }

  public getStatus(komplettera, aterremitterad, hasMultipleRemisses, planerad, vantarKomplettering): number {
    if (komplettera)
      return 1;
    else if (aterremitterad)
      return 2;
    else if (hasMultipleRemisses)
      return 3;
    else if (planerad)
      return 4;
    else if (vantarKomplettering)
      return 5;
    else
      return 0;
  }
  /* Add new remiss*/
  public addRemissModalIsOpen = new BehaviorSubject<boolean>(false);

  public openAddRemissModal() {
    this.addRemissModalIsOpen.next(true);
  }
  public addRemissModalClosed() {
    this.addRemissModalIsOpen.next(false);
  }

  /* Sorting */
  private defaultOrderColumn = 'pnr';
  public orderBy: string = this.defaultOrderColumn;
  public sortAscending: boolean = true;
  public setOrderBy(column: string, reload: boolean = false) {
    if (!reload)
      this.sortAscending = this.orderBy != column ? true : !this.sortAscending;
    this.orderBy = column;
    this.sortedData.sort(this.dynamicSort.sort(column, this.sortAscending));
  }

  /* Show/Hide table columns */
  public possibleTableColumns: SelectListItem[] = [];
  public visibleTableColumnsFC = new FormControl();
  private initVisibleTableColumns() {
    this.possibleTableColumns = [
      new SelectListItem('Källa', 'remisstyp'),
      new SelectListItem('Remissdatum', 'remissdatum'),
      new SelectListItem('Remissnr (Klinfys)', 'remissnr'),
      new SelectListItem('Remiterande enhet', 'remitent'),
      new SelectListItem('Mobilisering', 'mobilisering'),
      new SelectListItem('Prioritering', 'prioritering'),
      new SelectListItem('Status', 'status')
    ];
    this.visibleTableColumnsFC.setValue(['remissdatum', 'remissnr', 'remitent', 'prioritering', 'status']);
    this.detector.markForCheck();
  }
  public columnIsVisible(column: string): boolean {
    let visibleColumns: string[] = this.visibleTableColumnsFC.value;
    return visibleColumns.indexOf(column) > -1;
  }

  /* Show remiss modal*/
  public showRemissModalIsOpen = new BehaviorSubject<boolean>(false);

  public openShowRemissModal(dataRemissWithId) {
    this.showRemissModalIsOpen.next(dataRemissWithId);
  }
  public showRemissModalClosed(reason: string) {
    this.showRemissModalIsOpen.next(null);
  }
}
