import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EditEchoVariablesComponent } from '../../modules/settings/pages/editechovariables/editechovariables.component';
import { AuthGuard } from './../../_helpers/auth.guards';
import { ExaminationtypesComponent } from '../../modules/settings/pages/examinationstypes/examinationstypes.component';
import { ManageExaminationVariantsComponent } from './pages/manage-examination-variants/manage-examination-variants.component';
import { ManageTableComponent } from '../../modules/settings/pages/manage-table/manage-table.component';
import { DeleteEchoVariableModalComponent } from '../../modules/settings/components/delete-echovariable-modal/delete-echovariable-modal.component';
import { CalculationComponent } from '../../modules/settings/pages/calculation/calculation.component';

export const routes: Routes = [
  { path: 'examinationstypes', component: ExaminationtypesComponent, canActivate: [AuthGuard] },
  { path: 'examination-variants', component: ManageExaminationVariantsComponent, canActivate: [AuthGuard] },
  { path: 'editechovariables', component: EditEchoVariablesComponent, canActivate: [AuthGuard] },
  { path: 'manage-table', component: ManageTableComponent, canActivate: [AuthGuard] },
  { path: 'calculation', component: CalculationComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
