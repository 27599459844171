import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { startWith, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
/*import { PatientLakemedel } from '@model/data-patientlakemedel';*/
import { SelectListItem } from '@model/select-list-item';
import { PlaneratPatientLakemedel } from '@model/patient-planerat-lakemedel';
import { dateFormat } from 'highcharts';
import { RemissLakemedel } from '@model/remisslakemedel';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-lakemedel-modal',
  templateUrl: './add-lakemedel-modal.component.html',
  styleUrls: ['./add-lakemedel-modal.component.scss']
})
export class AddLakemedelModalComponent implements OnChanges {

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private http: HttpClient,
    private detector: ChangeDetectorRef
  ) { }

  @Input() editPatientLakemedel!: RemissLakemedel;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();
  
  @Output() onSubmit = new EventEmitter<FormGroup>();
  @ViewChild("content") modalHtml;
  @Input() newLakemedelToEdit: RemissLakemedel;

  public loading = new BehaviorSubject<boolean>(false);
  public saveAddLakemedelForm: FormGroup;
  public currentTime = new Date();
  submitted = false;

  //lakemedelList: Lakemedel[] = [new Lakemedel('Xylocain', 'https://www.fass.se/LIF/product?userType=2&nplId=19491123000011', [new LakemedelStyrka('10mg/ml'), new LakemedelStyrka('9mg/ml')], [new Lakemedeltyp('spray'), new Lakemedeltyp('injektion')])];


  lakemedelLista: string[] = ['Xylocain', 'Midazolam', 'Sonovue', 'Gelofusin (agiterad)', 'Nacl (agiterad)', 'Regadenoson'];
  lakemedelStyrkaLista: string[] = ['10mg/ml', '1mg/ml', '40mg/ml', '9mg/ml', '400ⴗg/5ml'];
  filteredLakemedel: Observable<string[]>;
  filteredStyrka: Observable<string[]>;
  public formItems: PlaneratPatientLakemedel[] = [];
  id = new FormControl();
  lakemedel = new FormControl();
  lakemedelTyp = new FormControl();
  lakemedelStyrka = new FormControl();
  lakemedelEnhet = new FormControl();
  lakemedelOrdination = new FormControl();
  lakemedelKommentar = new FormControl();
  lakemedelGivet = new FormControl();
  lakemedelTidGivet = new FormControl();
  lakemedelSignerat = new FormControl();
  lakemedelTidSignerat = new FormControl();
  lakemedelForeslaget = new FormControl();
  lakemedelOrdinerat = new FormControl();

  lakemedelEnhetLista: SelectListItem[] = [new SelectListItem('dos po', '1'), new SelectListItem('ml iv', '2')];


  ngOnChanges(): void {
    if (this.modalIsOpen) {
      this.openModal();
      this.setForm();

      this.saveAddLakemedelForm.get("lakemedel").valueChanges.subscribe(value => {
        this.lakemedelLista.push(value);
      });

      this.saveAddLakemedelForm.get("lakemedelStyrka").valueChanges.subscribe(value => {
        this.lakemedelStyrkaLista.push(value);
      });
    }
  }

  /* Modal */
  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'add-lakemedel-modal', size: 'lg' })
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }

  public closeModal() {
    this.modalService.dismissAll(); 
  }

  private setForm() {
    let timestampGivet = dateFormat('%H:%M', Date.now());
    let timestampSignerat = dateFormat('%H:%M', Date.now());
    let timestampOrdination = dateFormat('%H:%M', Date.now());

    this.saveAddLakemedelForm = this.fb.group({
      id: [Math.floor(Math.random() * 10000)],
      lakemedel: [''],
      lakemedelStyrka: [''],
      lakemedelMangd: [''],
      lakemedelEnhet: [''],
      lakemedelKommentar: [''],
      lakemedelTidOrdination: [timestampOrdination],
      lakemedelStatus: [''],
      lakemedelTidGivet: [timestampGivet],
      lakemedelSignerat: ['Johan'],
      lakemedelTidSignerat: [timestampSignerat]
    });
    this.initAutocompleteSelect();
  }
  private initAutocompleteSelect() {
    this.filteredLakemedel = this.lakemedel.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.lakemedel.setValue(''); //Trigger filtrering av läkemedel.
        return this.filterLakemedel(value);
      })
    );
    this.filteredStyrka = this.lakemedelStyrka.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.lakemedelStyrka.setValue(''); //Trigger filtrering av läkemedel.
        return this.filterStyrka(value);
      })
    );
    this.detector.markForCheck();
  }

  private normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  private filterLakemedel(text: string = ''): string[] {
    const filterValue = this.normalizeValue(text);
    return this.lakemedelLista.filter(lakemedel => this.normalizeValue(lakemedel).includes(filterValue));
  }

  private filterStyrka(text: string = ''): string[] {
    const filterValue = this.normalizeValue(text);
    return this.lakemedelStyrkaLista.filter(lakemedelStyrka => this.normalizeValue(lakemedelStyrka).includes(filterValue));
  }

  public submitForm() {
    //TODO: Save
    console.log(this.saveAddLakemedelForm.value);
    this.submitted = true;
    /* Om inget val är gjort av dessa checkboxar - Sätt föreslaget */
    //if (this.saveAddLakemedelForm.get('lakemedelOrdinerat').value == false
    //  && this.saveAddLakemedelForm.get('lakemedelForeslaget').value == false
    //  && this.saveAddLakemedelForm.get('lakemedelGivet').value == false) {
    //  this.saveAddLakemedelForm.get('lakemedelForeslaget').setValue('true');
    //}
    if (this.saveAddLakemedelForm.valid) {
      this.onSubmit.emit(this.saveAddLakemedelForm);
      this.closeModal();
      this.modalService.dismissAll(); //Sålänge - stäng modal
    }
  }
  get registerFormControl() {
    return this.saveAddLakemedelForm.controls;
  }
}
