import { ChangeDetectionStrategy, Component } from '@angular/core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-drawing-page',
  templateUrl: './drawing-page.component.html',
  styleUrls: ['./drawing-page.component.scss']
})
export class DrawingPageComponent {

  public images: string[] = ['resurs-1','resurs-2','resurs-3','resurs-4','resurs-5','resurs-6','resurs-7','resurs-8','resurs-9'];

  public faPencil = faPencilAlt;

  public onEditImageNotExists(event, image) {
    event.target.src = '/assets/img/' + image + '.png';
  }

  /** EDIT Images **/
  public imageToEdit: string;
  public modalIsOpen: boolean = false;

  public editImage(imageName: string) {
    this.imageToEdit = imageName;
    this.modalIsOpen = true;
  }

  public modalClosed() {
    this.imageToEdit = null;
    this.modalIsOpen = false;
  }
  
}
