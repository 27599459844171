import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataPatient } from '@model/data-patient';
import { PatientRemiss } from '@model/data-patientremiss';
import { RemissService } from '../../../core/services/remiss.service';
import { DataPatientRemissUndersokning } from '../../../model/data-patientremissundersokning';
import { map } from 'rxjs/operators';
import { query } from '@angular/animations';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { Observable } from 'rxjs/internal/Observable';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  constructor(
    private dataService: RemissService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private http: HttpClient
  ) {
  }

  public patientId: any;
  public remissId: any;
  public undersokningstypId: any;
  //public dataPatienter = new BehaviorSubject<DataPatientRemissUndersokning>(null);
  public filteredPatienter: DataPatientRemissUndersokning;
  public url: string;

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      this.patientId = params.get('patientId');
      
      this.remissId = params.get('remissId');
      this.undersokningstypId = params.get('undersokningstypId');
    });
    this.url = this.route.snapshot.url[0].path.toString();

    this.getRemisser(this.patientId);
  }

  private getRemisser(patientId) {
    console.log(patientId);
   //TODO: Hämta remissid:n för patienten
  }
}
