<style>
  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .mat-expansion-panel-header {
    overflow: visible !important;
    z-index: 5000 !important;
  }

  .mat-tab-label-active .mat-tab-labels {
    font-weight: bold;
    color: #000 !important;
  }

  .mat-form-field {
    line-height: 3.125;
  }
</style>


<div class="container-fluid">
  <div class="col-12 mt-2">
    <ph-alert-message [messageItem]="alertMessage|async" [isSelfClosing]="true"></ph-alert-message>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <h1>Arbetslista</h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-2">
      <button class="btn btn-primary" (click)="openAddRemissModal(true)">Lägg till remiss</button>
    </div>
    <!--<div class="col-6 text-right">
    <button class="btn btn-link"><i class="fas fa-history"></i> Visa gamla remisser</button>
  </div>-->

    <div class="col-md-2 offset-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="search">Sök</span>
        </div>
        <input type="text" class="form-control" placeholder="Sök" aria-label="Sök" aria-describedby="search">
      </div>
    </div>
  </div>
  
  <div class="row">
    <!--<div class="col-2 form-group">
      <label class="form-label">Tilldelad:</label>
      <select class="form-control">
        <option>Visa alla</option>
      </select>
    </div>-->
    
    <!--<div class="col-1 form-group" *ngIf="priorityList$ | async as priorityList">
    <label class="form-label">Prioritering:</label>
    <select class="form-control" (change)="onSelected($any($event.target).value)">
      <option value="0">-Visa alla-</option>
      <option *ngFor="let prio of priorityList" [value]="prio.id">{{prio.description}}</option>
    </select>
  </div>-->
    <!--<div class="col-1 form-group" *ngIf="remDoctorList$ | async as remDocList">
      <label class="form-label">Remitentläkare:</label>
      <select class="form-control" (change)="onSelectedRemDoc($any($event.target).value)">
        <option value="0">-Visa alla-</option>
        <option *ngFor="let doc of remDocList" [value]="doc.id">{{doc.remittanceDoctor}}</option>
      </select>
    </div>-->
    
    
    
    <!--<div class="col-2 form-group">
      <label class="form-label">Status:</label>
      <select class="form-control">
        <option>Visa alla</option>
      </select>
    </div>
    <div class="col-2 form-group">
      <label class="form-label">Typ:</label>
      <select class="form-control">
        <option>Inkommande</option>
        <option>Pågående</option>
        <option>Avslutade</option>
      </select>
    </div>-->
    </div>
    <!--<button class="btn btn-link"><i class="fa fa-filter"></i> Filtrera</button>-->
  <div class="row"> 
    <!--<mat-tab-group class="w-100">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon"></mat-icon>
          Oplanerade
        </ng-template>-->
    <div class="col-lg-12 pt-5">
      <table class="table table-striped">
        <thead>
          <tr>

            <th>
              <div class="form-group">
                <label class="form-label">Datum:</label>
                <select class="form-control">
                  <option>Visa alla</option>
                </select>
              </div>
            </th>
            <th></th>
            <th>
              <div class="form-group" *ngIf="remClinicList$ | async as remClinicList">
                <label class="form-label">Remitentklinik:</label>
                <select class="form-control" (change)="onSelectedRemClinic($any($event.target).value)">
                  <option value="0">-Visa alla-</option>
                  <option *ngFor="let clinic of remClinicList" [value]="clinic.id">{{clinic.remittanceClinic}}</option>
                </select>
              </div>
            </th>
            <th></th>
            <th>
              <div class="form-group" *ngIf="remittancePriorityList$ | async as prios">
                <label class="form-label">Prioritering:</label>
                <select class="form-control" (change)="onSelectedPrio($any($event.target).value)">
                  <option value="0">-Visa alla-</option>
                  <option *ngFor="let prio of prios" [value]="prio.id">{{prio.description}}</option>
                </select>
              </div>
            </th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="2" class="float-right">
              <button class="btn btn-link" [ngbPopover]="tableColumnsPopover" popoverTitle="Välj kolumner" autoClose="outside" placement="left"><i class="fas fa-cog"></i> Vad vill du se i tabellen?</button>
              <ng-template #tableColumnsPopover>
                <form>
                  <mat-form-field appearance="outline">
                    <mat-label>Välj kolumner</mat-label>
                    <mat-select [formControl]="visibleTableColumnsFC" multiple>
                      <mat-option *ngFor="let column of possibleTableColumns" [value]="column.value">{{column.text}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </form>
              </ng-template>
            </th>
          </tr>
          </thead>
        <thead>
          <tr>
            <th *ngIf="columnIsVisible('remisstyp')" class="clickable" (click)="setOrderBy('remisstyp')">
              Källa
              <span class="th-caret" *ngIf="orderBy == 'remisstyp'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('remissdatum')" class="clickable" (click)="setOrderBy('remissdatum')">
              Inkom
              <span class="th-caret" *ngIf="orderBy == 'remissdatum'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('pnr')" class="clickable" (click)="setOrderBy('pnr')">
              PNR
              <span class="th-caret" *ngIf="orderBy == 'pnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <!--<th *ngIf="columnIsVisible('remissnr')" class="clickable" (click)="setOrderBy('remissnr')">
              Remissnr (klinfys)
              <span class="th-caret" *ngIf="orderBy == 'remissnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>-->
            <th *ngIf="columnIsVisible('remitent')" class="clickable" (click)="setOrderBy('remitent')">
              Remiterande enhet
              <span class="th-caret" *ngIf="orderBy == 'remitent'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('mobilisering')" class="clickable" (click)="setOrderBy('mobilisering')">
              Särskilda behov
              <span class="th-caret" *ngIf="orderBy == 'mobilisering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('prioritering')" class="clickable text-nowrap" (click)="setOrderBy('prioritering')">
              Prioritering <span [ngbTooltip]="'Här står info om prioriteringen'"><i class="fas fa-info-circle"></i></span>
              <span class="th-caret" *ngIf="orderBy == 'prioritering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('status')" class="clickable" (click)="setOrderBy('status')">
              Status
              <span class="th-caret" *ngIf="orderBy == 'status'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th>
              Tilldelad till
            </th>
            <th>
              Typ
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!(remittanceList$|async)">
            <th class="text-center" colspan="100">
              <ng-container>
                <ph-loading-spinner></ph-loading-spinner>
              </ng-container>
            </th>
          </tr>
          <ng-container *ngIf="remittanceList$ | async as remittanceList">
            <tr *ngFor="let remiss of remittanceList; index as i">
              <ng-container *ngIf="!remiss.planerad">
                <td *ngIf="columnIsVisible('remisstyp')">{{remiss.remisstyp}}</td>
                <td *ngIf="columnIsVisible('remissdatum')">{{remiss.timestamp}}</td>
                <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{remiss.personalIdentityNumber}}</td>
                <!--<td *ngIf="columnIsVisible('remissnr')"><a [routerLink]="['/plan-examination', remiss.id, remiss.patientId]">{{remiss.id}}</a></td>-->
                <td *ngIf="columnIsVisible('remitent')">{{remiss.remittanceClinicDesc}}</td>
                <td *ngIf="columnIsVisible('mobilisering')"><ng-container *ngFor="let mobList of remiss.mobilizationList">{{mobList.description}}<br></ng-container></td>
                <td *ngIf="columnIsVisible('prioritering')">{{remiss.prioDescription}}</td>
                <td *ngIf="columnIsVisible('status')">
                  <!--<span *ngIf="remiss.komplettera"><a href="#"><i class="fas fa-exclamation"></i> Begär komplettering</a></span>
  <span *ngIf="remiss.aterremitterad"><i class="fas fa-exclamation"></i> Återremiterad</span>
  <span *ngIf="remiss.remisstyp == 'PDF'"><a href="#"><i class="fas fa-exclamation"></i> Komplettera</a></span>
  <span *ngIf="remiss.vantarKomplettering"><i class="fas fa-exclamation"></i> Väntar på komplettering</span>-->
                  {{remiss.statusComment}}
                </td>
                <td>
                </td>
                <td>
                </td>
                <td>
                  <a href="#">
                    <i class="far fa-star"></i>
                  </a>
                </td>
                <td>
                  <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                    <button class="btn btn-primary btn-sm" (click)="openShowRemissInfoModal(remiss)">Bedöm</button>
                    <!--<button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <a mat-menu-item *ngIf="remiss.remisstyp != 'PDF'" [routerLink]="['/plan-examination', remiss.id, remiss.patientId]">Planera undersökning</a>
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.id, '1', remiss.patientId]">Starta undersökning</a>-->
                    <!--TODO: Ändra hårdkodat undersökningstyp-->
                    <!--<a mat-menu-item class="border-top" (click)="openShowRemissInfoModal(remiss)">Visa remiss</a>-->
                    <!--<a mat-menu-item class="border-top" (click)="openEditRemissModal(remiss.id)">Redigera remiss</a>-->
                    <!--<a mat-menu-item *ngIf="remiss.remisstyp == 'PDF'"><b>Komplettera remiss</b></a>
                    <a mat-menu-item *ngIf="remiss.komplettera"><b>Begär komplettering</b></a>-->
                    <!--<a mat-menu-item>Visa historik</a>
                      <a mat-menu-item class="border-top" [routerLink]="['/overview-patient-page', remiss.patientId]">Visa patient</a>
                      <a mat-menu-item>Visa journal</a>
                    </mat-menu>-->
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <!--</mat-tab>-->
    <!--<mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="example-tab-icon"></mat-icon>
        Planerade
      </ng-template>
    </mat-tab>-->
    <!--<mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon"></mat-icon>
          Klara
        </ng-template>
      </mat-tab>
    </mat-tab-group>-->
  </div>
</div>
<!--(openEditRemissModal)="openEditRemissModal($event)"-->
<!--[valdRemissId]="valdRemissId"-->
<add-remiss-modal [modalIsOpen]="addRemissModalIsOpen | async"
                  (modalClosed)="addRemissModalClosed($event)"
                  [patientRemiss]="valdRemiss"
                  [newPatient]="isNewPatient"></add-remiss-modal>

<show-remiss-modal [modalIsOpen]="showRemissInfoModalIsOpen"
                   [patientRemiss]="valdRemiss"
                   (remissEdit)="remissEditClicked($event)"
                   (openRemissModal)="openShowRemissInfoModal($event)"
                   (modalClosed)="showRemissModalClosed()"></show-remiss-modal>



<!--<style>
  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .mat-expansion-panel-header {
    overflow: visible !important;
    z-index: 5000 !important;
  }
  .mat-tab-label-active .mat-tab-labels {
    font-weight: bold;
    color: #000 !important;
  }
</style>


<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-12">
      <h1>Hantera remisser</h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-6">
      <button class="btn btn-primary" (click)="openAddRemissModal()">Lägg till remiss</button>
    </div>
    <div class="col-6 text-right">
      <button class="btn btn-link"><i class="fas fa-history"></i> Visa gamla remisser</button>
    </div>
  </div>
  <div class="row mt-1">
      <div class="col-md-3">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="search">Sök</span>
        </div>
        <input type="text" class="form-control" placeholder="Sök" aria-label="Sök" aria-describedby="search">
      </div>
    </div>
    <div class="col-md-9 text-right">
      <button class="btn btn-link" [ngbPopover]="tableColumnsPopover" popoverTitle="Välj kolumner" autoClose="outside" placement="left"><i class="fas fa-cog"></i> Vad vill du se i tabellen?</button>
      <ng-template #tableColumnsPopover>
        <form>
          <mat-form-field appearance="outline">
            <mat-label>Välj kolumner</mat-label>
            <mat-select [formControl]="visibleTableColumnsFC" multiple>
              <mat-option *ngFor="let column of possibleTableColumns" [value]="column.value">{{column.text}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </ng-template>
    </div>
  </div>
  <div class="row">
    <mat-tab-group class="w-100">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon"></mat-icon>
          Oplanerade
        </ng-template>
        <div class="col-lg-12 pt-5">
          <table class="table">
            <tr>
              <th *ngIf="columnIsVisible('remisstyp')" class="clickable" (click)="setOrderBy('remisstyp')">
                Källa
                <span class="th-caret" *ngIf="orderBy == 'remisstyp'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissdatum')" class="clickable" (click)="setOrderBy('remissdatum')">
                Remissdatum
                <span class="th-caret" *ngIf="orderBy == 'remissdatum'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('pnr')" class="clickable" (click)="setOrderBy('pnr')">
                PNR
                <span class="th-caret" *ngIf="orderBy == 'pnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissnr')" class="clickable" (click)="setOrderBy('remissnr')">
                Remissnr (klinfys)
                <span class="th-caret" *ngIf="orderBy == 'remissnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remitent')" class="clickable" (click)="setOrderBy('remitent')">
                Remiterande enhet
                <span class="th-caret" *ngIf="orderBy == 'remitent'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('mobilisering')" class="clickable" (click)="setOrderBy('mobilisering')">
                Mobilisering
                <span class="th-caret" *ngIf="orderBy == 'mobilisering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('prioritering')" class="clickable text-nowrap" (click)="setOrderBy('prioritering')">
                Prioritering <span [ngbTooltip]="'Här står info om prioriteringen'"><i class="fas fa-info-circle"></i></span>
                <span class="th-caret" *ngIf="orderBy == 'prioritering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('status')" class="clickable" (click)="setOrderBy('status')">
                Status
                <span class="th-caret" *ngIf="orderBy == 'status'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr *ngFor="let remiss of sortedData; index as i">
              <ng-container *ngIf="!remiss.planerad">
                <td *ngIf="columnIsVisible('remisstyp')">{{remiss.remisstyp}}</td>
                <td *ngIf="columnIsVisible('remissdatum')">{{remiss.remissdatum}}</td>
                <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{remiss.pnr}} <span [ngbTooltip]="'Det finns flera remisser på patienten!'"><i *ngIf="remiss.hasMultipleRemisses" class="fas fa-exclamation-triangle"></i></span></td>
                <td *ngIf="columnIsVisible('remissnr')"><a [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">{{remiss.remissnr}}</a></td>
                <td *ngIf="columnIsVisible('remitent')">{{remiss.remitent}}</td>
                <td *ngIf="columnIsVisible('mobilisering')">{{remiss.mobilisering}}</td>
                <td *ngIf="columnIsVisible('prioritering')">{{remiss.prioritering}}</td>
                <td *ngIf="columnIsVisible('status')">
                  <span *ngIf="remiss.komplettera"><a href="#"><i class="fas fa-exclamation"></i> Begär komplettering</a></span>
                  <span *ngIf="remiss.aterremitterad"><i class="fas fa-exclamation"></i> Återremiterad</span>
                  <span *ngIf="remiss.remisstyp == 'PDF'"><a href="#"><i class="fas fa-exclamation"></i> Komplettera</a></span>
                  <span *ngIf="remiss.vantarKomplettering"><i class="fas fa-exclamation"></i> Väntar på komplettering</span>
                </td>
                <td>
                  <a href="#">
                    <i class="far fa-star"></i>
                  </a>
                </td>
                <td>
                  <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <a mat-menu-item *ngIf="remiss.remisstyp != 'PDF'" [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">Planera undersökning</a>
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, remiss.patientId]">Starta undersökning</a>
                      <a mat-menu-item class="border-top" (click)="openShowRemissModal(sortedData.remissId)">Visa remiss</a>
                      <a mat-menu-item *ngIf="remiss.remisstyp == 'PDF'"><b>Komplettera remiss</b></a>
                      <a mat-menu-item *ngIf="remiss.komplettera"><b>Begär komplettering</b></a>
                      <a mat-menu-item>Visa historik</a>
                      <a mat-menu-item class="border-top" [routerLink]="['/overview-patient-page', remiss.patientId]">Visa patient</a>
                      <a mat-menu-item>Visa journal</a>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
            </tr>
          </table>

        </div>

      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon"></mat-icon>
          Planerade
        </ng-template>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="example-tab-icon"></mat-icon>
          Klara
        </ng-template>

      </mat-tab>
    </mat-tab-group>

  </div>
</div>
<add-remiss-modal [modalIsOpen]="addRemissModalIsOpen | async"
                  (modalClosed)="addRemissModalClosed()"></add-remiss-modal>

<show-remiss-modal [modalIsOpen]="showRemissModalIsOpen | async"
                   [patientRemiss]="sortedData"
                   [patientData]="sortedData"
                   (modalClosed)="showRemissModalClosed()"></show-remiss-modal>-->
