import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { combineLatest, EMPTY, Observable, throwError } from 'rxjs';
import { InfMobilization, SysInfGender, SysInfStaffRoles, SysInfLanguage, SysInfPriority, SysInfRemittanceDoctor, SysInfMedicalExaminationType, SysInfRemittanceClinic } from '@model/remiss/data-remiss';
//import { Remittance } from '../model-generated/remisser/remittance';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { SaveRemittanceDto } from '@model/remisser/save-remittance-dto';
import { tap, catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class RemissService {
  remittanceList$ = this.http.get<RemittanceDto[]>(this.baseUrl + 'api/remittance')
    .pipe(tap(data => console.log("Remisser ", JSON.stringify(data))));

  remDoctorList$ = this.http.get<SysInfRemittanceDoctor[]>(this.baseUrl + 'api/remittance/remittanceDoctor')
    .pipe(tap(data => console.log("Remitentläkare ", JSON.stringify(data))));

  remClinicList$ = this.http.get<SysInfRemittanceClinic[]>(this.baseUrl + 'api/remittance/remittanceClinic')
  .pipe(tap(data => console.log("Remissklinik ", JSON.stringify(data))));

  remMobilizationList$ = this.http.get<InfMobilization[]>(this.baseUrl + 'api/remittance/mobilization')
    .pipe(tap(data => console.log("Mobilisering ", JSON.stringify(data))));

  genderList$ = this.http.get<SysInfGender[]>(this.baseUrl + 'api/remittance/gender')
    .pipe(tap(data => console.log("Kön ", JSON.stringify(data))));

  staffRolesList$ = this.http.get<SysInfStaffRoles[]>(this.baseUrl + 'api/staffRoles')
    .pipe(tap(data => console.log("Personalroll ", JSON.stringify(data))));

  languagesList$ = this.http.get<SysInfLanguage[]>(this.baseUrl + 'api/remittance/languages')
    .pipe(tap(data => console.log("Personalroll ", JSON.stringify(data))));

  undersokningstyperList$ = this.http.get<SysInfMedicalExaminationType[]>(this.baseUrl + 'api/remittance/examinationtypes')
    .pipe(tap(data => console.log("Undersökningstyp ", JSON.stringify(data))));

  remittancePriorityList$ = this.http.get<SysInfPriority[]>(this.baseUrl + 'api/remittance/prio')
    .pipe(tap(data => console.log("Prioritering ", JSON.stringify(data))));
  //remittanceDoctorList$ = combineLatest([
  //  this.remittanceList$,
  //  this.remDoctorList$
  //]).pipe(
  //  map(([remittance, ]) =>
  //    remittance.map(rem => ({
  //      ...rem
  //    } as ViewRemittance))
  //  )
  //);
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public getRemittance(id: number): Observable<RemittanceDto> {
    if (id == null)
      return EMPTY;

    return this.http.get<RemittanceDto>(this.baseUrl + `api/remittance/${id}`);
  }
  public saveRemittances(remiss: SaveRemittanceDto): Observable<any> {
    if (remiss.id >= 0) 
      return this.http.put<SaveRemittanceDto>(this.baseUrl + `api/remittance/${remiss.id}`, remiss);
    else
      return this.http.post<SaveRemittanceDto>(this.baseUrl + 'api/remittance', remiss);
  }
}
