import { ChangeDetectionStrategy, Component, Injectable, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs/internal/Observable';
import { PlannedExaminationDto } from '../../../../core/services/undersokning.service';
import { VariablesService } from '../../../../core/services/variabler.service';
import { ViewEchoVariableDto } from '../../../../model/undersokningsvarianter/view-echo-variable-dto';
import { SysEchoGroupDto } from '../../../../model/undersokningsvarianter/sys-echo-group-dto';
import { Subscription, groupBy, mergeMap, of, tap, toArray } from 'rxjs';
import { Typselectlist, VariabelGrupper } from '../../echo-testbadd/echo-testbadd.component';
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'echo-edit-kavldata-modal-modal',
  templateUrl: './echo-edit-kavldata-modal.component.html',
  styleUrls: ['./echo-edit-kavldata-modal.component.scss']
})
export class EchoEditKavldataModalModalComponent implements OnInit {
  faInfoCircle = faInfoCircle;
  $subscriptions: Subscription[] = [];
  variables: ViewEchoVariableDto[] = [];
  typeSelectLists: Typselectlist[] = [];
  constructor(
    private modalRef: NgbActiveModal,
    private variableService: VariablesService
  ) { }

  private validatorNotZero = [
    Validators.required,
    Validators.pattern(/^(?![0]\d*)/)
  ];
  private _editForm: FormGroup = new FormGroup({
});
  get editForm(): FormGroup { return this._editForm; }

  //////////
  ///
  private fieldIsInvalid(field: string) {
    return this.editForm.controls[field].invalid && this.editForm.controls[field].touched;
  }
  //////////
  ///
  private fieldHasError(field: string) {
    return this.editForm.controls[field].errors;
  }
  //////////
  ///
  ngOnInit(): void {
    this.setForm();
  }
  //////////
  ///
  ngOnDestroy(): void {
    this.$subscriptions.forEach(_ => _.unsubscribe());
  }
  //////////
  ///
  private setForm() {
  }
  //////////
  ///
  public onSubmit() {
    //TODO: Filtrera ut variabler som är ändrade ch skicka bara dom tillbaka?
    this.modalRef.close(this.variables);
  }

  //////////
  ///
  onCancel(): void {
    this.modalRef.dismiss();
  }
  tabToNextVariable(event: any) {
    console.log("Öppna nästa index grupp" + event.gindex + 1 + "index" + event.vindex);
    //Kolla så att det inte är sista valda variabeln i gruppen för då ska man gå vidare och öppna första variabeln i nästa grupp.
    //if ((event.vindex + 1) == this.grupper[event.gindex].variables.filter(_ => _.selected == true).length && (event.gindex + 1) != this.grupper.length) {
    //  this.grupper[event.gindex + 1].variables.filter((_, index) => _.selected == true)[0].showEdit = true;
    //}
    //else {
    //this.grupper[event.gindex + 1].variables[event.vindex + 1].showEdit = true;
    //}
    //Stäng dom icke aktuella variablerna
    this.variables.find(_ => _.id != event.id).showEdit = false;
    //Öppna nästa
    this.variables[event.vindex + 1].showEdit = true;
  }
  //////////
  ///
  showVariableValuesChanged(event: any, variable: ViewEchoVariableDto) {
    variable = event.variable;
    variable.currentValue = event.value;
    //this.variableChangeInput(variable, event.value);
  }
  //////////
  ///
  variableChangeInput(variable?: ViewEchoVariableDto, value?: any) {
    if (value) {
      variable.currentValue = value;
      //if (variable.impossibleValueMax != null && Number(variable.currentValue) > variable.impossibleValueMax) {
      //  variable.validationError = "Omöjligt maxvärde";
      //  return;
      //}
      //if (variable.impossibleValueMin != null && Number(variable.currentValue) < variable.impossibleValueMin) {
      //  variable.validationError = "Omöjligt minvärde";
      //  return;
      //}
      //group.hasUnSelectedVariablesWithValue = true;
      variable.validationError = "";
    }
    //else {
    //  group.hasUnSelectedVariablesWithValue = false;
    //  return;
    //}
  }

}
@Injectable({
  providedIn: "any"
})
export class EchoEditKavldataModalModalService {
  constructor(private modalService: NgbModal) { }

  public show(variables: ViewEchoVariableDto[]): Observable<ViewEchoVariableDto[]> {
    const modalRef = this.modalService.open(EchoEditKavldataModalModalComponent, { backdrop: "static", size: "xl" });
    modalRef.componentInstance.variables = variables;
    return modalRef.closed;
  }
}


