import { Component, Inject, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { VariablesService } from '@core/services/variabler.service';
import { faSpinner, faDraftingCompass } from '@fortawesome/free-solid-svg-icons';
import { faArrowUp, faSquareRootAlt } from '@fortawesome/free-solid-svg-icons';
import { OnDestroy } from '@angular/core';
import { EchoVariablesDto } from '@model/undersokningsvarianter/echo-variables-dto';
import { Subscription } from 'rxjs';
import { EditVariableModalService } from '../../components/add-echovariable-modal/add-echovariable-modal.component';
import { EditVariableFormulaModalService } from '../../components/formula-echovariable-modal/formula-echovariable-modal.component';
import { DeleteVariableModalService } from '../../components/delete-echovariable-modal/delete-echovariable-modal.component';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'editechovariables',
  templateUrl: './editechovariables.component.html',
  styleUrls: ['./editechovariables.component.scss']
})
export class EditEchoVariablesComponent implements OnInit, OnDestroy {
  public loading = new BehaviorSubject<boolean>(false);

  //Ikoner
  faSpinner = faSpinner;
  faDraftingCompass = faDraftingCompass;
  faSquareRootAlt = faSquareRootAlt;
  faArrowUp = faArrowUp;

  $subscriptions: Subscription[] = [];
  unitList: any = [];
  formatList: any = [];
  valgruppList: any = [];

  //Filter
  formatValue = 0;
  statusValue = 1;
  unitValue = 0;
  valgruppValue = 0;
  filter = "";

  constructor(
    @Inject('BASE_URL') private baseUrl: string,
    private dataService: VariablesService,
    public translate: TranslateService,
    private editVariableModal: EditVariableModalService,
    private editFormulaModal: EditVariableFormulaModalService,
    private deleteVariableModal: DeleteVariableModalService,
    private toastr: ToastrService
  ) { }
  //////////
  ///
  ngOnInit(): void {
    this.getEchoVariables(this.thePager.currentPage);
    this.getUnits();
    this.getFormats();
    this.getValgrupper();
    
  }

  //////////
  ///
  ngOnDestroy(): void {
    this.$subscriptions.forEach(_ => _.unsubscribe());
  }

  thePager = {
    id: "pager",
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    maxSize: 10
  };
  emptyFill: any[] = [];
  showingItems!: number;
  items: any[] = [];

  //////////
  ///
  getEchoVariables(page: number): void {
    this.thePager.currentPage = page;
    this.dataService.getAllaEchoVariablesList(page, this.thePager.itemsPerPage, this.statusValue, this.unitValue, this.formatValue, this.valgruppValue, this.filter)
      .subscribe(([count, records]) => {
        this.thePager.totalItems = count;
        this.items = records;
        this.showingItems = this.items.length;
        this.emptyFill = new Array(20 - this.showingItems);
      });
  }
  //////////
  ///
  private inputChangeTimer: ReturnType<typeof setTimeout> | null = null;
  inputChange(event: string) {
    if (event.length > 0 && event.length < 3)
      return;

    this.filter = event;

    if (this.inputChangeTimer != null)
      clearTimeout(this.inputChangeTimer);

    this.inputChangeTimer = setTimeout(() => {
      this.getEchoVariables(1);
      this.inputChangeTimer = null;
    }, 1000);
    return;
  }
  //////////
  ///
  statusChange(event: any) {
    this.statusValue = event;
    this.getEchoVariables(1);
  }
  //////////
  ///
  changeUnit(event: number) {
    this.unitValue = event;
    this.getEchoVariables(1);
  }
  //////////
  ///
  valgruppChange(event: any) {
    this.valgruppValue = event;
    console.log("Valgrupp" + this.valgruppValue);
    this.getEchoVariables(1);
  }
  ////////
  ///
  formatChange(event: number) {
    this.formatValue = event;
    this.getEchoVariables(1);
  }
  //////////
  ///
  clearFilter() {
    this.statusValue = 1;
    this.unitValue = 0;
    this.filter = "";
    this.getEchoVariables(1);
    //this.statusChange(1);
    //this.changeUnit(0);
    //    this.fetch(1, "");
  }
  //////////
  ///
  public getUnits() {
    this.dataService.getAllVariableUnits().subscribe(_ => this.unitList = _.map(l => { return { value: l.id, text: l.unit } }));
  }
  //////////
  ///
  public getFormats() {
    this.dataService.getAllVariableFormats().subscribe(_ => this.formatList = _.map(l => { return { value: l.id, text: l.format } }));
  }
  //////////
  ///
  public getValgrupper() {
    this.dataService.getInfoSysInfEchoValueType().subscribe(_ => this.valgruppList = _.map(l => { return { value: l.id, text: l.description } }));
  }

  /*Edit Modal*/
  public currentVariable: EchoVariablesDto;
  public currentVariableId: number = 0;
  //////////
  ///
  openEditShowVariable(id: number, variableName: string): void {
    const modalRef = this.editVariableModal.show(id, variableName);
    this.$subscriptions.push(
      modalRef.subscribe(() => {
        this.getEchoVariables(1);
      })
    );
  }
  //////////
  ///
  topFunction() {
    window.scrollTo(0, 0);
  }
  /*Edit Graderingar Modal*/
  openEditFormula(id: number, sysDescription: string, format: string) {
    const modalRef = this.editFormulaModal.show(id, sysDescription, format);
    this.$subscriptions.push(
      modalRef.subscribe({
        next: () => {
          this.toastr.success("Graderingar är sparade.", "", { positionClass: 'toast-top-center' });
          this.getEchoVariables(1);
        }
      })
    );
  }
  //////////
  ///
  public openDeleteVariable(id: number, description: string) {
    const modalRef = this.deleteVariableModal.show(id, description);
    this.$subscriptions.push(
      modalRef.subscribe({
        next: () => {
          this.getEchoVariables(1);
          this.toastr.success("Variabeln är raderad.", "", { positionClass: 'toast-top-center' });
        },
        error: () => { this.toastr.warning("Något gick fel.", "", { positionClass: 'toast-top-center' }); }
      })
    );
  }
  //////////
  ///
  public resetVariable(variable: EchoVariablesDto) {
    variable.active = true;
    this.$subscriptions.push(
      this.dataService.saveVariable(variable).subscribe()
    );
    this.toastr.success("Variabeln har återställts..", "", { positionClass: 'toast-top-center' });
    window.scrollTo(0, 0);

  }
  //////////
  ///
  private pageChangeTimer: ReturnType<typeof setTimeout> | null = null;
  pageChanged(page: number): void {
    this.thePager.currentPage = page;

    if (this.pageChangeTimer != null)
      clearTimeout(this.pageChangeTimer);

    this.pageChangeTimer = setTimeout(() => {
      this.getEchoVariables(page);
      this.pageChangeTimer = null;
    }, 300);
  }
}
