import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Remiss } from '@model/data-remiss';
import { RemissLakemedel } from '@model/remisslakemedel';
import { DataPatientRemissUndersokning } from '@model/data-patientremissundersokning';
import { faAngleDown, faAngleUp, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PlaneratPatientLakemedel } from '@model/patient-planerat-lakemedel';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'patient-lakemedel',
  templateUrl: './patient-lakemedel.component.html',
  styleUrls: ['./patient-lakemedel.component.scss']
})
export class PatientLakemedelComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private detector: ChangeDetectorRef,
  ) { }


  @Output() editLakemedel: any;
  @Output() onSubmit = new EventEmitter<FormGroup>();
  public lakemedelRuta: boolean = false;
  public faChevronUp = faChevronCircleUp;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public dataFromDeviceWithId: DataPatientRemissUndersokning;
  @Input() remiss: Remiss;
  public remissen: Remiss;
  public remissLakemedel: RemissLakemedel[] = [];
  public patientLakemedel = new BehaviorSubject<RemissLakemedel[]>(null);
  public editPatientLakemedel = new BehaviorSubject<PlaneratPatientLakemedel>(null);
  public newLakemedelToEdit = new BehaviorSubject<PlaneratPatientLakemedel>(null);
  public dataRemissWithId: Remiss;
  ngOnInit(): void {
    this.lakemedelRuta = false;
  }

  /* Show läkemedel modal*/
  public showLakemedelModalIsOpen = new BehaviorSubject<boolean>(false);

  public openShowLakemedelModal() {
    this.showLakemedelModalIsOpen.next(true);
  }

  public openEditShowLakemedelModal(lakemedel?: RemissLakemedel) {
    if (lakemedel) {
      /*TODO: Redigera*/
    } else {
      /*TODO: Lägg till nytt*/
    }
    this.showLakemedelModalIsOpen.next(true);
  }

  public showLakemedelModalClosed() {
    this.showLakemedelModalIsOpen.next(false);
  }

  public newLakemedelSubmitted(saveAddLakemedelForm) {
    this.newLakemedelToEdit.next(null);
      this.remissLakemedel.push({
      lakemedel: saveAddLakemedelForm.get('lakemedel').value,
      lakemedelEnhet: saveAddLakemedelForm.get('lakemedelEnhet').value,
      lakemedelKommentar: saveAddLakemedelForm.get('lakemedelKommentar').value,
      lakemedelMangd: saveAddLakemedelForm.get('lakemedelMangd').value,
      lakemedelStatus: saveAddLakemedelForm.get('lakemedelStatus').value,
      lakemedelTidOrdination: saveAddLakemedelForm.get('lakemedelTidOrdination').value,
      lakemedelStyrka: saveAddLakemedelForm.get('lakemedelStyrka').value,
      lakemedelTidGivet: saveAddLakemedelForm.get('lakemedelTidGivet').value,
      lakemedelSignerat: saveAddLakemedelForm.get('lakemedelSignerat').value,
      lakemedelTidSignerat: saveAddLakemedelForm.get('lakemedelTidSignerat').value
    });
    this.newLakemedelToEdit.next(null);
    this.detector.markForCheck();
  }
}
