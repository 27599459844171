import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
//import { DataExaminationTypeList } from '@model/data-examination-type-list';
import { DataExaminationItem } from '@model/data-examination-item';
import { ExampleItemgroups } from '@model/data-exampleItemgroups';
import { ExampleSavedDataExaminationType } from '@model/data-exampleSavedDataExaminationType-type';
import { PlaneratPatientLakemedel } from '@model/patient-planerat-lakemedel';
import { catchError, map, startWith } from 'rxjs/operators';
import { RemissService } from '@core/services/remiss.service';
import { InfMobilization, SysInfPriority } from '@model/remiss/data-remiss';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { PatientService } from '@core/services/patient.service';
import { PatientListDto } from '@model/patienter/patient-list-dto';
import { VariablesService } from '@core/services/variabler.service';
import { RegEchoVariantDto } from '@model/undersokningsvarianter/reg-echo-variant-dto';
import { UndersokningsVariantService } from '@core/services/undersokningsvariant.service';
import { SysEchoGroupDto } from '@model/undersokningsvarianter/sys-echo-group-dto';
import { MatSelectChange } from '@angular/material/select';
import { OnDestroy } from '@angular/core';
import { AlertMessageItem, AlertMessageType } from '../../../../model/alert-message-item';
import { EMPTY } from 'rxjs';
import { VariablesByGroupsDto } from '@model/undersokningsvarianter/variables-by-groups-dto';

//import { MatExpansionModule } from '@angular/material/expansion';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'plan-examination',
  templateUrl: './plan-examination.component.html',
  styleUrls: ['plan-examination.component.scss']
})
export class PlanExaminationComponent implements OnInit, OnDestroy {
  alertMessage: any;

  constructor(
    private route: ActivatedRoute,
    private dataService: RemissService,
    private variantService: UndersokningsVariantService,
    private variableService: VariablesService,
    private patientService: PatientService,
    private fb: FormBuilder,
    public translate: TranslateService,
    private http: HttpClient,
    private detector: ChangeDetectorRef
  ) { }
  //remittancePriorityList$ = this.dataService.remittancePriorityList$.pipe(catchError(err => {
  //  this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
  //  return EMPTY;
  //}));
  public saveAddPatientExaminationForm: FormGroup;
  public variableItems: ExampleItemgroups[] = [];
  /*  public dataPatienter = new BehaviorSubject<DataPatient[]>(null);*/
  public dataExaminationTypes = new BehaviorSubject<ExampleSavedDataExaminationType[]>(null);
  /*Läkemedel*/
  public lakemedelToEdit = new BehaviorSubject<PlaneratPatientLakemedel>(null);
  public newLakemedelToEdit = new BehaviorSubject<PlaneratPatientLakemedel>(null);
  public lakemedel: PlaneratPatientLakemedel[] = [];
  public patientLakemedel = new BehaviorSubject<PlaneratPatientLakemedel[]>(null);

  public examinations: DataExaminationItem[] = [new DataExaminationItem('Ekokardiografi', '1'), new DataExaminationItem('Arbetsprov', '2')];
  public newExamination: DataExaminationItem = new DataExaminationItem('0', '2');
  public examinationOptions: string[] = ['Ekokardiografi', 'Arbetsprov', 'Ergospirometri'];
  variablesByGroup = new BehaviorSubject<VariablesByGroupsDto[]>(null);
  indikationLista: string[] = ['Covid-19', 'Hjärtinfarkt'];
  filteredIndikation: Observable<string[]>;

  public selectedExaminationTab = new FormControl(0);

  public typeSelected: boolean;
  public isEmptyType = false;
  public mobiliseringString: string;
  public dataRemissWithId = new RemittanceDto;
  public dataPatientWithId = new PatientListDto;
  public dataWithId = new BehaviorSubject<RegEchoVariantDto>(null);
  public mobiliseringOptions: Observable<InfMobilization[]>;
  public prioOptions: Observable<SysInfPriority[]>;
  examinationTypesList: Observable<RegEchoVariantDto[]>;
  remMobilizationList$ = this.dataService.remMobilizationList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      let remissid = params.get('remissid');
      this.getData(remissid);
      this.getVariables();
      this.setForm();
      this.saveAddPatientExaminationForm.get("fc_indikation").valueChanges.subscribe(value => {
        this.indikationLista.push(value);
      });
    })

    //this.getEchoTypes();
    this.typeSelected = false;
  }
  ngOnDestroy() {
    this.dataExaminationTypes.unsubscribe();
    this.lakemedelToEdit.unsubscribe();
    this.newLakemedelToEdit.unsubscribe();
    this.patientLakemedel.unsubscribe();
    this.dataExaminationTypes.unsubscribe();
    this.variablesByGroup.unsubscribe();
  }
  private setForm() {
    this.saveAddPatientExaminationForm = new FormGroup({
      examinationType: new FormControl('', Validators.required),
      fc_degreeOfDifficulty: new FormControl('', Validators.required),
      fc_indikation: new FormControl('', Validators.required),
      tee: new FormControl('', Validators.required),
      variables: new FormArray([])
    });
    this.initAutocompleteSelect();
  }
  get variables() { return this.saveAddPatientExaminationForm.get('variables') as FormArray; }
  get examinationType() { return this.saveAddPatientExaminationForm.get('examinationType'); }
  get fc_degreeOfDifficulty() { return this.saveAddPatientExaminationForm.get('fc_degreeOfDifficulty'); }
  get fc_indikation() { return this.saveAddPatientExaminationForm.get('fc_indikation'); }
  get tee() { return this.saveAddPatientExaminationForm.get('tee'); }

  get registerFormControl() {
    return this.saveAddPatientExaminationForm.controls;
  }
  public getData(remissid) {
    this.dataService.getRemittance(remissid).subscribe((data) => {
      this.dataRemissWithId = data;
      console.table(data);
      //this.remittanceInfo.next(data);
    });
    //this.dataService.getRemittance(remissid).subscribe((data) => {
    //  this.dataRemissWithId = data;
    //  this.patientService.getPatient(data.patientId).subscribe(pdata => {
    //    this.dataPatientWithId = pdata;
    //  });
    //  console.table(data);
    //  //this.remittanceInfo.next(data);
    //});
   
    //if (remissid) {
    //this.dataService.getUndersokning(remissid).subscribe((data) => {
    // this.dataRemissWithId.next(data);
    // console.log(data);
  /* });*/


      //this.http.get('/assets/plan-examination/patienter.json')
      //  .subscribe((data: DataPatient[]) => {
      //    this.dataPatientWithId = data.filter(item => item.remisser.map((remiss) => remiss.id).indexOf(remissid) > -1)[0];
      //    this.dataRemissWithId = this.dataPatientWithId.remisser.find(remiss => remiss.id == remissid);
      //    this.dataRemissWithId.mobiliseringString = this.getMobiliseringString(this.mobiliseringOptions, this.dataRemissWithId.mobilisering);

      //    this.detector.markForCheck();
      //  });
    //}
    //else {
    //  this.http.get('/assets/plan-examination/patienter.json')
    //    .subscribe((data: DataPatient[]) => {
    //      this.dataPatientWithId = data.filter(item => item.pnr == '19760902-2929')[0];
    //      //data.find((item) => item.pnr == '19760902-2929')[0];
    //      this.dataRemissWithId = data.map(item => item.remisser.find(remiss => remiss.id == 1))[0];
    //      this.detector.markForCheck();
    //    });
    //}

  }
  //public getEchoTypes() {
  //  this.examinationTypesList = this.variantService.getAllaEchoVarianter();
  //}

  get echoVariables() {
    return this.saveAddPatientExaminationForm.get('variables') as FormArray;
  }
  getVariables() {
    const variablesForm = this.fb.group({});
    this.variableService.getAllEchoVariablesByGroup(2, "", "").subscribe(data => {
      this.variablesByGroup.next(data);
      console.log(data);
      data.forEach(item => {
        item.sysEchoVariables.forEach(variable => {
          this.echoVariables.push(variablesForm);
          this.saveAddPatientExaminationForm.addControl(variable.id.toString(), new FormControl());
        });
      });
    });
  }

  private initAutocompleteSelect() {
    this.filteredIndikation = this.fc_indikation.valueChanges.pipe(
      startWith(''),
      map(value => {
        this.fc_indikation.setValue('');
        return this.filterIndikation(value);
      })
    );
    this.detector.markForCheck();
  }
  private normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '').toString();
  }
  private filterIndikation(text: string = ''): string[] {
    const filterValue = this.normalizeValue(text);
    return this.indikationLista.filter(indikation => this.normalizeValue(indikation).includes(filterValue));
  }
  public selectedExaminationType: string = "0";
  public getDataFromExistingEchoTypes(id) {
    if (id == "0") {
      this.typeSelected = false;
    } else {
      // Om undersökningstypen inte är tom (Inte 'Välj undersökningstyp' eller en helt ny)
      this.typeSelected = true;
      /*this.getSelectedTypeData(this.selectedExaminationType);*/
    }
  }
  selectedVariants: RegEchoVariantDto;

  public selectedValue(event: MatSelectChange) {
    //let selectedExaminationTypes: Array<number> = this.saveAddPatientExaminationForm.get('personForm.mobilisering').value;
    //this.selectedVariants = {
    //  id: event.value,
    //  variantName: event.source.triggerValue
    //};
  }
  public getDataFromEchoType(event: MatSelectChange) {
    event.value.forEach((variant) => {
      this.variantService.getEchoVariant(variant).subscribe(data => {
        if (data.valdaVarJson) {
          let varIds = data.valdaVarJson.split(",");
          for (var i = 0; i < varIds.length; i++) {
            this.saveAddPatientExaminationForm.get(varIds[i]).patchValue(varIds[i]);
          }
        }
      });
    });
  }


  public getMobiliseringString(mobiliseringOptions, value): string {
    return mobiliseringOptions.filter(mobilisering => mobilisering.value == value)[0].text;
  }
  public hasPlannedExaminations(remisser): boolean {
    return remisser.filter(remiss => remiss.planerad && !remiss.undersokt)?.length > 0;
  }
  public hasCompletedExaminations(remisser): boolean {
    return remisser.filter(remiss => remiss.undersokt)?.length > 0;
  }
  public hasOplaneradeRemisser(remisser): boolean {
    return remisser.filter(remiss => !remiss.planerad)?.length > 1;
  }

  public examinationExists(examinationName): boolean {
    return this.examinations.filter(ex => ex.name == examinationName)?.length > 0;
  }
  public addExamination() {
    if (this.newExamination.name != '0') {
      this.examinations.push(this.newExamination); 
      this.resetNewExamination();
      this.detector.markForCheck();
      this.selectExaminationTab(this.examinations.length-1);
    }
  }

  public removeExamination(examinationIndex: number) {
    let examinationToRemove = this.examinations[examinationIndex];
    //Minska sortorder för alla med högre sortorder än den som ska tas bort
    this.examinations.forEach(ex => {
      if (Number(ex.sortorder) > Number(examinationToRemove.sortorder))
        ex.sortorder = (Number(ex.sortorder)-1).toString();
    });
    this.examinations.splice(examinationIndex, 1);
    this.resetNewExamination();
    this.detector.markForCheck();
  }
  private resetNewExamination() {
    this.newExamination = new DataExaminationItem('0', (this.examinations.length+1).toString());
  }
  private selectExaminationTab(index: number) {
    //Vänta ut rendering, reset till 0 innan set till rätt index
    setTimeout(() => { this.selectedExaminationTab.setValue(0); this.detector.markForCheck(); }, 50);
    setTimeout(() => { this.selectedExaminationTab.setValue(index); this.detector.markForCheck(); }, 100);
  }


  /* Show läkemedel modal*/
  public showLakemedelModalIsOpen = new BehaviorSubject<boolean>(false);

  public openShowLakemedelModal() {
    this.showLakemedelModalIsOpen.next(true);
  }
  public openEditShowLakemedelModal(lakemedel?: PlaneratPatientLakemedel) {
    if (!lakemedel) {
      this.patientLakemedel.next(null);

    } else {
      this.lakemedelToEdit.next(lakemedel);
    }
    this.showLakemedelModalIsOpen.next(true);
  }
  public showLakemedelModalClosed() {
    this.patientLakemedel.next(null);
    this.lakemedelToEdit.next(null);
    this.showLakemedelModalIsOpen.next(false);
  }
  //public newLakemedelSubmitted(saveAddLakemedelForm) {
  //  this.patientLakemedel.next(null);
  //  this.lakemedel.push({
  //    remissId: this.dataRemissWithId.id,
  //    id: Math.floor(Math.random() * 10000),
  //    lakemedel: saveAddLakemedelForm.get('lakemedel').value,
  //    lakemedelEnhet: saveAddLakemedelForm.get('lakemedelEnhet').value,
  //    lakemedelKommentar: saveAddLakemedelForm.get('lakemedelKommentar').value,
  //    lakemedelMangd: saveAddLakemedelForm.get('lakemedelMangd').value,
  //    lakemedelOrdination: saveAddLakemedelForm.get('lakemedelOrdination').value,
  //    lakemedelOrdinerat: saveAddLakemedelForm.get('lakemedelOrdinerat').value,
  //    lakemedelTidOrdination: saveAddLakemedelForm.get('lakemedelTidOrdination').value,
  //    lakemedelForeslaget: saveAddLakemedelForm.get('lakemedelForeslaget').value,
  //    lakemedelStyrka: saveAddLakemedelForm.get('lakemedelStyrka').value,
  //    lakemedelGivet: saveAddLakemedelForm.get('lakemedelGivet').value,
  //    lakemedelTidGivet: saveAddLakemedelForm.get('lakemedelTidGivet').value,
  //    lakemedelSignerat: saveAddLakemedelForm.get('lakemedelSignerat').value,
  //    lakemedelTidSignerat: saveAddLakemedelForm.get('lakemedelTidSignerat').value
  //  })
  //  this.patientLakemedel.next(null);
  //  console.table(this.lakemedel);
  //  this.detector.markForCheck();
  //}


}
