<form [formGroup]="editForm" (ngSubmit)="submitForm()" (document:keydown.control.arrowdown)="tabba()">
  <div class="container-fluid mt-2">
    <div class="row sticky-top mb-2 bg-light px-3 border-top border-bottom py-3" *ngIf="examination">
      <div class="bg-grey">
        <div class="row">
          <div class="col-6">
            <b>{{examination?.patient?.name}} {{examination?.patient?.lastname}}</b> {{examination?.patient?.gender}}, {{examination?.patient?.personalIdentityNumber}}, {{examination?.plannedexamination?.weight}} Kg, {{examination?.plannedexamination?.length}} cm
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="card my-1">
              <div class="card-body bg-light">
                <div class="row">
                  <div class="col-6">
                    <div>
                      <b>Planerade varianter: </b>
                      <ng-container *ngFor="let v of varianter | async">
                        <div ngbDropdown class="d-inline-block">
                          <span class="badge bg-dark m-1">
                            <button type="button" *ngIf="v.variables.length > 0" class="btn btn-xs btn-dark float-right" id="dropdownVariantInfo" ngbDropdownToggle tabindex="-1">
                              {{v.variantName}}
                            </button>
                          </span>
                          <div ngbDropdownMenu #infoVariant class="dropdown-menu">
                            <ul *ngFor="let variable of v.variables" class="list-unstyle p-1 m-0">
                              <li class="p-0 m-0 dropdown-item pointer" [ngbTooltip]="'Visa metodbeskrivning'" (click)="openMetodbeskrivning('')">{{variable.shortName}} </li>
                            </ul>
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="d-flex justify-content-center" *ngIf="!examination?.plannedexamination?.addedVariablesDescs || !examination?.plannedexamination?.removedVariablesDescs">
                      <ph-loading-spinner></ph-loading-spinner>
                    </div>
                    <p *ngIf="examination?.plannedexamination?.addedVariablesDescs != ''">
                      <small>
                        <b *ngIf="examination?.plannedexamination?.addedVariablesDescs.length > 0">Tillagda variabler: </b>
                        <ng-container *ngFor="let text of examination?.plannedexamination?.addedVariablesDescs; let isLast=last"><i>{{text}}<span *ngIf="!isLast">, </span></i></ng-container>
                      </small>
                    </p>
                    <p *ngIf="examination?.plannedexamination?.removedVariablesDescs != ''">
                      <small>
                        <b *ngIf="examination?.plannedexamination?.removedVariablesDescs.length > 0">Borttagna variabler: </b>
                        <ng-container *ngFor="let text of examination?.plannedexamination?.removedVariablesDescs; let isLast=last"><i>{{text}}<span *ngIf="!isLast">, </span></i></ng-container>
                      </small>
                    </p>
                    <button class="btn btn-link btn-sm" type="button" (click)="showMorInfoPatient = !showMorInfoPatient">
                      {{ showMorInfoPatient ? "Visa mindre": "Visa mer" }}
                    </button>
                  </div>
                  <div class="col-4" *ngIf="examination?.plannedexamination?.otherInformationTexts.length > 0">
                    <b>Övrigt: </b>
                    <ng-container *ngFor="let otherText of examination?.plannedexamination.otherInformationTexts">
                      <span class="badge bg-dark mx-1">{{otherText.description}}</span>
                    </ng-container>
                  </div>
                  <div class="col-2">
                    <div ngbDropdown class="float-end">
                      <button type="button" class="btn btn-xs btn-outline-primary" id="dropdownConfig" ngbDropdownToggle tabindex="-1"><fa-icon [icon]="faBars"></fa-icon></button>
                      <div ngbDropdownMenu>
                        <button ngbDropdownItem>Hantera läkemedel</button>
                        <button ngbDropdownItem>Visa remiss</button>
                        <button ngbDropdownItem (click)="openEditExamination()">Redigera undersökning</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div *ngIf="showMorInfoPatient">
                  <hr>
                  <b>Undersökningstyp: </b>{{examination?.plannedexamination?.uTypDescription}}<br>
                  <b>Hälsoproblem: </b>{{examination?.remiss?.healthProblems}}<br>
                </div>
              </div>
            </div>
            <div *ngIf="!(examination)" class="row my-5">
              <div class="d-flex justify-content-center">
                <ph-loading-spinner></ph-loading-spinner>
              </div>
            </div>
            <div class="card">
              <div class="card-body">

                <div class="row d-flex justify-content-center">
                  <div class="col-lg-6 d-flex justify-content-center">
                    <button class="btn btn-primary mb-1" (click)="toggleSelectUlValues(showSeletListUlValues = !showSeletListUlValues)">{{ !showSeletListUlValues ? "Välj en mätning från UL-apparat": "Välj inte en mätning från UL-apparat" }}</button>
                  </div>
                </div>
                <div class="row d-flex justify-content-center">
                  <div class="col-lg-4 d-flex justify-content-center">
                    <select class="form-control form-select mb-1" *ngIf="showSeletListUlValues" (change)="changeSelectedMatningFromUl(($event.target).value)" formControlName="selectedSavedUlValues">
                      <option value="0">Välj mätning</option>
                      <option *ngFor="let item of selectListUlValues" [value]="item.id">{{item.namn}}</option>
                    </select>
                    <!--Bekräfta radering modal -->
                    <div ngbDropdown class="d-inline-block" *ngIf="currentSelectedSavedUl?.id">
                      <button class="btn btn-danger btn-sm mx-1" tabindex="-1" [ngbTooltip]="'Radera mätningarna för filen '+ currentSelectedSavedUl.namn"><span class="px-1" ngbDropdownToggle><fa-icon [icon]="faTrash"></fa-icon></span></button>

                      <div ngbDropdownMenu>
                        <div class="container">
                          <h5>Vill du verkligen radera undersökningsvärdena för <b>{{currentSelectedSavedUl.namn}}</b>?</h5>
                          <div class="dropdown-divider"></div>
                          <button type="button" class="btn btn-warning btn-xs" ngbDropdownItem (click)="deleteExaminationValues(currentSelectedSavedUl.id)">Radera</button>
                        </div>
                      </div>
                    </div>
                    <!-- /Bekräfta radering modal -->
                  </div>
                </div>
              </div>
            </div>
            <div class="row my-1" *ngIf="currentSelectedSavedUl?.id">
              <div class="col-lg-3 pl-2">
                <div class="form-check form-switch">
                  <label for="editAllaVariabler">Redigera alla</label>
                  <input class="form-check-input ml-1" type="checkbox" id="editAllaVariabler" formControlName="editAllaVariabler" (change)="toggleEditAllVariabler()">
                </div>
              </div>
              <div class="col-lg-2 pl-2">
                <div class="form-check form-switch">
                  <label for="showAllaVariabler">Visa alla</label>
                  <input class="form-check-input ml-1" type="checkbox" id="showAllaVariabler" formControlName="showAllaVariabler" (change)="toggleShowAllVariabler()">
                </div>
              </div>
              <div class="col-lg-4 pl-2">
                <button class="btn btn-primary btn-sm" (click)="openKvalModal()">Öppna kvalitativa bedömningar</button>
              </div>
            </div>
            <div class="row justify-content-end" *ngIf="currentSelectedSavedUl?.id">
              <div class="col-lg-12 d-flex justify-content-center text-center py-5"><small><i>Klicka ctrl + ↓ för att öppna redigering av värden. Tryck tab för att komma till nästa värde.</i></small></div>
            </div>
            <div class="row" *ngIf="currentSelectedSavedUl?.id">
              <ng-container *ngFor="let variable of variables; let vindex = index">
                <ng-container *ngIf="variable.selected">
                  <!--<hr *ngIf="firstMatchWarning(variable)">-->
                  <!--Visa bara variabler som är med i planeringen av undersökningen och har varningrar eller saknar värde. TODO: Gruppera varningarna på annat sätt så att det blir lättare att kolla-->
                  <ng-container *ngIf="(editForm.value.editAllaVariabler && variable.currentValue != '') || editForm.value.showAllaVariabler || (variable.currentValue == '' || variable.currentValue == null) || variable.warningSymbol">

                    <div class="col-xxl-2 col-lg-4 col-md-4 col-sm-6 py-1 my-0">
                      <div class="row">
                        <div class="col-10" (click)="showHideEdit(variable, true)">
                          <!--Varningsmodal som ska flyttas till egen komponent sen-->
                          <div ngbDropdown class="d-inline-block" *ngIf="variable.warningSymbol != '' && variable.warningSymbol != null">
                            <span [ngbTooltip]="variable.warningText" class="px-1" ngbDropdownToggle><i [ngClass]="variable.warningSymbol + ' ' + variable.warningSymbolColor"></i></span>
                            <div ngbDropdownMenu>
                              <div class="container">
                                <h5>Hantera uppmärksamhet</h5>
                                <button type="button" class="btn btn-primary btn-xs">Stäng</button>
                              </div>
                            </div>
                          </div>
                          <!-- /Varningsmodal slut -->
                          <small [ngbTooltip]="variable.description">{{variable.shortName | truncate:20:'...'}}</small>
                          <span class="float-end">
                            <small *ngIf="!variable.showEdit" class="px-1" [ngbTooltip]="(variable.valueTypeId > 0 && variable.valueTypeId < 7) ? variable.graderingText : ''" (click)="variable.showEdit = true">{{variable.currentValue}}</small> <i *ngIf="(variable.currentValue > 0 || variable.calculatedValue) && variable.unitId != 16">{{variable.unit}}</i>
                            <small *ngIf="variable.showEdit && variable.ulValue"><fa-icon [icon]="faInfoCircle" [ngbTooltip]="'Ursprungligt värde: ' + variable.ulValue"></fa-icon></small>
                          </span>
                        </div>
                        <edit-value-examination [gindex]="gindex"
                                                [vindex]="vindex"
                                                [showEditVariable]="variable.showEdit || (variable.valueTypeId > 0 && variable.valueTypeId < 7)"
                                                [style]="1"
                                                [currentValue]="variable.currentValue"
                                                [valdGrupp]="selectedValgrupp"
                                                (tabToNextVariable)="tabToNextVariable($event)"
                                                [variable]="variable"
                                                (variableValuesChanged)="showVariableValuesChanged($event, variable)"
                                                (showEdit)="showEdit($event, variable)"
                                                [variableId]="variable.id"
                                                [typeSelectList]="variable.typeSelectList"
                                                (openEditInfoVariabel)="openEditShowVariable(variable.id, variable.shortName)"></edit-value-examination>
                        <div class="text-danger">
                          <i>
                            <small>{{variable.validationError}}</small>
                          </i>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <!--<hr *ngIf="lastMatchWarning(variable)">-->
                </ng-container>
              </ng-container>
            </div>
            <div class="row d-flex justify-content-end mb-2">
              <div class="col-lg-6 d-flex justify-content-end">
                <button class="btn btn-primary mx-2" tabindex="-1" *ngIf="currentSelectedSavedUl?.id" (click)="openAddUndersokningsvarden(currentSelectedSavedUl.id, currentSelectedSavedUl.namn)">Spara redigeringar</button>
                <button class="btn btn-primary" (click)="openAddUndersokningsvarden(0, '')" tabindex="-1" *ngIf="checkIfAnyInputValues()">Spara som ny mätning från Ul-apparat</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="row">
          <div class="form-check form-switch">
            <label for="textTypeEdit">Redigera</label>
            <input class="form-check-input ml-1" type="checkbox" id="textTypeEdit" formControlName="textTypeEdit">
          </div>
          <h2>Utlåtandetext</h2>
          <!--<ng-container *ngFor="let grupp of grupper">-->
          <ng-container *ngFor="let v of variables">
            <ng-container *ngIf="v.graderingText != null && v.graderingText != ''">
              <!--Visning redigering-->
              <ng-container *ngIf="editForm.value.textTypeEdit == true">
                <div ngbDropdown>
                  <button type="button" class="btn btn-outline-defaul btn-sm" ngbDropdownToggle><span [ngbTooltip]="v.currentValue + ' ' + v.unit">{{v.graderingText}}. </span></button>
                  <div ngbDropdownMenu>
                    <button tabindex="-1" ngbDropdownItem (click)="showHideEdit(v, v.showEdit = !v.showEdit)">Redigera</button>
                    <button tabindex="-1" ngbDropdownItem (click)="showHideEditTextVariable(v, v.showEditText = !v.showEditText)">Ändra text</button>
                  </div>
                </div>

                <input class="form-control-sm form-control" *ngIf="v.showEditText ? true : false" [ngbTooltip]="v.currentValue + ' ' + v.unit" formControlName="var_table{{v.id}}_text" (focusout)="graderingtextChange(v, $event.target.value)" [value]="v.graderingsText">
              </ng-container>
              <!--Visning text-->
              <ng-container *ngIf="editForm.value.textTypeEdit == false"><span [ngbTooltip]="v.currentValue + ' ' + v.unit">{{v.graderingText}}. </span></ng-container>
            </ng-container>
          </ng-container>
          <!--</ng-container>-->
        </div>
        <div class="row">
          <result-table [variables]="variables" [undersokning]="examination"></result-table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <table class="table">
          <thead>
            <tr>
              <th>Varabel</th>
              <th>Gränser</th>
              <th>Min-Max</th>
              <th>Graderingstext</th>
              <th>Värde</th>
              <th>Metod</th>
              <th></th>
            </tr>
          </thead>
          <ng-container>
            <tbody *ngFor="let variable of variables; let vindex = index;">
              <tr>
                <td><button tabindex="-1" class="btn btn-link" (click)="openEditShowVariable(variable.id, variable.shortName)" [ngbTooltip]="'Redigera variabel'"><fa-icon [icon]="faEdit"></fa-icon></button> {{variable.shortName}}</td>
                <td>
                  <span *ngIf="variable.calculationJson.intervalltyp" class="float-right"><a (click)="variable.visibleGransvarden = !variable.visibleGransvarden">{{ variable.visibleGransvarden ? " Dölj": " Visa"}}</a></span>
                  <span *ngIf="!variable.calculationJson.intervalltyp" class="float-right"><a (click)="editGraderingar(variable.id, variable.shortName, variable.format)">Lägg till graderingar</a></span>
                  <!--<i class="{{variable.visibleGransvarden ? 'fas fa-chevron-up' : 'fas fa-chevron-down'}}"></i>-->
                  <div class="row" *ngIf="variable.visibleGransvarden">
                    <div class="col-12">
                      <div class="card">
                        <div class="card-body">
                          <table class="table table-responsive m-3">
                            <thead>
                              <tr>
                                <th *ngIf="variable.calculationJson.kontyp != 0">Kön</th>
                                <th></th>
                                <th>Graderingstext</th>
                                <th><button tabindex="-1" class="btn btn-outline-primary" (click)="editGraderingar(variable.id, variable.shortName, variable.format, variable)" [ngbTooltip]="'Redigera graderingar'"><fa-icon [icon]="faEdit"></fa-icon></button></th>
                              </tr>
                            </thead>
                            <tbody *ngFor="let formel of variable.calculationJson?.formler; index as i">
                              <tr [ngClass]="formel.graderingstyp == 7 ? 'fw-bold' : ''" *ngIf="formel?.formel">
                                <td *ngIf="variable.calculationJson.kontyp != 0">{{formel.kon == 1 ? 'Kvinna' : 'Man'}}</td>
                                <td class="nowrap">
                                  <ng-container *ngIf="variable.calculationJson.intervalltyp == 2">
                                    {{formel.startValue}}
                                    {{formel.graderingsTecken}}
                                    <span *ngIf="formel.graderingstyp != 1 && formel.graderingstyp != 8 && formel.graderingstyp != 7"> - </span>
                                    <span *ngIf="formel.graderingstyp == 1"> > </span>
                                    <span *ngIf="formel.graderingstyp == 8"> <</span>
                                  </ng-container>
                                  {{formel.formel}} <b>{{formel.intervalltyp == 1 ? ' = ' + formel.beraknat : ''}}</b> {{variable.unit}}
                                </td>
                                <td>{{formel.graderingsText}}</td>
                                <td></td>
                                <td><span *ngIf="formel.visaEjISvar">(visas inte i svaret)</span></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                </td>
                <td>{{variable.impossibleValueMin}} - {{variable.impossibleValueMax}}</td>
                <td><div *ngIf="variable.validationError != ''" class="text-danger"><i><small>{{variable.validationError}}</small></i></div><i *ngIf="variable.validationError == ''">{{variable.graderingText}}</i></td>
                <td><span [ngbTooltip]="variable.valueTypeId > 0 ? variable.graderingText : ''" (click)="variable.showEdit = true">{{variable.currentValue}} {{variable.currentValue != "" && variable.unitId != 16 ? variable.unit : ''}}</span></td>
                <td>
                  <edit-value-examination [gindex]="gindex"
                                          [vindex]="vindex"
                                          [showEditVariable]="variable.showEdit"
                                          (tabToNextVariable)="tabToNextVariable($event)"
                                          [variable]="variable"
                                          [currentValue]="variable.currentValue"
                                          [style]="2"
                                          [valdGrupp]="selectedValgrupp"
                                          (showEdit)="showEdit($event, variable, grupp)"
                                          (variableValuesChanged)="showVariableValuesChanged($event, variable, grupp)"
                                          [variableId]="variable.id"
                                          [validate]="variable.validateInput"
                                          [typeSelectList]="variable.typeSelectList"
                                          (openEditInfoVariabel)="openEditShowVariable(variable.id, variable.shortName)"></edit-value-examination>

                </td>
              </tr>
            </tbody>
          </ng-container>
        </table>
      </div>
    </div>

  </div>
</form>

