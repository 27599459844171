import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { faImages, faInfoCircle, faPencilAlt, faSearchPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-overview-vers2',
  templateUrl: './overview-page-vers2.component.html',
  styleUrls: ['./overview-page-vers2.component.scss']
})
export class OverviewPageVers2Component implements OnInit {

  constructor(
    public translate: TranslateService,
    private http: HttpClient
  ) { }

  public dataItemGroups = new BehaviorSubject<DataItemGroup[]>(null);
  public drawedImages: string[] = ['resurs-2-edit', 'resurs-3-edit'];

  public faInfoCircle = faInfoCircle;
  public faImages = faImages;
  public faSearchPlus = faSearchPlus;
  public faPencilAlt = faPencilAlt;
  public faPlusCircle = faPlusCircle;

  public diagramItems: DataItem[] = [];

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.http.get('/assets/echo.json')
      .subscribe((data: DataItemGroup[]) => {
        this.dataItemGroups.next(data);
        data.forEach(() => this.diagramItems.push(null));
      });
  }

  /* Edit ItemGroup */
  public itemGroupToEdit = new BehaviorSubject<DataItemGroup>(null);
  public editItemGroupModalIsOpen = new BehaviorSubject<boolean>(false);
  public openEditGroupModal(itemGroup: DataItemGroup) {
    this.itemGroupToEdit.next(itemGroup);
    this.editItemGroupModalIsOpen.next(true);
  }
  public editItemGroupModalClosed(e) {
    this.itemGroupToEdit.next(null);
    this.editItemGroupModalIsOpen.next(false);
  }

  /* Show diagram for DataItem */
  public randomSerieData: any;
  public showDiagramForItem(index: number, item: DataItem) {
    let previousItem = this.diagramItems[index];
    this.diagramItems[index] = null; //reset
    if (previousItem != item)
      this.diagramItems[index] = item;

    this.randomSerieData = [
      [Date.UTC(2021, 0, 1), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 3), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 4), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 5), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 6), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 7), parseFloat((Math.random() * 10).toFixed(1))]
    ];
  }

  /* Preview/Draw images */
  public imageToPreview = new BehaviorSubject<string>(null);
  public previewImageModalIsOpen = new BehaviorSubject<boolean>(false);
  public openPreviewImageModal(imageName) {
    this.imageToPreview.next(imageName);
    this.previewImageModalIsOpen.next(true);
  }
  public removePreviewedImage() {
    this.drawedImages = this.drawedImages.filter(x => x != this.imageToPreview.getValue());
    this.imageToPreview.next(null);
  }
  public previewImageModalClosed(e) {
    this.imageToPreview.next(null);
    this.previewImageModalIsOpen.next(false);
  }

  public addImageModalIsOpen = new BehaviorSubject<boolean>(false);
  public openAddImageModal() { this.addImageModalIsOpen.next(true); }
  public addImageModalClosed(e) { this.addImageModalIsOpen.next(false); }

}
