import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter, OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Remiss } from '@model/data-remiss';
import { DataPatientRemissUndersokning } from '@model/data-patientremissundersokning';
//import { ExampleSavedDataItem } from '@model/data-exampleSavedDataItem-type';
import { faAngleDown, faAngleUp, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RemissService } from '@core/services/remiss.service';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { StepperSelectionEvent } from '@angular/cdk/stepper';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'examination-in-progress',
  templateUrl: './examination-in-progress.component.html',
  styleUrls: ['./examination-in-progress.component.scss'],
})
export class ExaminationInProgressComponent implements OnInit {

  constructor(
    private dataService: RemissService,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private _formBuilder: FormBuilder
  ) { }

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  FormGroup3: FormGroup;
  FormGroup4: FormGroup;
  FormGroup5: FormGroup;
  FormGroup6: FormGroup;
  @Output() onSubmit = new EventEmitter<FormGroup>();
  @Output() selectionChange: EventEmitter<StepperSelectionEvent>
  public activestep: boolean = false;
  examination: DataPatientRemissUndersokning;
  public remittanceInfo = new RemittanceDto;
  public lakemedelRuta: boolean = false;
  private sub: Subscription = null!;
  public undersokningstypId: string;


  ngOnInit(): void {
    this.sub = this.route.paramMap.subscribe((params) => {
      let undersokningstypId = params.get('undersokningstypId');
      let remissid = params.get('remissid');
      let patientId = params.get('patientId');
      this.getData(patientId);
    });
  }
  remittance$: Observable<RemittanceDto> | undefined;

  onStepChange() {
    this.activestep = true;
  }
  onChangeTab(item: DataPatientRemissUndersokning) {
    this.examination = item;
  }
  private getData(remissid) {
    this.remittance$ = this.dataService.getRemittance(remissid);
  //  this.openShowPatientinfoModal();
  }
  public getMobiliseringString(mobiliseringOptions, value): string {
    return mobiliseringOptions.filter(mobilisering => mobilisering.value == value)[0].text;
  }
  public hasPlannedExaminations(remisser): boolean {
    return remisser.filter(remiss => remiss.planerad && !remiss.undersokt)?.length > 0;
  }
  public hasCompletedExaminations(remisser): boolean {
    return remisser.filter(remiss => remiss.undersokt)?.length > 0;
  }
  public hasOplaneradeRemisser(remisser): boolean {
    return remisser.filter(remiss => !remiss.planerad)?.length > 1;
  }
  /* Show remiss modal*/
  public showRemissModalIsOpen = new BehaviorSubject<boolean>(false);

  public openShowRemissModal() {
    this.showRemissModalIsOpen.next(true);
  }

  public showRemissModalClosed() {
    this.showRemissModalIsOpen.next(false);
  }
  /* Show patientinfo modal*/
  public showPatientinfoModalIsOpen = new BehaviorSubject<boolean>(false);

  public openShowPatientinfoModal() {
    this.showPatientinfoModalIsOpen.next(true);
  }
  public showPatientinfoModalClosed() {
    this.showPatientinfoModalIsOpen.next(false);
  }
  public newPatientInfoSubmitted(saveAddInfoUndersokningForm) {
    this.remittanceInfo.weight = saveAddInfoUndersokningForm.get('vikt').value;
    this.remittanceInfo.height = saveAddInfoUndersokningForm.get('langd').value;
    this.detector.markForCheck();
  }
}

