export class AlertMessageItem {
  constructor(
    public type: AlertMessageType,
    public message: string
  ) { }
}

export enum AlertMessageType {
  Primary = 'primary',
  Secondary = 'secondary',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Danger = 'danger',
  Light = 'light',
  Dark = 'dark'
}
