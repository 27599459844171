
<div class="me-auto d-flex flex-column flex-shrink-0 bg-dark h-100 border-right border-secondary" style="width: 8rem;">
  <ul class="nav nav-pills flex-column mb-auto text-center sticky-top sticky-offset">
    <li class="nav-item py-3">
      <a class="py-3 nav-link" [ngbTooltip]="'Översikt patient'" [routerLinkActive]="['active']" [routerLink]="['/overview-patient-page', filteredPatienter?.patientId]">
        <i class="fas fa-tachometer-alt fa-2x"></i>
      </a>
    </li>
    <li class="nav-item py-3 dropdown open">
      <button mat-button [matMenuTriggerFor]="matMenu2" class="btn btn-link" [ngClass]="{'active' : url == 'examination-in-progress'}" [ngbTooltip]="'Starta undersökning'"><i class="fas fa-user-md fa-2x" width="24" height="24"></i> <b class="caret"></b></button>
      <!--TODO: Hämta remisser för patienten-->
      <mat-menu #matMenu2="matMenu">
        <!--<ng-container *ngFor="let remiss of remisser | async">
          <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, remiss?.patientId]" [routerLinkActive]="['active']" [ngbTooltip]="remiss.remitent + ' - ' + remiss.remissdatum"> RemissId {{remiss.id}}</a>
        </ng-container>-->
      </mat-menu>
    </li>
    <li class="nav-item py-3">
      <a href="#" class="py-3 nav-link" [routerLinkActive]="['active']" [ngbTooltip]="'Visa Remisser'" [routerLink]="['/patient-remittance-list', filteredPatienter?.patientId]">
        <i class="fas fa-book fa-2x"></i>
      </a>
    </li>
    <li class="nav-item py-3 dropdown open">
      <button mat-button [matMenuTriggerFor]="menu" class="btn btn-link" [ngbTooltip]="'Gör uthopp'"><i class="fas fa-hospital fa-2x"></i> <b class="caret"></b></button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item>Cosmic</a>
        <a mat-menu-item>EKOPAC</a>
      </mat-menu>
    </li>
    <li class="nav-item py-3">
      <a href="#" class="py-3 nav-link" [routerLinkActive]="['active']" [ngbTooltip]="'Visa historik'">
        <i class="fas fa-history fa-2x"></i>
      </a>
    </li>
  </ul>
</div>
