import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Input } from '@angular/core';
import { OnChanges } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faImages, faInfoCircle, faPencilAlt, faSearchPlus, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';
import { ExampleItemgroups } from '@model/data-exampleItemgroups';
import { ExampleSavedDataItem } from '@model/data-exampleSavedDataItem-type';
import { DataPatientRemissUndersokning } from '@model/data-patientremissundersokning';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'examination-result',
  templateUrl: './examination-result.component.html',
  styleUrls: ['./examination-result.component.scss']
})
export class ExaminationResultComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    public translate: TranslateService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
  ) { }
  public dataItemGroups = new BehaviorSubject<DataPatientRemissUndersokning>(null);
  public drawedImages: string[] = ['resurs-2-edit', 'resurs-3-edit'];

  showGeneratedText: Boolean = false;
  editGenerated: Boolean = false;
  genereradText1: String;
  utlatandeRubrik1: String;
  genereradText2: String;
  utlatandeRubrik2: String;
  genereradText3: String;
  utlatandeRubrik3: String;
  genereradText4: String;
  utlatandeRubrik4: String;

  public faInfoCircle = faInfoCircle;
  public faImages = faImages;
  public faSearchPlus = faSearchPlus;
  public faPencilAlt = faPencilAlt;
  public faPlusCircle = faPlusCircle;
  @Input() activeTab: boolean;
  public diagramItems: ExampleSavedDataItem[] = [];
  @Input() examination = new BehaviorSubject<DataPatientRemissUndersokning>(null);
  /*public dataFromDeviceWithId: DataPatientRemissUndersokning;*/
  ngOnInit(): void {
    //console.log(this.activeTab);
    //if (this.activeTab) {
    //  this.selectionChange();
    //}
    //this.getData();
    //this.route.paramMap.subscribe((params) => {
    //  let undersokningstypId = params.get('undersokningstypId');
    //  //let remissid = params.get('remissid');
    //  //this.getData(remissid);
    //  this.getExaminationVariables(undersokningstypId);
    //});
  }
  public editGeneratdeText() {
    this.editGenerated = !this.editGenerated;
  }
  getAlertSymbolForItem(value?: any, ref?: any): object {
    if (value == null || value == "")
      return { 'styleClass': 'fas fa-exclamation-triangle', 'text': 'Värde saknas' };
    if (ref) {
      if (value < ref.lowerExtreme)
        return { 'styleClass': 'icon-speedometer-1', 'text': 'Väldigt lågt värde', 'value': value };
      if (value < ref.lowerNormal)
        return { 'styleClass': 'icon-speedometer-2', 'text': 'Lågt värde', 'value': value };
      if (value > ref.upperNormal)
        return { 'styleClass': 'icon-speedometer-4', 'text': 'Högt värde', 'value': value };
      if (value > ref.upperExtreme)
        return { 'styleClass': 'icon-speedometer-5', 'text': 'Väldigt högt värde', 'value': value };
    }
    else
      return null;
  }
  
  public genereraText() {
    this.showGeneratedText = true;
    
    this.genereradText1 = "<p>Vänster kammares inre diameter lätt ökad. Väggarna normaltjocka. Väggrörligheten lätt nedsatt. AV-planrörligheten (MAPSE) vid nedre normalgränsen och utflödesintegralen lätt sänkt. Ejektionsfraktionen lätt nedsatt, enligt Simpson. Den sammantagna systoliska funktionen lätt nedsatt. Det diastoliska fyllnadsflödet normalt. E/e´ normal, vilket talar för normalt fyllnadstryck. Flödeshastigheten över klaffprotesen normal, ingen insufficiens. Aorta ascendens normalvid. Mitalisklaffen har en insufficiens av lindrig-måttlig grad. Vänster förmak lätt dilaterat.  </p>";
    this.utlatandeRubrik1 = "Vänster hjärthalva";
    this.genereradText2 = "<p>Höger kammare normalstor. Väggrörligheten radiellt normal och TAPSE är normal.  EF skattas normal. Flödeshastigheten över pulmonalisklaffen normal, liten insufficiens. Tricuspidalisklaffen har en liten insufficiens med hastighet vid övre normalgränsen. Höger förmak normalstort. Vena cava inferior normalvid och andningsvariationen normal, vilket talar för normalt CVP. </p>";
    this.utlatandeRubrik2 = "Höger hjärthalva";
    this.genereradText3 = "<p>Vilotrycket i lungkretsloppet skattas normalt </p>";
    this.utlatandeRubrik3 = "Lungkretsloppet";
    this.genereradText4 = "";
    this.utlatandeRubrik4 = "Sammanfattning";

  }
  /* Show diagram for DataItem */
  public variableSerieData: any = [];
  public todaysDate = new Date();
  public showDiagram(item: ExampleSavedDataItem) {
    console.table(item.history);
    this.variableSerieData = [];
    item.history.forEach(value => {
      this.variableSerieData.push([
        Date.UTC(value.year, value.month, value.day),
        value.value])
    })
    this.variableSerieData.push([
      Date.UTC(2022, 4, 11),
      item.inputValue]);

  }
  //selectionChange() {
  //  console.table(this.examination);
  //}

  //getExaminationVariables(id) {
  //  this.http.get('/assets/examination-in-progress/examination-in-progress.json')
  //    .subscribe((data: DataPatientRemissUndersokning[]) => {
  //      this.dataFromDeviceWithId = data.filter((type) => type.undersokningstypId == id)[0];
  //      data.forEach(() => this.dataFromDeviceWithId.itemgroups.push(null),
  //        this.dataFromDeviceWithId.itemgroups.forEach(group => {
  //          /*group.items.filter(item => item.inputValue),*/
  //          //group.items.forEach(item => {
  //          //  item.history?.forEach(value => {
  //          //    console.log(value.value);
  //          //    item.historySerie.push(value.value);
  //          //  })
  //          //});
  //          if (group.items.filter(item => item.inputValue).length.toString() ==
  //            group.items.filter(item => item.selected).length.toString()) {
  //            group.isClosed = true;
  //          }

  //        })
  //      );
  //      //this.dataFromDeviceWithId.getAlertSymbolOptions = this.getAlertSymbolOptions(this.getAlertSymbolOptions, this.dataFromDeviceWithId.itemgroups.alertSymbol); // TODO: HÄR!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
  //      this.detector.markForCheck();
  //    });
  //}
  //private getData() {
  //  this.http.get('/assets/echo.json')
  //    .subscribe((data: ExampleItemgroups[]) => {
  //      this.dataItemGroups.next(data);
  //      data.forEach(() => this.diagramItems.push(null));
  //    });
  //}

  /* Edit ItemGroup */
  public itemGroupToEdit = new BehaviorSubject<ExampleItemgroups>(null);
  public editItemGroupModalIsOpen = new BehaviorSubject<boolean>(false);
  public openEditGroupModal(itemGroup: ExampleItemgroups) {
    this.itemGroupToEdit.next(itemGroup);
    this.editItemGroupModalIsOpen.next(true);
  }
  public editItemGroupModalClosed(e) {
    this.itemGroupToEdit.next(null);
    this.editItemGroupModalIsOpen.next(false);
  }

  /* Show diagram for DataItem */
  //public randomSerieData: any;
  //public showDiagramForItem(index: number, item: ExampleSavedDataItem) {
  //  let previousItem = this.diagramItems[index];
  //  this.diagramItems[index] = null; //reset
  //  if (previousItem != item)
  //    this.diagramItems[index] = item;

  //  this.randomSerieData = [
  //    [Date.UTC(2021, 0, 1), parseFloat((Math.random() * 10).toFixed(1))],
  //    [Date.UTC(2021, 0, 3), parseFloat((Math.random() * 10).toFixed(1))],
  //    [Date.UTC(2021, 0, 4), parseFloat((Math.random() * 10).toFixed(1))],
  //    [Date.UTC(2021, 0, 5), parseFloat((Math.random() * 10).toFixed(1))],
  //    [Date.UTC(2021, 0, 6), parseFloat((Math.random() * 10).toFixed(1))],
  //    [Date.UTC(2021, 0, 7), parseFloat((Math.random() * 10).toFixed(1))]
  //  ];
  //}

  /* Preview/Draw images */

  public imageToPreview = new BehaviorSubject<string>(null);
  public previewImageModalIsOpen = new BehaviorSubject<boolean>(false);
  public openPreviewImageModal(imageName) {
    this.imageToPreview.next(imageName);
    this.previewImageModalIsOpen.next(true);
  }
  public removePreviewedImage() {
    this.drawedImages = this.drawedImages.filter(x => x != this.imageToPreview.getValue());
    this.imageToPreview.next(null);
  }
  public previewImageModalClosed(e) {
    this.imageToPreview.next(null);
    this.previewImageModalIsOpen.next(false);
  }

  public addImageModalIsOpen = new BehaviorSubject<boolean>(false);
  public openAddImageModal() { this.addImageModalIsOpen.next(true); }
  public addImageModalClosed(e) { this.addImageModalIsOpen.next(false); }

}
