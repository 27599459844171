import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, EMPTY, Observable, Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DynamicSortService } from '@core/services/dynamic-sort.service';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { SelectListItem } from '@model/select-list-item';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { RemissService } from '@core/services/remiss.service';
import { AlertMessageItem, AlertMessageType } from '@model/alert-message-item';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OnDestroy } from '@angular/core';
import { catchError, map, startWith } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'remittance-list',
  templateUrl: './remittance-list.component.html',
  styleUrls: ['./remittance-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class RemittanceListComponent implements OnInit {
  alertMessage = new BehaviorSubject<AlertMessageItem>(null);
  /*Ta bort om vi inte ska ha exemplet med expanderbar tabell*/
  errorMessage = '';
  //expandedElement: DataPatient | null;
  //status: [];
  //remittanceList$ = this.dataService.remittanceList$.pipe(catchError(err => {
  //  this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
  //  return EMPTY;
  //}));

  private selectedRemDocSubject = new BehaviorSubject<number>(0);
  remDocSelectedAction$ = this.selectedRemDocSubject.asObservable();

  private selectedRemClinicSubject = new BehaviorSubject<number>(0);
  remClinicSelectedAction$ = this.selectedRemClinicSubject.asObservable();

  private selectedPrioSubject = new BehaviorSubject<number>(0);
  remPrioSelectedAction$ = this.selectedPrioSubject.asObservable();
  

  remittanceList$ = combineLatest([this.dataService.remittanceList$,
    this.remDocSelectedAction$, this.remClinicSelectedAction$, this.remPrioSelectedAction$]).pipe(map(([remittance, remDoc, remClinic, remPrio]) =>
      remittance.filter(rem =>
        (remDoc ? rem.remittanceDoctorId === remDoc : true) &&
        (remClinic ? rem.remittanceClinicId === remClinic : true) &&
        (remPrio ? rem.sysInfPriorityId === remPrio : true)),
      catchError(err => {
        this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, err.message));
        return EMPTY;
      })));

  remDoctorList$ = this.dataService.remDoctorList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, err));
    return EMPTY;
  }));

  remClinicList$ = this.dataService.remClinicList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, err));
    return EMPTY;
  }));

  remittancePriorityList$ = this.dataService.remittancePriorityList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, err));
    return EMPTY;
  }));

  remittanceChoosenToEdit: Observable<boolean>;
/*@Output() openShowRemissInfoModal = new EventEmitter<ViewRemittance>();*/
  public valdRemiss: RemittanceDto;
  @Output() remissEdit = new EventEmitter<RemittanceDto>();
  public valdPatient: number;
  isNewPatient: boolean = true;
  
  //sortedData: Remittance[]; 
  //sortedDataOriginal: PatientRemiss[];

  constructor(
    private dataService: RemissService,
    public translate: TranslateService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private modalService: NgbModal,
    private dynamicSort: DynamicSortService
  ) { }

  public faCaretDown = faCaretDown;
  public faCaretUp = faCaretUp;

  //public dataRemissWithId: Remiss;
  //public dataPatientWithId: DataPatient;

  ngOnInit(): void {
    this.initVisibleTableColumns();
    
  }
  onSelectedRemDoc(remDocId: string): void {
    this.selectedRemDocSubject.next(+remDocId);
  }
  onSelectedRemClinic(remClinicId: string): void {
    this.selectedRemClinicSubject.next(+remClinicId);
  }
  onSelectedPrio(prioId: string): void {
    this.selectedPrioSubject.next(+prioId);
  }
  
  //ngOnChanges(): void {
  //  this.getData();
  //  this.initVisibleTableColumns();
  //  if (this.remissEdit)
  //    console.log(this.remissEdit);
  //  if (this.remittanceChoosenToEdit)
  //    this.openAddRemissModal();
  //}
  //public loadDataErrorMessage = new BehaviorSubject<AlertMessageItem>(null);
  //private loadMessage(message: string, style: string) {
  //  this.loadDataErrorMessage.next(new AlertMessageItem(AlertMessageType.style, message));
  //  console.error(message);
  //}
  public getStatus(komplettera, aterremitterad, hasMultipleRemisses, planerad, vantarKomplettering): number {
    if (komplettera)
      return 1;
    else if (aterremitterad)
      return 2;
    else if (hasMultipleRemisses)
      return 3;
    else if (planerad)
      return 4;
    else if (vantarKomplettering)
      return 5;
    else
      return 0;
  }
  /* Add new remiss*/
  public addRemissModalIsOpen = new BehaviorSubject<boolean>(false);


  public valtRemissId: number;
  public openAddRemissModal(newPatient?: boolean) {
    //if(id)
    //  this.valtRemissId = id;
    if (newPatient)
      this.isNewPatient = newPatient;
    this.addRemissModalIsOpen.next(true);
  }
  public addRemissModalClosed(reason: string) {
    if (reason === 'Save') {
      this.alertMessage.next(new AlertMessageItem(AlertMessageType.Success, "Remissen är sparad."));
      window.scrollTo(0, 0);
    }
    this.addRemissModalIsOpen.next(false);
  }

  public remissEditClicked(remiss: RemittanceDto) {
    // the index will appear here
    this.isNewPatient = false;
    this.modalService.dismissAll();
    this.valdRemiss = remiss;
    console.log(this.valdRemiss);
    this.addRemissModalIsOpen.next(true);
    
  //  this.addRemissModalIsOpen.next(false);
  }
  /* Edit remiss*/
  //public valdRemissId: number;
  public openEditRemissModal(remiss: RemittanceDto) {
    //this.valdRemiss = remiss;
    console.log('remissid: ' + remiss.id);
    this.addRemissModalIsOpen.next(true);
  }
  /* Sorting */
  private defaultOrderColumn = 'pnr';
  public orderBy: string = this.defaultOrderColumn;
  public sortAscending: boolean = true;
  public setOrderBy(column: string, reload: boolean = false) {
    if (!reload) {
      this.sortAscending = this.orderBy != column ? true : !this.sortAscending;
    }
    this.orderBy = column;
    this.remittanceList$.pipe(map(y => {
      y.sort();
    }));
  //  this.remittanceList.sort(this.dynamicSort.sort(column, this.sortAscending));
  }
  /* Show/Hide table columns */
  public possibleTableColumns: SelectListItem[] = [];
  public visibleTableColumnsFC = new FormControl();
  private initVisibleTableColumns() {
    this.possibleTableColumns = [
      new SelectListItem('Källa', 'remisstyp'),
      new SelectListItem('Remissdatum', 'remissdatum'),
      new SelectListItem('PNR', 'pnr'),
      new SelectListItem('Remissnr (Klinfys)', 'remissnr'),
      new SelectListItem('Remiterande enhet', 'remitent'),
      new SelectListItem('Mobilisering', 'mobilisering'),
      new SelectListItem('Prioritering', 'prioritering'),
      new SelectListItem('Status', 'status')
    ];
    this.visibleTableColumnsFC.setValue(['remissdatum', 'pnr', 'remissnr', 'remitent', 'prioritering', 'mobilisering', 'status']);
    this.detector.markForCheck();
  }
  public columnIsVisible(column: string): boolean {
    let visibleColumns: string[] = this.visibleTableColumnsFC.value;
    return visibleColumns.indexOf(column) > -1;
  }

  /* Show remiss modal*/
  public showRemissModalIsOpen = new BehaviorSubject<boolean>(false);

  public showRemissModalClosed() {
    this.showRemissModalIsOpen.next(false);
    this.showRemissInfoModalIsOpen = false;
  }

  public showRemissInfoModalIsOpen: boolean = false;

  public openShowRemissInfoModal(currentRemiss: RemittanceDto) {
    this.valdRemiss = currentRemiss;
    this.showRemissInfoModalIsOpen = true;
  }
}
