import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TableSettings, TableSettingRowGroup } from '@model/table-setting'
import { faAngleDown, faAngleUp, faCheckCircle, faChevronCircleUp, faPencilAlt, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'table-preview-modal',
  templateUrl: 'table-preview-modal.component.html',
  styleUrls: ['table-preview-modal.component.scss']
})
export class TablePreviewModalComponent implements OnChanges {
  constructor(
    private modalService: NgbModal
  ) { }

  public faPlusCircle = faPlusCircle;
  public faChevronUp = faChevronCircleUp;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;
  public faPencilAlt = faPencilAlt;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;

  @Input() modalIsOpen: boolean;
  @Input() settings: TableSettings;

  @Output() modalClosed = new EventEmitter();
  
  @ViewChild("content") modalHtml;

  public groups: TableSettingRowGroup[] = [];
  public openGroups: OpenGroup[];

  ngOnChanges(): void {
    if (this.modalIsOpen == true) {
      this.openModal();
      this.groups = this.settings.groups;
      this.openGroups = this.settings.groups.map(g => {
        const openRows = g.rows.map(r => {
          let nrVariableRows = 1;
          if (r.variablesSecond) nrVariableRows++;
          if (r.variablesThird?.length > 0) nrVariableRows += r.variablesThird.length;
          return new OpenRow(nrVariableRows);
        });
        return new OpenGroup(g.rows.length, openRows);
      });
      console.log(this.openGroups);
    }
  }

  /** Öppna/Stäng rad med variabler **/
  public toggleRow(groupIndex: number, rowIndex: number) {
    let openGroup = this.openGroups[groupIndex];
    const isOpen = openGroup.openRows[rowIndex].open;
    openGroup.openRows[rowIndex].open = !isOpen;
    if (isOpen) {
      openGroup.anyOpenRow = true;
    } else { //Kolla om någon annan rad är öppen
      const anyOpenRow = openGroup.openRows.filter(r => r.open == true)?.length > 0;
      openGroup.anyOpenRow = anyOpenRow;
    }
  }

  public toggleGroup(groupIndex: number) {
    let openGroup = this.openGroups[groupIndex];
    openGroup.anyOpenRow = !openGroup.anyOpenRow;
    openGroup.openRows.forEach(r => r.open = openGroup.anyOpenRow);
  }

  allGroupsIsOpen = false;
  public toggleAllGroups() {
    this.allGroupsIsOpen = !this.allGroupsIsOpen;
    this.openGroups.forEach(group => {
      group.openRows.forEach(r => r.open = this.allGroupsIsOpen);
      group.anyOpenRow = this.allGroupsIsOpen;
    });
  }

  /** Open/Close modal **/
  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'table-preview-modal', size: 'lg', backdrop: 'static', keyboard: false })
  }
  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }
}

export class OpenGroup {
  constructor(
    public nrRows: number = 1,
    public openRows: OpenRow[] = []
  ) {}

  public anyOpenRow: boolean = false;

  public getNrRows(): number {
    let rows = 0;
    this.openRows.forEach(groupRow => {
      rows += 1;
      if (groupRow.open) rows += groupRow.nrVariables;
    })
    return rows;
  }

  public isRowOpen(rowIndex: number): boolean {
    const openRow = this.openRows[rowIndex];
    return openRow.open;
  }

}

export class OpenRow {
  constructor(
    public nrVariables: number = 1,
    public open: boolean = false
  ) {}
}