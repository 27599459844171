import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PatientLakemedelComponent } from './components/patient-lakemedel/patient-lakemedel.component';
import { ExaminationsRoutingModule } from './examinations.routing';
import { ExaminationInProgressComponent } from './echo/examination-in-progress/examination-in-progress.component';
import { OverviewPageVers2Component } from './echo/overview-page-vers2/overview-page-vers2.component';
import { ExaminationResultComponent } from './echo/examination-result/examination-result.component';
import { InputSnurraComponent } from './echo/input-snurra/input-snurra.component';
import { InputDataTableComponent } from './echo/input-data-table/input-data-table.component';
import { InputSnurraVers2Component } from './echo/input-snurra-vers2/input-snurra-vers2.component';
import { InputTableComponent } from './echo/input-table/input-table.component';
import { OverviewPageComponent } from './echo/overview-page/overview-page.component';
import { InputCarouselComponent } from './echo/input-carousel/input-carousel.component';
import { EditItemgroupModalComponent } from './components/edit-itemgroup-modal/edit-itemgroup-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { AppRoutingModule } from '../../app-routing.module';
import { DefiniatorComponent } from './echo/definiator/definiator.component';
import { AddInfoUndersokningModalComponent } from './components/add-infoUndersokning-modal/add-infoUndersokning-modal.component';
import { EditItemSurveyModalComponent } from './components/edit-itemsurvey-modal/edit-itemsurvey-modal.component';
import { PlanExaminationComponent } from '../patient/pages/plan-examination/plan-examination.component';
import { AddLakemedelModalComponent } from './components/add-lakemedel-modal/add-lakemedel-modal.component';
import { EditImageModalComponent } from './components/edit-image-modal/edit-image-modal.component';
import { PreviewImageModalComponent } from './components/preview-image-modal/preview-image-modal.component';
import { AddImageModalComponent } from './components/add-image-modal/add-image-modal.component';
import { DrawingPageComponent } from './drawing-page/drawing-page.component';
import { MatStepperModule } from '@angular/material/stepper';
import { ExaminationsListComponent } from './examinations-list/examinations-list.component';
import { EditExaminationModalComponent } from './components/add-examination-modal/add-examination-modal.component';
import { EchoTestbaddComponent } from './echo-testbadd/echo-testbadd.component';
import { EditValueExaminationComponent } from './components/edit-value-examination/edit-value-examination.component';
import { TruncatePipe } from '../../core/pipes/truncate-pipe/truncate-pipe';
import { EchoEditKavldataModalModalComponent } from './components/echo-edit-kavldata-modal/echo-edit-kavldata-modal.component';
import { OptionValueExaminationComponent } from './components/option-value-examination/option-value-examination.component';
import { ResultTableComponent } from './echo/result-table/result-table.component';

@NgModule({
  declarations: [
    AddInfoUndersokningModalComponent,
    InputSnurraComponent,
    DefiniatorComponent,
    InputTableComponent,
    ResultTableComponent,
    InputDataTableComponent,
    ExaminationInProgressComponent,
    OverviewPageVers2Component,
    ExaminationResultComponent,
    EditItemgroupModalComponent,
    InputSnurraVers2Component,
    OverviewPageComponent,
    InputCarouselComponent,
    EditItemSurveyModalComponent,
    PlanExaminationComponent,
    AddLakemedelModalComponent,
    PatientLakemedelComponent,
    EditImageModalComponent,
    PreviewImageModalComponent,
    AddImageModalComponent,
    DrawingPageComponent,
    ExaminationsListComponent,
    EditExaminationModalComponent,
    EchoTestbaddComponent,
    EditValueExaminationComponent,
    TruncatePipe,
    EchoEditKavldataModalModalComponent,
    OptionValueExaminationComponent
    ],
  imports: [
    AppRoutingModule,
    SharedModule,
    ExaminationsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule
  ],
  exports: [],
  providers: []
})
export class ExaminationsModule { }
