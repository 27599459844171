<div class="container mt-3">
  <h1 class="text-center">{{'pages.manage-table.title'|translate}}</h1>
  <hr>

  <div class="row justify-content-center">
    <div class="col-xl-10">
      <div class="row mb-3">
        <div class="col">
          <form [formGroup]="settingForm">
            <div class="form-floating">
              <input formControlName="title" id="title" type="text" class="form-control" placeholder="Namn på tabell" />
              <label for="title">Namn på tabell</label>
            </div>
          </form>
        </div>
        <div class="col text-end">
          <button class="btn btn-info" (click)="openPreviewModal()">Förhandsgranska tabell</button>
        </div>
      </div>

      <table class="table table-bordered">
        <thead>
          <tr>
            <td><i>Steg 1: Lägg till grupprad här</i></td>
            <td>
              <form [formGroup]="settingForm">
                <div class="form-floating">
                  <input formControlName="headerText" id="headerText" type="text" class="form-control" placeholder="Rubrik" />
                  <label for="headerText">Rubrik</label>
                </div>
              </form>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let group of (settings|async).groups; let groupIndex = index">
            <td class="pt-3">
              <div class="row">
                <div class="col">
                  <h5>{{group.name | titlecase}}</h5>
                </div>
                <div class="col-auto">
                  <a class="float-end clickable" (click)="openTableAddGrouprowModal(group, groupIndex)">
                    <span class="fa-stack" ngbTooltip="Redigera" placement="left">
                      <i class="fas fa-circle fa-stack-2x"></i>
                      <i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i>
                    </span>
                  </a>
                </div>
              </div>
            </td>
          
            <td>
              <table class="table table-bordered mb-0">
                <tr *ngFor="let row of group.rows; let rowIndex = index">
                  <td>
                    <div class="row">
                      <div class="col align-self-center">
                        <h6 class="m-0">{{row.rowName | titlecase}}</h6>
                      </div>
                      <div class="col-auto">
                        <a class="float-end clickable" (click)="openTableAddGroupSubrowsModal(groupIndex, rowIndex)"> 
                          <span class="fa-stack" ngbTooltip="Redigera" placement="left">
                            <i class="fas fa-circle fa-stack-2x"></i>
                            <i class="fas fa-pencil-alt fa-stack-1x fa-inverse"></i>
                          </span>
                        </a>
                      </div>
                    </div>
                  </td>
                </tr>   
                <tr>
                  <td class="py-3 px-2">
                    <button class="btn btn-link btn-icon btn-lg p-0" (click)="openTableAddGroupSubrowsModal(groupIndex, -1)">
                      <span class="fa-stack" ngbTooltip="Lägg till rad" placement="right">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fas fa-plus fa-stack-1x fa-inverse"></i>
                      </span>
                    </button>
                  </td>
                  <td></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr class="bg-light">
            <td class="p-3">
              <button class="btn btn-link btn-icon btn-lg p-0" (click)="openTableAddGrouprowModal(null, -1)">
                <span class="fa-stack" ngbTooltip="Lägg till grupprad" placement="right">
                  <i class="fas fa-circle fa-stack-2x"></i>
                  <i class="fas fa-plus fa-stack-1x fa-inverse"></i>
                </span>
              </button>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
      
      <div class="text-end">
        <button class="btn btn-secondary me-2">Avbryt</button>
        <button class="btn btn-primary" (click)="saveSettings()">Spara</button>
      </div>

      <hr>

      <div class="row">
        <div class="col">
          <button class="btn btn-sm btn-info" (click)="jsonDownload()"><i class="fas fa-download me-2"></i>Ladda ner till JSON</button>
        </div>
        <div class="col">
          <div class="row">
            <div class="col-auto rounded-start text-bg-info">
              <label class="col-form-label-sm clickable" onclick="document.getElementById('json-file-upload').click()"><i class="fas fa-upload me-2"></i>Läs in tabell från JSON</label>
            </div>
            <div class="col ps-0">
              <input id="json-file-upload" class="form-control form-control-sm not-rounded-start" type="file" (change)="handleFileInput($event)">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<table-add-grouprow-modal [modalIsOpen]="tableAddGrouprowModalIsOpen | async"
                          [rowGroup]="selectedRowGroup"
                          (onSubmit)="tableAddGrouprowModalSubmit($event)"
                          (onDelete)="tableAddGrouprowModalDelete()"
                          (modalClosed)="tableAddGrouprowModalClosed()"
                          ></table-add-grouprow-modal>

<table-add-group-subrows-modal [modalIsOpen]="tableAddGroupSubrowsModalIsOpen | async"
                               [row]="selectedRow"
                               (onSubmit)="tableAddGroupSubrowsSubmit($event)"
                               (onDelete)="tableAddGroupSubrowsModalDelete()"
                               (modalClosed)="tableAddGroupSubrowsModalClosed()"
                               ></table-add-group-subrows-modal>

<table-preview-modal [modalIsOpen]="previewModalIsOpen | async"
                     [settings]="previewSettings | async"
                     (modalClosed)="closePreviewModal()"
                     ></table-preview-modal>
