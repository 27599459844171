import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormGroup} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'edit-itemsurvey-modal',
  templateUrl: './edit-itemsurvey-modal.component.html',
  styleUrls: ['./edit-itemsurvey-modal.component.scss']
})
export class EditItemSurveyModalComponent implements OnChanges {

  constructor(
    public translate: TranslateService,
    private modalService: NgbModal,
  ) { }

  @Input() item: DataItem;
  @Input() itemGroup: DataItemGroup;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();
 

  @ViewChild("content") modalHtml;

  public form: FormGroup;
  public loading = new BehaviorSubject<boolean>(false);
  public formItems: DataItem[] = [];
  public drawedImages: string[] = ['resurs-2-edit', 'resurs-3-edit'];

  public diagramItems: DataItem[] = [];
  public opiniontext: string;

  ngOnInit() {
    //TODO: Ändra fuskat index
    this.showDiagramForItem(2, this.item);
  }
  ngOnChanges(): void {
    if (this.modalIsOpen && this.item) {
      this.openModal();
    }
  }

  /* Modal */
  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'edit-itemgroup-modal', size: 'xl' })
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }

  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
    this.opiniontext = null; //reset
  }

  public getRangeError(formItem): any {
    let error = this.form.get(formItem.variable).getError('rangeError');
    return error ? error : null;
  }
  public getErrorText(formItem): any {
    return { value: this.form.get(formItem.variable).getError('errorText') };
  }
  /* Show diagram for DataItem */
  public randomSerieData: any;
  public showDiagramForItem(index: number, item: DataItem) {
    let previousItem = this.diagramItems[index];
    this.diagramItems[index] = null; //reset
    if (previousItem != item)
      this.diagramItems[index] = item;

    this.randomSerieData = [
      [Date.UTC(2021, 0, 1), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 3), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 4), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 5), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 6), parseFloat((Math.random() * 10).toFixed(1))],
      [Date.UTC(2021, 0, 7), parseFloat((Math.random() * 10).toFixed(1))]
    ];
  }
  public onSubmit() {
    //TODO: Save
    this.closeModal();
  }
}
