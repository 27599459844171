import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { startWith, map } from 'rxjs/operators';
//import { PatientRemiss } from '@model/data-patientremiss';
import { SelectListItem } from '@model/select-list-item';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
//import { Remiss } from '@model/data-remiss';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { PatientListDto } from '@model/patienter/patient-list-dto';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'show-remiss-modal',
  templateUrl: './show-remiss-modal.component.html',
  styleUrls: ['./show-remiss-modal.component.scss']
})
export class ShowRemissModalComponent implements OnChanges, OnDestroy {

  @Input() modalIsOpen: boolean;
  @Input() patientRemiss: RemittanceDto;
  @Output() remissEdit = new EventEmitter<RemittanceDto>();
  @Output() modalClosed = new EventEmitter<string>();
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;

  constructor(
    public translate: TranslateService,
    private modalService: NgbModal,
    private http: HttpClient,
    private detector: ChangeDetectorRef
  ) { }
  @ViewChild("content") modalHtml;

  public loading = new BehaviorSubject<boolean>(false);
  public mobiliseringString: string;
  public mobiliseringOptions: SelectListItem[] = [];

  ngOnChanges(): void {
    if (this.modalIsOpen) {
      this.openModal();
      this.setForm();
    //  this.saveStatusRemissForm.get('remissStatus').setValue('skickaplanering');
    }
  }
  ngOnDestroy() {
    console.log('stänger');
  }
  get remissStatus() { return this.saveStatusRemissForm.get('remissStatus'); }
  get delegeraLakare() { return this.saveStatusRemissForm.get('delegeraLakare'); }
  get commentRemissStatus() { return this.saveStatusRemissForm.get('commentRemissStatus'); }
  get remissId() { return this.saveStatusRemissForm.get('remissId'); }
  get id() { return this.saveStatusRemissForm.get('id'); }

  public saveStatusRemissForm: FormGroup;
 public setForm() {
   this.saveStatusRemissForm = new FormGroup({
      id: new FormControl("", Validators.required),
      remissId: new FormControl("", Validators.required),
      remissStatus: new FormControl("", Validators.required),
      delegeraLakare: new FormControl(""),
      commentRemissStatus: new FormControl('')
    },
    { updateOn: "blur" });
    this.saveStatusRemissForm.reset();
  }
  public getData() {
  //  this.mobiliseringString = this.getMobiliseringString(this.mobiliseringOptions, this.patientRemiss.mobilisering);
  }
  /* Modal */
  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'show-remiss-modal', size: 'xl', backdrop: 'static' })
      .result.then((reason) => { this.modalClosed.emit(reason) }, (reason) => {this.modalClosed.emit(reason) });
  }

  //public closeModal() {
  //  this.modalClosed.emit('Close');
  //  this.modalClosed.complete();
  //  this.modalService.dismissAll('Close');
  //}

  //public delegera: boolean = false;
  //public avsla: boolean = false;
  //public komplettering: boolean = false;
  //kompletteringFraga(q) {
    
  //  if (q == 'delegera') {
  //    this.avsla = false;
  //    this.komplettering = false;
  //    this.delegera = !this.delegera;
  //  }
  //  if (q == 'avsla') {
  //    this.delegera = false;
  //    this.komplettering = false;
  //    this.avsla = !this.avsla;
  //  }
  //  if (q == 'komplettering') {
  //    this.avsla = false;
  //    this.delegera = false;
  //    this.komplettering = !this.komplettering;
  //  }
  //}
  //public editRemiss(remiss: ViewRemittance) {
  //  this.remissEdit.emit(remiss);
  //  this.modalService.dismissAll();
  //}
  //public getMobiliseringString(mobiliseringOptions, value): string {
  //  return mobiliseringOptions.filter(mobilisering => mobilisering.value == value)[0].text;
  //}
}
