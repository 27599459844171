<!--<div class="input-group input-group-sm col-sm-12 m-0 p-0">
  <div class="col-sm-12 form-control text-lg-right py-3">{{input}}</div>
</div>-->
<form [formGroup]="formelGrupp">
  <div class="input-group input-group-sm col-sm-12 m-0 p-0">
    <input type="text" class="form-control plaintext" formControlName="formelcontrol" readonly>
  </div>
  <div class="text-danger" *ngIf="formelcontrol.errors?.paranteserror && formelcontrol.touched">
    Fel antal parenteser.
  </div>
</form>
<!--<div class="input-group input-group-sm col-sm-12 m-0 p-0">
  <div class="form-control text-sm-right" type="text">{{result}}</div>
</div>-->
<div class="col-sm-12 p-1 m-0">
  <button class="btn btn-info col-sm-6" type="button" (click)="allClear()" [ngbTooltip]="'Radera formel'">C</button>
  <button class="btn btn-warning col-sm-3" type="button" (click)="clear()" [ngbTooltip]="'Radera föregående'"><svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><path d="M576 128c0-35.3-28.7-64-64-64H205.3c-17 0-33.3 6.7-45.3 18.7L9.4 233.4c-6 6-9.4 14.1-9.4 22.6s3.4 16.6 9.4 22.6L160 429.3c12 12 28.3 18.7 45.3 18.7H512c35.3 0 64-28.7 64-64V128zM271 175c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z" /></svg></button>
  <button class="btn btn-secondary col-sm-3" type="button" (click)="pressOperator('/')">/</button>
</div>

<div class="col-sm-12 p-1 m-0">
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('7')">7</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('8')">8</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('9')">9</button>
  <button class="btn btn-lg btn-secondary col-sm-3 p-1" type="button" (click)="pressOperator('*')">X</button>
</div>
<div class="col-sm-12 p-1 m-0">
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('4')">4</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('5')">5</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('6')">6</button>
  <button class="btn btn-lg btn-secondary col-sm-3 p-1" type="button" (click)="pressOperator('-')">-</button>
</div>
<div class="col-sm-12 p-1 mt-0">
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('1')">1</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('2')">2</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('3')">3</button>
  <button class="btn btn-lg btn-secondary col-sm-3 p-1" type="button" (click)="pressOperator('+')">+</button>
</div>
<div class="col-sm-12 p-1 m-0">
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('.')">.</button>
  <button class="btn btn-lg btn-outline-secondary col-sm-3 p-1" type="button" (click)="pressNum('0')">0</button>
  <button class="btn btn-lg btn-success col-sm-6 p-1" type="button" (click)="getAnswer()">=</button>
</div>
<div class="col-sm-12 px-1 m-0">
  <ng-container *ngFor="let t of tecken">
    <button class="btn btn-lg btn-secondary col-sm-3 p-1 mt-2" type="button" (click)="pressTecken(t)">{{t.beskrivning}}</button>
  </ng-container>
</div>
<div class="col-sm-12 px-1 m-0">
  <ng-container *ngFor="let const of constants">
    <button class="btn btn-lg btn-dark col-sm-3 p-1 mt-2" type="button" (click)="pressConst(const)">{{const.description}}</button>
  </ng-container>
</div>
<div class="col-sm-12 mt-2">
  <div class="card bg-light" *ngIf="answer">
    <div class="card-body">
      <b>Uträknat:</b> {{answer}} {{cVariable.unitShort}}
    </div>
  </div>
</div>
