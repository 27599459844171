import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { TableSettingRowGroup } from '@model/table-setting'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'table-add-grouprow-modal',
  templateUrl: 'table-add-grouprow-modal.component.html',
  styleUrls: ['table-add-grouprow-modal.component.scss']
})

export class TableAddGrouprowComponent implements OnChanges {
  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }

  @Input() rowGroup: TableSettingRowGroup;
  @Input() modalIsOpen: boolean;

  @Output() modalClosed = new EventEmitter();
  @Output() onSubmit = new EventEmitter<FormGroup>();
  @Output() onDelete = new EventEmitter();

  @ViewChild("content") modalHtml;

  public form: FormGroup;

  ngOnChanges(): void {
    if (this.modalIsOpen == true) {
      this.openModal();
      this.setForm()
    }
  } 

  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'table-add-grouprow-modal', size: 'md', backdrop: 'static', keyboard: false });
  }

  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }

  private setForm() {
    this.form = this.fb.group({
      name: new FormControl(this.rowGroup.name, Validators.required),
    });
  }

  get name() {
    return this.form.get('name') as FormControl;
  }

  public submitForm() {
    if (this.form.valid) {
      this.modalService.dismissAll(); //Stänger modal
      this.onSubmit.emit(this.form);
    } 
    else this.form.markAllAsTouched();
  }

  public deleteGroup() {
    this.modalService.dismissAll();
    this.onDelete.emit();
  }
}

