
  <div class="modal-header">
    <h5 class="modal-title" id="narstaende-uppg-modal">Vill du verkligen radera variabeln?</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="onCancel()"></button>
  </div>
  <form>
    <div class="modal-body">
      <div class="alert alert-danger" role="alert">
        Vill du verkligen radera <b>{{vDescription}}</b> variabeln? Den går inte att återskapa. Du kan däremot skapa en ny variabel med samma namn.
      </div>
    </div>
    <div class="row">
      <div class="col-8 offset-3">
        <div class="alert alert-danger" role="alert" *ngIf="errorMessage!==''">
          <pre style="font-family: inherit">{{errorMessage}}</pre>
        </div>
      </div>
    </div>
    <div class="modal-footer clearfix">
      <button type="button" class="btn btn-secondary cancel" (click)="onCancel()">Avbryt</button>
      <button type="button" class="btn btn-danger delete" (click)="onDelete()">Radera variabel</button>
    </div>
  </form>
