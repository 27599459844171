
<div class="row justify-content-center">
    <div class="col-md-8 col-lg-6 col-xl-5">
        <div id="input-carousel-container" class="mx-5">
            <input-carousel [formGroup]="echoForm"
                            (onSubmit)="onSubmit($event)"
                            [itemGroups]="dataItemGroups"
                            [(activeItemGroup)]="activeItemGroup"
                            [(activeDataItem)]="activeItem"
                            ></input-carousel>
        </div>
    </div>
    <div class="col-md-8 col-lg-6 col-xl-5 pl-md-0">
        <!--<div id="patient-info">
            <label>{{ echoForm.value.patientId }}</label>
        </div>-->
        <div id="content-table">
            <div class="ordered-masonry-with-flex">
                <ng-container *ngFor="let group of dataItemGroups">
                    <input-data-table [formGroup]="echoForm"
                                        [itemGroup]="group"
                                        [(activeItemGroup)]="activeItemGroup"
                                        [(activeItem)]="activeItem"
                                        ></input-data-table>
                </ng-container>
            </div>
        </div>
    </div>
</div>
