<ng-template #content let-modal size="xl">
  <div class="modal-header">
    <h5 class="modal-title" id="edit-itemsurvey-modal">{{item.variable}} </h5>
    <!--<button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>-->
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>

  </div>
  <div class="modal-body">
    <div class="card bg-dark text-light">
      <div class="card-body">
        <div class="row">
          <div class="col-4">
            <label>
              <b>Variabel:</b> {{item.caption}}
            </label>
          </div>

          <div class="col-4">
              <ng-container *ngIf="item.format=='[]'">
                <ng-container *ngFor="let option of item.options">
                  <ng-container *ngIf="option.selected=='true'">
                    <p><span><b>Värde:</b> {{option.value}}</span></p>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-container *ngIf="item.format=='##'">
                    <p><span><b>Värde:</b> {{item.value}}</span></p>
              </ng-container>
              <p *ngIf="collapsed"><input type="text" class="form-control" placeholder="Ange nytt värde"></p>
          </div>
          <div class="col-4">
            <p class="text-right"><a class="link text-decoration-underline text-light" (click)="collapsed=!collapsed">Ändra värde <span [ngClass]="collapsed ? 'fas fa-chevron-up' : 'fas fa-chevron-down'"></span></a>
          </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="row">
        <div [class.col-5]="item.format=='[]'" [class.col-9]="item.format=='##'">
          <div class="form-group">
            <label for="opinionText"><b>Utlåtandetext:</b></label><span class="float-right pb-1"><button class="btn" (click)="opiniontext='<b>' + opiniontext + '</b>'"><i class="fas fa-bold"></i></button><button class="btn" (click)="opiniontext='<i>' + opiniontext + '</i>'"><i class="fas fa-italic"></i></button></span>
            <textarea class="form-control" name="opinionText" rows="10" [(ngModel)]="opiniontext"></textarea>
          </div>
        </div>
        <ng-container *ngIf="item.format=='[]'">
          <div class="col-4">
            <label><b>Välj ett annat utlåtande</b></label>
            <ul class="list-group">
              <li class="list-group-item" style="cursor: pointer;" *ngFor="let option of item.options | slice:1" (click)="opiniontext=option.text" [ngClass]="opiniontext == option.text ? 'active' : ''">{{option.text}}</li>
            </ul>
          </div>
        </ng-container>
        <div class="col-3">
          <div *ngIf="drawedImages && drawedImages.length > 0" class="p-5">
            <div *ngFor="let image of drawedImages">
              <div class="zoomable-image pl-2 pl-lg-0 mb-0 mb-lg-2" (click)="openPreviewImageModal(image)">
                <img [src]="'assets/img/' + image + '.png'" class="img-fluid" />
                <fa-icon [icon]="faSearchPlus" class="zoom-icon" size="2x"></fa-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="dataitem-info-box mt-4 px-0 pt-2 pr-1">
          <diagram [dataItem]="item"
                   [series]="[{name: item.shortCaption, data: randomSerieData}]" [style]="w-100"></diagram>
        </div>
      </div>
        </div>
        
          

  <!--<ng-container *ngIf="form && !(loading | async)">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="modal-body">
        <div class="form-group row" *ngFor="let formItem of item">
          <div class="col-3 text-right">
            <label class="col-form-label">
              {{formItem.shortCaption}}
              <span class="pl-2" *ngIf="formItem.symbol">{{formItem.symbol}}</span>
            </label>
          </div>
          <div class="col-7">
            <ng-container [ngSwitch]="formItem.format">
              <ng-container *ngSwitchCase="'[]'">
                <select class="form-control" [formControlName]="formItem.variable" required
                        [class.ng-error]="getRangeError(formItem) && getRangeError(formItem) < 3"
                        [class.ng-warning]="getRangeError(formItem) && getRangeError(formItem) >= 3">
                  <option *ngFor="let opt of formItem.options" [value]="opt.value">{{opt.text}}</option>
                </select>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input type="text" class="form-control" [formControlName]="formItem.variable" required
                       [placeholder]="formItem.unit.length>0 ? '('+formItem.unit+')' : ''"
                       [class.ng-error]="getRangeError(formItem) && getRangeError(formItem) < 3"
                       [class.ng-warning]="getRangeError(formItem) && getRangeError(formItem) >= 3">
              </ng-container>
            </ng-container>

            <div class="input-error">
              <ng-container [ngSwitch]="getRangeError(formItem)">
                <span *ngSwitchCase="1" class="form-text text-danger">
                  <i class="fas fa-exclamation-circle"></i>
                  <span class="pl-2" translate>pages.input.ref.lower-extreme</span>
                </span>
                <span *ngSwitchCase="2" class="form-text text-danger">
                  <i class="fas fa-exclamation-circle"></i>
                  <span class="pl-2" translate>pages.input.ref.upper-extreme</span>
                </span>
                <span *ngSwitchCase="3" class="form-text text-warning">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span class="pl-2" translate [translateParams]="getErrorText(formItem)">pages.input.ref.lower-normal</span>
                </span>
                <span *ngSwitchCase="4" class="form-text text-warning">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span class="pl-2" translate [translateParams]="getErrorText(formItem)">pages.input.ref.upper-normal</span>
                </span>
              </ng-container>
            </div>

            <span class="form-text">{{formItem.caption}}</span>
          </div>
        </div>
      </div>-->
  <div class="modal-footer">
    <button type="button" class="btn btn-info cancel" (click)="closeModal()">{{'common.close' | translate}}</button>
    
    <button type="submit" class="btn btn-success save">{{'common.save' | translate}}</button>
  </div>
  <!-- </form>
  </ng-container>-->
</ng-template>
