<div class="col-md-6 offset-md-3 mt-5">
  <div class="card">
    <h4 class="card-header">Logga in</h4>
    <div class="card-body">
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="username">Användarnamn</label>
          <input type="text" formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
          <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
            <div *ngIf="f.username.errors.required">Användarnamn är obligatoriskt</div>
          </div>
        </div>
        <div class="form-group">
          <label for="password">Lösenord</label>
          <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
          <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
            <div *ngIf="f.password.errors.required">Lösenord är obligatoriskt</div>
          </div>
        </div>
        <button [disabled]="loading" class="btn btn-primary">
          <span *ngIf="loading" class="spinner-border spinner-border-sm me-1"></span>
          Logga in
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
      </form>
    </div>
  </div>
</div>
