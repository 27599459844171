<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title" id="edit-itemgroup-modal">{{itemGroup.group}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <ng-container *ngIf="form && !(loading | async)">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <div class="modal-body">
        <div class="form-group row" *ngFor="let formItem of formItems">
          <div class="col-3 text-right">
              <label class="col-form-label">
                  {{formItem.shortCaption}}
                  <span class="pl-2" *ngIf="formItem.symbol">{{formItem.symbol}}</span>
              </label>
          </div>
          <div class="col-7">
            <ng-container [ngSwitch]="formItem.format">
              <ng-container *ngSwitchCase="'[]'" >
                <select class="form-control" [formControlName]="formItem.variable" required
                        [class.ng-error]="getRangeError(formItem) && getRangeError(formItem) < 3"
                        [class.ng-warning]="getRangeError(formItem) && getRangeError(formItem) >= 3">
                  <option *ngFor="let opt of formItem.options" [value]="opt.value">{{opt.text}}</option>
                </select>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input type="text" class="form-control" [formControlName]="formItem.variable" required
                       [placeholder]="formItem.unit.length>0 ? '('+formItem.unit+')' : ''"
                       [class.ng-error]="getRangeError(formItem) && getRangeError(formItem) < 3"
                       [class.ng-warning]="getRangeError(formItem) && getRangeError(formItem) >= 3">
              </ng-container>
            </ng-container>

            <div class="input-error">
              <ng-container [ngSwitch]="getRangeError(formItem)">
                <span *ngSwitchCase="1" class="form-text text-danger">
                  <i class="fas fa-exclamation-circle"></i>
                  <span class="pl-2" translate>pages.input.ref.lower-extreme</span>
                </span>        
                <span *ngSwitchCase="2" class="form-text text-danger">
                  <i class="fas fa-exclamation-circle"></i>
                  <span class="pl-2" translate>pages.input.ref.upper-extreme</span>
                </span>        
                <span *ngSwitchCase="3" class="form-text text-warning">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span class="pl-2" translate [translateParams]="getErrorText(formItem)">pages.input.ref.lower-normal</span>
                </span>        
                <span *ngSwitchCase="4" class="form-text text-warning">
                  <i class="fas fa-exclamation-triangle"></i>
                  <span class="pl-2" translate [translateParams]="getErrorText(formItem)">pages.input.ref.upper-normal</span>
                </span>        
              </ng-container>
            </div>

            <span class="form-text">{{formItem.caption}}</span>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-info cancel" (click)="closeModal()">{{'common.close' | translate}}</button>
        <button type="submit" class="btn btn-success save">{{'common.save' | translate}}</button>
      </div>
    </form>
  </ng-container>
</ng-template>
