import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TableSettings, TableSettingRowGroup, TableSettingRow } from '@model/table-setting';
import { ApiService } from '@core/services/api.service';

import { faAngleDown, faAngleUp, faCheckCircle, faChevronCircleUp, faPencilAlt, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { BehaviorSubject } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-manage-table',
  templateUrl: './manage-table.component.html',
  styleUrls: ['./manage-table.component.scss']
})
export class ManageTableComponent implements OnInit {
  constructor(
/*    private apiService: ApiService,*/
    private detector: ChangeDetectorRef,
    private fb: FormBuilder
  ) { }

  public faPlusCircle = faPlusCircle;
  public faChevronUp = faChevronCircleUp;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;
  public faPencilAlt = faPencilAlt;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  private selectedGroupIndex: number;
  private selectedRowIndex: number;

  ngOnInit(): void {
    this.setForm();
    //TODO: Läsa in från json redan här?
  }

  public settingForm: FormGroup;
  public settings = new BehaviorSubject<TableSettings>(new TableSettings());
  
  //Selected
  public selectedRowGroup: TableSettingRowGroup;
  public selectedRow: TableSettingRow;

  //Modals
  public tableAddGroupSubrowsModalIsOpen = new BehaviorSubject<boolean>(false);
  public tableAddGrouprowModalIsOpen = new BehaviorSubject<boolean>(false);
  public previewModalIsOpen = new BehaviorSubject<boolean>(false);
  public previewSettings = new BehaviorSubject<TableSettings>(null);


  private setForm() {
    this.settingForm = this.fb.group({
      title: new FormControl(this.settings.getValue().title),
      headerText: new FormControl(this.settings.getValue().headerText)
    });
    this.settingForm.valueChanges.subscribe(() => this.settingFormChange());
  }

  private settingFormChange() {
    if (this.settingForm.valid) {
      const _settings = this.settings.getValue();
      _settings.title = this.settingForm.get('title').value;
      _settings.headerText = this.settingForm.get('headerText').value;
      this.settings.next(_settings);
    }
  }

  public saveSettings() {
    //TODO: Spara
    console.log(this.settings.getValue());
  }


  /***  Group Row  ***/

  //Open/Close
  public openTableAddGrouprowModal(group: TableSettingRowGroup, groupIndex: number) {
    this.selectedGroupIndex = groupIndex;
    this.selectedRowGroup = !group ? new TableSettingRowGroup() : group;
    this.tableAddGrouprowModalIsOpen.next(true);
  }
  public tableAddGrouprowModalClosed() {
    this.tableAddGrouprowModalIsOpen.next(false);
  }

  //Save
  public tableAddGrouprowModalSubmit(name) {
    const _settings = this.settings.getValue();
    if (this.selectedGroupIndex >= 0) {
      _settings.groups[this.selectedGroupIndex].name = name.value.name;
    }
    else {
      let i: number = _settings.groups.length;
      _settings.groups.push({ id: i, name: name.value.name, rows: [] });
    }
    this.settings.next(_settings);
    this.tableAddGrouprowModalIsOpen.next(false);
  }

  //Delete
  public tableAddGrouprowModalDelete() {
    const _settings = this.settings.getValue();
    _settings.groups.splice(this.selectedGroupIndex, 1);
    this.settings.next(_settings);
    this.tableAddGrouprowModalIsOpen.next(false);
  }


  /***  Table Group Subrows  ***/

  // Open/Close
  public openTableAddGroupSubrowsModal(groupIndex, rowIndex) {
    this.selectedGroupIndex = groupIndex;
    this.selectedRowIndex = rowIndex;
    this.selectedRow = 
      rowIndex >= 0 ?
        this.settings.getValue().groups[this.selectedGroupIndex].rows[this.selectedRowIndex] 
        : new TableSettingRow();

    this.tableAddGroupSubrowsModalIsOpen.next(true);
  }
  public tableAddGroupSubrowsModalClosed() {
    this.tableAddGroupSubrowsModalIsOpen.next(false);
  }

  //Save
  public tableAddGroupSubrowsSubmit(row: TableSettingRow) {
    const _settings = this.settings.getValue();
    if (this.selectedRowIndex >= 0) { 
      _settings.groups[this.selectedGroupIndex].rows[this.selectedRowIndex] = row;
    }
    else _settings.groups[this.selectedGroupIndex].rows.push(row);
    this.settings.next(_settings);
    this.tableAddGroupSubrowsModalIsOpen.next(false);
  }

  //Delete
  public tableAddGroupSubrowsModalDelete() {
    const _settings = this.settings.getValue();
    _settings.groups[this.selectedGroupIndex].rows.splice(this.selectedRowIndex, 1);
    this.settings.next(_settings);
    this.tableAddGroupSubrowsModalIsOpen.next(false);
  }


  /***  Preview Table  ***/
  //Open/Close
  public openPreviewModal() {
    this.previewSettings.next(this.settings.getValue());
    this.previewModalIsOpen.next(true);
  }
  public closePreviewModal() {
    this.previewModalIsOpen.next(false);
  }


  /**  JSON Table Handling  **/
  public jsonDataLoaded = false;

  public handleFileInput(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const jsonData = JSON.parse(reader.result as string);
      if (jsonData) { 
        this.settings.next(jsonData);
        this.setForm();
      }
      console.log(jsonData)
    };
    reader.readAsText(file);
  }
  public jsonDownload() {
    let jsonString = JSON.stringify(this.settings.getValue());
    console.log('efter', jsonString)

    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = this.settings.getValue().title + '_table-settings.json';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

}
