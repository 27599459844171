<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title">{{row.rowName ? "Redigera rad" : "Ny rad"}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>

  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="modal-body">

      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="form-floating">
            <input formControlName="rowName" id="form" type="text" class="form-control text-center" value="rowName" 
                   placeholder="Text på tabellraden">
            <label for="rowName">Text på tabellraden:</label>
          </div>
          <i class="invalid-feedback"
             [class.d-block]="rowName?.invalid && rowName?.touched">Obligatoriskt fält</i>
        </div>
        <div class="col-12">
          <div class="text-center fst-italic my-3">
            <p>
              När två värden används, sker uträkningen värde 1 genom värde 2.<br>
              När värdena är ifyllda klicka på "Förhandsgranska tabell".
            </p>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="form-floating">
            <input formControlName="variablesFirst" id="variablesFirst" class="form-control" 
                   placeholder="Värde 1 - Klicka för att välja värde"
                   [matAutocomplete]="variableAutoComplete" />
            <mat-autocomplete #variableAutoComplete>
              <mat-option *ngFor="let option of variableOptionTexts" [value]="option"> {{option}} </mat-option>
            </mat-autocomplete>
            <label for="variablesFirst">Värde 1:</label>
          </div>
          <i class="invalid-feedback"
             [class.d-block]="variablesFirst?.invalid && variablesFirst?.touched">Obligatoriskt fält</i>
          <div class="form-floating my-3">
            <input formControlName="variablesSecond" id="variablesSecond" class="form-control"
                   placeholder="Värde 2 - Klicka för att välja värde"
                   [matAutocomplete]="variableAutoComplete" />
            <mat-autocomplete #variableAutoComplete>
              <mat-option *ngFor="let option of variableOptionTexts" [value]="option"> {{option}} </mat-option>
            </mat-autocomplete>
            <label for="variablesSecond">Värde 2:</label>
          </div>
          <div class="card">
            <div class="card-header py-0">
              <label class="col-form-label">Associerade värden:</label>
            </div>
            <div class="card-body">
              <div formArrayName="variablesThird">
                <div cdkDropList [cdkDropListData]="variablesThird"
                                 (cdkDropListDropped)="dragDropVariable($event)">
                  <div cdkDrag *ngFor="let _variablesthird of variablesThird.controls; let i=index;">
                    <div class="row g-2 mb-2">
                      <div class="col">
                        <input [formControlName]="i" id="variablesthird-{{ i }}" for="variablesthird-{{ i }}" class="form-control" 
                                [matAutocomplete]="variableAutoComplete" />
                        <mat-autocomplete #variableAutoComplete>
                          <mat-option *ngFor="let option of variableOptionTexts" [value]="option"> {{option}} </mat-option>
                        </mat-autocomplete>
                        
                        <i class="invalid-feedback"
                          [class.d-block]="_variablesthird?.invalid && _variablesthird?.touched">Obligatoriskt fält</i>
                      </div>
                      <div class="col-sm-auto">
                        <button type="button" class="btn btn-secondary cursor-move" cdkDragHandle ngbTooltip="Flytta upp/ner"><i class="fas fa-arrows-alt-v"></i></button>
                      </div>
                      <div class="col-sm-auto text-end">
                        <button type="button" class="btn btn-outline-danger px-2" (click)="deleteThird(i)" ngbTooltip="Ta bort värde"><i class="fas fa-minus-circle"></i></button>
                      </div>
                    </div>
                  </div>
                  <button class="btn btn-sm btn-primary" type="button" (click)="addThird()">
                    <i class="fas fa-plus me-2"></i>Lägg till associerat värde
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button *ngIf="row.rowName" type="button" class="btn btn-outline-danger float-start" (click)="deleteRow()">Ta bort</button>
      <button type="submit" class="btn btn-primary float-end" (ngSubmit)="submitForm()">Spara</button>
      <button type="button" class="btn btn-secondary float-end me-2" (click)="closeModal()">Avbryt</button>
    </div>
  </form>
</ng-template>



