import { ChangeDetectionStrategy, EventEmitter, OnChanges } from '@angular/core';
import { OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { Component } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { single } from 'rxjs/operators';
import { CalculationService, Formel, FormelTecken, Grunddata } from '../../../core/services/calculation.service';
import { Constants, VariablesService } from '../../../core/services/variabler.service';
import { EchoVariablesDto } from '../../../model/undersokningsvarianter/echo-variables-dto';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-create-formula',
  templateUrl: './create-formula.component.html'
})
export class CreateFormulaComponent implements OnInit {
  @Output() formula = new EventEmitter<any>();
  @Output() formulaChanged = new EventEmitter<boolean>();
  @Output() showParantesesError = new EventEmitter<boolean>();
  @Output() formulaAnswer = new EventEmitter<any>();
  @Output() message = new EventEmitter<string>();
  @Output() openGrunddata = new EventEmitter<boolean>();
  @Input() cVariable: EchoVariablesDto;
  @Input() grunddata: Grunddata;
  @Input() type: Number;
  @Input() controlId: Number;
  @Input() formel: string;

  answer: string = "";
  result: string = '';

  //@Input() formler: Formel[];
  //formel: Formel;
  constants: Constants[] = [{ id: 1, description: "BSA", value: 0 }, { id: 2, description: "Ålder", value: 0 }, { id: 3, description: "Längd", value: 0 }, { id: 4, description: "Vikt", value: 0 }];
  tecken: FormelTecken[] = [{ id: 1, beskrivning: '(' }, { id: 2, beskrivning: ')' }, { id: 3, beskrivning: '√', utanKonstant: 'sqrt(' }, { id: 4, beskrivning: 'log()', utanKonstant: 'log(' }, { id: 5, beskrivning: 'ln()', utanKonstant: 'ln(' }, { id: 6, beskrivning: '10^', utanKonstant: 'pow(10,' }, { id: 7, beskrivning: 'π', tillFormeln: 'PI' }];
  constructor(
    private service: CalculationService
  ) { }
  //////////
  ///
  ngOnInit() {
    this.setForm();
  }
  private _formelGrupp: FormGroup = new FormGroup({
    formelcontrol: new FormControl("", this.validatorParanteses())
  });
  get formelGrupp(): FormGroup { return this._formelGrupp; }
  get formelcontrol() { return this.formelGrupp.get("formelcontrol"); }
  //////////
  ///
  setForm() {
    this.formelGrupp.get("formelcontrol").patchValue(this.formel);
  }
  //////////
  ///
  pressNum(num: string) {

    //Do Not Allow . more than once
    if (num == ".") {
      if (this.formelGrupp.get("formelcontrol").value != "") {

        const lastNum = this.getLastOperand()
        console.log(lastNum.lastIndexOf("."))
        if (lastNum.lastIndexOf(".") >= 0) return;
      }
    }

    //Do Not Allow 0 at beginning. 
    //Do Not Allow 0 at beginning.
    //Javascript will throw Octal literals are not allowed in strict mode.
    if (num == "0") {
      if (this.formelGrupp.get("formelcontrol").value == "") {
        return;
      }
      const PrevKey = this.formelGrupp.get("formelcontrol").value[this.formelGrupp.get("formelcontrol").value.length - 1];
      if (PrevKey === '/' || PrevKey === '*' || PrevKey === '-' || PrevKey === '+') {
        return;
      }
    }

    this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value + num);
    this.formula.emit({ formel: this.formelGrupp.get("formelcontrol").value, id: this.controlId });
    this.formulaChanged.emit(true);
    //  this.calcAnswer();
  }
  //////////
  ///
  pressConst(constant: Constants) {
    this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value + constant.description);
    this.formula.emit({ formel: this.formelGrupp.get("formelcontrol").value, id: this.controlId });
    this.formulaChanged.emit(true);
  }
  //////////
  ///
  pressTecken(tecken: FormelTecken) {
    if (tecken.utanKonstant != null) {
      this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value + tecken.utanKonstant);
    }
    else if (tecken.tillFormeln != null)
      this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value + tecken.tillFormeln);
    else
      this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value + tecken.beskrivning);

    this.formula.emit({ formel: this.formelGrupp.get("formelcontrol").value, id: this.controlId });
    this.formulaChanged.emit(true);
  }
  //////////
  ///
  getLastOperand() {
    let pos: number;
    console.log(this.formelGrupp.get("formelcontrol").value)
    pos = this.formelGrupp.get("formelcontrol").value.toString().lastIndexOf("+")
    if (this.formelGrupp.get("formelcontrol").value.toString().lastIndexOf("-") > pos) pos = this.formelGrupp.get("formelcontrol").value.lastIndexOf("-")
    if (this.formelGrupp.get("formelcontrol").value.toString().lastIndexOf("*") > pos) pos = this.formelGrupp.get("formelcontrol").value.lastIndexOf("*")
    if (this.formelGrupp.get("formelcontrol").value.toString().lastIndexOf("/") > pos) pos = this.formelGrupp.get("formelcontrol").value.lastIndexOf("/")
    console.log('Last ' + this.formelGrupp.get("formelcontrol").value.substr(pos + 1))
    return this.formelGrupp.get("formelcontrol").value.substr(pos + 1)
  }
  //////////
  ///
  pressOperator(op: string) {

    //Do not allow operators more than once
    const lastKey = this.formelGrupp.get("formelcontrol").value[this.formelGrupp.get("formelcontrol").value.length - 1];
    if (lastKey === '/' || lastKey === '*' || lastKey === '-' || lastKey === '+') {
      return;
    }

    this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value + op);
    this.formula.emit({ formel: this.formelGrupp.get("formelcontrol").value, id: 0 });
    this.formulaChanged.emit(true);
    /* this.calcAnswer();*/
  }
  //////////
  ///
  clear() {
    if (this.formelGrupp.get("formelcontrol").value != "") {
      this.formelGrupp.get("formelcontrol").setValue(this.formelGrupp.get("formelcontrol").value.substr(0, this.formelGrupp.get("formelcontrol").value.length - 1))
    }
    this.formula.emit({ formel: this.formelGrupp.get("formelcontrol").value, id: 0 });
  }
  //////////
  ///
  allClear() {
    this.result = '';
    this.formelGrupp.get("formelcontrol").setValue('');
    this.formula.emit({ formel: this.formelGrupp.get("formelcontrol").value, id: 0 });
  }
  checkParanteses(): boolean {
    let leftParCount = (this.formel.split("(").length - 1);
    let rightParCount = (this.formel.split(")").length - 1);
    //Om det inte är lika många parenteser på varje sida ska felmedelande visas
    if (leftParCount != rightParCount) {
      return true;
    }
    else
      return false;
  }
  //////////
  ///
  getAnswer() {
    //TODO: Kolla om grunddata som finns med i formeln har ett värde
    this.formelcontrol.markAsTouched();
    console.log(this.grunddata);
    if (this.formelGrupp.get("formelcontrol").value.includes('BSA') && !(Number(this.grunddata.längd) > 0) && !(Number(this.grunddata.vikt) > 0)) {
      this.openGrunddata.emit(true);
      this.message.emit("Ange vikt och längd för att beräkna BSA");
    }
    else if (this.formelGrupp.get("formelcontrol").value.includes('Ålder') && !(Number(this.grunddata.ålder) > 0)) {
      this.openGrunddata.emit(true);
      this.message.emit("Ange ålder");
    }
    else if (this.formelGrupp.get("formelcontrol").value.includes('Längd') && !(Number(this.grunddata.längd) > 0)) {
      this.openGrunddata.emit(true);
      this.message.emit("Ange längd");
    }
    else if (this.formelGrupp.get("formelcontrol").value.includes('Vikt') && !(Number(this.grunddata.vikt) > 0)) {
      this.openGrunddata.emit(true);
      this.message.emit("Ange vikt");
    }
    //else if (this.checkParanteses() == true)
    //  this.showParantesesError.emit(true);
    //this.calcAnswer();
    //TODO: Kolla om någon konstant används och om den har ett värde.
    //console.log(this.cVariable.format);
    else if (this.formelGrupp.valid) {
      console.log(this.grunddata);
      let formel = { formel: this.formelGrupp.get("formelcontrol").value, format: this.cVariable.format } as Formel;
      this.service.getCalculatedFormulaLinnea(formel, this.grunddata).subscribe(data => {
        this.formulaAnswer.emit("=" + data + this.cVariable.unitShort);
        this.answer = data;
      });
    }
  }
  validatorParanteses(): ValidatorFn {

    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }
      let sammaAntalParanteser: boolean = false;

      let leftPar = value.match(/\(/)?.length ? value.match(/\(/).length : 0;
      let rightPar = value.match(/\)/)?.length ? value.match(/\)/).length : 0;

      if (leftPar == rightPar)
        sammaAntalParanteser = true;

      return !sammaAntalParanteser ? { paranteserror: true } : null;
    }
  }
}


