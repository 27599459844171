import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ChangeDetectionStrategy, Component, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataPatient } from '@model/data-patient';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { DataPatientRemissUndersokning, SavedDataItem, Gradering, DataItemReferenceValues, ValueFromDevice, Itemgroups } from '@model/data-patientremissundersokning';
import { ToggleListItem } from '@model/toggle-list-item';
import { RefValidatorSymbolService } from '@core/services/ref-validator-symbols.service ';
import { FormGroup, FormBuilder } from '@angular/forms';
//import { EchoOptionKit } from '@model/undersokningsvarianter/variabel-echo-option-kit';
import { VariablesService } from '@core/services/variabler.service';
import { SysInfEchoOptionDto } from '@model/undersokningsvarianter/sys-inf-echo-option-dto';
import { OnChanges } from '@angular/core';
import { faImages, faInfoCircle, faPencilAlt, faSearchPlus, faPlusCircle, faAngleDown, faAngleUp, faChevronCircleUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'input-table',
  templateUrl: './input-table.component.html',
  styleUrls: ['input-table.component.scss'],
})
export class InputTableComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private dataService: VariablesService,
    public translate: TranslateService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private refValidatorSymbol: RefValidatorSymbolService,
    private _formBuilder: FormBuilder
  ) { }
  public faPlusCircle = faPlusCircle;
  isLinear = false;
  @Output() currentExamination = new EventEmitter<DataPatientRemissUndersokning>();
  @Output() onSubmit = new EventEmitter<FormGroup>();
  public faInfoCircle = faInfoCircle;
  public faImages = faImages;
  public faSearchPlus = faSearchPlus;
  public faPencilAlt = faPencilAlt;
  public faChevronUp = faChevronCircleUp;
  public faAngleDown = faAngleDown;
  public faAngleUp = faAngleUp;
  public symbolToggle: string;
  public viewType: string;
  public isCheckedShowAllVariables: string;
  public variableItems: Itemgroups[] = [];
  //public dataFromDevice$ = new BehaviorSubject<DataPatientRemissUndersokning>(null);
  public hideSymbols: boolean = false;
  public allGroupsOpen: boolean = false;
  public typeSelected: boolean;
  public undersokningstypId: string;
  public plannedPatientExamination = new DataPatientRemissUndersokning();
  public alertSymbols: ToggleListItem[] = [];
  public showValues: boolean = false;
  public countClickImportButton: number = 0;
  ngOnInit(): void {

    this.route.paramMap.subscribe((params) => {
      let undersokningstypId = params.get('undersokningstypId');
      this.getExaminationVariables(undersokningstypId);
    });
    this.getAlertSymbolOptions();
    this.viewType = '1';
    this.isCheckedShowAllVariables = '1';
    this.symbolToggle = '0';

  }

  showValuesClick() {
    this.countClickImportButton++;
    this.showValues = true;
    this.currentExamination.emit(this.plannedPatientExamination);
    this.plannedPatientExamination.itemgroups.forEach(group => {
      this.hasSelectedItemsOrValue(group);
    });
  }
  changeSelectBoxes(item: SavedDataItem, e) {
    item.inputValue = e.target.value;
    if (item?.ifStatements && !item.isMattVarde) {
      item.ifStatements.forEach(_ => {
        if (eval(e.target.value + _.operator + _.ifRightside)) {
          _.thenShowVariables.forEach(_v => {
            this.plannedPatientExamination.itemgroups.forEach(group => {
              group?.items.forEach(item => {
                console.log(e.target.value + _.operator + _.ifRightside);
                if (_v.showVar == item.variable) {
                  item.oplaneratExtravarde = true;
                } 
              })
            });
          });
        }
        else {
          item.oplaneratExtravarde = false;
        }
      })
    }
  }
  showMachineValue(i, currentItem: SavedDataItem, currentMachineValue?: any) {
    currentItem.inputValue = currentMachineValue;
    if (this.countClickImportButton == 1 && currentItem.inputValue == null) {
      currentItem.styleClass = 'bg-warning';
    }
    else if (this.countClickImportButton == 2 && currentItem.inputValue != null) {
      currentItem.styleClass = 'bg-light';
    }
    else {
      currentItem.styleClass = 'bg-light';
    }
    return Number(i) == (this.countClickImportButton - 1);
  }

  getAlertSymbolOptions() {
    this.http.get('/assets/toggle-listor/alertSymbol.json')
      .subscribe((data: ToggleListItem[]) => {
        data.forEach((item) => {
          this.alertSymbols.push(new ToggleListItem(item.styleClass, item.text, item.value));
        });
        console.table(this.alertSymbols);
        this.detector.markForCheck();
      });
  }
  showAlertSymbolsChanged(item) {
    console.log("Selected value: " + item.value);
    this.symbolToggle = item.value;
  }
  hideShowGroup(group) {
    group.isClosed = !group.isClosed;
  }
  showallGroups(show) {
    if (show == 'true')
      this.allGroupsOpen = true;
    else if (show == 'false')
      this.allGroupsOpen = false;
  }
  getAlertSymbolForItem(value?: any, ref?: DataItemReferenceValues, gradering?: Gradering, item?:SavedDataItem): object {
    if (item?.gradering) {
      item.gradering.filter(g => {
        if (g.intervalBottom < value && g.intervalTop > value) {
          return { 'styleClass': "fas fa-exclamation-triangle", 'text': g.text };
        }
      });
    }
    if (value == null || value == "") {
      return { 'styleClass': 'fas fa-exclamation-triangle', 'text': 'Värde saknas' };
    }
    if (item?.ifStatements && item.isMattVarde) {
      item.ifStatements.forEach(_ => {
        if (_.ifLeftside + _.operator + _.ifRightside) {
          _.thenShowVariables.forEach(_v => {
            this.plannedPatientExamination.itemgroups.forEach(group => {
              group?.items.forEach(item => {
                if ((_v.showVar == item.variable) && this.countClickImportButton != 0) {
                  item.styleClass = 'bg-warning';
                  item.oplaneratExtravarde = true;
                }
              })
            });
          });
        }  
      });
    }
    

    if (item.inputValue == null || item.inputValue == 0) {
      item.styleClass = 'bg-warning';
      return { 'styleClass': 'fas fa-exclamation-triangle', 'text': 'Värde saknas' };
    }
    else {
      item.styleClass = 'bg-light';
    }
    if (gradering) {
      if (item.inputValue > gradering.intervalBottom && item.inputValue < gradering.intervalTop) {
        return { 'styleClass': 'fas fa-exclamation-triangle', 'text': gradering.text, 'value': item.inputValue };
      }
    }
    else
      return null;
    if (value == null || value == "")
      return { 'styleClass': 'fas fa-exclamation-triangle', 'text': 'Värde saknas' };
    if (ref) {
      if (value < ref.lowerExtreme)
        return { 'styleClass': 'icon-speedometer-1', 'text': 'Väldigt lågt värde', 'value': value };
      if (value < ref.lowerNormal)
        return { 'styleClass': 'icon-speedometer-2', 'text': 'Lågt värde', 'value': value };
      if (value > ref.upperNormal)
        return { 'styleClass': 'icon-speedometer-4', 'text': 'Högt värde', 'value': value };
      if (value > ref.upperExtreme)
        return { 'styleClass': 'icon-speedometer-5', 'text': 'Väldigt högt värde', 'value': value };
    }
    else
      return null;
  }

  getExaminationVariables(id) {
    this.http.get('/assets/examination-in-progress/examination-in-progress.json')
      .subscribe((data: DataPatientRemissUndersokning[]) => {
        this.plannedPatientExamination = data.filter((type) => type.undersokningstypId == id)[0];
        this.plannedPatientExamination.itemgroups.forEach(group => {
            if (group.items.filter(item => item.inputValue).length.toString() ==
              group.items.filter(item => item.selected).length.toString()) {
              group.isClosed = true;
            }
        });
        this.detector.markForCheck();
      });
  }

  public hasSelectedItemsOrValue(group?: Itemgroups): boolean {
    // Om bara variabler för den planerade undersökningen ska visas.
    if (this.isCheckedShowAllVariables == "1" &&
      group?.items?.filter(item => item?.isMattVarde == true &&
        (item?.selected || item?.inputValue != null))?.length > 0)
      return true;
    // Om alla variabler ska synas.
    else if (this.isCheckedShowAllVariables == "2")
      return true;
  }
  /* Show patientinfo modal*/
  public valdRemiss: RemittanceDto;
  public showPatientinfoModalIsOpen = new BehaviorSubject<boolean>(false);
  public remittanceInfo = new RemittanceDto;
  public openShowPatientinfoModal(currentRemiss: RemittanceDto) {
    this.valdRemiss = currentRemiss;
    this.showPatientinfoModalIsOpen.next(true);
  }
  public showPatientinfoModalClosed() {
    this.showPatientinfoModalIsOpen.next(false);
  }
  public newPatientInfoSubmitted(saveAddInfoUndersokningForm) {
    //this.dataPatient.next(null);
    this.remittanceInfo.weight = saveAddInfoUndersokningForm.get('vikt').value;
    this.remittanceInfo.height = saveAddInfoUndersokningForm.get('langd').value;
    //this.remittanceInfo.blodtryck = saveAddInfoUndersokningForm.get('blodtryck').value;
    //this.dataPatient.next(null);
    this.detector.markForCheck();
  }
  /* Preview/Draw images */
  public drawedImages: string[] = ['resurs-2-edit', 'resurs-3-edit'];
  public imageToPreview = new BehaviorSubject<string>(null);
  public previewImageModalIsOpen = new BehaviorSubject<boolean>(false);
  public openPreviewImageModal(imageName) {
    this.imageToPreview.next(imageName);
    this.previewImageModalIsOpen.next(true);
  }
  public removePreviewedImage() {
    this.drawedImages = this.drawedImages.filter(x => x != this.imageToPreview.getValue());
    this.imageToPreview.next(null);
  }
  public previewImageModalClosed(e) {
    this.imageToPreview.next(null);
    this.previewImageModalIsOpen.next(false);
  }

  public addImageModalIsOpen = new BehaviorSubject<boolean>(false);
  public openAddImageModal() { this.addImageModalIsOpen.next(true); }
  public addImageModalClosed(e) { this.addImageModalIsOpen.next(false); }

  allComplete: boolean = false;
  // Uppdater markerat värde
  public updateCheckedItem(item, value) {
    item.inputValue = value;
    //  this.checkIfClose(group);
  }
  /* Show diagram for DataItem */
  public variableSerieData: any = [];
  public todaysDate = new Date();
  public showDiagram(item: SavedDataItem) {
    //console.table(item.history);
    this.variableSerieData = [];
    item.history.forEach(value => {
      this.variableSerieData.push([
        Date.UTC(value.year, value.month, value.day),
        value.value])
    })
    this.variableSerieData.push([
      Date.UTC(2022, 4, 11),
      item.inputValue]);
  }
  checkedList: any;
}

