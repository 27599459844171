
<div class="container">
    <div class="row justify-content-center">
        <div class="col-auto m-3" *ngFor="let image of images">
            <div class="clickable editable-image" (click)="editImage(image)">
                <img src="/assets/img/{{image}}-edit.png" (error)="onEditImageNotExists($event, image)" class="img-fluid" style="height:120px" />
                <fa-icon [icon]="faPencil" class="pen-icon" size="2x"></fa-icon>
            </div>
        </div>
    </div>

    <edit-image-modal [imageName]="imageToEdit"
                      [modalIsOpen]="modalIsOpen"
                      (modalClosed)="modalClosed()"></edit-image-modal>
</div>