import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { SeriesOptionsType } from 'highcharts';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { ExampleSavedDataItem } from '@model/data-exampleSavedDataItem-type';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'diagram',
  template: '<div [chart]="chart | async"></div>',
})
export class DiagramComponent implements OnInit, OnChanges {

  constructor(
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      this.initChart();
    });
  }
  @Input() dataItemExamination: ExampleSavedDataItem;
  @Input() dataItem: DataItem;
  @Input() series: SeriesOptionsType[];
  @Input() yAxisMin: number;
  @Input() yAxisMax: number;
  @Input() plotLines1: number;
  @Input() plotLines2: number;
  @Input() plotLines3: number;
  @Input() plotLines4: number;

  private previousDataItem: DataItem;
  public chart = new BehaviorSubject<Chart>(null);
  ngOnChanges(): void {
    if (this.dataItem && this.dataItem != this.previousDataItem && this.series) {
      this.previousDataItem = this.dataItem;
      this.initChart();
    }
    else if (this.dataItemExamination)
      this.initCharExaminationPage();
    // TODO: detta är bara för att jag ska kunna visa exempeldiagram på översikten för patient
    else {
      this.initCharExempel();
    }
  }
  ngOnDestroy() {
    this.chart.unsubscribe();
  }


  // TODO: Manuella värden för översikten på patient
  private initCharExempel() {
    let c = new Chart({
      chart: {
        type: 'line',
        height: '300px',
        style: {
          fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif"'
        }
      },
      title: {
        text: 'Trender'
      },
      subtitle: {
        text: '',
        style: { "fontSize": ".9rem" }
      },
      series: this.series,
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: '%e %b',
          week: '%e %b',
          month: '%b %y'
        },
        title: {
          text: this.translate.instant('components.chart.xaxis.default-title'),
          style: { "fontSize": ".85rem" }
        }
      },
      yAxis: {
        title: {
          text: this.translate.instant('components.chart.yaxis.default-title') + ('' ? ' (' + '' + ')' : ''),
          style: { "fontSize": ".85rem" }
        },
        min: 0,
        max: 10,
        tickInterval: 2,
        plotLines: [ //Referens-värden
          {
            value: 0,
            color: '#d21a23', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.lower-extreme'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }, {
            value: 1,
            color: '#e68839', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.lower-normal'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }, {
            value: 8,
            color: '#e68839', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.upper-normal'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }, {
            value: 10,
            color: '#d21a23', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.upper-extreme'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }
        ]
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        valueSuffix: '' ? ' ' + '' : '',
        shared: true
      },
      legend: { enabled: this.series.length > 1 },
      credits: { enabled: false },
      colors: ['#17a2b8']
    });
    this.chart.next(c);
  }

  private initChart() {
    let c = new Chart({
      chart: {
        type: 'line',
        height: '300px',
        style: {
          fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif"'
        }
      },
      title: {
        text: this.dataItem.shortCaption
      },
      subtitle: {
        text: this.dataItem.caption,
        style: { "fontSize": ".9rem" }
      },
      series: this.series,
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: '%e %b',
          week: '%e %b',
          month: '%b %y'
        },
        title: {
          text: this.translate.instant('components.chart.xaxis.default-title'),
          style: { "fontSize": ".85rem" }
        }
      },
      yAxis: {
        title: {
          text: this.translate.instant('components.chart.yaxis.default-title') + (this.dataItem.unit ? ' (' + this.dataItem.unit + ')' : ''),
          style: { "fontSize": ".85rem" }
        },
        min: this.yAxisMin,
        max: this.yAxisMax,
        tickInterval: 2,
        plotLines: [ //Referens-värden
          {
            value: 0,
            color: '#d21a23', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.lower-extreme'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          },{
            value: 1,
            color: '#e68839', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.lower-normal'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          },{
            value: 8,
            color: '#e68839', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.upper-normal'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          },{
            value: 10,
            color: '#d21a23', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.upper-extreme'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }
        ]
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        valueSuffix: this.dataItem.unit ? ' ' + this.dataItem.unit : '',
        shared: true
      },
      legend: { enabled: this.series.length > 1 },
      credits: { enabled: false },
      colors: ['#17a2b8']
    });
    this.chart.next(c);
  }
  private initCharExaminationPage() {


    let c = new Chart({
      chart: {
        type: 'line',
        height: '300px',
        style: {
          fontFamily: '"Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif"'
        }
      },
      title: {
        text: this.dataItemExamination.shortCaption
      },
      subtitle: {
        text: this.dataItemExamination.caption,
        style: { "fontSize": ".9rem" }
      },
      series: this.series,
      xAxis: {
        type: "datetime",
        dateTimeLabelFormats: {
          day: '%e %b',
          week: '%e %b',
          month: '%b %y'
        },
        title: {
          text: this.translate.instant('components.chart.xaxis.default-title'),
          style: { "fontSize": ".85rem" }
        }
      },
      yAxis: {
        title: {
          text: this.translate.instant('components.chart.yaxis.default-title') + (this.dataItemExamination.unit ? ' (' + this.dataItemExamination.unit + ')' : ''),
          style: { "fontSize": ".85rem" }
        },
        tickInterval: 1,
        plotLines: [ //Referens-värden
          {
            value: this.plotLines1,
            color: '#d21a23', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.lower-extreme'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }, {
            value: this.plotLines2,
            color: '#e68839', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.lower-normal'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }, {
            value: this.plotLines3,
            color: '#e68839', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.upper-normal'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }, {
            value: this.plotLines4,
            color: '#d21a23', width: 2,
            label: { text: this.translate.instant('components.chart.plotlines.upper-extreme'), align: 'right', x: -3, style: { fontSize: ".7rem" } }
          }
        ]
      },
      tooltip: {
        xDateFormat: '%Y-%m-%d',
        valueSuffix: this.dataItemExamination.unit ? ' ' + this.dataItemExamination.unit : '',
        shared: true
      },
      legend: { enabled: this.series.length > 1 },
      credits: { enabled: false },
      colors: ['#17a2b8']
    });
    this.chart.next(c);
  }
}
