
export class TableSettings {
  constructor(
    public title: string = '',
    public headerText: string = '',
    public groups: TableSettingRowGroup[] = []
  ) { }
}

export class TableSettingRowGroup {
  public id: any;
  public name: any;
  public rows: TableSettingRow[] = [];
}

export class TableSettingRow {
  public id: number;
  public rowName: any;
  public variablesFirst: any;
  public variablesSecond: any;
  public variablesThird: any[] = [];
}
