
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { ChangeDetectorRef, EventEmitter, OnChanges } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { ListviewSysEchoVariableDto } from '@model/undersokningsvarianter/listview-sys-echo-variable-dto';

import { OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { GrunddataDto } from '@model/beräkningar/grunddata-dto';
//import { GrunddataDto } from '../../../../model-generated/beräkningar/grunddata-dto';

import { SinusValsalvaDto } from '@model/beräkningar/sinus-valsalva-dto';
import { EchoVariablesDto } from '../../../../model/undersokningsvarianter/echo-variables-dto';
import { VariablesService } from '../../../../core/services/variabler.service';
import { BeräkningsMetoderDto } from '@model/beräkningar/beräknings-metoder-dto';
import { CalculationBsaDto } from '@model/beräkningar/calculation-bsa-dto';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CalculationService, Formel, Grunddata } from '../../../../core/services/calculation.service';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-calculation',
  templateUrl: './calculation.component.html',
  styleUrls: ['./calculation.component.scss']
})
export class CalculationComponent implements OnInit {

  submitted = false;
  bsaClick = false;

  //grundData: Observable<GrunddataDto>;
  sinusValsalva: any;//Observable<SinusValsalvaDto>;
  variableList: Observable<ListviewSysEchoVariableDto[]>;
  variablerForBerakningList$: Observable<ListviewSysEchoVariableDto[]>;
  metoderList$: Observable<BeräkningsMetoderDto[]>;
  metoderForBsaList$: Observable<CalculationBsaDto[]>;
  //result: string = "";
  result: Observable<any>;
  bsa: Observable<any>;
  metoderListJson: any;
  public inputDescription;
  public variableSelected: boolean;
  public metodSelected: boolean;
  public bsaMetodSelected: boolean;
  public selectedVariableId: number = 0;
  public selectedMetod: BeräkningsMetoderDto;
  public selectedVariable: Observable<EchoVariablesDto>;//selma testar
  public selectedMetodId: number = 0;
  public selectedBsaMetod: CalculationBsaDto;
  public selectedBSAmetodId: number = 0;
  public selectedJson: string = "";//selma testar
  public varById: EchoVariablesDto;//selma testar
  public grunddata: Grunddata;
  public formTest: Formel;
  //public beraknaForm: FormGroup;
  //public b = GrunddataDto;
  public get beraknaForm(): FormGroup { return this._beraknaForm; }
  private _beraknaForm!: FormGroup;
  public get beraknaBsaForm(): FormGroup { return this._beraknaBsaForm }
  private _beraknaBsaForm!: FormGroup;
  public get grunddataForm(): FormGroup { return this._grunddataForm }
  private _grunddataForm!: FormGroup;

  private service: CalculationService;

  txtVikt;
  // @Output() onSubmit = new EventEmitter<FormGroup>();
  constructor(
    public translate: TranslateService,
    private dataService: VariablesService,
    //private beräkningService :  importera Service här
    private http: HttpClient
  ) { }


  ngOnInit(): void {
    this.getVariabler();
    //throw new Error('Method not implemented.');
    this.getMetoder();
    this.getBsaMetoder();
    
    this.variableSelected = false;
    this.bsaMetodSelected = false;
    this._beraknaForm = new FormGroup({
      variabelNamn: new FormControl(0, [Validators.required, Validators.pattern(/^\d*$/)]),
      metodName: new FormControl(0, this.validatorNotZero),
      vikt: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')),
      langd: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')),
      gender: new FormControl(0, this.validatorNotZero),
      alder: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')),
      varde: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')),
      metodNamn: new FormControl(0, [Validators.required, Validators.pattern(/^\d*$/)])
    });

    this._beraknaBsaForm = new FormGroup({
      metodNamn: new FormControl(0, [Validators.required, Validators.pattern(/^\d*$/)])
    });

    this._grunddataForm = new FormGroup({
      vikt: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')]),
      langd: new FormControl('', [Validators.required, Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')]),
      gender: new FormControl(0, this.validatorNotZero),
      alder: new FormControl('', Validators.pattern('^-?[0-9]\\d*(\\.\\d{1,4})?$')),
      metodNamn: new FormControl(0, [Validators.required, Validators.pattern(/^\d*$/)]),//bsa metod
      metodName: new FormControl(0, this.validatorNotZero)
    });
  }
  private validatorNotZero = [
    Validators.required,
    Validators.pattern(/^(?![0]\d*)/)
  ];


  public getVariabler() {
    this.variablerForBerakningList$ = this.dataService.getAllaEchoVariables();
    //testar med hela lista, todo: filtrera bort uppmätta variabler
    // this.variableSelected = true;
    //this._examinationTypeForm.get('examinationType').patchValue(id);
  }
  public getVariabelData(id: number) {
    //this.variablerForBerakningList$ = this.dataService.getAllaEchoVariables();
    //testar med hela lista, todo: filtrera bort uppmätta variabler
    this.variableSelected = true;
    this.selectedVariableId = id;
    console.log("Vald variabel id: " + id);


    //this.varById = this.dataService.getVariable(id).subscribe((variabel) => {
   
    //  console.log(variabel.calculationJson);
    //});

   this.dataService.getVariable(id).subscribe((variabel) => {
     this.varById = variabel;
      console.log(variabel.calculationJson);
    });

    //console.log(this.varById);
    //this.selectedVariable = this.dataService.getVariable(id);
    //console.log(this.selectedVariable);
    //this.selectedVariable = this.dataService.getVariable(id); //this.beraknaForm.get('variabelNamn').value;
    //this.selectedJson = this.http.get<VariabelFormler>
   // console.log(JSON.stringify(this.selectedVariable));
    //this._examinationTypeForm.get('examinationType').patchValue(id);
  }
  public getVariabelJson(txt: string) {
   /* this.varById = this.dataService.getVariable(this.selectedVariableId);*/
    //this.selectedJson = txt;
    console.log(this.selectedVariableId);
  }
  public getMetoder() {
    //this.metoderList$ = this.dataService.getAllaMetoder();
    this.metoderList$ = this.http.get<BeräkningsMetoderDto[]>('/assets/calculationTest/BeräkningsMetoderJson.json');


  }
  public getBsaMetoderData() {
    this.selectedBsaMetod = this.grunddataForm.get('metodNamn').value;
  }

  public getBsaMetoder() {
    //this.metoderForBsaList$ = this.dataService.getBsaMetoder();
    this.metoderForBsaList$ = this.http.get<CalculationBsaDto[]>('/assets/calculationTest/CalculationBSA.json');
    //var a = this.metoderForBsaList$;

  }
  public getBsaMetodData(id: number) {
    
    this.bsaMetodSelected = true;
    this.selectedBSAmetodId = id;
   
  }
  //public getMetoderData(metod: BeräkningsMetoderDto) {
  //  //this.metoderList$ = this.dataService.getAllaMetoder();
  //  //this.metoderList$ = this.http.get<BeräkningsMetoderDto[]>('/assets/calculationTest/BeräkningsMetoderJson.json');
  // // this.http.get<BeräkningsMetoderDto>('/assets/calculationTest/BeräkningsMetoderJson.json').subscribe((metod) => { console.log(metod.metodId, "beskr:" + metod.beskrivning) });
  //  //this.selectedMetod = metod;
  //  //console.log(this.selectedMetod);
  //  //console.log("Result: " + JSON.stringify(this.selectedMetod.metodNamn));
  //  this.selectedMetod = metod;
  //  console.log(this.selectedMetod);
  //}

  public getMetoderData() {

    this.selectedMetod = this.beraknaForm.get('metodName').value;
    
  }

  errorMessage: string = '';
  formSubmitted: boolean = false;
  onSubmit(): void {
    //här göra api anropet för att hämta data
    //this.sinusValsalva = this.getCalculatedData();// anropa via servicen
    //här ska man kolla om alla har formcontrolname (formgrupp med controllerna inuti)
    this.formSubmitted = true;

    console.log("Andra knappen -  form submitted - test");
   /* if (this.beraknaForm.valid) {*/
      //let grunddata: GrunddataDto = {
      //  grunddataId: 1,
      //  metodId: this.selectedMetod.metodId ?? 0,
      //  //TODO: Hårdkodad ålder
      //  ålder: this.grunddataForm.get("alder")?.value,
      //  //vikt: this.vikt.value,
      //  vikt: this.grunddataForm.get("vikt")?.value,
      //  längd: this.grunddataForm.get("langd")?.value,
      //  kön: this.grunddataForm.get("gender")?.value,
      //  bsaMetod: this.selectedBSAmetodId
    //};
    this.grunddata = {
      längd: this.grunddataForm.get("langd")?.value,
      vikt: this.grunddataForm.get("vikt")?.value,
      ålder: this.grunddataForm.get("alder")?.value
    };
    let varde = this.beraknaForm.get("varde")?.value;
    //let formel = this.varById
    console.log(this.varById.calculationJson);
    if (this.varById.calculationJson == null) {
      console.log("Beräkning för vald variabel ej klar");
    }

    //this.service.getCalculatedFormulaLinnea(formel, grunddata).subscribe(data
    let intervallTyp = this.varById.calculationJson.intervalltyp;
    //intervall Typ 1 = Formel intervall; 2 = Enkelt intervall
    if (intervallTyp == 2) {
      //get värde
      this.beraknaForm.get("varde")?.value;
      //anropa funktion och få ut text
    }
    else if (intervallTyp == 1) {
     // this.service.getCalculatedFormulaLinnea("1+1", this.grunddata).subscribe(_ => console.log(_));
      //anropa funktion för att göra beräkningen - foreach loop
      //this.formTest = this.varById.calculationJson.formler[0];
      console.log(this.varById.calculationJson);

      this.service.getCalculatedFormulaSelma(this.varById.calculationJson, this.grunddata).subscribe(_ => console.log(_));
      //this.service.getCalculatedFormulaLinnea(this.varById.calculationJson.formler, this.grunddata).subscribe(_ => console.log(_));
      //this.varById.calculationJson.formler.forEach(item => {
      //  console.log(item.formel);//funkar
      //  //this.myFunction(item.formel, this.grunddata);
      //  this.service.getCalculatedFormulaLinnea(item, this.grunddata).subscribe(_ => console.log(_));
      //  /*let test = this.service.getCalculatedFormulaLinnea(item.formel, grunddata)*/
      //  //fortsätta här imorgon, försöka att ropa Linneas beräkning - testa via Postman
      //  //console.log(test);
      //})
      //this.varById.calculationJson.formler.forEach(myFunction);

    }
     else {
       console.log("Beräkning för vald variabel ej klar");
    }

   
      //för varje formel som json ineåller gå igenom och gör ber.
      //if (this.selectedVariableId == 1)//sinus valsalva
      //{
      //  this.result = this.dataService.getCalculatedValue(grunddata);
      //}
      //else if (this.selectedVariableId == 6)//kammarseptum
      //{
      //  this.result = this.dataService.getUtlatandeText(grunddata, varde, this.selectedMetod.metodId)
      //}
      //else {
      //  // this.result =
      //  console.log("Beräkning för vald variabel ej klar");
      //}


      
     /* console.log(grunddata);*/
      //var a = this.dataService.getCalculatedValue(grunddata);
      //this.bsa = this.dataService.getBSA(grunddata);
      console.log(this.bsa);
      //this.result = this.dataService.getCalculatedValue(grunddata);
    //this.dataService.getCalculatedKajsa(grunddata, 0).subscribe(data => this.result = data);//todo:ta bort hårdkodad
   // this.result = this.bsa;//testar - ta bort
      //this.result = this.dataService.getUtlatandeText(grunddata, varde, this.selectedMetod.metodId);
      console.log(this.result);
      //console.log(a);
      //this.result = a;
    //}
    //else {
    //  //TODO: Lägg till valideringstext för alla kontroller.
    //  this.errorMessage = 'Formuläret måste fyllas i korrekt.';
    //}
  }

//  myFunction(i: string, grunddata: Grunddata) {
//    console.log(i);
//    console.log(grunddata);
//    this.service.getCalculatedFormulaLinnea(i, grunddata).subscribe(_ => console.log(_));
 
//}
  //onSubmitBsa(): void {
  //  if (this.beraknaBsaForm.valid) {
  //    //this.bsa = this.dataService.getBSA()
  //    console.log("Bsa form submitted - test");
  //  }

   
  /*}*/
  onSaveGrunddata(): void {
    /*   if (this.grunddataForm.valid) {*/
    this.bsaClick = true;
      console.log("Första knappen - grunddata form submitted - test");
     
      let grunddataDto: GrunddataDto = {
        grunddataId: 1,
        //metodId: this.selectedMetod.metodId ?? 0,
        metodId: 1,
        //TODO: Hårdkodad ålder
        ålder: this.grunddataForm.get("alder")?.value,
        //vikt: this.vikt.value,
        vikt: this.grunddataForm.get("vikt")?.value,//ska inte vara bokstäver, inte typ 4 siffror
        längd: this.grunddataForm.get("langd")?.value,//ska inte vara bokstäver, och inte mer en tre siffror
        kön: this.grunddataForm.get("gender")?.value,
        bsaMetod: this.selectedBSAmetodId
    };
   /* let varde = this.beraknaForm.get("varde")?.value;*/
      console.log(grunddataDto);

      this.bsa = this.dataService.getBSA(grunddataDto);
    /*}*/
  }

  

  berakna(formValues) {
    console.log(formValues);
  }
}


 
  //public getCalculatedData() {
  //  //här göra api anropet för att hämta data
  // // this.sinusValsalva = this.getCalculatedData(); anropa via servicen

 // }

  //todo: skapa en separat service till beräkningar eller  använd befintig
 
//}
//}
