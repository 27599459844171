import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbModal, NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { startWith, map, catchError, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef } from '@angular/core';
import { Remittance, InfMobilization, SysInfMedicalExaminationType } from '@model/remiss/data-remiss';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { RemissService } from '@core/services/remiss.service';
import { formatDate } from '@angular/common';
import { PatientService } from '@core/services/patient.service';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { SaveRemittanceDto } from '@model/remisser/save-remittance-dto';
import { AlertMessageItem, AlertMessageType } from '@model/alert-message-item';
import { EMPTY } from 'rxjs';



@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-remiss-modal',
  templateUrl: './add-remiss-modal.component.html',
  styleUrls: ['./add-remiss-modal.component.scss']
})
export class AddRemissModalComponent implements OnChanges {
  alertMessage: any;

  constructor(
    public translate: TranslateService,
    private dataService: RemissService,
    private patientService: PatientService,
    private modalService: NgbModal
  ) { }
  //Ikoner
  faCalendar = faCalendar;

  //@Input() itemGroup: PatientRemiss;
  //@Input() dataWithId;
  //@Input() valdRemiss: RemittanceDto;
  @Input() patientRemiss: RemittanceDto;
  @Input() newPatient: boolean;
  @Input() valdPatientId: number;
  isLinear = false;
  @Output() selectionChange: EventEmitter<StepperSelectionEvent>
  public activestep: boolean = false;
  @Input() valdRemissId: number;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter<FormGroup>();
  @ViewChild("content") modalHtml;

  public loading = new BehaviorSubject<boolean>(false);
 // public formItems: PatientRemiss[] = [];
 /* public mobiliseringOptions: SelectListItem[] = [];*/
  //public konOptions: SelectListItem[] = [];
  //public prioOptions: SelectListItem[] = [];
  public toDay = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  undersokningarList: Observable<SysInfMedicalExaminationType[]>;
  //remitentklinikerList: Observable<SysInfRemittanceClinic[]>;
  //remiterandeLakareList: Observable<SysInfRemittanceDoctor[]>;
  //filteredRemitentKliniker: Observable<string[]>;
  //filteredRemitentLakare: Observable<string[]>;
  mobiliseringList: Observable<InfMobilization[]>;
 
  remittancePriorityList$ = this.dataService.remittancePriorityList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  remDoctorList$ = this.dataService.remDoctorList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  remittanceList$ = this.dataService.remittanceList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  remClinicList$ = this.dataService.remClinicList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  remMobilizationList$ = this.dataService.remMobilizationList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  genderList$ = this.dataService.genderList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  languagesList$ = this.dataService.languagesList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  undersokningstyperList$ = this.dataService.undersokningstyperList$.pipe(catchError(err => {
    this.alertMessage.next(new AlertMessageItem(AlertMessageType.Danger, "Något gick fel"));
    return EMPTY;
  }));
  //selectedMobilisering: InfMobilization;

  /*filteredLanguageList: Observable<string[]>;*/
  submitted = false;
  ngOnChanges(): void {
    if (this.modalIsOpen) {
      this.openModal();
      this.setForm();
      this.openEditRemiss();
      //this.addExaminationType();
      //this.saveAddRemissForm.get("undersokningar").valueChanges.subscribe(value => {
      //  this.undersokningarSelect.push(value);
      //});
    }
  }
  //addExaminationType() {
  //  const examinationForm = this.fb.group({
  //    undersokning: ['null', Validators.required],
  //    prioritering: ['null', Validators.required]
  //  });
  //  this.examinations.push(examinationForm);
  //}
  deleteExaminationType(examinationIndex: number) {
    this.examinations.removeAt(examinationIndex);
  }
  get remissForm() { return this.saveAddRemissForm.get('remissForm'); }
  get personForm() { return this.saveAddRemissForm.get('personForm'); }
  get patientId() { return this.saveAddRemissForm.get('personForm.patientId'); }
  get id() { return this.saveAddRemissForm.get('personForm.id'); }
  get fnamn() { return this.saveAddRemissForm.get('personForm.fnamn'); }
  get enamn() { return this.saveAddRemissForm.get('personForm.enamn'); }
  get telefon() { return this.saveAddRemissForm.get('personForm.telefon'); }
  get adress() { return this.saveAddRemissForm.get('personForm.adress'); }
  get postnr() { return this.saveAddRemissForm.get('personForm.postnr'); }
  get postort() { return this.saveAddRemissForm.get('personForm.postort'); }
  get epost() { return this.saveAddRemissForm.get('personForm.epost'); }
  get pnr() { return this.saveAddRemissForm.get('personForm.pnr'); }
  get alder() { return this.saveAddRemissForm.get('personForm.alder'); }
  get kon() { return this.saveAddRemissForm.get('personForm.kon'); }
  get vikt() { return this.saveAddRemissForm.get('personForm.vikt'); }
  get langd() { return this.saveAddRemissForm.get('personForm.langd'); }
  get mobilisering() { return this.saveAddRemissForm.get('personForm.mobilisering'); }
  get inkomDatum() { return this.saveAddRemissForm.get('remissForm.inkomDatum'); }
  get remitentklinik() { return this.saveAddRemissForm.get('remissForm.remitentklinik'); }
  get remitentlakare() { return this.saveAddRemissForm.get('remissForm.remitentlakare'); }
  get fragestallning() { return this.saveAddRemissForm.get('remissForm.fragestallning'); }
  get halsoproblem() { return this.saveAddRemissForm.get('remissForm.halsoproblem'); }
  get undersokningar() { return this.saveAddRemissForm.get('remissForm.undersokningar'); }
  get prioritering() { return this.saveAddRemissForm.get('remissForm.prioritering'); }
  get sprak() { return this.saveAddRemissForm.get('personForm.sprak'); }
  get tolkbehov() { return this.saveAddRemissForm.get('personForm.tolkbehov'); }
  get overkansligLakemedel() { return this.saveAddRemissForm.get('personForm.overkansligLakemedel'); }
  get difficultToExamine() { return this.saveAddRemissForm.get('personForm.difficultToExamine'); }
  get underTyper() { return this.saveAddRemissForm.get('remissForm.')}
  get examinations() {
    return this.saveAddRemissForm.get('remissForm.examinations') as FormArray;
  }
  //public selectedValue(event: MatSelectChange) {
  //  this.selectedMobilisering = {
  //    id: event.value,
  //    description: event.source.triggerValue
  //  };
  //  console.log(this.selectedMobilisering);
  //}
  /* Modal */
  private openModal() {
    console.log(this.patientRemiss);
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'add-remiss-modal', size: 'xl' })
      .result.then((reason) => this.modalClosed.emit(reason), (reason) => this.modalClosed.emit(reason));
  }

  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit('Close');
  }
  public saveAddRemissForm: FormGroup;
  //public personForm: FormGroup;
  //public remissForm: FormGroup;
  private setForm() {
    // TODO: Fixa valideringen. Om "Välj" är valt ska valideringsfel visas om fältet är obligatoriskt
    this.saveAddRemissForm = new FormGroup({
      remissForm: new FormGroup({
        inkomDatum: new FormControl(null, Validators.required),
        remitentklinik: new FormControl(null, Validators.required),//('null', Validators.required)
        remitentlakare: new FormControl(null, Validators.required),//('null', Validators.required)
        fragestallning: new FormControl('', Validators.required),
        halsoproblem: new FormControl('', Validators.required),

      undersokningar: new FormControl('')
        //  examinations: this.fb.array([])
      }),
      personForm: new FormGroup({
        patientId: new FormControl(''),
        id: new FormControl(''),
        fnamn: new FormControl('', Validators.required),
        enamn: new FormControl('', Validators.required),
        telefon: new FormControl(''),
        adress: new FormControl('', Validators.required),
        postnr: new FormControl('', Validators.required),
        postort: new FormControl('', Validators.required),
        epost: new FormControl(''),
        pnr: new FormControl('', Validators.required),
        alder: new FormControl('', Validators.required),
        kon: new FormControl('', Validators.required),
        vikt: new FormControl(''),
        langd: new FormControl(''),
        mobilisering: new FormControl(''),
        overkansligLakemedel: new FormControl(''),
        sprak: new FormControl(''),
        tolkbehov: new FormControl(''),
        difficultToExamine: new FormControl('')
      })
    },//);
    //this.saveAddRemissForm.reset();
      { updateOn: "blur" });
    
    

    // Om modalen öppnas från patientlistan och det saka läggas till en remiss på patienten.
    //if (this.valdPatientId) {
    //  this.patientService.getPatient(this.valdPatientId).subscribe((prevUppg) => {
    //    console.table(prevUppg);
    //    if (prevUppg[0].languageId) {
    //      this.saveAddRemissForm.get('personForm.tolkbehov').patchValue('true');
    //    }
    //    this.saveAddRemissForm.get('personForm.pnr').disable();
    //    this.saveAddRemissForm.get('personForm').patchValue({
    //      patientId: this.valdPatientId,
    //      fnamn: prevUppg[0].name,
    //      enamn: prevUppg[0].lastname,
    //      telefon: prevUppg[0].phone,
    //      adress: prevUppg[0].address,
    //      postnr: prevUppg[0].zipcode,
    //      postort: prevUppg[0].postOrt,
    //      epost: prevUppg[0].ePost,
    //      pnr: prevUppg[0].personalIdentityNumber,
    //      alder: prevUppg[0].age,
    //      kon: prevUppg[0].genderId,
    //      vikt: prevUppg[0].weight,
    //      langd: prevUppg[0].height,
    //      sprak: prevUppg[0].languageId,
    //      overkansligLakemedel: prevUppg[0].hypersensivityToMedicine
    //      // TODO: Lägg till svårundersökt och mobilisering
    //      //mobilisering: ,
    //    });
    //  }, error => {
    //    console.error(error);
    //  });
    //}
    // Rediger remiss
    if (this.patientRemiss && !this.newPatient) {
      //if (this.patientRemiss.patient.languageId) {
      //  this.saveAddRemissForm.get('personForm.tolkbehov').patchValue('true');
    //  //}
      console.log('remiss: ', this.patientRemiss);
      this.saveAddRemissForm.get('personForm.tolkbehov').disable();
      this.saveAddRemissForm.get('personForm')?.patchValue({
        id: this.patientRemiss.id,
        patientId: this.patientRemiss.patientId,
        fnamn: this.patientRemiss.name,
        enamn: this.patientRemiss.lastname,
        telefon: this.patientRemiss.patientPhone,
        adress: this.patientRemiss.patientAddress,
        postnr: this.patientRemiss.patientZipCode,
        postort: this.patientRemiss.patientPostOrt,
        epost: this.patientRemiss.patientEpost,
        pnr: this.patientRemiss.personalIdentityNumber,
        alder: this.patientRemiss.patientAge,
        kon: this.patientRemiss.patientGenderId,
        vikt: this.patientRemiss.weight,
        timestamp: this.patientRemiss.tid,
        langd: this.patientRemiss.height,
        sprak: this.patientRemiss.patientLanguageId,
        overkansligLakemedel: this.patientRemiss.patientHypersensivityToMedicine,
    /* TODO: Lägg till svårundersökt och mobilisering*/
        mobilisering: this.mobiliseringList
      });
      this.saveAddRemissForm.get('remissForm').setValue({
        remitentklinik: this.patientRemiss.remittanceClinicId,
        remitentlakare: this.patientRemiss.remittanceDoctorId,
        fragestallning: this.patientRemiss.questionStatment,
        halsoproblem: this.patientRemiss.healthProblems,
        undersokningar: null,//testar
    //  examinations: this.patientRemiss.scheduledMedicalExaminations.push()
      });
    }
  }
  onStepChange(e) {
    if (!this.saveAddRemissForm.get('personForm').valid) {
  // this should make all invalid fields light up in red
  this.saveAddRemissForm.get('personForm').markAllAsTouched();
      return;
  }
  //  this.stepper.next();
  }
  public submitForm() {
    console.log('submitForm', this.saveAddRemissForm.value);
    this.submitted = true;
    if (!this.saveAddRemissForm.valid) {
      //Något felaktigt i formuläret, visa alla felmeddelanden, stäng ej modal
      this.saveAddRemissForm.markAllAsTouched();
      return;
    }
    if (!this.tolkbehov.value) {
      this.sprak.setValue("null");
      // TODO: Nollställ språk på patienten
    }
    if (this.saveAddRemissForm.get('personForm').valid && this.saveAddRemissForm.get('remissForm').valid) {
      //let selectedexaminations: Array<number> = this.saveAddRemissForm.get('undersokningar').value;
      let selectedMobilizations: Array<number> = this.saveAddRemissForm.get('personForm.mobilisering').value;
      //let selectedExTypes: Array<number> = this.saveAddRemissForm.get('remissForm.undersokningar').value;
      let r: SaveRemittanceDto = {
        id: this.id.value,
        //id: 55,
        patientId: this.patientId.value,
        //patientId: 55,
        remittanceClinicId: this.remitentklinik.value,
        remittanceDoctorId: this.remitentlakare.value,
        questionStatment: this.fragestallning.value,
        healthProblems: this.halsoproblem.value,
        sysInfPriorityId: 1, // TODO: Fixa
       /* sysInfPriorityId: this.prioritering.value,*/
        //plannedExaminationId: null,
        prioInfo: "",
        weight: this.vikt.value,
        height: this.langd.value,
        timestamp: this.toDay,
        remittanceClinicDesc: "", //TODO: Ta bort?
        remittanceDoctorDesc: "", //TODO: Ta bort?
        plannedExaminationTypesIds: null,//hårdkodad för att testa
        mobilizationIds: selectedMobilizations,//[1,2], //hårdkodad för att testa,
        lastname: this.enamn.value,
        name: this.fnamn.value,
        remittanceStatusId: 5,
        personnummer: this.pnr.value,
        genderId: this.kon.value,
        age: this.alder.value,
        address: this.adress.value,
        zipcode: this.postnr.value,
        postOrt: this.postort.value,
        ePost: this.epost.value,
        phone: this.telefon.value,
        languageId: this.sprak.value,
        //weight: this.vikt.value,
        //lenght: this.langd.value,
        dateVl: this.toDay.toString(),
        hypersensivityToMedicine: this.overkansligLakemedel.value,
        difficultToExamine: this.difficultToExamine.value,
        statusComment: "test",
        examinationTypesIds: [],
        arrivalDatum: ''   
      };
      console.log(r);
      this.dataService.saveRemittances(r).subscribe(newRemiss => {
        this.modalClosed.emit('Save');
        this.modalService.dismissAll();
      }, error => {
        console.error(error);
      });
    }

    //TODO: Save
  }
  get registerPatientFormControl() {
    return this.saveAddRemissForm.get('personForm');
  }
  get registerRemissFormControl() {
    return this.saveAddRemissForm.get('remissForm');
  }
    //private initAutocompleteSelect() {
  //  this.filteredRemitentKliniker = this.remitentklinik.valueChanges.pipe(
  //    startWith(''),
  //    map(value => {
  //      this.remiterandeLakareList.setValue(''); //Trigger filtrering av läkare på klinik
  //      return this.filterRemitentKliniker(value);
  //    })
  //  );
  //  this.filteredRemitentLakare = this.remiterandeLakareList.valueChanges.pipe(
  //    startWith(''),
  //    map(value => this.filterRemitentLakare(value))
  //  );
  //}
  //private filterRemitentKliniker(text: string): string[] {
  //  const filterValue = this.normalizeValue(text);
  //  return this.remitentklinikerList.filter(klinik => this.normalizeValue(klinik).includes(filterValue));
  //}
  //private filterRemitentLakare(text: string = ''): string[] {
  //  const filterValue = this.normalizeValue(text);
  //  let searchResult = this.remiterandeLakareList.filter(lakare => this.normalizeValue(lakare.name).includes(filterValue));
  //  if (this.remitentklinik.value) {
  //    searchResult = searchResult.filter(lakare => lakare.klinik == this.remitentklinik.value);
  //  }
  //  return searchResult.map(lakare => lakare.name);
  //}
  //private normalizeValue(value: string): string {
  //  return value.toLowerCase().replace(/\s/g, '');
  //}


    // Redigera remiss
  openEditRemiss() {
    if (this.valdRemissId) {
      this.dataService.getRemittance(this.valdRemissId).subscribe((selRemiss) => {
        console.table(selRemiss);
        if (selRemiss[0].patient.languageId) {
          this.saveAddRemissForm.get('tolkbehov').patchValue('true');
        }
        this.saveAddRemissForm.get('pnr').disable();
        this.saveAddRemissForm.patchValue({
          patientId: selRemiss[0].patientId,
          fnamn: selRemiss[0].name,
          enamn: selRemiss[0].lastname,
          telefon: selRemiss[0].phone,
          adress: selRemiss[0].address,
          postnr: selRemiss[0].zipcode,
          postort: selRemiss[0].postOrt,
          epost: selRemiss[0].ePost,
          pnr: selRemiss[0].personalIdentityNumber,
          alder: selRemiss[0].age,
          kon: selRemiss[0].genderId,
          sprak: selRemiss[0].languageId,
          overkansligLakemedel: selRemiss[0].hypersensivityToMedicine,
          id: selRemiss[0].id,
          remitentKlinik: selRemiss[0].remittanceClinicId,
          remitentLakare: selRemiss[0].remittanceDoctorId,
          fragestallning: selRemiss[0].questionStatment,
          halsoproblem: selRemiss[0].healthProblems,
          prioritering: selRemiss[0].sysInfPriorityId,
          //plannedExaminationId: null,
          vikt: selRemiss[0].weight,
          langd: selRemiss[0].height
          //timestamp: selRemiss[0].timestamp,
          //remittanceStatusId: 5,
          //personnummer: selRemiss[0].personalIdentityNumber,
          //sprak: selRemiss[0].patient.languageId,
          //weight: this.vikt.value,
          //lenght: this.langd.value,
          //dateVl: selRemiss[0].dateVl,
          //hypersensivityToMedicine: selRemiss[0].patient.hypersensivityToMedicine
          //mobilisering: ,
        });
      }, error => {
        console.error(error);
      });
    }
  }
}
