import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'preview-image-modal',
  templateUrl: './preview-image-modal.component.html'
})
export class PreviewImageModalComponent implements OnChanges {

  constructor(
    public translate: TranslateService,
    private modalService: NgbModal
  ) { }

  @Input() imageName: string;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();
  @Output() removeImageClick = new EventEmitter();

  @ViewChild("content") modalHtml;

  public faTrashAlt = faTrashAlt;

  ngOnChanges(): void {
    if (this.modalIsOpen && this.imageName)
      this.openModal();
  }

  private openModal() {
    this.modalService.open(this.modalHtml, {ariaLabelledBy: 'preview-image-modal', size: 'md'})
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }

  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }

  public removeImage() {
    this.removeImageClick.emit();
    this.closeModal();
  }

}
