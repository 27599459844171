<div class="h-100">
  <div class="d-flex flex-nowrap h-100">
    <app-side-menu></app-side-menu>
    <div class="container-fluid">
      <div class="row bg-dark p-4 mb-3 text-light" style="height: 170px;">
        <div class="col-lg-1 my-auto text-center">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x" style="color: #FFF;"></i>
            <i class="fas fa-user fa-stack-1x fa-inverse" style="color: #343a40;"></i>
          </span>
        </div>
        <div class="col-lg-3 my-auto">
          
        </div>
      </div>

      <h1>Översikt patient</h1>
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4>Undersökningar</h4>
            </div>
            <!--<div class="card-body" *ngIf="dataPatientWithId">
              <ng-container *ngIf="hasPlannedExaminations(dataPatientWithId)">
                <h6 class="mt-1 bg-success p-2">Planerade</h6>
                <span class="row px-3">
                  <span class="col-md-2">Ukod</span><span class="col-2">Akod</span><span class="col-3">RemissId</span><span class="col-3" [ngbTooltip]="'Undersökningsdatum'">Planerat datum</span>
                </span>-->
                <!--<span class="row"><span class="col-md-2">{{remiss.undersokningstyp}}</span><span class="col-2">{{remiss.ukod}}</span><span class="col-2">{{remiss.akod}}</span><span class="col-2">{{remiss.remissId}}</span><span class="col-2">{{remiss.undersokningsdatum}}</span></span>-->
                <!--<mat-expansion-panel *ngFor="let remiss of dataPatientWithId.patientremiss" class="mt-1">
                  <mat-expansion-panel-header *ngIf="!remiss.undersokt && remiss.planerad">
                    <span class="col-2">{{remiss.ukod}}</span><span class="col-2">{{remiss.akod}}</span><span class="col-3">{{remiss.id}}</span><span class="col-3">{{remiss.undersokningsdatum}}</span><span>
                      <span *ngIf="remiss.warning" class="col-md-1" [ngbTooltip]="remiss.warningText"><i class="fas fa-exclamation-triangle text-danger"></i></span>
                    </span>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent *ngIf="remiss.undersokt==true">
                    <p class="border-top pt-4"><b>Klartext: </b>{{remiss.klartext}}</p>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
              <ng-container *ngIf="hasCompletedExaminations(dataPatientWithId.remisser)">
                <h6 class="mt-1 bg-light p-2">Utförda</h6>
                <span class="row px-3">
                  <span class="col-md-2">Ukod</span><span class="col-2">Akod</span><span class="col-3">RemissId</span><span class="col-3" [ngbTooltip]="'Undersökningsdatum'">Undersökningsdatum</span>
                </span>
                <mat-expansion-panel *ngFor="let remiss of dataPatientWithId.remisser" class="mt-1">
                  <mat-expansion-panel-header *ngIf="remiss.undersokt">
                    <span class="col-2">{{remiss.ukod}}</span><span class="col-2">{{remiss.akod}}</span><span class="col-3">{{remiss.id}}</span><span class="col-3">{{remiss.undersokningsdatum}}</span><span>
                      <span *ngIf="remiss.warning" class="col-md-1" [ngbTooltip]="remiss.warningText"><i class="fas fa-exclamation-triangle text-danger"></i></span>
                    </span>
                  </mat-expansion-panel-header>
                  <ng-template matExpansionPanelContent *ngIf="remiss.undersokt">
                    <p class="border-top pt-4"><b>Klartext: </b>{{remiss.klartext}}</p>
                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap float-right">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, dataPatientWithId.patientId]">Visa undersökning</a>
                      <a mat-menu-item class="border-top" (click)="openShowRemissModal(dataPatientRemissWithId)">Visa remiss</a>
                      <a mat-menu-item>Visa historik</a>
                    </mat-menu>
                  </ng-template>
                </mat-expansion-panel>
              </ng-container>
            </div>-->
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <diagram [series]="[{data: randomSerieData}]"></diagram>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="card">
            <div class="card-header">
              <h4>Diagnoser</h4>
            </div>
            <!--<div class="card-body" *ngIf="dataPatientWithId">
              </div>-->
            </div>
          </div>
            </div>
          </div>
        </div>
  </div>
        <!--<show-remiss-modal [modalIsOpen]="showRemissModalIsOpen | async"
                           [patientRemiss]="dataRemissWithId"
                           (modalClosed)="showRemissModalClosed()"
                           (openRemissModal)="openShowRemissInfoModal($event)"></show-remiss-modal>-->
