<style>
  a.list-group-item.active {
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
    border-right: none;
  }
  #selectVariables {
    background-color: #e9ecef !important;
    border: 1px solid #ced4da !important;
  }
  a.list-group-item.active:after {
    content: " ";
    position: absolute;
    right: -15px;
    top: 20%;
    border-top: 15px solid transparent;
    border-right: none;
    border-left: 15px solid #e9ecef;
    border-bottom: 15px solid transparent;
  }
  </style>
<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col-6">
      <h1 class="text-center">Undersökningstyper</h1>
      <form>
        <div class="container bg-light border pt-3 pb-1">
        <div class="form-group text-center">
          <div class="btn-group btn-group-toggle" data-toggle="buttons">
            <label class="btn btn-secondary btn-sm" [class.active]="createNewExaminationType==false">
              <input type="radio" name="typeFromExisting" id="examinationType" [(ngModel)]="createNewExaminationType" [value]="false" [ngbTooltip]="'Använd befintlig undersökningstyp'" (click)="getEchoTypes()">Från befintlig
            </label>
            <label class="btn btn-secondary btn-sm" [class.active]="createNewExaminationType==true">
              <input type="radio" name="typecreateNew" id="examinationType" [(ngModel)]="createNewExaminationType" [value]="true" [ngbTooltip]="'Skapa ny underökningstyp'" (click)="getDataFromExistingEchoTypes('new')">Skapa ny
            </label>
          </div>
        </div>
          </div>
        <div>
          <div *ngIf="!createNewExaminationType">
            <div class="form-group">
              <label for="examinationTypeOption">Vilken undersökning vill du utgå ifrån:</label>
              <select class="form-control" id="examinationTypeOption" (change)="getDataFromExistingEchoTypes($event.target.value)">
                <option *ngFor="let examinationType of dataExaminationType | async; index as i" [value]="examinationType.url">{{examinationType.name}}</option>
              </select>
            </div>
          </div>
          <ng-container *ngFor="let itemGroups of exampleSavedDataExaminationType | async; index as i">
            <div class="form-group">
              <label for="examinationtypeName">Namn på den nya undersökningstypen: </label>
              <input type="text" class="form-control" id="examinationtypeName" aria-describedby="examinationtypeNameHelp" placeholder="Namn" value="">
            </div>
            <div class="form-group">
              <label for="examinationtypeDesc">Beskrivning av undersökningstypen: </label>
              <textarea type="text" class="form-control" id="examinationtypeDesc" aria-describedby="examinationtypeDescHelp" placeholder="Beskrivning av undersökningstypen"></textarea>
            </div>
            <div class="row" *ngIf="typeSelected || createNewExaminationType">
              <div class="col-md-6">
                <ng-container *ngFor="let group of itemGroups.itemgroups" class="list-group" id="list-tab" role="tablist">
                  <a class="list-group-item list-group-item-action" [class.active]="activeGroup==group.group || (createNewExaminationType && isFirst)" (click)="activeGroup=group.group" data-toggle="list" href="/examinationstypes#{{group.group}}" role="tab" aria-controls="{{group.group}}">{{group.group}}</a>
                </ng-container>
              </div>
              <div class="col-md-6" id="selectVariables">
                <ng-container *ngFor="let group of itemGroups.itemgroups" class="tab-content">
                  <ng-container *ngIf="activeGroup==group.group">
                    <div class="tab-pane fade" [class.active]="activeGroup==group.group" [class.show]="activeGroup==group.group" id="{{group.group}}" role="tabpanel" aria-labelledby="group.group">
                      <ng-container *ngFor="let item of group.items">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" value="" id="{{item.shortCaption}}" [checked]="item.selected == 'true'">
                          <label class="form-check-label" for="{{item.shortCaption}}">
                            {{item.shortCaption}}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </div>
          </ng-container>
</div>
        <hr>
        <p class="text-right">
          <button type="button" class="btn btn-secondary">Avbryt</button>
          <button type="submit" class="btn btn-primary">Spara</button>
        </p>
      </form>
    </div>
  </div>
</div>
