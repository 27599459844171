<style>
  .last {
    display: none;
  }

  .example-additional-selection {
    opacity: 0.75;
    font-size: 0.75em;
  }

  .mat-form-field-infix, .mat-select-value, .mat-select-multiple, .mat-select-trigger {
    padding: 1.1em 0.8em 0.5em 0.9em !important;
  }

  .mat-select {
    display: inline-block;
    width: 100%;
    outline: none;
  }

  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-underline::before {
    background-color: transparent !important;
  }

  ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: transparent !important;
    padding: 3px;
  }

  ::ng-deep .mat-select-arrow-wrapper {
    margin-top: 4px !important;
    padding-top: 4px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
  }

  ::ng.deep .mat-select-value, .mat-select-value-text, .ng-star-inserted {
    padding-left: 5px !important;
  }

  ::ng-deep .mat-step-header[aria-labelledby="disabled_af"] {
    pointer-events: none !important;
    cursor: not-allowed;
    opacity: 0.6;
  }
</style>
<ng-template #content let-modal size="xl">
  <div class="modal-header">
    <h5 class="modal-title">Ny remiss <span *ngIf="valdPatientId">({{pnr.value}})</span></h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div><div class="modal-body">
    <form [formGroup]="saveAddRemissForm" (ngSubmit)="submitForm()">

      <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange($event)">

        <mat-step [stepControl]="remissArray1" formGroupName="personForm">
          <input type="hidden" formControlName="patientId">
          <input type="hidden" formControlName="id">
          <ng-template matStepLabel>Fyll i personuppgifter</ng-template>
          <div class="row d-flex justify-content-center">
            <div class="col-lg-4 col-sm-12">
              <label class="col-form-label" for="pnr">
                Personnummer:
              </label>
              <input type="text" class="form-control" formControlName="pnr">
              <div *ngIf="pnr.touched && pnr.errors" class="text-danger font-italic">
                <div *ngIf="pnr.errors.required">Personnummer är obligatoriskt</div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <label class="col-form-label" for="kon">
                Kön:
              </label>
              <select class="form-select form-control" formControlName="kon" *ngIf="genderList$ | async as konList">
                <option>Välj kön</option>
                <option *ngFor="let kon of konList" [value]="kon.id">{{kon.description}}</option>
              </select>
              <div *ngIf="kon.touched && kon.errors" class="text-danger font-italic">
                <div *ngIf="kon.errors.required">Kön är obligatoriskt</div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <label class="col-form-label" for="alder">
                Ange ålder:
              </label>
              <input type="text" class="form-control" formControlName="alder">
              <div *ngIf="alder.touched && alder.errors" class="text-danger font-italic">
                <div *ngIf="alder.errors.required">Ålder är obligatoriskt</div>
              </div>
            </div>
          </div>
          <hr>
          <div class="row d-flex justify-content-center">
            <div class="col-lg-5 col-sm-12">
              <label class="col-form-label" for="fnamn">
                Förnamn:
              </label>
              <input type="text" class="form-control" formControlName="fnamn">
              <div *ngIf="fnamn.touched && fnamn.errors" class="text-danger font-italic">
                <div *ngIf="fnamn.errors.required">Förnamn är obligatoriskt</div>
              </div>
            </div>
            <div class="col-lg-5 col-sm-12">
              <label class="col-form-label" for="enamn">
                Efternamn:
              </label>
              <input type="text" class="form-control" formControlName="enamn">
              <div *ngIf="enamn.touched && enamn.errors" class="text-danger font-italic">
                <div *ngIf="enamn.errors.required">Efternamn är obligatoriskt</div>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-group row d-flex justify-content-center mb-0 mt-0">
            <div class="col-lg-4 col-sm-6">
              <label class="col-form-label" for="adress">
                Adress:
              </label>
              <input type="text" class="form-control" formControlName="adress">
              <div *ngIf="adress.touched && adress.errors" class="text-danger font-italic">
                <div *ngIf="adress.errors.required">Adress är obligatoriskt</div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <label class="col-form-label" for="postnr">
                Postnummer:
              </label>
              <input type="text" class="form-control" formControlName="postnr">
              <div *ngIf="postnr.touched && postnr.errors" class="text-danger font-italic">
                <div *ngIf="postnr.errors.required">Postnummer är obligatoriskt</div>
              </div>
            </div>
            <div class="col-lg-3 col-sm-12">
              <label class="col-form-label" for="postort">
                Postort:
              </label>
              <input type="text" class="form-control" formControlName="postort">
              <div *ngIf="postort.touched && postort.errors" class="text-danger font-italic">
                <div *ngIf="postort.errors.required">Postort är obligatoriskt</div>
              </div>
            </div>
          </div>
          <hr>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-5 col-sm-12">
              <label class="col-form-label" for="epost">
                E-post:
              </label>
              <input type="text" class="form-control" formControlName="epost">
            </div>
            <div class="col-lg-5 col-sm-12">
              <label class="col-form-label" for="telefon">
                Telefon:
              </label>
              <input type="text" class="form-control" formControlName="telefon">
            </div>
          </div>
          <hr>
          <div class="form-group row d-flex justify-content-center" *ngIf="remMobilizationList$ | async as mobiliseringList">
            <div class="col-lg-3 col-sm-6 pt-1">
              <label class="col-form-label" for="vikt">
                Vikt (kg):
              </label>
              <input type="text" class="form-control" formControlName="vikt">
            </div>
            <div class="col-lg-3 col-sm-6 pt-1">
              <label class="col-form-label" for="langd">
                Längd (cm):
              </label>
              <input type="text" class="form-control" formControlName="langd">
            </div>
            <div class="col-lg-4 col-sm-12 pt-1">
              <label class="col-form-label" for="mobilisering">
                Särskilda behov:
              </label>
              <select class="form-select form-control" formControlName="mobilisering">
                <option value="0">Välj särskilda behov</option>
                <!--<mat-select-trigger>-->
                <option *ngFor="let mob of mobiliseringList" [value]="mob.id">{{mob.description}}</option>
              </select>
              <!--<mat-form-field appearance="fill" class="w-100 border-0 bg-white">
              <mat-label class="bg-white pt-4 px-0 mt-2 mx-0 py-0 mb-0">Välj särskilda behov</mat-label>
              <mat-select [formControl]="mobilisering" class="form-control bg-white pl-3" multiple>-->
              <!--<mat-option *ngFor="let mob of mobiliseringList" [value]="mob.id">{{mob.description}}</mat-option>-->
              <!--<mat-select-trigger>
                {{sysEchoGroupsUnd.value ? sysEchoGroupsUnd.value[0] : ''}}
                <span *ngIf="groups.value?.length > 1" class="example-additional-selection">
                  (+{{sysEchoGroupsUnd.value.length - 1}} {{sysEchoGroupsUnd.value?.length === 2 ? 'andra' : 'andra'}})
                </span>
              </mat-select-trigger>-->
              <!--<mat-option *ngFor="let mob of mobiliseringList | async" [value]="mob.id">{{mob.description}}</mat-option>
              </mat-select>-->
              <!--</mat-form-field>-->
              <!--<mat-form-field appearance="fill" class="w-100 border-0 bg-white">
              <mat-label class="bg-white pt-4 pl-2 mt-2 ml-2 pb-0 mb-0">Välj mobilisering</mat-label>
              <mat-select [formControl]="mobilisering" multiple class="form-control bg-white pl-3" (selectionChange)="selectedMobilisering($event)" *ngIf="remMobilizationList$ | async as mobiliseringList">
                <mat-select-trigger>-->
              <!--{{mobilisering.value ? mobilisering.value[0] : ''}}-->
              <!--<span *ngIf="mobilisering.value?.length > 0" class="example-additional-selection">-->
              <!--<span *ngIf="mobilisering.value?.length">-->
              <!--(+{{mobilisering.value.length - 1}} {{mobilisering.value?.length === s ? 'andra' : 'andra'}})-->
              <!--{{mobilisering.value.length}} valda
                    </span>
                  </mat-select-trigger>
                  <mat-option *ngFor="let mob of mobiliseringList" [value]="mob.id" id="{{mob.description}}">
                    {{mob.description}}
                  </mat-option>
                </mat-select>
              </mat-form-field>-->
            </div>
          </div>
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-2 ">
              <div class="form-check pt-4">
                <input class="form-check-input" type="checkbox" value="" id="overkansligLakemedel" formControlName="overkansligLakemedel">
                <label class="form-check-label" for="overkansligLakemedel">
                  Överkänslig mot läkemedel
                </label>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-check pt-4">
                <input class="form-check-input" type="checkbox" value="" id="difficultToExamine" formControlName="difficultToExamine">
                <label class="form-check-label" for="difficultToExamine">
                  Svårundersökt patient
                </label>
              </div>
            </div>
            <!--</div>
            <div class="form-group row d-flex justify-content-center">-->
            <div class="col-sm-2">
              <label class="col-form-label" for="tolkbehov">
              </label>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" id="tolkbehov" formControlName="tolkbehov">
                <label class="form-check-label" for="tolkbehov">
                  Patienten har tolkbehov
                </label>
              </div>
            </div>
            <div class="col-sm-4">
              <ng-container *ngIf="tolkbehov.value">
                <label class="col-form-label" for="sprak">
                  Språk:
                </label>
                <select class="form-select form-control" formControlName="sprak" *ngIf="languagesList$ | async as languageList">
                  <option value="null">Välj språk</option>
                  <option *ngFor="let language of languageList" [value]="language.id">{{language.language}}</option>
                </select>
              </ng-container>
            </div>
          </div>
          <div class="d-flex justify-content-center form-group">
            <!--<button mat-stroked-button matStepperNext (click)="onStepChange($event)">Nästa</button>-->
            <button class="btn" mat-stroked-button matStepperNext (click)="onStepChange($event)" [disabled]="!personForm.valid">Nästa</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="remissArray2" label="Fyll i remissinformation" formGroupName="remissForm" [aria-labelledby]="!personForm.valid ? 'disabled_af' : null">
          <div class="form-group row d-flex">
            <div class="col-lg-5 col-sm-12 offset-lg-1">
              <label class="col-form-label" for="inkomDatum">
                Datum då remissen inkom till kliniken:
              </label>
              <div class="input-group">
                <input type="text"
                       placeholder="Datum då remissen inkom"
                       class="form-control"
                       [formControl]="inkomDatum" #d="ngbDatepicker" ngbDatepicker required>
                <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
              </div>
              
            </div>
          </div>
          <div class="form-group row d-flex justify-content-center" *ngIf="remClinicList$ | async as remiterandeKlinikList">
            <div class="col-lg-5 col-sm-12">
              <label class="col-form-label" for="remitentklinik">
                Remiterande klinik:
              </label>
              <select class="form-select form-control" formControlName="remitentklinik">
                <option value="null">Välj klinik</option>
                <option *ngFor="let klinik of remiterandeKlinikList" [value]="klinik.id">{{klinik.remittanceClinic}}</option>
              </select>
            </div>
            <div class="col-lg-5 col-sm-12" *ngIf="remDoctorList$ | async as remiterandeLakareList">
              <label class="col-form-label" for="remitentlakare">
                Remiterande läkare:
              </label>
              <select class="form-select form-control" formControlName="remitentlakare">
                <option value="null">Välj läkare</option>
                <option *ngFor="let lakare of remiterandeLakareList" [value]="lakare.id">{{lakare.remittanceDoctor}}</option>
              </select>
            </div>
          </div>
          <!--<fieldset class="border p-1 m-1">
            <h4 class="p-1 offset-md-1"><span class="badge badge-secondary">Remitent</span></h4>
            <div class="form-group row d-flex justify-content-center">
              <div class="col-lg-5 col-sm-12">
                <label class="col-form-label" for="remitentklinik">
                  Remiterande klinik:
                </label>
                <input type="text"
                       placeholder="Sök klinik"
                       class="form-control"
                       [formControl]="remitentklinik"
                       [matAutocomplete]="klinikerAC">
                <mat-autocomplete #klinikerAC="matAutocomplete">
                  <mat-option *ngFor="let option of filteredRemitentKliniker | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </div>
              <div class="col-lg-5 col-sm-12">
                <label class="col-form-label" for="remitentlakare">
                  Remiterande läkare:
                </label>
                <input type="text"
                       placeholder="Sök läkare"
                       class="form-control"
                       [formControl]="remitentlakare"
                       [matAutocomplete]="lakareAC">
                <mat-autocomplete #lakareAC="matAutocomplete">
                  <mat-option *ngFor="let option of filteredRemitentLakare | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </fieldset>-->
          <div class="form-group row d-flex justify-content-center">
            <div class="col-lg-10">
              <label class="col-form-label">
                Hälsoproblem:
              </label>
              <textarea class="form-control" formControlName="halsoproblem" rows="5"></textarea>
              <span class="text-danger"
                    *ngIf="(halsoproblem.touched || submitted) && halsoproblem.errors?.required">
                Hälsoproblem är obligatorikt
              </span>
            </div>
          </div>
          <div class="form-group row d-flex justify-content-center">

            <div class="col-lg-10">
              <label class="col-form-label">
                Frågeställning:
              </label>
              <textarea class="form-control" formControlName="fragestallning" rows="5"></textarea>
              <span class="text-danger"
                    *ngIf="(fragestallning.touched || submitted) && fragestallning.errors?.required">
                Frågeställning är obligatorikt
              </span>
            </div>
          </div>
          <br />
          <div class="form-group row d-flex justify-content-center">

            <div class="col-lg-10">
              <label class="col-form-label" for="undersokningar">
                Undersökningar:
              </label>
              <mat-form-field appearance="fill" class="w-100 border-0 bg-white">
                <mat-label>Välj önskad undersökning:</mat-label>
                <mat-select [formControl]="undersokningar" multiple *ngIf="undersokningstyperList$ | async as undersokningarList">
                  <mat-option *ngFor="let item of undersokningarList" [value]="item.id">{{item.description}}</mat-option>
                </mat-select>
              </mat-form-field>

            </div>
          </div>
          <!--<div class="form-group row d-flex justify-content-center mb-4">
            <div class="col-lg-10 col-md-12">
              <label class="col-form-label" for="prioritering">
                Önskad prioritering:
              </label>
              <select class="form-select form-control" formControlName="prioritering" *ngIf="remittancePriorityList$ | async as prios">
                <option value="null">Välj önskad prioritering</option>
                <option *ngFor="let prio of prios" [value]="prio.id">{{prio.description}}</option>
              </select>
            </div>
          </div>-->
          <!--<div class="row d-flex justify-content-center">
            <div class="col-10">
              <h4>Önskade undersökningar</h4>
            </div>
          </div>-->
          <!--<div class="form-group row d-flex justify-content-center" formArrayName="examinations">
            <div class="col-lg-10 border p-1" *ngFor="let examinationForm of examinations.controls; let index = index">
              <div class="row" [formGroup]="examinationForm">
                <div class="col-md-5">
                  <label class="col-form-label" for="undersokning">
                    Önskad undersökning:
                  </label>
                  <select class="form-select form-control" formControlName="undersokning">
                    <option value="null">Välj önskad undersökning</option>
                    <option *ngFor="let undersokning of undersokningarList | async" [value]="undersokning.id">{{undersokning.description}}</option>
                  </select>
                </div>
                <div class="col-md-5">
                  <label class="col-form-label" for="prioritering">
                    Önskad prioritering:
                  </label>
                  <select class="form-select form-control" formControlName="prioritering">
                    <option value="null">Välj önskad prioritering</option>
                    <option *ngFor="let prio of prioriteringList | async" [value]="prio.id">{{prio.description}}</option>
                  </select>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-danger" style="margin-top: 35px;" (click)="deleteExaminationType(index)">Radera</button>
                </div>
              </div>
            </div>
          </div>-->
          <!--<div class="row d-flex justify-content-center">
            <div class="col-10">
              <button class="btn btn-primary btn-sm" (click)="addExaminationType()"><i class="fas fa-plus-circle"></i> Lägg till fler</button>
            </div>
          </div>-->
          <div class="form-group d-flex justify-content-center">
            <button class="btn" mat-button matStepperPrevious>Tillbaka</button>
            <button class="btn" mat-stroked-button matStepperNext (click)="onStepChange($event)" [disabled]="!remissForm.valid">Nästa</button>
          </div>
          <!--<mat-step>
            <ng-template matStepLabel>Klar</ng-template>
            <p class="text-center">Du är klar.</p>
            <div class="form-group row d-flex justify-content-center">
              <button mat-button matStepperPrevious>Tillbaka</button>
              <button mat-button (click)="stepper.reset()">Återställ</button>
            </div>
          </mat-step>-->

        </mat-step>
        <mat-step [stepControl]="remissArray3" label="Klar" (click)="onStepChange($event)" [aria-labelledby]="(!personForm.valid || !remissForm.valid) ? 'disabled_af' : null">
          <p class="text-center">Du är klar.</p>
          <div class="form-group row d-flex justify-content-center">
            <button class="btn" mat-button matStepperPrevious>Tillbaka</button>
            <button class="btn" mat-button (click)="stepper.reset()">Återställ</button>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-lg btn-success" (ngSubmit)="submitForm()">{{'common.save' | translate}}</button>
          </div>
          <!--<button type="button" class="btn btn-info cancel" (click)="closeModal()">{{'common.close' | translate}}</button>
          <button type="submit" class="btn btn-lg btn-success" (ngSubmit)="submitForm()">{{'common.save' | translate}}</button>-->
        </mat-step>



      </mat-horizontal-stepper>

    </form>
  </div>
</ng-template>
