<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title">{{ rowGroup.name ? "Redigera grupprad" : "Ny grupprad" }}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div> 
  
  <form [formGroup]="form" (ngSubmit)="submitForm()">
    <div class="modal-body">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <label class="col-form-label col-sm-4" for="name">Grupp</label>
            <div class="col-sm-8">
              <input formControlName="name" name="name" type="text" class="form-control" />
              <i class="invalid-feedback" 
                 [class.d-block]="name.invalid && name.touched">Vänligen ange namn på grupp</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button *ngIf="rowGroup.name" type="button" class="btn btn-outline-danger float-start" (click)="deleteGroup()">Ta bort</button>
      <button type="submit" class="btn btn-primary float-end" (ngSubmit)="submitForm()">Spara</button>
      <button type="button" class="btn btn-secondary float-end me-2" (click)="closeModal()">Avbryt</button>
    </div>
  </form>
</ng-template>