import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { RegEchoVariantDto } from '@model/undersokningsvarianter/reg-echo-variant-dto';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UndersokningsVariantService {
  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) { }

  public saveNyUndersokningsvariant(variant: RegEchoVariantDto): Observable<RegEchoVariantDto> {
    return this.http.post<RegEchoVariantDto>(this.baseUrl + 'api/echovariants', variant);
  }


  examinationTypesList$ = this.http.get<RegEchoVariantDto[]>(this.baseUrl + 'api/echovariants')
    .pipe(tap(data => console.log("Ekovariant ", JSON.stringify(data))));

  public getEchoVariant(id: number): Observable<RegEchoVariantDto> {
    if (id == null)
      return EMPTY;
    return this.http.get<RegEchoVariantDto>(this.baseUrl + `api/echovariants/${id}`);
  }
  public editVariant(variant: RegEchoVariantDto): Observable<RegEchoVariantDto> {
    if (variant.id > 0)
      return this.http.put<RegEchoVariantDto>(this.baseUrl + `api/echovariants/${variant.id}`, variant);
  }

  public raderaVariant(id: number): Observable<void> {
    return this.http.delete<void>(this.baseUrl + `api/echovariants/${id}`);
  }
  public getEchoVariantsVariables(variantids: number[]): Observable<number[]> {
    return this.http.post<number[]>(this.baseUrl + `api/echovariants/selectvariableids`, variantids );
  }
  public getAllEchoVariantsVariables(): Observable<RegEchoVariantDto[]> {
    return this.http.get<RegEchoVariantDto[]>(this.baseUrl + 'api/echovariants/getAllVariantsVariableids');
  }
}
export class VariantVariables {
  variantId: number;
  //selected?: boolean | false;
  variableIds?: number[] | [];
}
