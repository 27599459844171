
<div class="container-fluid">
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card body">
          <div class="row  align-items-center">
            <div class="col-11">
              <div class="row">
                <div *ngFor="let image of drawedImages" class="col-lg-1">
                  <div class="zoomable-image p-1" (click)="openPreviewImageModal(image)">
                    <img [src]="'assets/img/' + image + '.png'" class="img-fluid" />
                    <fa-icon [icon]="faSearchPlus" class="zoom-icon" size="2x"></fa-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1">
              <a class="link " (click)="openAddImageModal()" [ngbTooltip]="'pages.overview.images.add' | translate"><fa-icon [icon]="faPlusCircle" class="fa-3x"></fa-icon>&ensp;</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 order-lg-1 order-1" *ngIf="examination">
      <div *ngFor="let itemGroup of examination.itemgroups; index as i">
        <div class="row" *ngIf="!itemGroup?.isClosed">
          <div class="col-lg-12">
            <table class="table table-sm table-bordered table-hover">
              <thead>
                <tr>
                  <th colspan="5" class="clearfix">
                    <span class="float-left">{{itemGroup?.group}}</span>
                    <!--<span class="float-right" [ngbTooltip]="'common.edit' | translate" placement="left">
                      <a (click)="openEditGroupModal(itemGroup)" class="px-2"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                    </span>-->
                  </th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngFor="let item of itemGroup?.items">
                  <tr *ngIf="item.inputValue!=null&&item.inputValue!=''" class="clickable" (click)="showDiagramForItem(i, item)"
                      [class.selected]="diagramItems[i] && diagramItems[i].caption == item.caption">
                    <td class="text-center" style="width:10%">
                      <span [class.text-info]="item.caption" [class.text-transparent]="!item.caption" class="mr-1" [ngbTooltip]="item.caption" placement="right">
                        <fa-icon [icon]="faInfoCircle"></fa-icon>
                      </span>
                    </td>
                    <td class="text-center" style="width:10%">
                      {{item.symbol}}
                    </td>
                    <td style="width:30%">{{item.shortCaption}}</td>
                    <td style="width:20%">
                      <div class="row">
                        <div class="col-6">{{item.inputValue ? item.inputValue : '[inputValue]'}}</div>
                        <div class="col-6 d-flex justify-content-end">
                          <button *ngIf="item.history" popoverClass="popoverWidth" (click)="showDiagram(item)" class="float-right btn btn-xs btn-outline-info rounded-0" [ngbPopover]="popHistoryContent" [autoClose]="'outside'" popoverTitle="Historik" placement="right auto"><i class="fas fa-chart-bar" *ngIf="item.history"></i></button>
                          <ng-template #popHistoryContent>
                            <div class="p-4 display-block">
                              <h5 class="pr-4 text-nowrap">Tidigare undersökningar</h5>
                              <diagram [dataItemExamination]="item"
                                       [series]="[{name: item.shortCaption, data: variableSerieData}]"
                                       [yAxisMin]="item.minValue"
                                       [yAxisMax]="item.maxValue"
                                       [plotLines1]="item.ref?.lowerExtreme"
                                       [plotLines2]="item.ref?.lowerNormal"
                                       [plotLines3]="item.ref?.upperNormal"
                                       [plotLines4]="item.ref?.upperExtreme"></diagram>
                            </div>
                          </ng-template>
                        </div>
                      </div>
                    </td>
                    <td style="width:20%">
                      {{item.unit}}

                    </td>
                    <td style="width: 10%">
                      <i *ngIf="item.inputValue!='' && item.inputValue!=null" [ngClass]="getAlertSymbolForItem(item.inputValue, item.ref)?.styleClass" [ngbTooltip]="getAlertSymbolForItem(item.inputValue, item.ref)?.text">
                      </i>
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
          <!--<div class="col-lg-6 px-0 pt-2">-->
          <!--<button class="float-right btn btn-xs btn-outline-info rounded-0" (click)="showDiagramForItem(i, null)">&times;</button>-->
          <!--</div>-->
        </div>
      </div>
    </div>
    <div class="col-lg-6 order-2">
      <div class="row">
        <div class="col align-self-end">
          <button class="btn btn-lg btn-primary float-right" (click)="genereraText()">Generera utlåtande</button>
        </div>
      </div>

      <h1 *ngIf="showGeneratedText">Utlåtande</h1>
      <ng-container *ngIf="!editGenerated && showGeneratedText">
        <h4>{{utlatandeRubrik1}}</h4>
        <div (click)="editGeneratdeText()" [innerHTML]="genereradText1"></div>
        <h4>{{utlatandeRubrik2}}</h4>
        <div (click)="editGeneratdeText()" [innerHTML]="genereradText2"></div>
        <h4>{{utlatandeRubrik3}}</h4>
        <div (click)="editGeneratdeText()" [innerHTML]="genereradText3"></div>
        <ng-container *ngIf="examination">
          <h4>Övrigt</h4>
          <div (click)="editGeneratdeText()">
            {{examination.ovrigtUndersokningText}}
          </div>
          <!--<app-definiator></app-definiator>-->
        </ng-container>
        <h4>{{utlatandeRubrik4}}</h4>
        <div (click)="editGeneratdeText()" [innerHTML]="genereradText4"></div>


      </ng-container>
      <ng-container *ngIf="editGenerated && showGeneratedText">
        <h4>{{utlatandeRubrik1}}</h4>
        <textarea class="form-control" rows="15" [innerHTML]="genereradText1" [(ngModel)]="genereradText1"></textarea><br>
        <h4>{{utlatandeRubrik2}}</h4>
        <textarea class="form-control" rows="15" [innerHTML]="genereradText2" [(ngModel)]="genereradText2"></textarea><br>
        <h4>{{utlatandeRubrik3}}</h4>
        <textarea class="form-control" rows="15" [innerHTML]="genereradText3" [(ngModel)]="genereradText3"></textarea><br>
        <h4>Övrigt</h4>
        <textarea class="form-control" rows="15" [innerHTML]="examination.ovrigtUndersokningText" [(ngModel)]="examination.ovrigtUndersokningText"></textarea><br>
        <h4>{{utlatandeRubrik4}}</h4>
        <textarea class="form-control" rows="15" [innerHTML]="genereradText3" [(ngModel)]="genereradText4"></textarea><br>

        <button class="btn btn-primary" (click)="editGeneratdeText()">Stäng editering</button>
      </ng-container>

    </div>
  </div>
</div>


<edit-itemgroup-modal [itemGroup]="itemGroupToEdit | async"
                      [modalIsOpen]="editItemGroupModalIsOpen | async"
                      (modalClosed)="editItemGroupModalClosed($event)"></edit-itemgroup-modal>

<preview-image-modal [imageName]="imageToPreview | async"
                     [modalIsOpen]="previewImageModalIsOpen | async"
                     (modalClosed)="previewImageModalClosed($event)"
                     (removeImageClick)="removePreviewedImage()"></preview-image-modal>

<add-image-modal [modalIsOpen]="addImageModalIsOpen | async"
                 (modalClosed)="addImageModalClosed($event)"></add-image-modal>
