import { Component, Input } from '@angular/core';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'ph-loading-spinner',
  template: `<fa-icon [icon]="faSpinner" [spin]="true" [size]="size"></fa-icon>`
})
export class LoadingSpinnerComponent {
  public faSpinner = faSpinner;
  @Input() size: string = "3x";
}
