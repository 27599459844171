import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataPatient } from '@model/data-patient';
import { PatientRemiss } from '@model/data-patientremiss';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
//import { MatExpansionModule } from '@angular/material/expansion';
//import { Sort } from '@angular/material/sort';
import { ChangeDetectorRef } from '@angular/core';
import { DynamicSortService } from '@core/services/dynamic-sort.service';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { SelectListItem } from '@model/select-list-item';
import { formatDate } from '@angular/common';
import { ToggleListItem } from '@model/toggle-list-item';
import { PatientService } from '@core/services/patient.service';
import { PatientListDto } from '@model/patienter/patient-list-dto';
import { AlertMessageItem, AlertMessageType } from '@model/alert-message-item';
import { PatientRemittanceListComponent } from './../../pages/patient-remittance-list/patient-remittance-list.component';
import { RemittanceDto } from '@model/remisser/remittance-dto';
import { OnDestroy } from '@angular/core';
import { catchError } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PatientListComponent implements OnInit {
  alertMessage = new BehaviorSubject<AlertMessageItem>(null);
  status: [];

  //sortedData: PatientRemiss[];

  //sortedDataOriginal: PatientRemiss[];
  
  constructor(
    public translate: TranslateService,
    private http: HttpClient,
    private dataService: PatientService,
    private detector: ChangeDetectorRef,
    private dynamicSort: DynamicSortService
  ) { }
  errorMessage = '';

  isNewPatient: boolean = true;
  patientLista: PatientListDto[];
  public faCaretDown = faCaretDown;
  public faCaretUp = faCaretUp;
/*  public toDay = formatDate(new Date(), 'yyyy-MM-dd', 'en');*/
 
  //public dataRemissWithId: Remiss;
  //public dataPatientWithId: DataPatient;
 
  ngOnInit(): void {
    this.getData();
    //this.personalToggle.setValue('all');
    this.initVisibleTableColumns();
  }
  private getData() {
    this.dataService.getPatienter().subscribe(_ => this.patientLista = _);
    //dataService.getAllPatients()
    //this.http.get('/assets/plan-examination/patienter.json')
    //  .subscribe((data: DataPatient[]) => {
    //    this.sortedData = data.map(patient => {
    //      return patient.remisser.map(remiss => {
    //        let patientremiss = new PatientRemiss();
    //        patientremiss.pnr = patient.pnr;
    //        patientremiss.planeradTid = remiss.planeradTid;
    //        patientremiss.planeradLakare = remiss.planeradLakare;
    //        patientremiss.planeradBma = remiss.planeradBma;
    //        patientremiss.remissId = remiss.id;
    //        patientremiss.patientId = patient.patientId;
    //        patientremiss.undersokningstypId = remiss.undersokningstypId;
    //        patientremiss.planeradDatum = remiss.planeradDatum;
    //        //this.personalLista.filter(personal => patientremiss.planeradBma != personal.text).push({ styleClass: '', text: patientremiss.planeradBma, value: patientremiss.planeradBma});
    //        this.getPersonalLista(patientremiss);
    //        return patientremiss; 
    //      });
    //    }).flat();
        /*console.table(this.personalLista);*/
        this.setOrderBy(this.orderBy, true); //Sort by default

        //patientremisser.forEach(array => {
        //  array.forEach(item => {
        //    this.sortedData.push(item);
        //  })
        //});
        /*this.sortedDataOriginal = this.sortedData;*/

        this.detector.markForCheck();
 /*     });*/
  }
  /*Visa remissmodal*/
  public showRemissInfoModalIsOpen: boolean = false;
  public valdRemiss: PatientListDto;
  public openShowRemissInfoModal(currentRemiss: PatientListDto, remissId?: number) {
    console.log(currentRemiss);
    this.valdRemiss = new PatientListDto();
    //TODO: Omvandla till andra klassen
    this.valdRemiss.lastname = currentRemiss.lastname;
    this.valdRemiss.name = currentRemiss.name;
    this.valdRemiss.personalIdentityNumber = currentRemiss.personalIdentityNumber
    if (remissId) {
      this.valdRemiss.id = remissId
      //this.valdRemiss.healthProblems = currentRemiss.remittanceList.find(r => r.id == remissId).healthProblems
      //this.valdRemiss.questionStatment = currentRemiss.remittanceList.find(r => r.id == remissId).questionStatment
      //this.valdRemiss.height = currentRemiss.remittanceList.find(r => r.id == remissId).height
      this.valdRemiss.weight = currentRemiss.remittanceList.find(r => r.id == remissId).weight
      //this.valdRemiss.remittanceClinicDesc = currentRemiss.remittanceList.find(r => r.id == remissId).remittanceClinicDesc
      //this.valdRemiss.timestamp = currentRemiss.remittanceList.find(r => r.id == remissId).timestamp.toString()
    }


    //this.valdRemiss.healthProblems = currentRemiss.remittanceList.find(r => r.id == remissId).healthProblems

    /*this.valdRemiss.questionStatment = currentRemiss.remittanceList;*/
    /*this.valdRemiss.questionStatment = currentRemiss.questionStatment;*/

    this.showRemissInfoModalIsOpen = true;
  }
  //public getPersonalLista(patientremiss: PatientRemiss) {
  //  /*console.table(patientremiss);*/
  //  //this.personalLista.push({ styleClass: '', text: patientremiss.planeradBma, value: patientremiss.planeradBma });
  //  //this.personalLista.push({ styleClass: '', text: patientremiss.planeradLakare, value: patientremiss.planeradLakare });

  //  let bmaExist = this.checkIfValueExist(patientremiss.planeradBma, this.personalLista);
  //  let lakareExist = this.checkIfValueExist(patientremiss.planeradLakare, this.personalLista);

  //  if (!bmaExist) {
  //    this.personalLista.push({ styleClass: '', text: patientremiss.planeradBma, value: patientremiss.planeradBma });
  //  }
  //  if (!lakareExist) {
  //    this.personalLista.push({ styleClass: '', text: patientremiss.planeradLakare, value: patientremiss.planeradLakare });
  //  }
  //}

  public checkIfValueExist(value, arr) {
  var status = false;

  for (var i = 0; i < arr.length; i++) {
    let arrayValue = arr[i].value;
    if (arrayValue == value) {
      status = true;
      break;
    }
  }

  return status;
}
  /* Add new remiss*/
  public addRemissModalIsOpen = new BehaviorSubject<boolean>(false);
  public valdPatientId: number;
  public openAddRemissModal(id, newPatient?: boolean) {
    if (newPatient)
      this.isNewPatient = newPatient;
    this.valdPatientId = id;
    this.addRemissModalIsOpen.next(true);
  }
  public addRemissModalClosed() {
    this.addRemissModalIsOpen.next(false);
  }
  public remissEditClicked(remiss: PatientListDto) {
    // the index will appear here
    this.isNewPatient = false;
    this.valdRemiss = remiss;
    console.log(this.valdRemiss);
    this.addRemissModalIsOpen.next(true);

    //  this.addRemissModalIsOpen.next(false);
  }
    /* Show remiss modal*/
  public showRemissModalIsOpen = new BehaviorSubject<boolean>(false);

  public showRemissModalClosed(reason: string) {
    this.showRemissModalIsOpen.next(false);
  }

  /* Filtrera personal */
  //public personalToggle = new FormControl();

  //public personalSelected(personal: string): boolean {
  //  if (personal == 'all') {
  //    return true;
  //  }
  //  else {
  //    if (personal == this.personalToggle.value)
  //      return true;
  //    else
  //      return false;
  //  }
  //}


  /* Sorting */
  private defaultOrderColumn = 'pnr';
  public orderBy: string = this.defaultOrderColumn;
  public sortAscending: boolean = true;
  public setOrderBy(column: string, reload: boolean = false) {
    if (!reload)
      this.sortAscending = this.orderBy != column ? true : !this.sortAscending;
    this.orderBy = column;
  //  this.sortedData.sort(this.dynamicSort.sort(column, this.sortAscending));
  }

  /* Show/Hide table columns */
  public possibleTableColumns: SelectListItem[] = [];
  public visibleTableColumnsFC = new FormControl();
  private initVisibleTableColumns() {
    this.possibleTableColumns = [
      new SelectListItem('PNR', 'pnr'),
      new SelectListItem('Namn', 'namn'),
      new SelectListItem('Efternamn', 'efternamn'),
      //new SelectListItem('Undersökningstid', 'planeradTid'),
      //new SelectListItem('Läkare', 'planeradLakare'),
      //new SelectListItem('BMA', 'planeradBma'),
    ];
    this.visibleTableColumnsFC.setValue(['pnr', 'namn', 'efternamn']);
    this.detector.markForCheck();
  }
  public columnIsVisible(column: string): boolean {
    let visibleColumns: string[] = this.visibleTableColumnsFC.value;
    return visibleColumns.indexOf(column) > -1;
  }

}
