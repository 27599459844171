import { ChangeDetectionStrategy, Component, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
/*import { PatientLakemedel } from '@model/data-patientlakemedel';*/
import { OnInit } from '@angular/core';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ChangeDetectorRef } from '@angular/core';
import { CalculationService, Formel, Formular, Grunddata, IntervallTyp, TabGroup, VariabelFormler, VariabelFormularFormController } from '../../../../core/services/calculation.service';
import { VariablesService } from '../../../../core/services/variabler.service';
import { EchoVariablesDto } from '../../../../model/undersokningsvarianter/echo-variables-dto';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'formula-echovariable-modal',
  templateUrl: './formula-echovariable-modal.component.html',
  styleUrls: ['./formula-echovariable-modal.component.scss']
})
export class FormulaEchoVariableModalComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private activeModal: NgbActiveModal,
    private ref: ChangeDetectorRef,
    private service: CalculationService,
    private vService: VariablesService
  ) { }

  //Ikoner
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;

  formlerToAdd: Formel[] = [];
  variableName: string;
  cVariableId: number;
  //cVariableShortName: string;
  cVariable: EchoVariablesDto;
  cVariableFormat: string;
  formular: TabGroup[] = [
    {
    tabGroup: true,
    typId: 1,
    group: [{
      header: "Kvinnor",
      konId: 1,
      formcontroller: [
        {
        id: 0,
        reftypId: 1,
        visaEjISvar: false,
        intervallTyp: 1
      }]
    },
    {
      header: "Män",
      konId: 2,
      formcontroller: [
        {
        id: 0,
        reftypId: 2,
        visaEjISvar: false,
        intervallTyp: 1
      }]
    }],
    },
    {
    tabGroup: false,
    typId: 1,
    group: [{
      header: "Formler för män och kvinnor",
      konId: 0,
      formcontroller: [{
        id: 0,
        reftypId: 2,
        visaEjISvar: false,
        intervallTyp: 2
      }]
    }]
  }, {
    tabGroup: false,
    typId: 2,
    group: [{
      header: "Gradering för män och kvinnor",
      konId: 0,
      formcontroller: [{
        id: 0,
        reftypId: 1,
        visaEjISvar: false,
        intervallTyp: 2
      }]
    }]
    }
  //  , {
  //  tabGroup: true,
  //  typId: 2,
  //  group: [{
  //    header: "Gradering för män",  
  //    konId: 2,
  //    formcontroller: [{
  //      id: 0,
  //      reftypId: 1,
  //      visaEjISvar: false,
  //      intervallTyp: 0
  //    }]
  //  }]
  //}, {
  //  tabGroup: true,
  //  typId: 2,
  //  group: [{
  //    header: "Gradering för kvinnor",
  //    konId: 1,
  //    formcontroller: [{
  //      id: 0,
  //      reftypId: 1,
  //      visaEjISvar: false,
  //      intervallTyp: 0
  //    }]
  //  }]
  //  }
  ];

  intervalltyp: IntervallTyp[] = [{ id: 1, beskrivning: "Uträknade intervall" }, { id: 2, beskrivning: "Intervall" }];
  public loading = new BehaviorSubject<boolean>(false);
  expandedIndex = 0;
  submitted = false;
  graderingstypNamn: string;
  grunddata: Grunddata;
  errorMessage: string = "";
  showForm: boolean = false;
  ngOnInit() {
    this.setForm();
    if (this.cVariableId)
      this.getData();
  }
  public editVariableFormulaForm: FormGroup;
  //////////
  ///
  setForm() {
    this.editVariableFormulaForm = new FormGroup({
      intervalltyp: new FormControl(0),
      kontyp: new FormControl(this.grunddata.kon, [Validators.required]),
      alder: new FormControl(this.grunddata.ålder),
      langd: new FormControl(this.grunddata.längd),
      vikt: new FormControl(this.grunddata.vikt),
      referens: new FormControl()
    });
  }
  formler: Formel[] = [];
  //////////
  ///
  getData() {
    this.vService.getVariable(this.cVariableId)?.subscribe(_ => {
      this.cVariable = _;
      console.log(this.cVariableFormat);
      if (_.calculationJson) {
        this.cVariable.calculationJson = _.calculationJson;
        console.log(this.cVariable.calculationJson);
        this.editVariableFormulaForm.patchValue({
          intervalltyp: this.cVariable.calculationJson.intervalltyp,
          kontyp: this.cVariable.calculationJson.kontyp
        });
        let formler = [];

        this.changeIntervalltypSelect(true);
      }
      //  //_.calculationJson.formler.flatMap(cv => {
      //  //  let f = {
      //  //    id: cv.id,
      //  //    kon: cv.kon,
      //  //    graderingstyp: cv.graderingstyp,
      //  //    formel: cv.formel
      //  //  } as Formel;
      //  //  this.formler.push(f);
      //  //});
      //  //this.formkontroller.forEach(f => {
      //  //  this.formler.forEach(_ => { if (_.id == f.id) { f.formel = _.formel } });
      //  //});

      //  console.log(this.formular);



      //let jsonData = JSON.stringify(this.cVariable.calculationJson);

      //console.log(this.jsonData.intervalltyp);

    });
    this.ref.markForCheck();

  }
  //////////
  ///
  formChanged: boolean = false;
  konTypChange() {
    this.ref.markForCheck();
    //this.formChanged = true;
    ////Rensa
    //console.table(this.formlerToAdd);
  }
  //////////
  ///
  changeIntervalltypSelect(firstTime: boolean = false) {
    this.ref.markForCheck();
    //if (firstTime == false)
    //  this.formChanged = true;
    ////Rensa
    //this.formlerToAdd = [];
    
  //  console.table(this.formlerToAdd);
  }
  //////////
  ///
  updateGrunddata() {
    this.grunddata = { ålder: this.editVariableFormulaForm.get('alder').value ?? "0", vikt: this.editVariableFormulaForm.get('vikt').value ?? "0", längd: this.editVariableFormulaForm.get('langd').value ?? "0" }
  }
  //////////
  ///
  public message: string = "";
  showMessage(message: string) {
    this.message = message;
  }
  //////////
  ///
  showHideGrunddata(showGrunddata: boolean) {
    if (showGrunddata == true)
      this.grunddataOpen = true;
    else
      this.grunddataOpen = false;
  }
  //////////
  ///
  getFormulaData(formulaData: VariabelFormularFormController, konId: number) {
    //Om det finns formulärdata
    console.table("detta" + formulaData + "Kön" + konId);
    if (formulaData != null) {
      //Skapa ett objekt för datan
      const formel: Formel = {
        kon: konId,
        graderingstyp: formulaData.graderingsTyp,
        formel: formulaData.formel,
        graderingsTecken: formulaData.graderingsTecken ?? "test",
        format: this.cVariableFormat,
        graderingsText: formulaData.graderingsText,
        visaEjISvar: formulaData.visaEjISvar,
        intervalltyp: formulaData.intervallTyp,
        startValue: formulaData.graderingsTyp != 1 ? formulaData.startValue : '',
        beraknat: formulaData.intervallTyp == 2 ? formulaData.formel : null,
        deleted: formulaData.deleted
      };
      const index = this.formlerToAdd.findIndex(f => (f.kon == formel.kon && f.graderingstyp == formel.graderingstyp && f.intervalltyp == formel.intervalltyp));
      if (index > -1) {
        this.formlerToAdd.splice(index, 1);
      }
      //if (formulaData.delete != true)
        this.formlerToAdd.push(formel);
     
      console.log('Att spara: ');
      console.table(this.formlerToAdd);
      this.ref.markForCheck();
    }
  }
  //////////
  ///
  public grunddataOpen: boolean = false;
  //////////
  ///
  public toggleGrunddata() {
    this.grunddataOpen = !this.grunddataOpen;
  }
  validationError: boolean = false;
  submitForm() {
    console.log(this.editVariableFormulaForm.get('kontyp').value);
    //TODO: Fixa så att det inte läggs till flera gånger. Filtreringen nedan.
//    this.formlerToAdd = this.formlerToAdd.filter(_ => _.intervalltyp === this.editVariableFormulaForm.get('intervalltyp')?.value && ((this.editVariableFormulaForm.get('kontyp')?.value == 1 && (_.kon === 1 || _.kon === 2))));
/*     || (this.editVariableFormulaForm.get('kontyp')?.value === 1 && (_.kon === 1 || _.kon === 2)))*/
    let kontyp = this.editVariableFormulaForm.get('intervalltyp')?.value == 2 ? 1 : this.editVariableFormulaForm.get('kontyp').value;
    let parantesesError: boolean = false;
    // Gå igenom alla formler och kolla så att de har rätt antal parenteser a
    //this.formlerToAdd.forEach(_ => {
    //  let leftParCount = (_.formel.split("(").length - 1);
    //  let rightParCount = (_.formel.split(")").length - 1);
    //  //Om det inte är lika många parenteser på varje sida ska felmedelande visas
    //  if (leftParCount != rightParCount) {
    //    parantesesError = true;
    //    _.showErrorMessageParanteses = true;
    //  }
    //});

    let variabelFormler = {
      intervalltyp: this.editVariableFormulaForm.get('intervalltyp')?.value ?? 0,
      kontyp: kontyp,
      formler: this.formlerToAdd,
      format: this.cVariableFormat
    } as VariabelFormler;
    if (!this.validationError && !parantesesError) {
      this.service.editVariableFormula(this.cVariableId, variabelFormler).subscribe({
        next: (data) => {
          this.activeModal.close();
        },
        error: (errorResponse: any) => {
          //  this.errorMessage = errorResponse.error;
        }
      });
    }
  }
  //////////
  ///
  public onCancel(): void {
    this.activeModal.dismiss();
  }
  //////////
  ///
  onTabChanged(e: Event) {
    this.ref.markForCheck();
  }
  //////////
  ///
  //Nollställ formulär
  clearForm() {
    this.editVariableFormulaForm.reset();
  }
}
@Injectable({
  providedIn: "any"
})

export class EditVariableFormulaModalService {
  constructor(private modalService: NgbModal) { }

  public show(id: number, variableName: string, format: string, patientGrundData?: Grunddata): Observable<unknown> {
    const modalRef = this.modalService.open(FormulaEchoVariableModalComponent, { backdrop: "static", size: "xl" });
    modalRef.componentInstance.cVariableId = id;
    modalRef.componentInstance.variableName = variableName;
    modalRef.componentInstance.cVariableFormat = format;
    modalRef.componentInstance.grunddata = patientGrundData ?? { längd: '0', ålder: '0', vikt: '0' };
    return modalRef.closed;
  }
}
