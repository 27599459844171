<style>
  .last {
    display: none;
  }

  .example-additional-selection {
    opacity: 0.75;
    font-size: 0.75em;
  }
</style>
<ng-template #content let-modal size="xl">
  <div class="modal-header">
    <h5 class="modal-title">Remiss</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <form [formGroup]="saveStatusRemissForm" (ngSubmit)="submitForm()">
    <input type="hidden" formControlName="remissId" [value]="patientRemiss.remissId">
    <input type="hidden" formControlName="id" [value]="patientRemiss.id">
    <div class="modal-body">

      <div class="row">
        <div class="col-md-6">
          <div class="card p-1">
            <div class="card-body">
              <div class="row">
                <div class="col-sm-6">
                  <h3>Patient</h3>
                </div>
                <div class="col-sm-6 d-flex justify-content-end">
                  <a [routerLink]="['/overview-patient-page', patientRemiss.patientId]" (click)="modal.dismiss()" class="link">Visa översikt</a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <b>Namn:</b>
                </div>
                <div class="col-lg-8 col-sm-6">
                  {{patientRemiss.name}} {{patientRemiss.lastname}}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <b>Personnr:</b>
                </div>
                <div class="col-lg-8 col-sm-6">
                  {{patientRemiss.personalIdentityNumber}}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <b>Vikt (från remiss):</b>
                </div>
                <div class="col-lg-8 col-sm-6">
                  {{patientRemiss.weight}} kg
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <b>Längd (från remiss):</b>
                </div>
                <div class="col-lg-8 col-sm-6">
                  {{patientRemiss.height}} cm
                </div>
              </div>

              <!--<div class="row">
              <div class="col-4">
                <b>Ålder:</b>
              </div>
              <div class="col-8">
                {{patientRemiss.alder}} år
              </div>
            </div>-->
            </div>
          </div>
          <div class="card mt-3">
            <div class="card-header">Remitent</div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <b>Remiterande enhet:</b>
                </div>
                <div class="col-lg-8 col-sm-6">
                  {{patientRemiss.remittanceClinicDesc}}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 col-sm-6">
                  <b>Remiterande läkare:</b>
                </div>
                <div class="col-lg-8 col-sm-6">
                  {{patientRemiss.remittanceDoctorDesc}}
                </div>
              </div>
              <!--<div class="row">
              <div class="col-4">
                <b>Telefonnr remiterande klinik:</b>
              </div>
              <div class="col-8">
                {{patientRemiss.telefonremitent}}
              </div>
            </div>-->
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="card">
            <div class="card-header">Tidigare kontakter</div>
            <div class="card-body">
              <h6>Remisser</h6>
              <table class="table w-100 table-striped">
                <tr><th>Inkom</th><th>Typ</th><th></th></tr>
                <tr><td>{{patientRemiss.datum}}</td><td>...</td><td class="text-right"><button class="btn btn-sm btn-outline">Visa</button></td></tr>
              </table>
              <h6>Undersökningar</h6>
              <table class="table w-100 table-striped">
                <tr><th>Datum</th><th>Typ</th><th></th></tr>
                <tr><td>...</td><td>..</td><td class="text-right"><button class="btn btn-sm btn-outline">Visa</button></td></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <div class="card">
            <div class="card-header">
              Remiss
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <b>Remissid:</b>
                </div>
                <div class="col-lg-9 col-sm-6">
                  {{patientRemiss.id}}
                </div>
              </div>
              <!--<div class="row">
              <div class="col-4">
                <b>Remissnr:</b>
              </div>
              <div class="col-8">
                {{patientRemiss.remissId}}
              </div>
            </div>-->
              <div class="row">
                ss
                 <
                <div class="col-lg-3 col-sm-6">
                  <b>Remissdatum:</b>
                </div>
                <div class="col-lg-9 col-sm-6">
                  {{patientRemiss.datum}}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <b>Frågeställning:</b>
                </div>
                <div class="col-lg-9 col-sm-6">
                  {{patientRemiss.questionStatment}}
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <b>Hälsoproblem:</b>
                </div>
                <div class="col-lg-9 col-sm-6">
                  {{patientRemiss.healthProblems}}
                </div>
              </div>
            </div>
          </div>






          <!--<div class="row">
          <div class="col-4">
            <b>Indikation:</b>
          </div>
          <div class="col-8">
            {{patientRemiss.indikation}}
          </div>
        </div>-->
          <!--<div class="row">
        <div class="col-4">
          <b>Mobilisering:</b>
        </div>
        <div class="col-8">-->
          <!--{{mobiliseringString}}-->
          <!--</div>
        </div>-->
          <!--<div class="row">
          <div class="col-4">
            <b>Behov av tolk:</b>
          </div>
          <div class="col-8">
            {{patientRemiss.tolkbehov ? "Ja": "Nej"}}
            <ng-container *ngIf="patientRemiss.tolkbehov"> - {{patientRemiss.sprak}}</ng-container>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <b>Prioritering:</b>
          </div>
          <div class="col-8">
            {{patientRemiss.prioritering}}
          </div>
        </div>-->


        </div>

      </div>

      <mat-button-toggle-group class="mt-2 ml-4" formControlName="remissStatus">
        <mat-button-toggle value="skickaplanering" checked class="p-2"><i class="fa-solid fa-circle-check"></i> Planera</mat-button-toggle>
        <mat-button-toggle value="komplettering" class="p-2"><i class="fa fa-solid fa-reply"></i> Begär komplettering</mat-button-toggle>
        <mat-button-toggle (click)="remissEdit.emit(patientRemiss)" class="p-2"> Komplettera remiss</mat-button-toggle>
        <mat-button-toggle value="avsla" class="p-2"><i class="fas fa-exclamation-triangle"></i> Avslå</mat-button-toggle>
        <mat-button-toggle value="delegera" class="p-2"><i class="fa fa-solid fa-share"></i> Delegera</mat-button-toggle>
      </mat-button-toggle-group>

      <!--<div class="row mt-2">
      <div class="col-12">
        <button class="btn btn-sm btn-secondary mr-1" (click)="kompletteringFraga('komplettering')"><i class="fa fa-solid fa-reply"></i> Begär komplettering <fa-icon class="float-right mx-2" [icon]="komplettering ? faAngleDown : faAngleUp"></fa-icon></button>
        <button class="btn btn-sm btn-secondary mr-1" (click)="remissEdit.emit(patientRemiss)"><i class="fa fa-solid fa-reply"></i> Komplettera remiss</button>
        <button class="btn btn-sm btn-danger mr-1" (click)="kompletteringFraga('avsla')"><i class="fas fa-exclamation-triangle"></i> Avslå <fa-icon class="float-right mx-2" [icon]="avlsa ? faAngleDown : faAngleUp"></fa-icon></button>
        <button class="btn btn-sm btn-info" (click)="kompletteringFraga('delegera')"><i class="fa fa-solid fa-share"></i> Delegera <fa-icon class="float-right mx-2" [icon]="delegera ? faAngleDown : faAngleUp"></fa-icon></button>
      </div>

    </div>-->
      <div class="row" *ngIf="remissStatus.value == 'delegera'">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Välj läkare som du vill delegera remissen till: </label>
            <select class="form-control" formControlName="delegeraLakare">
              <option>Välj läkare</option>
              <option></option>
            </select>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="remissStatus.value=='delegera' || remissStatus.value=='komplettering' || remissStatus.value=='avsla'">
        <div class="col-12">
          <div class="form-group">
            <label class="form-label">Kommentar: </label>
            <textarea class="form-control" rows="3" formControlName="commentRemissStatus"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info cancel" (click)="modal.dismiss('Close')">{{'common.close' | translate}}</button>
      <button type="submit" class="btn btn-lg btn-danger" (ngSubmit)="submitForm()" *ngIf="remissStatus.value=='avsla'">Avslå</button>
      <button type="submit" class="btn btn-lg btn-success" (ngSubmit)="submitForm()" *ngIf="remissStatus.value=='komplettering'">Begär komplettering</button>
      <button type="submit" class="btn btn-lg btn-success" (ngSubmit)="submitForm()" *ngIf="remissStatus.value=='delegera'">Delegera</button>
      <a class="btn btn-info btn-lg" (click)="modal.dismiss()" [routerLink]="['/plan-examination', patientRemiss.id, patientRemiss.patientId]" *ngIf="remissStatus.value!='avsla' && remissStatus.value!='delegera' && remissStatus.value!='komplettering'">Skapa undersökning <i class="fa fa-solid fa-angle-right"></i></a>
    </div>
  </form>
</ng-template>
