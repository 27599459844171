export class PatientRemiss {
  pnr: string;
  patientId: any;
  fname: string;
  ename: string;
  remisstyp: string;
  remissId: number;
  remissnr: number;
  remissdatum: string;
  remitent: string;
  remiterandeLakare: string;
  prioritering: number;
  tolkbehov: boolean;
  sprak: string;
  hasMultipleRemisses: boolean;
  undersokt: boolean;
  planerad: boolean;
  planeradTid: string;
  planeradDatum: string;
  planeradBma: string;
  planeradLakare: string;
  komplettera: boolean;
  aterremitterad: boolean;
  vantarKomplettering: boolean;
  status: number;
  mobilisering: string;
  mobiliseringString: string;
  undersokningstypId: string;
}



