import { Component, OnChanges, Input, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { ExaminationService } from '@core/services/examination.service';
import { HttpClient } from '@angular/common/http';
import { DataItemGroup } from './model/data-item-group';
import { DataItem } from './model/data-item';
import { RangeError } from './model/range-error';
import { Router } from '@angular/router';

import { AuthenticationService } from './core/services/authentication.service';
import { User } from './model/user';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent { 
  public patientId: string;
  public patient: string;

  public isExpanded: boolean = false;
  user: User;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {
    this.authenticationService.user.subscribe(x => this.user = x);
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
  logout() {
    this.authenticationService.logout();
  }
}
