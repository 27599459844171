import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { OnInit } from '@angular/core';
import { Output } from '@angular/core';
import { Input } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { Kon, RefTyp } from '../../../core/services/calculation.service';
import { VariablesService } from '../../../core/services/variabler.service';
import { EchoVariablesDto } from '../../../model/undersokningsvarianter/echo-variables-dto';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'select-variable-formula',
  templateUrl: './select-variable-formula.component.html'
})
export class SelectVariableFormulaComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private ref: ChangeDetectorRef,
    private dataService: VariablesService,
    private http: HttpClient

  ) { }
  refTyper: RefTyp[] = [{ id: 0, beskrivning: "Normalvärde" }, { id: 1, beskrivning: "Nedre referens" }, { id: 2, beskrivning: "Övre referens" }];
  @Input() cVariable: EchoVariablesDto;
  @Input() refTyp: RefTyp;
  @Input() kon: Kon;
  @Input() metodId: number;
  input: string = "";
  get constantsArray(): FormArray {
    return this.variabeFormulaForm.controls['constantsArray'] as FormArray;
  }
  ngOnInit() {
  }
  private validatorNotZero = [
    Validators.required,
    Validators.pattern(/^([1-9]\d*)?$/)
  ];
  variabeFormulaForm: FormGroup = new FormGroup({
    constantsArray: new FormArray([])
  });
 
  changeFormelSelect() {
    this.clearConstantsArray();
  }
  calculatedFormel: Observable<any>;
  formelInkKonst: string = '';
  clearConstantsArray() {
    this.constantsArray.clear();
  }
}

//När man väl valt en metod så följer formler för nedre och övre referens med.
