/// <reference path="../modules/settings/settings.module.ts" />
import { NgModule } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule } from '@angular/common';
import { AlertMessageComponent } from '../shared/components/alert-message/alert-message.component';
//import { NavMenuComponent } from '../shared/components/nav-menu/nav-menu.component';
import { SideMenuComponent } from '../shared/components/side-menu/side-menu.component';
import { LoadingSpinnerComponent } from '../shared/components/loading-spinner/loading-spinner';
import { Routes, RouterModule } from '@angular/router';
import { fakeBackendProvider } from '../_helpers/fake-backend';
import { ErrorInterceptor } from '../_helpers/error.interceptor';
import { BasicAuthInterceptor } from '../_helpers/basic-auth.interceptor';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { DiagramComponent } from './components/diagram/diagram.component';
//import { ImageDrawingModule } from 'ngx-image-drawing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ShowRemissModalComponent } from '../modules/patient/components/show-remiss-modal/show-remiss-modal.component';
import { DrawableCanvasComponent } from './components/drawable-canvas/drawable-canvas.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartModule } from 'angular-highcharts';
import { CreateFormulaComponent } from './components/create-formula/create-formula.component';
import { SelectVariableFormulaComponent } from './components/select-variable-formula/select-variable-formula.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AutofocusDirective } from '../core/directives/autofocus.directive';

//import { TranslateHttpLoader } from '@ngx-translate/http-loader/lib/http-loader';

//export function createTranslateLoader(http: HttpClient) {
//  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
//}
@NgModule({
  declarations: [
    CreateFormulaComponent,
    SelectVariableFormulaComponent,
    AlertMessageComponent,
    LoadingSpinnerComponent,
    SideMenuComponent,
    DiagramComponent,
    ShowRemissModalComponent,
    DrawableCanvasComponent,
    
  ],
  imports: [
    MatAutocompleteModule,
    MatNativeDateModule,
    ChartModule,
    CommonModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    MatExpansionModule,
    MatMenuModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDatepickerModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CdkAccordionModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AutofocusDirective
    //ImageDrawingModule
  ],
  exports: [
    MatNativeDateModule,
    MatAutocompleteModule,
    SelectVariableFormulaComponent,
    ShowRemissModalComponent,
    ChartModule,
    AlertMessageComponent,
    LoadingSpinnerComponent,
    CreateFormulaComponent,
    SideMenuComponent,
    DrawableCanvasComponent,
    DiagramComponent,
    CommonModule,
    HttpClientModule,
    NgbModule,
    RouterModule,
    MatTabsModule,
    MatMenuModule,
    MatTableModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    TranslateModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    NgSelectModule,
    CdkAccordionModule,
    NgxMaskDirective,
    NgxMaskPipe,
    AutofocusDirective
    //ImageDrawingModule
  ],
  providers: [fakeBackendProvider,
    provideNgxMask(),
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
  ]
})


export class SharedModule { }
