<style>
  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .mat-expansion-panel-header {
    overflow: visible !important;
    z-index: 5000 !important;
  }

  .mat-expansion-panel {
    overflow: visible !important;
  }

</style>

<div class="h-100">
  <div class="d-flex flex-nowrap h-100">
    <app-side-menu></app-side-menu>

    <div class="container-fluid">
      <div class="row bg-dark p-2 text-light border-bottom border-secondary mb-3" style="min-height: 100px;">
        <div class="col-lg-1 my-auto text-center">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x" style="color: #FFF;"></i>
            <i class="fas fa-user fa-stack-1x fa-inverse" style="color: #343a40;"></i>
          </span>
        </div>
        <div class="col-lg-8 my-auto">
          <!--{{dataRemissWithId.name}} {{dataRemissWithId.lastname}} | {{dataRemissWithId.personalIdentityNumber}}-->
        </div>
        <!--<div class="col-lg-3 my-auto text-right">
          <button (click)="openShowRemissModal(remittanceInfo)" class="btn btn-sm btn-outline-light text-white">Visa remiss</button>
        </div>-->
        <!--<div class="col-lg-3" *ngIf="dataRemissWithId.warningText || dataRemissWithId.warningTextLakemedel">-->
        <!--<div class="alert alert-danger" role="alert">
      <h4 class="alert-heading"><i class="fas fa-exclamation-circle"></i> Att tänka på</h4>
      <hr>
      {{dataRemissWithId.warningRemissText}}
      {{dataRemissWithId.warningTextLakemedel}}
    </div>-->
        <!--<patient-lakemedel [remiss]="dataRemissWithId"
                 [patient]="dataPatientWithId"></patient-lakemedel>-->
        <!--/div>-->
        <!--<div class="col-lg-4 my-1">

      <patient-lakemedel [remiss]="dataRemissWithId"
                         [patient]="dataPatientWithId"></patient-lakemedel>

    </div>-->
      </div>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a [routerLink]="'/remittance-list'">Remisser</a></li>
          <li class="breadcrumb-item active" aria-current="page">Planera</li>
        </ol>
      </nav>
      <!--<div class="row">
        <div class="col-12">-->
          <!--<h1>Skapa undersökning - {{dataRemissWithId.name}}</h1>-->
        <!--</div>
      </div>-->
      <div class="mt-2 row">
        <div class="mb-5 col-lg-8">
          <div class="card">
            <div class="card-header">
              <h2>Skapa undersökning</h2>
            </div>
            <div class="card-body">
              <h3>Patientinformation</h3>
              <form>
                <div class="mb-3 row">
                  <label for="fc_weight" class="col-sm-3 col-form-label">Vikt(kg):</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" id="fc_weight" placeholder="Vikt i kg" [value]="dataRemissWithId.weight">
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="fc_length" class="col-sm-3 col-form-label">Längd(cm):</label>
                  <div class="col-sm-9">
                    <input type="text" class="form-control" id="fc_length" placeholder="Längd i cm" [value]="dataRemissWithId.height">
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="fc_mobilization" class="col-sm-3 col-form-label">Särskilda behov:</label>
                  <div class="col-sm-9">
                    <select class="form-control form-control-lg" id="fc_mobilization">
                      <option [selected]>Välj särskilda behov</option>
                      <option *ngFor="let mobilisering of mobiliseringOptions | async" [value]="mobilisering.id">{{mobilisering.description}}</option>
                    </select>
                  </div>
                </div>
                <!--<div class="mb-3 row">
                  <label for="fc_prio" class="col-sm-3 col-form-label">Prioritering (1-5):</label>
                  <div class="col-sm-9">
                    <select class="form-control form-control-lg" id="fc_prio">
                      <option [selected]="true">Välj prioritering</option>
                      <option *ngFor="let prio of remittancePriorityList$ | async" [value]="prio.id">{{prio.description}}</option>
                    </select>
                  </div>
                </div>-->
              </form>
              <h3 class="mt-3">Undersökning</h3>
              <mat-tab-group class="pt-1" [selectedIndex]="selectedExaminationTab.value" (selectedIndexChange)="selectedExaminationTab.setValue($event)">
                <mat-tab *ngFor="let ex of examinations; let index = index" [label]="ex.name">
                  <div class="text-right mt-2">
                    <button type="button" class="btn btn-xs btn-sm btn-outline-danger" (click)="removeExamination(index)">Radera undersökning</button>
                  </div>
                  <form>
                    <div class="mb-3 row pt-2">
                      <label for="fc_examination" class="col-sm-3 col-form-label">Undersökning:</label>
                      <div class="col-sm-9">
                        <select class="form-control form-control-lg" id="fc_examination" disabled>
                          <option selected>{{ex.name}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="mb-3 row pt-2" *ngIf="examinations.length>1">
                      <label for="fc_order" class="col-sm-3 col-form-label">Ordning på undersökning:</label>
                      <div class="col-sm-9">
                        <select class="form-control form-control-lg" id="fc_order" name="fc_order" [(ngModel)]="ex.sortorder">
                          <option *ngFor="let option of examinations; let index = index" [value]="index+1">{{index+1}}</option>
                        </select>
                      </div>
                    </div>
                    <ng-container [ngSwitch]="ex.name">
                      <ng-container *ngSwitchCase="'Ekokardiografi'" [ngTemplateOutlet]="ekokardiografiForm" [ngTemplateOutletContext]="{examination:ex}"></ng-container>
                      <!-- <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultTemplate"></ng-container> -->
                    </ng-container>
                  </form>
                </mat-tab>
                <mat-tab id="examinationTab_new">
                  <ng-template mat-tab-label>
                    <mat-icon fontSet="fas" fontIcon="fa-plus-circle"></mat-icon>&nbsp;Lägg till
                  </ng-template>
                  <form>
                    <div class="mb-3 row pt-2">
                      <label for="fc_examination" class="col-sm-3 col-form-label">Undersökning:</label>
                      <div class="col-sm-9">
                        <!--<select class="form-control form-control-lg" id="fc_examination" name="fc_examination" [(ngModel)]="newExamination.name">
                          <option value="0">Välj undersökning</option>
                          <option *ngFor="let option of examinationOptions" [value]="option" [attr.disabled]="examinationExists(option) ? 'disabled' : null">{{option}}</option>
                        </select>-->
                      </div>
                    </div>
                    <div class="mb-3 row pt-2" *ngIf="examinations.length>0">
                      <label for="fc_order" class="col-sm-3 col-form-label">Ordning på undersökning:</label>
                      <div class="col-sm-9">
                        <select class="form-control form-control-lg" id="fc_order" name="fc_order" [(ngModel)]="newExamination.sortorder">
                          <option *ngFor="let option of examinations; let index = index" [value]="index+1">{{index+1}}</option>
                          <option [value]="examinations.length+1">{{examinations.length+1}}</option>
                        </select>
                      </div>
                    </div>
                    <ul class="list-inline text-right mb-0">
                      <li class="list-inline-item"><button type="submit" class="btn btn-primary" (click)="addExamination()">Lägg till</button></li>
                    </ul>
                  </form>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="card-footer">
              <ul class="list-inline text-right mb-0">
                <li class="list-inline-item"><button type="button" class="btn btn-secondary">Avbryt</button></li>
                <li class="list-inline-item"><button type="button" class="btn btn-primary">Spara</button></li>
              </ul>
            </div>
            <form [formGroup]="saveAddPatientExaminationForm">
              <ng-template #ekokardiografiForm let-examination="examination">

                <div class="mb-3 row">
                  <label for="fc_degreeOfDifficulty" class="col-sm-3 col-form-label">Svårighetsgrad (1-5):</label>
                  <div class="col-sm-9">
                    <select class="form-control form-control-lg" id="fc_degreeOfDifficulty" formControlName="fc_degreeOfDifficulty">
                      <option>Välj svårighetsgrad</option>
                    </select>
                  </div>
                </div>
                <div class="mb-3 row">
                  <label for="fc_indikation" class="col-sm-3 col-form-label">Indikation:</label>
                  <div class="col-sm-9">
                    <input type="text"
                           placeholder="Sök indikation"
                           class="form-control"
                           formControlName="fc_indikation"
                           [matAutocomplete]="indikationAC">
                    <mat-autocomplete #indikationAC="matAutocomplete">
                      <mat-option *ngFor="let optionIndikation of filteredIndikation | async" [value]="optionIndikation">
                        {{optionIndikation}}
                      </mat-option>
                    </mat-autocomplete>
                  </div>
                </div>
                <div class="form-check mb-3 col-sm-9 offset-sm-3 pl-5">
                  <input class="form-check-input" type="checkbox" value="" id="tee" formControlName="tee">
                  <label class="form-check-label" for="tee">
                    TEE ska göras
                  </label>
                </div>
                <div class="mb-3 row">
                  <label for="examinationType" class="col-sm-3 col-form-label">Undersökningsvariant att utgå från:</label>
                  <div class="col-sm-9">
                    <!--<select class="form-control" id="examinationTypeOption" (change)="getDataFromEchoType($event.target.value)" formControlName="fc_examinationtype">
    <ng-container *ngFor="let examinationType of examinationTypesList | async;index as i;">
      <option [value]="examinationType.id">{{examinationType.variantName}}</option>
    </ng-container>
  </select>-->
                    <mat-form-field appearance="fill" class="w-100 border-0 bg-white">
                      <mat-label class="bg-white pt-4 pl-2 mt-2 ml-2 pb-0 mb-0">Välj undersökningsvariant</mat-label>
                      <mat-select [formControl]="examinationType" multiple class="form-control bg-white pl-3" (selectionChange)="getDataFromEchoType($event)">
                        <mat-select-trigger>
                          <span *ngIf="examinationType.value?.length">
                            {{examinationType.value.length}} valda
                          </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let examinationType of examinationTypesList | async;index as i;" [value]="examinationType.id" id="{{examinationType.id}}">
                          {{examinationType.variantName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <br>
                <h4 class="mt-3 pt-2 pb-2 border-bottom">Variabler</h4>
                <div class="row">
                  <ng-container *ngFor="let grupp of variablesByGroup | async">
                    <div class="col-lg-2 col-md-3 col-sm-4 pt-2">
                      <h6>{{grupp.echoGroup}}</h6>
                      <ng-container *ngFor="let variable of grupp.sysEchoVariables">
                        <div class="form-check">
                          <input class="form-check-input" type="checkbox" id="var_{{variable.id}}" formControlName="{{variable.id}}" />
                          <label class="form-check-label" for="var_{{variable.id}}" [ngbTooltip]="variable.description">
                            {{variable.shortName}}
                          </label>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                </div>

                <!--<div class="row">
    <ng-container *ngIf="typeSelected && dataWithId">
      <ng-container *ngFor="let group of dataWithId.itemgroups">
        <div class="col-lg-2 col-md-3 col-sm-4 pt-2">
          <h6>{{group.group}}</h6>
          <ng-container *ngFor="let item of group.items">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="{{item.variable}}" [checked]="item.selected == 'true'">
              <label class="form-check-label" for="{{item.variable}}" [ngbTooltip]="item.caption" [class.font-weight-bold]="item.selected == 'true'">
                {{item.shortCaption}}
              </label>
              <span *ngIf="item.warning" class="float-right" [ngbTooltip]="item.warningText"><i class="fas fa-exclamation-triangle text-danger"></i></span>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>-->
              </ng-template>
            </form>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="card mb-3">
            <div class="card-header">
              <div class="row">
                <div class="col-sm-4">
                  <h4>Remiss</h4>
                </div>
              </div>
            </div>
            <div class="card-body">

              <!--<b>Mobilisering: </b><span class="float-right">{{dataRemissWithId.mobiliseringString}}</span><br>-->
              <!--<b>Behov av tolk: </b><span class="float-right">{{dataRemissWithId.patient.language ? "Ja": "Nej"}}</span><br>-->
              <!--<ng-container *ngIf="dataPatientWithId.patient.language"><b>Språk: </b><span class="float-right">{{dataRemissWithId.language}}</span><br></ng-container>-->
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Remiterande enhet: </b>
                </div><div class="col-md-8">
                  <!--{{dataRemissWithId.remittanceClinicDesc}}-->
                </div>
              </div>
              <!--<b>Telefonnr remiterande klinik: </b><span class="float-right">{{dataRemissWithId.remittanceClinic}}</span><br>-->
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Remiterande läkare: </b>
                </div><div class="col-md-8">
                  <!--{{dataRemissWithId.remittanceDoctorDesc}}-->
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Remissdatum: </b>
                </div><div class="col-md-8">
                  <!--{{dataRemissWithId.timestamp}}-->
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Remissnr: </b>
                </div><div class="col-md-8">
                  {{dataRemissWithId.id}}
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Prioritering: </b>
                </div><div class="col-md-8">
                  <!--{{dataRemissWithId.prioInfo}}-->
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4">
                  <b>Svårundersökt: </b>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-md-4"><b>Hälsoproblem: </b></div><div class="col-md-8">{{dataRemissWithId.healthProblems}}</div>
              </div>
              <div class="row">
                <div class="col-md-4"><b>Frågeställning: </b></div><div class="col-md-8">{{dataRemissWithId.questionStatment}}</div>
              </div>
              <!--<div class="text-right" *ngIf="hasOplaneradeRemisser(dataPatientWithId.remisser)">
    <div class="card bg-light">
      <div class="card-body">
        <h6 class="border-bottom">Andra remisser på patient som har oplanerad undersökning</h6>
        <ul *ngFor="let remiss of dataRemissWithId.remisser" class="list-unstyled">
          <li *ngIf="!remiss.planerad && (remiss.id != remiss.id)"><a href="#">{{remiss.remitent}} - <span class="text-nowrap">{{remiss.remissdatum}}</span></a></li>
        </ul>
      </div>
    </div>
  </div>-->


            </div>
                <div class="card-footer">
                  <ul class="nav justify-content-end">
                    <!--Visas om reissen inte kommer från journalsystem (inscannad remiss eller PDF-remiss?)-->
                    <!--<li class="nav-item"><a class="nav-link" href="#">Visa originalremiss</a></li>-->
                    <li class="nav-item"><a class="nav-link" href="#">Visa historik för remiss</a></li>
                    <li class="nav-item"><a class="nav-link" href="#">Återremittera</a></li>
                  </ul>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h4>Tidigare undersökningar</h4>
                </div>
                <div class="card-body" *ngIf="dataPatientWithId">
                  <i>Det finns inga tidigare undersökningar på patienten.</i>







                  <!--<ng-container *ngIf="hasPlannedExaminations(dataPatientWithId.remittanceList)">
      <h6 class="mt-1 bg-success p-2">Planerade</h6>
      <span class="row px-3">
        <span class="col-3">Remissnr</span>
        <span class="col-3" [ngbTooltip]="'Remissdatum'">Remissdatum</span>
      </span>-->
                  <!--<span class="row">-->
                  <!--<span class="col-md-2" *ngFor="scheduledMedicalExaminations"><span></span></span><span class="col-2">{{remiss.id}}</span><span class="col-2">{{remiss.timestamp}}</span></span>-->
                  <!--<mat-expansion-panel *ngFor="let remiss of dataPatientWithId.remisser" class="mt-1">
      <mat-expansion-panel-header *ngIf="!remiss.undersokt && (dataRemissWithId.id != remiss.id) && remiss.planerad">
        <span class="col-3">{{remiss.id}}</span><span class="col-3">{{remiss.timestamp}}</span><span>
          <span *ngIf="remiss.warning" class="col-md-1" [ngbTooltip]="remiss.warningText"><i class="fas fa-exclamation-triangle text-danger"></i></span>
        </span>
      </mat-expansion-panel-header>-->
                  <!--<ng-template matExpansionPanelContent *ngIf="remiss.undersokt==true">-->
                  <!--<ng-template matExpansionPanelContent>
          <p class="border-top pt-4"><b>Frågeställning: </b>{{remiss.questionStatment}}</p>
          <p class="border-top pt-4"><b>Hälsoproblem: </b>{{remiss.healthProblems}}</p>
        </ng-template>
      </mat-expansion-panel>
      </ng-container>-->
                  <!--<ng-container *ngIf="hasCompletedExaminations(dataPatientWithId.remisser)">
        <h6 class="mt-1 bg-light p-2">Utförda</h6>
        <span class="row px-3">
          <span class="col-md-2">Ukod</span><span class="col-2">Akod</span><span class="col-3">Remissnr</span><span class="col-3" [ngbTooltip]="'Undersökningsdatum'">Undersökningsdatum</span>
        </span>
        <mat-expansion-panel *ngFor="let remiss of dataPatientWithId.remisser" class="mt-1">
          <mat-expansion-panel-header *ngIf="remiss.undersokt">
            <span class="col-3">{{remiss.id}}</span><span class="col-3">{{remiss.undersokningsdatum}}</span><span>
              <span *ngIf="remiss.warning" class="col-md-1" [ngbTooltip]="remiss.warningText"><i class="fas fa-exclamation-triangle text-danger"></i></span>
            </span>
          </mat-expansion-panel-header>
          <ng-template matExpansionPanelContent *ngIf="remiss.undersokt">
            <p class="border-top pt-4"><b>Klartext: </b>{{remiss.klartext}}</p>
            <p class="text-right"><button class="btn btn-primary">Visa utlåtande</button></p>
          </ng-template>
        </mat-expansion-panel>
      </ng-container>-->
                </div>
              </div>
            </div>
      </div>
    </div>
    </div>
  </div>
<!--<add-lakemedel-modal [patientLakemedel]="lakemedelToEdit | async"
                     [newPatientLakemedel]="newLakemedelToEdit | async"
                     [modalIsOpen]="showLakemedelModalIsOpen | async"
                     (modalClosed)="showLakemedelModalClosed()"
                     (onSubmit)="newLakemedelSubmitted($event)"></add-lakemedel-modal>-->
