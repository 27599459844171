
<ng-container *ngIf="messageItem">

  <ngb-alert *ngIf="!isSelfClosing"
             [type]="messageItem.type"
             (closed)="messageItem = null">
    <ng-container *ngIf="false; else alertContent"></ng-container>
  </ngb-alert>

  <ng-container *ngIf="isSelfClosing">
    <ngb-alert #selfClosingAlert *ngIf="!selfClosingAlertClosed"
               [type]="messageItem.type"
               (closed)="selfClosingAlertClosed">
      <ng-container *ngIf="false; else alertContent"></ng-container>
    </ngb-alert>
  </ng-container>

</ng-container>

<ng-template #alertContent>
  <ng-container [ngSwitch]="messageItem.type">
    <ng-container *ngSwitchCase="messageTypes.Info">
      <fa-icon [icon]="faInfoIcon" class="text-info"></fa-icon>
    </ng-container>
    <ng-container *ngSwitchCase="messageTypes.Success">
      <fa-icon [icon]="faSuccessIcon" class="text-success"></fa-icon>
    </ng-container>
    <ng-container *ngSwitchCase="messageTypes.Warning">
      <fa-icon [icon]="faWarningIcon" class="text-warning"></fa-icon>
    </ng-container>
    <ng-container *ngSwitchCase="messageTypes.Error">
      <fa-icon [icon]="faErrorIcon" class="text-danger"></fa-icon>
    </ng-container>
  </ng-container>

  <span [innerHTML]="messageItem.message"></span>
</ng-template>
