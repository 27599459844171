export class DataPatientRemissUndersokning {
  patientId: string;
  pnr: string;
  remisstyp: string;
  remissId: number;
  remissnr: number;
  remissdatum: string;
  remissinfo: string;
  remitent: string;
  remiterandeLakare: string;
  prioritering: number;
  tolkbehov: boolean;
  sprak: string;
  hasMultipleRemisses: boolean;
  undersokt: boolean;
  planerad: boolean;
  komplettera: boolean;
  aterremitterad: boolean;
  vantarKomplettering: boolean;
  status: number;
  mobilisering: string;
  mobiliseringString: string;
  undersokningsvariant: string;
  undersokningstypId: string;
  hfrekvens: number;
  blodtryck: string;
  ovrigtUndersokningText: string;
  itemgroups: Itemgroups[];
  lakemedel: PatientLakemedel[];
  selectOptions: SelectListItem[];
}
export class Itemgroups {
  group: string;
  groupStatus: string;
  isClosed: boolean;
  items: SavedDataItem[];
}
export class SavedDataItem {
  variable: string;
  styleClass: string | 'bg-light';
  optional: boolean;
  caption: string;
  shortCaption: string;
  selected: any;
  isMattVarde: boolean;
  unit: string;
  warning: boolean;
  warningText: string;
  inputValue: number | 0;
  alertSymbol: string;
  alertTakePicture: boolean;
  manualValue: string;
  exactValue: boolean;
  oplaneratExtravarde: boolean;
  ref: DataItemReferenceValues;
  gradering: Gradering[];
  machineValues: ValueFromDevice[];
  history: History[];
  minValue: number;
  maxValue: number;
  ifStatements: IfStatement[];
  thenShow: [];
  //  historySerie: Array<number>;
}
export class History {
  year: number;
  month: number;
  day: number;
  value: any;
}
export class Gradering {
  number: number;
  intervalBottom: number;
  intervalTop: number;
  text: string;
}
export interface IfStatement {
  ifLeftside: string;
  operator: string;
  ifRightside: string;
  thenShowVariables: ShowVar[];
  variableInformation: string;
}
export interface ShowVar {
  showVar: string;
}

export class PatientLakemedel {
  lakemedel?: any;
  lakemedelStyrka?: any;
  lakemedelMangd?: any;
  lakemedelEnhet?: any;
  lakemedelKommentar?: any;
  lakemedelForeslaget?: any;
  lakemedelOrdinerat?: any;
  lakemedelOrdination?: any;
  lakemedelTidOrdination?: any;
  lakemedelGivet?: any;
  lakemedelTidGivet?: any;
  lakemedelSignerat?: any;
  lakemedelTidSignerat?: any;
}
export class SelectListItem {
  constructor(
    public text: string,
    public value: string
  ) { }
}
export interface DataItemReferenceValues {
  lowerExtreme: number;
  lowerNormal: number;
  upperNormal: number;
  upperExtreme: number;
}
export interface ValueFromDevice {
  id: any,
  value: number,
  status: any,
  latestUpdateDate: string,
  latestUpdateTime: string,
  deleted: boolean,
  deletedTime: string,
  selected: boolean
}


