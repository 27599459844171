
<form [formGroup]="formGroup" (ngSubmit)="onSubmit.emit(formGroup)">
    <div class="input-carousel" (keydown)="onKeyDown($event)">

        <ng-container *ngIf="activeItemGroup">

            <div class="input-carousel-item header-item">
                <h5>{{activeItemGroup.group}}</h5>
            </div>

            <ng-container *ngFor="let dataItem of activeItemGroup.items; index as i">

                <div class="input-carousel-item" 
                    [class.active-visible-item]="activeDataItem && activeDataItem.variable == dataItem.variable"
                    [class.first-visible-item]="(!activeDataItem || activeDataItem.variable != dataItem.variable) && i == activeDataItemIndex-2"
                    [class.second-visible-item]="(!activeDataItem || activeDataItem.variable != dataItem.variable) && i == activeDataItemIndex-1"
                    [class.second-last-visible-item]="(!activeDataItem || activeDataItem.variable != dataItem.variable) && i == activeDataItemIndex+1"
                    [class.last-visible-item]="(!activeDataItem || activeDataItem.variable != dataItem.variable) && i == activeDataItemIndex+2"
                    [class.hidden]="i <= activeDataItemIndex-3 || i >= activeDataItemIndex+3"
                    (click)="(!activeDataItem || activeDataItem.variable != dataItem.variable) ? setActiveDataItem(dataItem, true) : ''">

                    <div [id]="dataItem.variable" class="form-row">
                        <div class="col-12" *ngIf="activeDataItem && dataItem.variable == activeDataItem.variable">
                            <p class="item-caption">{{dataItem.caption}}</p>
                        </div>
                        <div class="col-5 text-right">
                            <label class="item-label col-form-label">
                                {{dataItem.shortCaption}}
                            </label>
                            <span>{{dataItem.symbol}}</span>
                        </div>
                        <div class="col-6">
                            <ng-container [ngSwitch]="dataItem.format">
                                <ng-container *ngSwitchCase="'[]'" >
                                    <select class="form-control" formControlName="{{dataItem.variable}}" required 
                                            (keydown)="selectListKeyPress($event)">
                                        <option *ngFor="let opt of dataItem.options" value="{{opt.value}}">{{opt.text}}</option>
                                    </select>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <input type="text" class="form-control" formControlName="{{dataItem.variable}}" required
                                        [placeholder]="dataItem.unit.length>0 ? '('+dataItem.unit+')' : ''">
                                </ng-container>
                            </ng-container>
                        </div>
                        <div class="col-12 input-error">
                            <ng-container [ngSwitch]="formGroup.get(dataItem.variable).getError('rangeError')">
                                <span *ngSwitchCase="1" class="text-danger">
                                    <i class="fas fa-exclamation-circle"></i>
                                    <span class="pl-2" translate>pages.input.ref.lower-extreme</span>
                                </span>        
                                <span *ngSwitchCase="2" class="text-danger">
                                    <i class="fas fa-exclamation-circle"></i>
                                    <span class="pl-2" translate>pages.input.ref.upper-extreme</span>
                                </span>        
                                <span *ngSwitchCase="3" class="text-warning">
                                    <i class="fas fa-exclamation-triangle"></i>
                                    <span class="pl-2" translate [translateParams]="getErrorText(dataItem)">pages.input.ref.lower-normal</span>
                                </span>        
                                <span *ngSwitchCase="4" class="text-warning">
                                    <i class="fas fa-exclamation-triangle"></i>
                                    <span class="pl-2" translate [translateParams]="getErrorText(dataItem)">pages.input.ref.upper-normal</span>
                                </span>        
                            </ng-container>
                        </div>
                    </div>
                </div>

            </ng-container>

            <ng-container *ngIf="activeItemIsTheLastInGroup && nextItemGroup">
                <div class="input-carousel-item last-visible-item header-item">
                    <h5>{{nextItemGroup.group}}</h5>
                </div>
            </ng-container>

            <ng-container *ngIf="activeItemGroupIsTheLast">
                <div class="input-carousel-item button-item" 
                    [class.active-visible-item]="submitBtnIsActive"
                    [class.hidden]="activeDataItemIndex < activeItemGroup.items.length-2">
                    <button #submitBtn type="button" class="btn btn-lg btn-success" (click)="onSubmit.emit(formGroup)">{{'common.save' | translate}}</button>
                </div>
            </ng-container>

        </ng-container>


    </div>
</form>
