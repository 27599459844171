<div class="container-fluid">
  <div class="row">
    <div class="col-lg order-lg-1 order-2">
      <div class="card">
        <div class="card-header">
          <h2 class="float-left">Utlåtande</h2>
          <div class="btn-group btn-group-toggle float-right align-right" data-toggle="buttons">
            <label class="btn btn-secondary btn-sm" [class.active]="textTypeEdit==true">
              <input type="radio" name="showDefiniatorText" id="textTypeEdit" [(ngModel)]="textTypeEdit" [value]="true" [ngbTooltip]="'Visa redigerbar vy'" (click)="textTypeEdit=true"><i class="fa fa-edit"></i>
            </label>
            <label class="btn btn-secondary btn-sm" [class.active]="textTypeEdit==false">
              <input type="radio" name="showDefiniatorText" id="textTypeEdit" [(ngModel)]="textTypeEdit" [value]="false" [ngbTooltip]="'Visa som flytande text'" (click)="textTypeEdit=false"><i class="fa fa-align-left"></i>
            </label>
          </div>
        </div>

        <div class="card-body lead" id="definiatorcontainer">
          <ng-container *ngFor="let itemGroup of dataItemGroups | async; index as i">
            <ng-container *ngFor="let item of itemGroup.items" [ngSwitch]="item.format">
              <ng-container [ngSwitch]="'[]'">
                <ng-container *ngFor="let option of item.options" [ngSwitch]="true">
                  <ng-container *ngSwitchCase="option.selected=='true'">
                    <!--Visa text för valda värdet i dropdown-->
                    <div *ngIf="textTypeEdit" class="row">
                      <div class="col-3 col-md-2 col-sm-1">
                        <a (click)="openEditSurveyModal(item)" [class.text-info]="item.variable" [ngbTooltip]="'Redigera ' + item.variable"><i class="fas fa-edit"></i></a>
                      </div>
                      <div class="col-9 col-md-10 col-sm-11">
                        <a (click)="openEditSurveyModal(item)" [class.text-info]="item.variable" class="mr-1" [ngbTooltip]="'Redigera ' + item.variable">{{item.variable}} {{option.text}}</a>
                      </div>
                    </div>
                    <span *ngIf="!textTypeEdit">{{item.variable}} {{option.text}}.</span>
                  </ng-container>
                </ng-container>
              </ng-container>
              <!--Om droplist med värden-->
              <ng-container [ngSwitch]="'##'">
                <ng-container *ngFor="let ref of item.linnearefs" [ngSwitch]="true">
                  <!--Kolla i vilket intervall värdet hamnat-->
                  <ng-container *ngIf="item.value!='' && ref.from <= item.value && ref.to > item.value">
                    <!--Om custom text-->
                    <div *ngIf="ref.customtext!=''">
                      <div *ngIf="textTypeEdit" class="row">
                        <div class="col-3 col-md-2 col-sm-1">
                          <a (click)="openEditSurveyModal(item)" [class.text-info]="item.caption" [class.text-transparent]="!item.caption" [ngbTooltip]="'Redigera ' + item.caption"><i class="fas fa-edit"></i></a>
                        </div>
                        <div class="col-9 col-md-10 col-sm-11">
                          <a (click)="openEditSurveyModal(item)" [class.text-info]="item.caption" [class.text-transparent]="!item.caption" class="mr-1" [ngbTooltip]="'Redigera ' + item.caption">{{item.caption}} {{ref.customtext}} ({{item.variable}} {{item.value}})</a>
                        </div>

                      </div>
                      <span *ngIf="!textTypeEdit">{{item.caption}}{{ref.customtext}} ({{item.variable}} {{item.value}})</span>
                    </div>
                    <!--Om autotext-->
                    <div *ngIf="ref.customtext==''&&ref.text!=''">
                      <div *ngIf="textTypeEdit" class="row">
                        <div class="col-3 col-md-2 col-sm-1">
                          <a (click)="openEditSurveyModal(item)" class="text-info" [ngbTooltip]="'Redigera ' + item.caption"><i class="fas fa-edit"></i></a>
                        </div>
                        <div class="col-9 col-md-10 col-sm-11">
                          <a (click)="openEditSurveyModal(item)" class="text-info mr-1" [ngbTooltip]="'Redigera ' + item.caption">{{item.caption}} {{ref.text}} ({{item.variable}} {{item.value}})</a>
                        </div>
                      </div>
                      <span *ngIf="!textTypeEdit">{{item.caption}}{{ref.text}} ({{item.variable}} {{item.value}})</span>
                      <br>
                    </div>
                    <!--Annars visa varning om att text saknas-->
                    <div *ngIf="ref.customtext==''&&ref.text==''">
                      <div *ngIf="textTypeEdit" class="row">
                        <div class="col-3 col-md-2 col-sm-1">
                          <a (click)="openEditSurveyModal(item)" [class.text-info]="item.caption" [class.text-transparent]="!item.caption" [ngbTooltip]="'Redigera ' + item.caption"><i class="fas fa-edit"></i></a>
                        </div>
                        <div class="col-9 col-md-10 col-sm-11">
                          <a (click)="openEditSurveyModal(item, opiniontext)" [class.text-info]="item.caption" [class.text-transparent]="!item.caption" class="mr-1" [ngbTooltip]="'Redigera ' + item.caption">{{item.caption}} saknar utlåtande ({{item.variable}} {{item.value}}) <i class="fas fa-exclamation-triangle text-danger"></i></a>
                        </div>
                      </div>
                      <span *ngIf="!textTypeEdit">{{item.caption}} Saknar utlåtande ({{item.variable}} {{item.value}})</span>
                      <br>
                    </div>
                  </ng-container>
                </ng-container>
                <!--Om det saknas värde för en obligatorisk fråga-->
                <div *ngIf="item.value != '' && item.urgent=='true'">
                  <div *ngIf="textTypeEdit" class="row">
                    <div class="col-3 col-md-2 col-sm-1">
                      <a (click)="openEditSurveyModal(item)" [class.text-info]="item.caption" [class.text-transparent]="!item.caption" [ngbTooltip]="'Redigera ' + item.caption"><i class="fas fa-edit"></i></a>
                    </div>
                    <div class="col-9 col-md-10 col-sm-11">
                      <a (click)="openEditSurveyModal(item)" [class.text-info]="item.caption" [class.text-transparent]="!item.caption" class="mr-1" [ngbTooltip]="'Redigera ' + item.caption">{{item.variable}} är inte besvarad <i class="fas fa-exclamation-triangle text-danger"></i></a>
                    </div>
                  </div>
                  <span *ngIf="!textTypeEdit">{{item.variable}} är inte besvarad</span>
                  <br>
                </div>

              </ng-container>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<edit-itemsurvey-modal [item]="itemSurveyToEdit | async"
                       [modalIsOpen]="editItemSurveyModalIsOpen | async"
                       (modalClosed)="editItemSurveyModalClosed($event)"></edit-itemsurvey-modal>
