import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
//import { createTranslateLoader } from '../../app.module';
import { PatientRoutingModule } from './patient.routing';
import { OverviewPatientComponent } from '../../modules/patient/pages/overview-patient/overview-patient.component';
import { PatientService } from '@core/services/patient.service';
import { AddRemissModalComponent } from './components/add-remiss-modal/add-remiss-modal.component';
//import { createTranslateLoader, SharedModule } from '../../shared/shared.module';
import { SharedModule } from '../../shared/shared.module';

import { RemissService } from '@core/services/remiss.service';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientRemittanceListComponent } from './pages/patient-remittance-list/patient-remittance-list.component';
import { RemittanceListComponent } from './pages/remittance-list/remittance-list.component';
import { AppRoutingModule } from '../../app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgbAlertModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
  declarations: [
    RemittanceListComponent,
    OverviewPatientComponent,
    AddRemissModalComponent,
    PatientListComponent,
    PatientRemittanceListComponent  ],
  imports: [
    PatientRoutingModule,
    MatFormFieldModule,
    MatInputModule,
    MatStepperModule,
    AppRoutingModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgbAlertModule,
    NgbDatepickerModule,
    NgSelectModule,
    FormsModule,
    //TranslateModule.forRoot({
    //  loader: {
    //    provide: TranslateLoader,
    //    useFactory: (createTranslateLoader),
    //    deps: [HttpClient]
    //  },
    //  defaultLanguage: 'sv'
    //})
  ],
  exports: [
  //  RouterModule
  ],
  providers: [PatientService, RemissService]
})
export class PatientModule { }

