
<table class="table table-sm border">
    <thead>
        <ng-container *ngIf="itemGroup.group">
            <tr class="header-row"
                [class.active-row]="activeItemGroup.group == itemGroup.group && isCollapsed">
                <td class="label-column header-column" colspan="2"
                    (click)="setActiveItem(itemGroup.items[0])">
                    {{itemGroup.group}}
                    <span class="icon pl-2" [class.text-transparent]="!isCollapsed" *ngIf="itemGroupHasErrorValidation()"><i class="fas fa-exclamation-circle text-danger"></i></span>
                    <span class="icon pl-2" [class.text-transparent]="!isCollapsed" *ngIf="itemGroupHasErrorValidation() == false && itemGroupWarningValidation()"><i class="fas fa-exclamation-triangle text-warning"></i></span>
                </td>
                <td class="caret-column"
                    (click)="isCollapsed = !isCollapsed">
                    <span class="icon" [class.d-none]="!isCollapsed"><i class="fas fa-caret-down"></i></span>
                    <span class="icon" [class.d-none]="isCollapsed"><i class="fas fa-caret-up"></i></span>
                </td>
            </tr>
            </ng-container>
    </thead>
    <tbody class="collapse" [class.show]="!isCollapsed"> <!-- [ngbCollapse]="isCollapsed" -->
        <ng-container *ngFor="let item of itemGroup.items; index as i">
        <tr [class.active-row]="item.variable == activeItem.variable"
            (click)="setActiveItem(item)">
            <td class="label-column">{{item.shortCaption}}</td>
            <td class="value-column"
                [class.empty-value-column]="!(formGroup.get(item.variable).value)">
                {{formGroup.get(item.variable).value}} {{item.unit}}
            </td>
            <td class="error-column">
                <span class="icon" *ngIf="itemHasErrorValidation(item)"><i class="fas fa-exclamation-circle text-danger"></i></span>
                <span class="icon" *ngIf="itemHasWarningValidation(item)"><i class="fas fa-exclamation-triangle text-warning"></i></span>
            </td>
        </tr>
        </ng-container>
    </tbody>
</table>
