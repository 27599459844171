import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';
import { ExampleItemgroups } from '@model/data-exampleItemgroups';
import { ExampleSavedDataItem } from '@model/data-exampleSavedDataItem-type';
import { RefValidatorService } from '@core/services/ref-validator.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'edit-itemgroup-modal',
  templateUrl: './edit-itemgroup-modal.component.html',
  styleUrls: ['./edit-itemgroup-modal.component.scss']
})
export class EditItemgroupModalComponent implements OnChanges {

  constructor(
    public translate: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private refValidator: RefValidatorService,
  ) { }

  @Input() itemGroup: ExampleItemgroups;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();

  @ViewChild("content") modalHtml;

  public form: FormGroup;
  public loading = new BehaviorSubject<boolean>(false);
  public formItems: ExampleSavedDataItem[];

  ngOnChanges(): void {
    if (this.modalIsOpen && this.itemGroup) {
      this.setForm();
      this.openModal();
    }
  }

  /* Modal */
  private openModal() {
    this.modalService.open(this.modalHtml, {ariaLabelledBy: 'edit-itemgroup-modal', size: 'lg'})
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }

  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }

  /* Form */
  private setForm() {
    this.loading.next(true);
    this.formItems = JSON.parse(JSON.stringify(this.itemGroup.items));
    this.form = this.fb.group({});
    this.formItems.forEach((item) => {
      let control: FormControl = new FormControl('',[this.refValidator.validator(item.ref)]);
      this.form.addControl(item.variable, control);
    });
    this.loading.next(false);
  }

  public getRangeError(formItem): any {
    let error = this.form.get(formItem.variable).getError('rangeError');
    return error ? error : null;
  }
  public getErrorText(formItem): any {
    return {value: this.form.get(formItem.variable).getError('errorText')};
  }

  public onSubmit() {
    //TODO: Save
    this.closeModal();
  }


}
