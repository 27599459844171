<div class="container-fluid">
  <div class="row mt-5">
    <div class="col-12">
      <h1>Undersökningar</h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-2">
      <button class="btn btn-primary" (click)="openAddExaminationModal(0)">Lägg till undersökning</button>
    </div>
    <!--<div class="col-md-2 offset-8">
      <div class="input-group">
        <div class="input-group-prepend">
          <span class="input-group-text" id="search">Sök</span>
        </div>
        <input type="text" class="form-control" placeholder="Sök" aria-label="Sök" aria-describedby="search">
      </div>
    </div>-->
  </div>
  
  <div class="row"> 
    <div class="col-lg-12 pt-5">
      <table class="table table-striped">
        <thead>
          <tr>
            <th *ngIf="columnIsVisible('created')" class="clickable" (click)="setOrderBy('created')">
              Skapad
              <span class="th-caret" *ngIf="orderBy == 'created'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('pnr')" class="clickable" (click)="setOrderBy('pnr')">
              PNR
              <span class="th-caret" *ngIf="orderBy == 'pnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('name')" class="clickable" (click)="setOrderBy('name')">
              Namn
              <span class="th-caret" *ngIf="orderBy == 'name'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!(examinations.length)">
            <th class="text-center" colspan="4">
              <ph-loading-spinner></ph-loading-spinner>
            </th>
          </tr>
        <ng-container *ngIf="examinations.length > 0">
          <tr *ngFor="let examination of examinations">
            <td *ngIf="columnIsVisible('created')">{{examination.created | date: 'yyyy-MM-dd'}}</td>
            <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{examination.pnr}}</td>
            <td *ngIf="columnIsVisible('name')" class="text-nowrap">
              {{examination.patientFnamn}} {{examination.patientEnamn}}
            </td>
            <td>
              <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                
                <!--Bekräfta radering modal -->
                <div ngbDropdown class="d-inline-block">
                  <button class="btn btn-danger btn-sm mx-1" [ngbTooltip]="'Radera undersökningen'"><span class="px-1" ngbDropdownToggle><fa-icon [icon]="faTrash"></fa-icon></span></button>
                  <div ngbDropdownMenu>
                    <div class="container">
                      <h5>Vill du verkligen radera undersökningen för <b>{{examination.patientFnamn}} {{examination.patientEnamn}}</b>?</h5>
                      <div class="dropdown-divider"></div>
                        <button type="submit" class="btn btn-warning btn-xs" (click)="confirmDelete(examination.id)" ngbDropdownItem>Radera</button>
                    </div>
                  </div>
                </div>
                <!-- /Bekräfta radering modal -->
                <button class="btn btn-primary btn-sm mx-1" (click)="openAddExaminationModal(examination.id)">Redigera</button>
                <a class="btn btn-primary btn-sm" [routerLink]="['/echo-testbadd', examination.id]">Starta</a>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
