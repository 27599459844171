////import { SharedModule } from './shared/shared.module';
////import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { ExaminationService } from '@core/services/examination.service';
import { DynamicSortService } from './core/services/dynamic-sort.service';
import { RefValidatorService } from './core/services/ref-validator.service';
import { RefValidatorSymbolService } from './core/services/ref-validator-symbols.service ';
//import { HomeComponent } from './pages/home/home.component';
//import { LoginComponent} from './pages/login/login.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CoreModule } from './../app/core/core.module';
import { ExaminationsModule } from './modules/examinations/examinations.module';
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { PatientModule } from './modules/patient/patient.module';
import { SettingsModule } from './modules/settings/settings.module';
import { fakeBackendProvider } from './_helpers/fake-backend';
//import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
//import { ChartModule } from 'angular-highcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
import { DragDropModule } from '@angular/cdk/drag-drop';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function CreateTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    //SharedModule,
    RouterModule,
    BrowserModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: CreateTranslateLoader,
        deps: [HttpClient]
      },
      defaultLanguage: 'sv'
    }),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CoreModule,
    ExaminationsModule,
    PatientModule,
    SettingsModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    ToastrModule.forRoot() // ToastrModule added
  ],
  providers: [
    ExaminationService,
    DynamicSortService,
    RefValidatorService,
    RefValidatorSymbolService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // provider used to create fake backend
    fakeBackendProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
