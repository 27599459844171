
<div class="container-fluid">
  <h1 class="text-center">{{'pages.calculation.title'|translate}}</h1>
  <div class="row justify-content-center">

    <div class="col-10">

      <form [formGroup]="grunddataForm" (ngSubmit)="onSaveGrunddata()" autocomplete="off">
        <div class="card">
          <div class="card-body">
            <h2 class="text-left">Patient information</h2>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="txtÄlder">Ange ålder: </label>
                  <input type="text" class="form-control" id="txtÄlder" placeholder="Ålder" formControlName="alder" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="txtVikt">Ange vikt i kg: </label>
                  <input type="text" class="form-control" id="txtVikt" placeholder="Vikt" formControlName="vikt" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="txtLangd">Ange längd i cm: </label>
                  <input type="text" class="form-control" id="txtLangd" placeholder="Längd" formControlName="langd" />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="txtKon" class="col-form-label">Välj kön: </label>
                  <div class="col-sm-6">
                    <input type="radio" id="radioKvinna" value="1" formControlName="gender">
                    <label for="radioKvinna">Kvinna</label>
                    <br>
                    <input type="radio" id="radioMan" value="2" formControlName="gender">
                    <label for="radioMan">Man</label>
                    <br>
                  </div>
                </div>
              </div>
            </div>

            <!--<div class="row mt-3">
              <div class="col-sm-6 d-flex justify-content-end">
                <button type="submit" class="btn btn-primary mb-2" [disabled]="!grunddataForm.valid">Spara</button>
              </div>
            </div>-->


          </div>
        </div>
      <!--</form>


      <form [formGroup]="beraknaBsaForm" (ngSubmit)="onSubmitBsa()" autocomplete="off">-->
        <div class="card">
          <div class="card-body">
            <h2 class="text-left">BSA beräkning</h2>
            <div class="col-sm-6">
              <div class="form-group">
                <label for="selectBsaMetod"><b>BSA metod:</b></label>
                <select class="form-select" *ngIf="metoderForBsaList$ | async" id="selectBsaMetod" (ngModelChange)="getBsaMetoderData()" formControlName="metodNamn">
                  <option value="0">Välj bsa beräknings metod</option>
                  <option *ngFor="let bsaMet of metoderForBsaList$ | async" [ngValue]="bsaMet">{{bsaMet.metodNamn}}</option>
                </select>

                <ng-container *ngIf="selectedBsaMetod?.metodId > 0 else ingenBsaMetodVald">
                  <!--Metoden {{selectedBsaMetod.metodNamn}} är vald.-->
                  Formel: <i> {{selectedBsaMetod.formel}}</i>

                </ng-container>
                <ng-template #ingenBsaMetodVald>
                  Ingen metod vald
                </ng-template>

              </div>
            </div>


            <!--<div class="row mt-3">
    <div class="col-sm-6 d-flex justify-content-end">
      <button type="submit" class="btn btn-primary mb-2" [disabled]="!grunddataForm.valid">Beräkna BSA</button>
    </div>
  </div>-->
            <div class="row mt-3">
              <div class="col-sm-6 d-flex justify-content-end">
                <button type="button" class="btn btn-primary mb-2" (click)="onSaveGrunddata()">Beräkna BSA</button>
              </div>
            </div>
            <!--[disabled]="!grunddataForm.valid"-->
            <!--<div class="col-sm-6" *ngIf="!(result | async) && !(bsa | async) && formSubmitted">
    <ph-loading-spinner></ph-loading-spinner>
  </div>-->
          
            <div class="col-sm-6" *ngIf="!(bsa | async) && formSubmitted">
    <ph-loading-spinner></ph-loading-spinner>
  </div>
            <div class="col-sm-6" *ngIf="(bsa | async)">
              <h5>BSA: </h5>
              <b>{{bsa | async}} m²</b>
            </div>
          </div>
        </div>
      </form>

      <form [formGroup]="beraknaForm" (ngSubmit)="onSubmit()" autocomplete="off">

        <div class="card">
          <div class="card-body">
            <h2 class="text-left">Beräkna variabler</h2>
                       <div class="col-sm-6">
              <div class="form-group">
                <label for="selectVariable"><b>Beräkning:</b></label>
                <select class="form-select" *ngIf="variablerForBerakningList$ | async" id="selectVariable" (change)="getVariabelData($event.target.value)" formControlName="variabelNamn">
                  //formControlName="selectVariable"
                  <option value="0">Välj variabel</option>
                  <option *ngFor="let variabel of variablerForBerakningList$ | async" [value]="variabel.id">{{variabel.shortName}}</option>
                </select>

                <ng-container *ngIf="selectedVariableId > 0 else ingenVariabelVald">
                  Variabel med id {{selectedVariableId}} är vald
                </ng-container>
                <ng-template #ingenVariabelVald>
                  Ingen variabel vald
                </ng-template>
              </div>
            </div>
            <!--<div class="col-sm-6">
              <div class="form-group">
                <label for="selectMetod"><b>Metod:</b></label>
                <select class="form-select" *ngIf="metoderList$ | async" id="selectMetod" (ngModelChange)="getMetoderData()" formControlName="metodName">

                  <option value="0">Välj metod</option>
                  <option *ngFor="let met of metoderList$ | async" [ngValue]="met">{{met.metodNamn}}</option>
                </select>

                <ng-container *ngIf="selectedMetod?.metodId > 0 else ingenMetodVald">-->
                  <!--Metoden {{selectedMetod.metodNamn}} är vald.-->
                  <!--Formel: <i> {{selectedMetod.formel}}</i>

                </ng-container>
                <ng-template #ingenMetodVald>
                  Ingen metod vald
                </ng-template>

              </div>
            </div>-->


            <!--<div class="row">
    <div class="col-sm-6">


      <div class="form-group">

        <label for="txtÄlder">Ange ålder: </label>
        <input type="text" class="form-control" id="txtÄlder" placeholder="Ålder" formControlName="alder" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="txtVikt">Ange vikt i kg: </label>
        <input type="text" class="form-control" id="txtVikt" placeholder="Vikt" formControlName="vikt" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="txtLangd">Ange längd i cm: </label>
        <input type="text" class="form-control" id="txtLangd" placeholder="Längd" formControlName="langd" />
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-6">
      <div class="form-group">
        <label for="txtKon" class="col-form-label">Välj kön: </label>
        <div class="col-sm-6">
          <input type="radio" id="radioKvinna" value="1" formControlName="gender">
          <label for="radioKvinna">Kvinna</label>
          <br>
          <input type="radio" id="radioMan" value="2" formControlName="gender">
          <label for="radioMan">Man</label>
          <br>
        </div>
      </div>
    </div>
  </div>-->
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="txtVärde">Mätt värde: </label>
                  <input type="text" class="form-control" id="txtVärde" placeholder="Värde" formControlName="varde" />
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col-sm-6 d-flex justify-content-end">
                <button type="submit" class="btn btn-primary mb-2">Beräkna</button>
                <!--[disabled]="!beraknaForm.valid"-->
              </div>
            </div>

            <div class="col-sm-6" *ngIf="!(result | async) && !(bsa | async) && formSubmitted">
              <ph-loading-spinner></ph-loading-spinner>
            </div>
            <div class="col-sm-6" *ngIf="(bsa | async)">
              <h5>BSA: </h5>
              <b>{{bsa | async}} m²</b>
            </div>

            <div class="col-sm-6" *ngIf="(result | async)">
              <h5>Resultat: </h5>
              <b>{{result | async}}</b>
            </div>
          </div>
        </div>
      </form>


    </div>
  </div>
</div>

