<style>
  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .mat-expansion-panel-header {
    overflow: visible !important;
    z-index: 5000 !important;
  }
</style>


<div class="container-fluid">
  <div class="col-12 mt-2">
    <ph-alert-message [messageItem]="alertMessage|async" [isSelfClosing]="true"></ph-alert-message>
  </div>
  <div class="row mt-5">
    <div class="col-12">
      <h1>Patienter idag</h1>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-6">
      <!--<section>
        <h4>Visa undersökningar tilldelade till: </h4>
        <mat-button-toggle-group>
          <mat-button-toggle value="0">Visa alla</mat-button-toggle>
          <mat-button-toggle *ngFor="let personal of personalLista" value="{{personal.value}}" [ngbTooltip]="personal.text">{{personal.text}}</mat-button-toggle>
        </mat-button-toggle-group>
      </section>-->
      <!--<form>
        <div class="input-group col-8">
          <div class="input-group-append">
            <span class="input-group-text">Visa personal tilldelad till:</span>
          </div>
          <select [formControl]="personalToggle" class="form-control" (change)="personalSelected($event.target.value)">
            <option value="all">Alla</option>
            <option *ngFor="let personal of personalLista" [value]="personal.value"><span *ngIf="personal.text == 'Johan Karlsson'">Mig</span><span *ngIf="personal.text != 'Johan Karlsson'">{{personal.text}}</span></option>
          </select>
          </div>
      </form>-->
    </div>
    <div class="col-lg-6 d-flex justify-content-end">
      <button class="btn btn-link" [ngbPopover]="tableColumnsPopover" popoverTitle="Välj kolumner" autoClose="outside" placement="left"><i class="fas fa-cog"></i> Vad vill du se i tabellen?</button>
      <ng-template #tableColumnsPopover>
        <form>
          <mat-form-field appearance="outline">
            <mat-label>Välj kolumner</mat-label>
            <mat-select [formControl]="visibleTableColumnsFC" multiple>
              <mat-option *ngFor="let column of possibleTableColumns" [value]="column.value">{{column.text}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </ng-template>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-lg-12">
      <table class="table table-striped">
        <thead>
          <tr>
            <th *ngIf="columnIsVisible('pnr')" class="clickable" (click)="setOrderBy('pnr')">
              PNR
              <span class="th-caret" *ngIf="orderBy == 'pnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('namn')" class="clickable" (click)="setOrderBy('namn')">
              Namn
              <span class="th-caret" *ngIf="orderBy == 'namn'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('efternamn')" class="clickable" (click)="setOrderBy('efternamn')">
              Efternamn
              <span class="th-caret" *ngIf="orderBy == 'efternamn'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <!--<th *ngIf="columnIsVisible('planeradTid')" class="clickable" (click)="setOrderBy('planeradTid')">
              Undersökningstid
              <span class="th-caret" *ngIf="orderBy == 'planeradTid'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>-->
            <!--<th *ngIf="columnIsVisible('planeradLakare')" class="clickable" (click)="setOrderBy('planeradLakare')">
              Planerad läkare
              <span class="th-caret" *ngIf="orderBy == 'planeradLakare'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>
            <th *ngIf="columnIsVisible('planeradBma')" class="clickable" (click)="setOrderBy('planeradBma')">
              Planerad BMA
              <span class="th-caret" *ngIf="orderBy == 'planeradBma'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
            </th>-->
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="!(patientLista)">
            <th class="text-center" colspan="100">
              <ng-container>
                <ph-loading-spinner></ph-loading-spinner>
              </ng-container>
            </th>
          </tr>
          <ng-container *ngIf="patientLista as patientLista">
            <tr *ngFor="let patient of patientLista; index as i">
              <ng-container>
                <!--*ngIf="remiss.planeradDatum == toDay && (personalSelected(remiss.planeradLakare) || personalSelected(remiss.planeradBma) || personalToggle.value == 'all')"-->

                <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{patient.personalIdentityNumber}}</td>
                <td *ngIf="columnIsVisible('namn')" class="text-nowrap">{{patient.name}}</td>
                <td *ngIf="columnIsVisible('efternamn')" class="text-nowrap">{{patient.lastname}}</td>

                <!--<td *ngIf="columnIsVisible('planeradTid')">{{remiss.planeradTid}}</td>
                <td *ngIf="columnIsVisible('planeradLakare')">{{remiss.planeradLakare}}</td>-->
                <!--<td *ngIf="columnIsVisible('planeradBma')">{{remiss.planeradBma}}</td>-->
                <td>
                  <a href="#">
                    <i class="far fa-star"></i>
                  </a>
                </td>
                <td>
                  <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <!--<button mat-menu-item [matMenuTriggerFor]="remisser" *ngIf="patient.remittanceList.length > 1; else enRemiss">Remisser</button>
                      <mat-menu #remisser="matMenu">
                        <a mat-menu-item class="border-top" (click)="openAddRemissModal(patient.id)"><i class="fas fa-plus"></i> Ny remiss</a>
                        <ng-container *ngFor="let remiss of patient.remittanceList">
                          <button mat-menu-item [matMenuTriggerFor]="remissInfo" *ngIf="patient.remittanceList.length > 1">Remiss {{remiss.id}}</button>
                          <mat-menu #remissInfo="matMenu">
                            <a mat-menu-item class="border-top" (click)="openShowRemissInfoModal(patient, remiss.id)">Visa remiss (remiss {{remiss.id}})</a>
                            <a mat-menu-item [routerLink]="['/plan-examination', remiss.id, patient.id]">Planera undersökning (remiss {{remiss.id}})</a>
                          </mat-menu>
                        </ng-container>
                      </mat-menu>-->
                      <!--<a mat-menu-item [routerLink]="['/examination-in-progress', patient.id]">Starta undersökning</a>-->
                      <ng-template #enRemiss>
                        <a mat-menu-item class="border-top" (click)="openAddRemissModal(patient.id)"><i class="fas fa-plus"></i> Ny remiss</a>
                        <ng-container *ngFor="let remiss of patient.remittanceList">
                          <a mat-menu-item class="border-top" (click)="openShowRemissInfoModal(patient, remiss.id)">Visa remiss</a>
                        </ng-container>
                      </ng-template>
                      <!--<a mat-menu-item *ngIf="remiss.remisstyp == 'PDF'"><b>Komplettera remiss</b></a>-->
                      <!--<a mat-menu-item *ngIf="remiss.komplettera"><b>Begär komplettering</b></a>-->
                      <a mat-menu-item class="border-top" [routerLink]="['/examination-in-progress', 1, 1, patient.id]">Undersökning</a>
                      <a mat-menu-item>Visa historik</a>
                      <a mat-menu-item class="border-top" [routerLink]="['/overview-patient-page', patient.id]">Visa patient</a>
                      <a mat-menu-item>Visa journal</a>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
<show-remiss-modal [modalIsOpen]="showRemissInfoModalIsOpen"
                   [patientRemiss]="valdRemiss"
                   (remissEdit)="remissEditClicked($event)"
                   (openRemissModal)="openShowRemissInfoModal($event)"
                   (modalClosed)="showRemissModalClosed($event)"></show-remiss-modal>

<add-remiss-modal [patientRemiss]="valdRemiss"
                  (openAddRemissModal)="openAddRemissModal($event)"
                  [modalIsOpen]="addRemissModalIsOpen | async"
                  (modalClosed)="addRemissModalClosed()"
                  [newPatient]="isNewPatient"></add-remiss-modal>

