import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { VariablesService } from '@core/services/variabler.service';
import { ListviewSysEchoVariableDto } from '@model/undersokningsvarianter/listview-sys-echo-variable-dto';
import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { EchoVariablesDto } from '../../../../model/undersokningsvarianter/echo-variables-dto';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'delete-echovariable-modal',
  templateUrl: './delete-echovariable-modal.component.html',
  styleUrls: ['./delete-echovariable-modal.component.scss']
})
export class DeleteEchoVariableModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal,
    private dataService: VariablesService
  ) { }
  errorMessage: string = "";
  cVariable: EchoVariablesDto;
  cVariableId: number;
  vDescription: string = "";
  $subscriptions: Subscription[] = [];
  public loading = new BehaviorSubject<boolean>(false);
  submitted = false;
  //////////
  ///
  ngOnInit(): void {
    this.dataService.getVariable(this.cVariableId).subscribe(_ => {
      this.cVariable = _; this.cVariable.active = false;
    });
  }
  //////////
  ///
  public onCancel(): void {
    this.activeModal.dismiss();
  }
  //////////
  ///
  public onDelete(): void {
    this.dataService.saveVariable(this.cVariable).subscribe({
      next: () => {
        this.activeModal.close();
      },
      error: (errorResponse) => {
        this.errorMessage = errorResponse;
      }
    });
    this.activeModal.close();
  }
}
@Injectable({
  providedIn: "any"
})

export class DeleteVariableModalService {
  constructor(private modalService: NgbModal) { }

  public show(id: number, description: string): Observable<unknown> {
    const modalRef = this.modalService.open(DeleteEchoVariableModalComponent, { backdrop: "static", size: "lg" });
    modalRef.componentInstance.cVariableId = id;
    modalRef.componentInstance.vDescription = description;
    return modalRef.closed;
  }
}
