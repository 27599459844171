import { Component, Input, ChangeDetectionStrategy, OnInit, OnChanges, ViewChild } from '@angular/core';
import { faCheckCircle, faExclamationCircle, faExclamationTriangle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { AlertMessageItem, AlertMessageType } from '@model/alert-message-item';
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'ph-alert-message',
  templateUrl: './alert-message.component.html'
})

export class AlertMessageComponent implements OnInit, OnChanges {

  @Input() messageItem: AlertMessageItem;

  @Input() isSelfClosing: boolean = true;
  @Input() selfClosesAfterSeconds: number = 10;
  
  @ViewChild('selfClosingAlert') selfClosingAlert: NgbAlert;
  public selfClosingAlertClosed: boolean = false;

  public messageTypes = AlertMessageType;

  public faInfoIcon = faInfoCircle;
  public faSuccessIcon = faCheckCircle;
  public faWarningIcon = faExclamationTriangle;
  public faErrorIcon = faExclamationCircle;
  public messageTypesWithIcon = [AlertMessageType.Info, AlertMessageType.Success, AlertMessageType.Warning, AlertMessageType.Error];

  ngOnInit() {
    setTimeout(() => {
      //TODO: Fixa så att info-boxen stängs av sig självt.
     /* if (this.selfClosingAlert)*/
        /*new this.selfClosingAlert.close();*/
    }, this.selfClosesAfterSeconds*1000);
  }

  ngOnChanges() {
    if (this.messageItem)
      if (this.messageTypesWithIcon.indexOf(this.messageItem.type) > -1)
        this.messageItem.message = '&ensp;' + this.messageItem.message;
  }
}
