<style>
  .mat-tab-body-content {
    overflow: hidden !important;
  }

  .mat-expansion-panel-header {
    overflow: visible !important;
    z-index: 5000 !important;
  }
</style>

<div class="h-100">
  <div class="d-flex flex-nowrap h-100">
    <app-side-menu></app-side-menu>
    <div class="container-fluid">
      <div class="row bg-dark p-4 mb-3 text-light border-bottom border-secondary" style="height: 170px;" *ngIf="dataPatientWithId">
        <div class="col-lg-1 my-auto text-center">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x" style="color: #FFF;"></i>
            <i class="fas fa-user fa-stack-1x fa-inverse" style="color: #343a40;"></i>
          </span>
        </div>
        <div class="col-lg-3 my-auto">
          {{dataPatientWithId.fname}} {{dataPatientWithId.ename}}<br>
          {{dataPatientWithId.pnr}}
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-12">
          <h1>Hantera remisser</h1>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-6">
          <button class="btn btn-primary" (click)="openAddRemissModal()">Lägg till remiss</button>
        </div>
        <div class="col-lg-6 text-right">
          <button class="btn btn-link" [ngbPopover]="tableColumnsPopover" popoverTitle="Välj kolumner" autoClose="outside" placement="left"><i class="fas fa-cog"></i> Vad vill du se i tabellerna?</button>
          <ng-template #tableColumnsPopover>
            <form>
              <mat-form-field appearance="outline">
                <mat-label>Välj kolumner</mat-label>
                <mat-select [formControl]="visibleTableColumnsFC" multiple>
                  <mat-option *ngFor="let column of possibleTableColumns" [value]="column.value">{{column.text}}</mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </ng-template>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-lg-12">
          <h3>Ej planerade remisser</h3>
          <table class="table">
            <tr>
              <th *ngIf="columnIsVisible('remisstyp')" class="clickable" (click)="setOrderBy('remisstyp')">
                Källa
                <span class="th-caret" *ngIf="orderBy == 'remisstyp'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissdatum')" class="clickable" (click)="setOrderBy('remissdatum')">
                Remissdatum
                <span class="th-caret" *ngIf="orderBy == 'remissdatum'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissnr')" class="clickable" (click)="setOrderBy('remissnr')">
                Remissnr (klinfys)
                <span class="th-caret" *ngIf="orderBy == 'remissnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remitent')" class="clickable" (click)="setOrderBy('remitent')">
                Remiterande enhet
                <span class="th-caret" *ngIf="orderBy == 'remitent'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('mobilisering')" class="clickable" (click)="setOrderBy('mobilisering')">
                Särskilda behov
                <span class="th-caret" *ngIf="orderBy == 'mobilisering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('prioritering')" class="clickable text-nowrap" (click)="setOrderBy('prioritering')">
                Prioritering <span [ngbTooltip]="'Här står info om prioriteringen'"><i class="fas fa-info-circle"></i></span>
                <span class="th-caret" *ngIf="orderBy == 'prioritering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('status')" class="clickable" (click)="setOrderBy('status')">
                Status
                <span class="th-caret" *ngIf="orderBy == 'status'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr *ngFor="let remiss of sortedData; index as i">
              <ng-container *ngIf="!remiss.planerad">
                <td *ngIf="columnIsVisible('remisstyp')">{{remiss.remisstyp}}</td>
                <td *ngIf="columnIsVisible('remissdatum')">{{remiss.remissdatum}}</td>
                <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{remiss.pnr}} <span [ngbTooltip]="'Det finns flera remisser på patienten!'"><i *ngIf="remiss.hasMultipleRemisses" class="fas fa-exclamation-triangle"></i></span></td>
                <td *ngIf="columnIsVisible('remissnr')"><a [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">{{remiss.remissnr}}</a></td>
                <td *ngIf="columnIsVisible('remitent')">{{remiss.remitent}}</td>
                <td *ngIf="columnIsVisible('mobilisering')">{{remiss.mobilisering}}</td>
                <td *ngIf="columnIsVisible('prioritering')">{{remiss.prioritering}}</td>
                <td *ngIf="columnIsVisible('status')">
                  <span *ngIf="remiss.komplettera"><a href="#"><i class="fas fa-exclamation"></i> Begär komplettering</a></span>
                  <span *ngIf="remiss.aterremitterad"><i class="fas fa-exclamation"></i> Återremiterad</span>
                  <span *ngIf="remiss.remisstyp == 'PDF'"><a href="#"><i class="fas fa-exclamation"></i> Komplettera</a></span>
                  <span *ngIf="remiss.vantarKomplettering"><i class="fas fa-exclamation"></i> Väntar på komplettering</span>
                </td>
                <td>
                  <a href="#">
                    <i class="far fa-star"></i>
                  </a>
                </td>
                <td>
                  <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <a mat-menu-item *ngIf="remiss.remisstyp != 'PDF'" [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">Planera undersökning</a>
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, remiss.patientId]">Starta undersökning</a>
                      <a mat-menu-item class="border-top" (click)="openShowRemissModal(dataRemissWithId)">Visa remiss</a>
                      <a mat-menu-item *ngIf="remiss.remisstyp == 'PDF'"><b>Komplettera remiss</b></a>
                      <a mat-menu-item *ngIf="remiss.komplettera"><b>Begär komplettering</b></a>
                      <a mat-menu-item>Visa historik</a>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12">
          <h3>Planerade remisser</h3>
          <table class="table">
            <tr>
              <th *ngIf="columnIsVisible('remisstyp')" class="clickable" (click)="setOrderBy('remisstyp')">
                Källa
                <span class="th-caret" *ngIf="orderBy == 'remisstyp'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissdatum')" class="clickable" (click)="setOrderBy('remissdatum')">
                Remissdatum
                <span class="th-caret" *ngIf="orderBy == 'remissdatum'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissnr')" class="clickable" (click)="setOrderBy('remissnr')">
                Remissnr (klinfys)
                <span class="th-caret" *ngIf="orderBy == 'remissnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remitent')" class="clickable" (click)="setOrderBy('remitent')">
                Remiterande enhet
                <span class="th-caret" *ngIf="orderBy == 'remitent'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('mobilisering')" class="clickable" (click)="setOrderBy('mobilisering')">
                Mobilisering
                <span class="th-caret" *ngIf="orderBy == 'mobilisering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('prioritering')" class="clickable text-nowrap" (click)="setOrderBy('prioritering')">
                Prioritering <span [ngbTooltip]="'Här står info om prioriteringen'"><i class="fas fa-info-circle"></i></span>
                <span class="th-caret" *ngIf="orderBy == 'prioritering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('status')" class="clickable" (click)="setOrderBy('status')">
                Status
                <span class="th-caret" *ngIf="orderBy == 'status'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th></th>
              <th></th>
            </tr>
            <tr *ngFor="let remiss of sortedData; index as i">
              <ng-container *ngIf="remiss.planerad && !remiss.undersokt">
                <td *ngIf="columnIsVisible('remisstyp')">{{remiss.remisstyp}}</td>
                <td *ngIf="columnIsVisible('remissdatum')">{{remiss.remissdatum}}</td>
                <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{remiss.pnr}} <span [ngbTooltip]="'Det finns flera remisser på patienten!'"><i *ngIf="remiss.hasMultipleRemisses" class="fas fa-exclamation-triangle"></i></span></td>
                <td *ngIf="columnIsVisible('remissnr')"><a [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">{{remiss.remissnr}}</a></td>
                <td *ngIf="columnIsVisible('remitent')">{{remiss.remitent}}</td>
                <td *ngIf="columnIsVisible('mobilisering')">{{remiss.mobilisering}}</td>
                <td *ngIf="columnIsVisible('prioritering')">{{remiss.prioritering}}</td>
                <td *ngIf="columnIsVisible('status')">
                  <span *ngIf="remiss.komplettera"><a href="#"><i class="fas fa-exclamation"></i> Begär komplettering</a></span>
                  <span *ngIf="remiss.aterremitterad"><i class="fas fa-exclamation"></i> Återremiterad</span>
                  <span *ngIf="remiss.remisstyp == 'PDF'"><a href="#"><i class="fas fa-exclamation"></i> Komplettera</a></span>
                  <span *ngIf="remiss.vantarKomplettering"><i class="fas fa-exclamation"></i> Väntar på komplettering</span>
                </td>
                <td>
                  <a href="#">
                    <i class="far fa-star"></i>
                  </a>
                </td>
                <td>
                  <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <a mat-menu-item *ngIf="remiss.remisstyp != 'PDF'" [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">Redigera planerad undersökning</a>
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, remiss.patientId]">Starta undersökning</a>
                      <a mat-menu-item class="border-top" (click)="openShowRemissModal(dataRemissWithId)">Visa remiss</a>
                      <a mat-menu-item *ngIf="remiss.remisstyp == 'PDF'"><b>Komplettera remiss</b></a>
                      <a mat-menu-item *ngIf="remiss.komplettera"><b>Begär komplettering</b></a>
                      <a mat-menu-item>Visa historik</a>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-lg-12">
          <h3>Gamla remisser</h3>
          <table class="table">
            <tr>
              <th *ngIf="columnIsVisible('remisstyp')" class="clickable" (click)="setOrderBy('remisstyp')">
                Källa
                <span class="th-caret" *ngIf="orderBy == 'remisstyp'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissdatum')" class="clickable" (click)="setOrderBy('remissdatum')">
                Remissdatum
                <span class="th-caret" *ngIf="orderBy == 'remissdatum'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remissnr')" class="clickable" (click)="setOrderBy('remissnr')">
                Remissnr (klinfys)
                <span class="th-caret" *ngIf="orderBy == 'remissnr'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('remitent')" class="clickable" (click)="setOrderBy('remitent')">
                Remiterande enhet
                <span class="th-caret" *ngIf="orderBy == 'remitent'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('mobilisering')" class="clickable" (click)="setOrderBy('mobilisering')">
                Mobilisering
                <span class="th-caret" *ngIf="orderBy == 'mobilisering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('prioritering')" class="clickable text-nowrap" (click)="setOrderBy('prioritering')">
                Prioritering <span [ngbTooltip]="'Här står info om prioriteringen'"><i class="fas fa-info-circle"></i></span>
                <span class="th-caret" *ngIf="orderBy == 'prioritering'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th *ngIf="columnIsVisible('status')" class="clickable" (click)="setOrderBy('status')">
                Status
                <span class="th-caret" *ngIf="orderBy == 'status'"><fa-icon [icon]="sortAscending ? faCaretDown : faCaretUp"></fa-icon></span>
              </th>
              <th></th>
            </tr>
            <tr *ngFor="let remiss of sortedData; index as i">
              <ng-container *ngIf="remiss.undersokt">
                <td *ngIf="columnIsVisible('remisstyp')">{{remiss.remisstyp}}</td>
                <td *ngIf="columnIsVisible('remissdatum')">{{remiss.remissdatum}}</td>
                <td *ngIf="columnIsVisible('pnr')" class="text-nowrap">{{remiss.pnr}} <span [ngbTooltip]="'Det finns flera remisser på patienten!'"><i *ngIf="remiss.hasMultipleRemisses" class="fas fa-exclamation-triangle"></i></span></td>
                <td *ngIf="columnIsVisible('remissnr')"><a [routerLink]="['/plan-examination', remiss.remissId, remiss.patientId]">{{remiss.remissnr}}</a></td>
                <td *ngIf="columnIsVisible('remitent')">{{remiss.remitent}}</td>
                <td *ngIf="columnIsVisible('mobilisering')">{{remiss.mobilisering}}</td>
                <td *ngIf="columnIsVisible('prioritering')">{{remiss.prioritering}}</td>
                <td *ngIf="columnIsVisible('status')">
                </td>
                <td>
                  <a href="#">
                    <i class="far fa-star"></i>
                  </a>
                </td>
                <td>
                  <div class="d-flex justify-content-end my-2 my-sm-0 mr-2">
                    <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm btn-primary text-nowrap">Åtgärd <i class="fas fa-chevron-down size-2x"></i></button>
                    <mat-menu #menu="matMenu">
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, remiss.patientId]">Visa undersökning</a>
                      <a mat-menu-item [routerLink]="['/examination-in-progress', remiss.remissId, remiss.undersokningstypId, remiss.patientId]">Visa svar</a>
                      <a mat-menu-item class="border-top" (click)="openShowRemissModal(dataRemissWithId)">Visa remiss</a>
                      <a mat-menu-item>Visa historik</a>
                    </mat-menu>
                  </div>
                </td>
              </ng-container>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
  </div>
    <add-remiss-modal [modalIsOpen]="addRemissModalIsOpen | async"
                      (modalClosed)="addRemissModalClosed()"></add-remiss-modal>
    <show-remiss-modal [modalIsOpen]="showRemissModalIsOpen | async"
                       [patientRemiss]="dataRemissWithId"
                       (modalClosed)="showRemissModalClosed($event)"></show-remiss-modal>


