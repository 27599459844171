import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_helpers/auth.guards';
import { OverviewPageComponent } from './modules/examinations/echo/overview-page/overview-page.component';
import { LoginComponent } from './pages/login/login.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'settings', loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule), canActivate: [AuthGuard] },
  { path: '', redirectTo: '/examinationslist', pathMatch: 'full' },
  { path: 'patient', loadChildren: () => import('./modules/patient/patient.module').then(m => m.PatientModule), canActivate: [AuthGuard] },
  { path: 'examination', loadChildren: () => import('./modules/examinations/examinations.module').then(m => m.ExaminationsModule), canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
