import { Component, AfterViewChecked, Input, ChangeDetectionStrategy, OnChanges, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';
import { SelectListItem } from '@model/select-list-item';

@Component({
    changeDetection: ChangeDetectionStrategy.OnPush,
    selector: 'input-carousel',
    templateUrl: './input-carousel.component.html',
    styleUrls: ['./input-carousel.component.scss']
})
export class InputCarouselComponent implements OnChanges, AfterViewChecked {

    constructor(
        public translate: TranslateService
    ) {}

    @Input() formGroup: FormGroup;
    @Output() onSubmit = new EventEmitter<FormGroup>();
    
    @Input() itemGroups: DataItemGroup[];
    @Input() activeItemGroup: DataItemGroup;
    @Output() activeItemGroupChange = new EventEmitter<DataItemGroup>();
    @Input() activeDataItem: DataItem;
    @Output() activeDataItemChange = new EventEmitter<DataItem>();

    @ViewChild('submitBtn') submitBtn: ElementRef;
    
    public submitBtnIsActive: boolean;

    public activeDataItemIndex: number;
    public activeItemGroupIsTheLast: boolean;
    public activeItemIsTheLastInGroup: boolean;
    public nextItemGroup: DataItemGroup;

    ngOnChanges() {
        this.setActiveDataItemIndex();
    }

    ngAfterViewChecked() {
        this.focusActiveDataItemInput();
    }

    public setActiveDataItem(item: DataItem, activatedByUserClick: boolean = false, toPrevious = false) {
        if (item) {
            this.activeDataItem = item;
            if (!activatedByUserClick && this.activeDataItem.visible() == false) {
                let nextIndex = toPrevious ? this.activeDataItemIndex-2 : this.activeDataItemIndex+2;
                this.activeDataItem = this.activeItemGroup.items[nextIndex];
            }
            this.activeDataItemChange.emit(this.activeDataItem);
            this.setActiveDataItemIndex();
            this.focusActiveDataItemInput();
        }
    }

    private setActiveDataItemIndex() {
        if (this.activeItemGroup) {
            if (this.activeDataItem)
                this.activeDataItemIndex = this.activeItemGroup.items.indexOf(this.activeDataItem);

            this.activeItemGroupIsTheLast = this.itemGroups[this.itemGroups.length-1].group == this.activeItemGroup.group;
            this.activeItemIsTheLastInGroup = this.activeDataItemIndex == this.activeItemGroup.items.length-1;
            this.nextItemGroup = (!this.activeItemGroupIsTheLast && this.activeItemIsTheLastInGroup) ? this.itemGroups[this.itemGroups.indexOf(this.activeItemGroup)+1] : null;
            this.submitBtnIsActive = false;
        }
    }

    public goToNextGroup(goToPrevious: boolean = false) {
        let currentGroupIndex = this.itemGroups.indexOf(this.activeItemGroup);
        if (!goToPrevious && currentGroupIndex < this.itemGroups.length-1) {
            this.activeItemGroup =  this.itemGroups[currentGroupIndex+1]; //If not last group -> go to next group
            this.setActiveDataItem(this.activeItemGroup.items[0]);
        } else if (currentGroupIndex > 0) {
            this.activeItemGroup = this.itemGroups[currentGroupIndex-1]; //If not first group -> go to previous group
            this.setActiveDataItem(this.activeItemGroup.items[this.activeItemGroup.items.length-1]);
        }
        this.activeItemGroupChange.emit(this.activeItemGroup);
    }

    private focusActiveDataItemInput() {
        if (this.activeDataItem) {
            let activeCarouselItem = document.getElementById(this.activeDataItem.variable);
            if (activeCarouselItem) {
                let inputElementTag = this.activeDataItem.format == '[]' ? 'select' : 'input';
                let foundInputs: any = activeCarouselItem.getElementsByTagName(inputElementTag);
                if (foundInputs.length > 0)
                    foundInputs[0].focus();
            }
        }
    }
    
    private focusSubmitBtn() {
        this.activeDataItem = null;
        this.submitBtnIsActive = true;
        this.submitBtn.nativeElement.focus();
    }

    public onKeyDown(e) {
        let toPrevious = false;
        let isLastItemInGroup = false;
        let isFirstItemInGroup = false;
        let nextItemIndex = this.activeDataItemIndex;
        switch(e.key) {
            case "Enter":
            case "ArrowDown":
                //Go to next item
                if (this.activeDataItemIndex < this.activeItemGroup.items.length-1) 
                    nextItemIndex = nextItemIndex+1;
                else
                    isLastItemInGroup = true;
                break;
            case "ArrowUp":
                //Go to previous item
                toPrevious = true;
                if (this.submitBtnIsActive)
                    nextItemIndex = this.activeItemGroup.items.length-1;
                else if (this.activeDataItemIndex > 0)
                    nextItemIndex = nextItemIndex-1;
                else
                    isFirstItemInGroup = true;
                break;
            default:
                if (this.activeDataItem.format == '[]')
                    this.setSelectItemToKeyPress(e);
                return;
        }
        if (isLastItemInGroup) {
            if (this.activeItemGroupIsTheLast)
                this.focusSubmitBtn();
            else
                this.goToNextGroup();
        }
        else if (isFirstItemInGroup)
            this.goToNextGroup(toPrevious);
        else {
            let nextItem = this.activeItemGroup.items[nextItemIndex];
            this.setActiveDataItem(nextItem, false, toPrevious);
        }
    }

    // Förhindra default för ArrowDown & ArrowUp för <select>
    public selectListKeyPress(e) {
        if (e.key == "ArrowDown" || e.key == "ArrowUp")
            e.preventDefault();
    }

    // Sätt aktiv select till option med value som matchar keypress
    private setSelectItemToKeyPress(e) {
        let key = Number(e.key)
        if (isNaN(key) || e.key === null || e.key === ' ')
            return;
        e.preventDefault();
        this.activeDataItem.options.forEach((option: SelectListItem) => {
            if (option.value == key.toString()) {
                let control = this.formGroup.get(this.activeDataItem.variable);
                control.setValue(option.value);
            }
        });
    }

    public getErrorText(dataItem): any {
        return {value: this.formGroup.get(dataItem.variable).getError('errorText')};
    }

}
