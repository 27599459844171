<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title">{{settings.title}}</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>

  <div class="modal-body">
    <button type="button" class="btn btn-sm btn-info float-end mb-2" (click)="toggleAllGroups()">
      <fa-icon class="me-2" [icon]="allGroupsIsOpen ? faAngleUp : faAngleDown"></fa-icon>{{allGroupsIsOpen ? "Stäng" : "Öppna"}} alla rader
    </button>
    <table class="table table-sm table-bordered">
      <thead>
        <tr>
          <th style="width:35px"></th>
          <th class="text-capitalize">{{settings.headerText}}</th>
          <th style="width:85px">Enhet</th>
          <th style="width:60px">Mätt</th>
          <th style="width:65px">Indexerad</th>
          <th style="width:45px">Ref</th>
          <!-- <th style="width:30px"></th>
          <th style="width:30px"></th> -->
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let group of groups; let groupIndex = index;">
          <ng-container *ngFor="let row of group.rows; let groupRowIndex = index;">
              
            <tr>
              <td *ngIf="groupRowIndex == 0"
                  class="text-center fw-bold clickable" 
                  [class.text-vertical]="openGroups[groupIndex].getNrRows() > 1"
                  [rowSpan]="openGroups[groupIndex].getNrRows()"
                  (click)="toggleGroup(groupIndex)">
                {{group.name | titlecase}}
              </td>

              <td class="clickable" (click)="toggleRow(groupIndex, groupRowIndex)">
                {{row.rowName | titlecase}}
                <fa-icon class="float-start mx-2" [icon]="openGroups[groupIndex].isRowOpen(groupRowIndex) ? faAngleUp : faAngleDown"></fa-icon>
              </td>
              <td>{{row.variablesFirst && row.variablesSecond ? 'xx/xx' : 'xx'}}</td>
              <td></td>
              <td>{{row.variablesFirst && row.variablesSecond ? 'xx/xx' : 'xx'}}</td>
              <td></td>
            </tr>

            <ng-container *ngIf="openGroups[groupIndex].isRowOpen(groupRowIndex)">
              <tr>
                <td class="bg-lighter"><small>Värde 1:</small> {{row.variablesFirst}}</td>
                <td colspan="4"></td>
              </tr>
              <tr *ngIf="row.variablesSecond">
                <td class="bg-lighter"><small>Värde 2:</small> {{row.variablesSecond}}</td>
                <td colspan="4"></td>
              </tr>
              <tr *ngFor="let varThree of row.variablesThird">
                <td class="bg-lighter"><small>Associerat:</small> {{varThree}}</td>
                <td colspan="4"></td>
              </tr>
            </ng-container>

          </ng-container>
        </ng-container>
      </tbody>
    </table>
  </div>

</ng-template>

