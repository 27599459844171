import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SelectListItem } from '@model/select-list-item';
import { TableSettingRow } from '@model/table-setting'
import { DataItemGroup } from '@model/data-item-group';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop'
import { ApiService } from '@core/services/api.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'table-add-group-subrows-modal',
  templateUrl: 'table-add-group-subrows-modal.component.html',
  styleUrls: ['table-add-group-subrows-modal.component.scss']
})
export class TableAddGroupSubrowsModalComponent implements OnChanges {

  constructor(
    //private apiService: ApiService,
    public translate: TranslateService,
    private fb: FormBuilder,
    private modalService: NgbModal
  ) { }
 
  @Input() modalIsOpen: boolean;
  @Input() row: TableSettingRow;

  @Output() modalClosed = new EventEmitter();
  @Output() onSubmit = new EventEmitter<TableSettingRow>();
  @Output() onDelete = new EventEmitter();
  
  @ViewChild("content") modalHtml;
  
  public form: FormGroup;
  public variableOptions: SelectListItem[] = [];
  public variableOptionTexts: string[] = [];

  ngOnChanges(): void {
    if (this.modalIsOpen == true) {
      this.openModal();
      this.setForm();
    }
  }

  private openModal() {
    this.modalService.open(this.modalHtml, { ariaLabelledBy: 'table-add-group-subrows-modal', size: 'lg', backdrop: 'static', keyboard: false })
  }
  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }
  public deleteRow() {
    this.modalService.dismissAll();
    this.onDelete.emit();
  }

  public submitForm() {
    if (this.form.valid) {
      this.modalService.dismissAll();
      const result = new TableSettingRow();
      result.id = this.row.id;
      result.rowName = this.rowName.value;
      result.variablesFirst = this.variablesFirst.value;
      result.variablesSecond = this.variablesSecond.value;
      result.variablesThird = this.variablesThird.value;
      this.onSubmit.emit(result);
    }
    else this.form.markAllAsTouched();
  }

  private setForm() {
    this.form = this.fb.group({
      rowName: new FormControl(this.row.rowName, Validators.required),
      variablesFirst: new FormControl(this.row.variablesFirst, Validators.required),
      variablesSecond: new FormControl(this.row.variablesSecond),
      variablesThird: this.fb.array(this.row.variablesThird.length ? this.row.variablesThird : []),
    });

    if (!this.row.rowName) this.addThird(); //Om det är en ny rad - Lägg till ett associerat som förval
    this.variableValuesSubscribe();

    //Läs in variabler
    //this.apiService.getEcho().subscribe((data: DataItemGroup[]) => {
    //  this.variableOptions = data.map(itemGroup => itemGroup.items.map(item => { return new SelectListItem(item.caption, item.variable) })).flat();
    //  this.variableOptionTexts = this.variableOptions.map(op => op.text).filter(t => t != '');
    //});
  }

  get rowName() {
    return this.form.get('rowName') as FormControl;
  }
  get variablesFirst() {
    return this.form.get('variablesFirst') as FormControl;
  }
  get variablesSecond() {
    return this.form.get('variablesSecond') as FormControl;
  }
  get variablesThird() {
    return this.form.get('variablesThird') as FormArray;
  }
  
  private variableValuesSubscribe() {
    this.variablesFirst.valueChanges.subscribe(value => {
      if (value == "") value = null;
    });
    this.variablesSecond.valueChanges.subscribe(value => {
      if (value == "") value = null;
    });
    this.variablesThird.valueChanges.subscribe((value: FormArray) => {
      if (value && value.controls) {
        value.controls.forEach(ctl => {
          if (ctl.value == "") ctl.setValue(null);
        })
      }
    });
  }

  /** Hantera Värde 3: Associerade värden **/
  addThird() {
    this.variablesThird.push(new FormControl('', Validators.required))
  }

  dragDropVariable(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.variablesThird.controls, event.previousIndex, event.currentIndex);
    this.variablesThird.updateValueAndValidity();
  }

  deleteThird(index) {
    this.variablesThird.removeAt(index)
  }

}
