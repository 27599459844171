<style>
  .last {
    display: none;
  }

  .example-additional-selection {
    opacity: 0.75;
    font-size: 0.75em;
  }
</style>
<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title">Nytt läkemedel</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <form [formGroup]="saveAddLakemedelForm" (ngSubmit)="submitForm()">
    <div class="modal-body">
      <fieldset class="border p-1 m-1">
        <h4 class="p-1 offset-md-3"><span class="badge badge-secondary">Läkemedel</span></h4>
        <div class="form-group row">
          <div class="col-10 offset-3">
            <div class="form-check">
              <input class="form-check-input" type="radio" id="lakemedelAnteckning" value="1" formControlName="lakemedelStatus">
              <label class="form-check-label" for="lakemedelAnteckning">
                Läkemedelsanteckning
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="lakemedelForeslaget" value="2" formControlName="lakemedelStatus">
              <label class="form-check-label" for="lakemedelForeslaget">
                Förslag på läkemedel
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="lakemedelOrdinerat" value="3" formControlName="lakemedelStatus">
              <label class="form-check-label" for="lakemedelOrdinerat">
                Läkemedel ordinerat
              </label>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="radio" id="lakemedelGivet" value="4" formControlName="lakemedelStatus">
              <label class="form-check-label" for="lakemedelGivet">
                Läkemedel givet
              </label>
            </div>
          </div>
        </div>
        <ng-container *ngIf="saveAddLakemedelForm.value.lakemedelStatus != '1'">
          <div class="form-group row">
            <div class="col-3 text-right">
              <label class="col-form-label" for="lakemedel">
                Läkemedel:
              </label>
            </div>
            <div class="col-7">
              <input type="text"
                     placeholder="Sök läkemedel"
                     class="form-control"
                     formControlName="lakemedel"
                     [matAutocomplete]="lakemedelAC">
              <mat-autocomplete #lakemedelAC="matAutocomplete">
                <mat-option *ngFor="let option of filteredLakemedel | async" [value]="option">
                  {{option}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-3 text-right">
              <label class="col-form-label" for="styrka">
                Styrka:
              </label>
            </div>
            <div class="col-7">
              <input type="text"
                     placeholder="Sök styrka"
                     class="form-control"
                     formControlName="lakemedelStyrka"
                     [matAutocomplete]="styrkaAC">
              <mat-autocomplete #styrkaAC="matAutocomplete">
                <mat-option *ngFor="let optionStyrka of filteredStyrka | async" [value]="optionStyrka">
                  {{optionStyrka}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-3 text-right">
              <label for="lakemedelMangd" class="col-form-label">Mängd: </label>
            </div>
            <div class="col-3">
              <input type="email" class="form-control" formControlName="lakemedelMangd" placeholder="Ange mängd">
            </div>
            <div class="col-1 text-right">
              <label for="lakemedelEnhet" class="col-form-label">Enhet: </label>
            </div>
            <div class="col-3">
              <select class="form-select form-control" formControlName="lakemedelEnhet">
                <option [selected]="true">Välj enhet</option>
                <option *ngFor="let enhet of lakemedelEnhetLista" [value]="enhet.value">{{enhet.text}}</option>
              </select>
            </div>
          </div>
        </ng-container>
        <div class="form-group row">
          <div class="col-3 text-right">
            <label for="lakemedelKommentar" class="col-form-label">Anteckning: </label>
          </div>
          <div class="col-lg-7">
            <textarea class="form-control" id="lakemedelKommentar" rows="3" formControlName="lakemedelKommentar"></textarea>
          </div>
        </div>
        <!--<div class="form-group row">
          <div class="col-3 text-right">
            <label for="lakemedelOrdination" class="col-form-label">Ordination: </label>
          </div>
          <div class="col-lg-7">
            <input class="form-control" id="lakemedelOrdination" rows="3" formControlName="lakemedelOrdination">
          </div>
        </div>-->

      </fieldset>
    </div>

    <div class="modal-footer">

      <button type="button" class="btn btn-info cancel" (click)="closeModal()">{{'common.close' | translate}}</button>
      <button type="submit" class="btn btn-lg btn-success" (ngSubmit)="submitForm()">{{'common.save' | translate}}</button>
    </div>
  </form>
</ng-template>
