import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from "@angular/material/form-field";
//import { DragDropModule } from '@angular/cdk/drag-drop';
import { ExaminationtypesComponent } from '../../modules/settings/pages/examinationstypes/examinationstypes.component';
import { ManageExaminationVariantsComponent } from './pages/manage-examination-variants/manage-examination-variants.component';
import { EditEchoVariablesComponent } from '../../modules/settings/pages/editechovariables/editechovariables.component';
import { ManageTableComponent } from '../../modules/settings/pages/manage-table/manage-table.component';
import { CalculationComponent } from '../../modules/settings/pages/calculation/calculation.component';
import { AddExaminationTypeModalComponent } from '../../modules/settings/components/add-examinationtype-modal/add-examinationtype-modal.component';
import { UndersokningService } from '@core/services/undersokning.service';
import { UndersokningsVariantService } from '@core/services/undersokningsvariant.service';
import { SettingsRoutingModule } from './settings.routing';
import { VariablesService } from '@core/services/variabler.service';
import { AddEchoVariableModalComponent } from './components/add-echovariable-modal/add-echovariable-modal.component';
import { SharedModule } from '../../shared/shared.module';
import { AppRoutingModule } from '../../app-routing.module';
import { DeleteEchoVariableModalComponent } from './components/delete-echovariable-modal/delete-echovariable-modal.component';
import { FormulaEchoVariableModalComponent } from './components/formula-echovariable-modal/formula-echovariable-modal.component';
import { CalculationService } from '../../core/services/calculation.service';
import { GraderingFormelEchoVariableComponent } from '../../shared/components/gradering-formel-echovariable/gradering-formel-echovariable.component';
import { TableAddGrouprowComponent } from './components/table-add-grouprow-modal/table-add-grouprow-modal.component';
import { TableAddGroupSubrowsModalComponent } from './components/table-add-group-subrows-modal/table-add-group-subrows-modal.component';
import { TablePreviewModalComponent } from './components/table-preview-modal/table-preview-modal.component';
import { AddTestUndesokningsvardenModalComponent } from '../examinations/components/add-test-undesokningsvarden-modal/add-test-undesokningsvarden-modal.component';
import { DragDropModule } from '@angular/cdk/drag-drop';

@NgModule({
  declarations: [
    GraderingFormelEchoVariableComponent,
    ExaminationtypesComponent,
    FormulaEchoVariableModalComponent,
    ManageExaminationVariantsComponent,
    ManageTableComponent,
    EditEchoVariablesComponent,
    AddEchoVariableModalComponent,
    AddExaminationTypeModalComponent,
    DeleteEchoVariableModalComponent,
    TableAddGrouprowComponent,
    TableAddGroupSubrowsModalComponent,
    TablePreviewModalComponent,
    CalculationComponent,
    AddTestUndesokningsvardenModalComponent
  ],
  imports: [
    SettingsRoutingModule,
    AppRoutingModule,
    SharedModule,
    DragDropModule
    //CdkAccordionModule,
    //DragDropModule,
    // MatFormFieldModule,
    // MatInputModule,
  ],
  exports: [
  ],
  providers: [
    UndersokningsVariantService,
    UndersokningService,
    VariablesService,
    CalculationService
  ]
})
export class SettingsModule { }

