import { NgModule } from '@angular/core';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { Routes, RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { UndersokningsVariantService } from './services/undersokningsvariant.service';
import { UndersokningService } from './services/undersokning.service';
import { VariablesService } from './services/variabler.service';
//import { MatMenuModule } from '@angular/material/menu';
import { LoginComponent } from '../pages/login/login.component';
import { HomeComponent } from '../pages/home/home.component';
import { CalculationService } from './services/calculation.service';
import { AutofocusDirective } from './directives/autofocus.directive';


@NgModule({
  imports: [
    RouterModule, NgbModule, SharedModule

  ],
  exports: [
    NavMenuComponent, LoginComponent,
    HomeComponent,
  ],
  declarations: [
    NavMenuComponent, LoginComponent,
    HomeComponent

  ],
  providers: [
    UndersokningsVariantService,
    UndersokningService,
    VariablesService,
    CalculationService
  ]
})

export class CoreModule { }
