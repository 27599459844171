import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { DataItemGroup } from '@model/data-item-group';

@Injectable({
  providedIn: 'root'
})
export class ExaminationService {

  constructor(private http: HttpClient) { }

  public getEcho(): Observable<DataItemGroup[]> {
    /*return this.http.get<DataItemGroup[]>('/assets/echo.json');*/
    return this.http.get<DataItemGroup[]>('/assets/examination-in-progress.json');
  }

}
