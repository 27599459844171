<h4><span *ngIf="plannedPatientExamination">{{plannedPatientExamination?.undersokningsvariant}}</span></h4>
<div class="card mt-1">
  <!--<div class="card-header">
    <div class="row">
      <div class="col-md-8">
        <section>
          <h4>Visa: </h4>
          <mat-button-toggle-group [(ngModel)]="isCheckedShowAllVariables" value="1">
            <mat-button-toggle value="1" (click)="showallGroups('false')" class="no-lineheight">Planerade</mat-button-toggle>
            <mat-button-toggle value="2" (click)="showallGroups('true')">Visa alla</mat-button-toggle>
          </mat-button-toggle-group>
        </section>
      </div>

      </div>
    </div>-->
  <ng-container *ngIf="plannedPatientExamination">
  <div class="card-header bg-white">
    <h6>Visuella observationer</h6>
    <div class="row">
      <ng-container *ngFor="let group of plannedPatientExamination?.itemgroups">
        <ng-container *ngIf="(group && hasSelectedItemsOrValue(group) && !allVariablesInGroupFinished)">
          <ng-container *ngFor="let item of group.items">
            <ng-container *ngIf="item.isMattVarde==false">
              <div class="form-group col-md-3 col-sm-12">
                <label class="form-label">{{item.caption}}: </label>
                <select class="form-select form-control w-100" (change)="changeSelectBoxes(item, $event)">
                  <option>Gör bedömning</option>
                  <ng-container *ngFor="let option of item.selectOptions">
                    <option [value]="option.value">{{option.text}}</option>
                  </ng-container>
                </select>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="card-body" *ngIf="viewType == '1'">
    <div class="row">
      <div class="col-sm-6">
        <h6>Mätvärden</h6>
      </div>
      <div class="col-sm-6 d-flex justify-content-end">
        <button class="btn btn-info btn-lg" (click)="showValuesClick()" [disabled]="countClickImportButton>1">Importera värden</button>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center" *ngIf="!(plannedPatientExamination?.itemgroups)">  
        <ph-loading-spinner></ph-loading-spinner>
      </div>
      <ng-container *ngFor="let group of plannedPatientExamination?.itemgroups">
        <div class="col-lg-3 col-md-4 col-sm-6 pt-2" *ngIf="(group && hasSelectedItemsOrValue(group))">
          <table class="table table-sm">
            <thead>
              <tr>
                <th>
                  {{group.group}}
                </th>
                <th class="clickable" (click)="hideShowGroup(group)">
                  <p class="d-flex justify-content-end"><fa-icon class="mx-2" [icon]="group.isClosed ? faAngleDown : faAngleUp"></fa-icon></p>
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let item of group.items">
              <tr *ngIf="(((isCheckedShowAllVariables == '1' && item.selected) || item.oplaneratExtravarde) || isCheckedShowAllVariables == '2') && item.isMattVarde && !group.isClosed" [ngClass]="item.styleClass">
                <td class="col-md-4">
                  <a href="#" [ngbTooltip]="'Visa metodbeskrivning'"><i class="fas fa-info-circle"></i></a> <ng-container *ngIf="item.caption!=''"><span [ngbTooltip]="item.caption" [ngClass]="[isCheckedShowAllVariables == '2' && item.selected ? 'font-weight-bold': '']"> {{item.shortCaption}}</span></ng-container><ng-container *ngIf="item.caption==''"> {{item.variable}}</ng-container>
                </td>
                <td class="text-right col-md-8">
                  <ng-container *ngFor="let itemValue of item?.machineValues; let i = index;">
                    <div class="row align-items-right" *ngIf="showMachineValue(i, item, item.machineValues[i].value)">
                      <div class="col-md-4">
                        <b>{{item.inputValue}}</b>
                        <span [ngbTooltip]="getAlertSymbolForItem(itemValue.value, item.ref, item.gradering, item)?.text"><i *ngIf="item.inputValue=='' || item.inputValue==null" [ngClass]="getAlertSymbolForItem(item.value, item.ref, item.gradering, item)?.styleClass"></i></span>
                      </div>
                      <div class="col-md-4 text-left">{{item.unit}}</div>
                      <div class="col-md-4">
                        <i *ngIf="item.inputValue!='' && item.inputValue!=null" [ngClass]="getAlertSymbolForItem(item.inputValue, item.ref, item.gradering, item)?.styleClass" [ngbTooltip]="getAlertSymbolForItem(item.inputValue, item.ref, item.gradering, item)?.text"></i>
                        <button *ngIf="item.history" [ngbTooltip]="'Visa historik'" popoverClass="popoverWidth" (click)="showDiagram(item)" class="btn btn-link btn-sm pr-1" [ngbPopover]="popHistoryContent" [autoClose]="'outside'" popoverTitle="Historik" placement="right auto"><i class="fas fa-chart-bar" *ngIf="item.history"></i></button>
                      </div>
                    </div>
                  </ng-container>
                  <ng-template #popHistoryContent>
                    <div class="p-4 display-block">
                      <h5 class="pr-4 text-nowrap">Tidigare undersökningar</h5>
                      <diagram [dataItemExamination]="item"
                               [series]="[{name: item.shortCaption, data: variableSerieData}]"
                               [yAxisMin]="item.minValue"
                               [yAxisMax]="item.maxValue"
                               [plotLines1]="item.ref?.lowerExtreme"
                               [plotLines2]="item.ref?.lowerNormal"
                               [plotLines3]="item.ref?.upperNormal"
                               [plotLines4]="item.ref?.upperExtreme"></diagram>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-container>
    </div>
    <div class="form-group" *ngIf="dataFromDevice$">
      <label class="form-label">Övriga fynd: </label>
      <textarea class="form-control" [(ngModel)]="dataFromDevice$.ovrigtUndersokningText"></textarea>
    </div>
    <div class="form-group" *ngIf="dataFromDevice$">
      <label class="form-label">Kommentarer undersökning (t ex svårundersökt): </label>
      <textarea class="form-control" rows="3"></textarea>
    </div>
    <div class="row mb-2">
      <div class="col">
        <div class="card">
          <div class="card body">
            <div class="row align-items-center">
              <div class="col-11">
                <div class="row">
                  <div *ngFor="let image of drawedImages" class="col-lg-1">
                    <div class="zoomable-image p-1" (click)="openPreviewImageModal(image)">
                      <img [src]="'assets/img/' + image + '.png'" class="img-fluid" />
                      <fa-icon [icon]="faSearchPlus" class="zoom-icon" size="2x"></fa-icon>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1">
                <a class="link" (click)="openAddImageModal()" [ngbTooltip]="'pages.overview.images.add' | translate"><fa-icon [icon]="faPlusCircle" class="fa-3x"></fa-icon>&ensp;</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </ng-container>
</div>
<div class="row mt-5">
  <div class="col-12 d-flex justify-content-end"><button class="btn btn-primary btn-lg" mat-button matStepperNext>Spara och granska</button></div>
</div>
<preview-image-modal [imageName]="imageToPreview | async"
                     [modalIsOpen]="previewImageModalIsOpen | async"
                     (modalClosed)="previewImageModalClosed($event)"
                     (removeImageClick)="removePreviewedImage()"></preview-image-modal>

<add-image-modal [modalIsOpen]="addImageModalIsOpen | async"
                 (modalClosed)="addImageModalClosed($event)"></add-image-modal>
