import { AbstractControl, ValidatorFn } from '@angular/forms';
import { RangeError } from '@model/range-error';

export class RefValidatorSymbolService {
  validator(ref): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (ref == undefined || c.value == '')
        return null;

      if (c.value < ref.lowerExtreme)
        return { 'iconClass': 'fa-exclamation-triangle' };
      if (c.value > ref.upperExtreme)
        return { 'iconClass': 'fa-exclamation-triangle' };
      if (c.value < ref.lowerNormal)
        return { 'iconClass': 'fa-exclamation-triangle' };
      if (c.value > ref.upperNormal)
        return { 'iconClass': 'fa-exclamation-triangle' };
      else
        return null;
    }
  }}
