<style>
  .last {
    display: none;
  }

  .example-additional-selection {
    opacity: 0.75;
    font-size: 0.75em;
  }
</style>
<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title">Patientinformation {{patientRemiss.personalIdentityNumber}}</h5>
    <!--<button type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>-->
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <form [formGroup]="saveAddInfoUndersokningForm" (ngSubmit)="submitForm()">
    <div class="modal-body">
      <!--<div class="alert alert-danger" role="alert">
        <h4 class="alert-heading"><i class="fas fa-exclamation-circle"></i> Att tänka på</h4>
        <hr>
        {{patientRemiss.warningRemissText}}
        {{patientRemiss.warningTextLakemedel}}
      </div>-->
      <fieldset class="border p-3 m-1">
        <div class="mt-2 row">
          <div class="col-3 text-right">
            <label class="col-form-label" for="pnr">
              Vikt:
            </label>
          </div>
          <div class="col-7">
            <input type="text" class="form-control" formControlName="vikt">
          </div>
        </div>
        <div class="mt-2 row">
          <div class="col-3 text-right">
            <label class="col-form-label" for="langd">
              Längd:
            </label>
          </div>
          <div class="col-7">
            <input type="text" class="form-control" formControlName="langd">
          </div>
        </div>
      </fieldset>

    </div>
    <div class="modal-footer">

      <button type="button" class="btn btn-info cancel" (click)="closeModal()">{{'common.close' | translate}}</button>
      <button type="submit" class="btn btn-lg btn-success" (ngSubmit)="submitForm()">{{'common.save' | translate}}</button>
    </div>
  </form>
</ng-template>
