import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { DataItem } from '@model/data-item';
import { DataItemGroup } from '@model/data-item-group';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-definiator',
  templateUrl: './definiator.component.html',
  styleUrls: ['./definiator.component.scss']
})
export class DefiniatorComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private http: HttpClient
  ) { }
  @Input() item: DataItem;
  @Input() itemGroup: DataItemGroup;
  
  public dataItemGroups = new BehaviorSubject<DataItemGroup[]>(null);
  public diagramItems: DataItem[] = [];
  //public variablItems: DataItem[] = [];

  public textTypeEdit: boolean;

  ngOnInit(): void {
    this.getData();
    this.textTypeEdit = true;
  }

  private getData() {
    this.http.get('/assets/examinations_types_json/echo_linnea.json')
      .subscribe((data: DataItemGroup[]) => {
        this.dataItemGroups.next(data);
        data.forEach(() => this.diagramItems.push(null));
      });
  }
  /* Show diagram for DataItem */

  /* Edit variable survey*/
  public itemSurveyToEdit = new BehaviorSubject<DataItem>(null);
  public editItemSurveyModalIsOpen = new BehaviorSubject<boolean>(false);

  public openEditSurveyModal(item: DataItem) {
    this.itemSurveyToEdit.next(item);
    this.editItemSurveyModalIsOpen.next(true);
  }
  public editItemSurveyModalClosed(e) {
    this.itemSurveyToEdit.next(null);
    this.editItemSurveyModalIsOpen.next(false);
  }
}
