import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_helpers/auth.guards';
import { OverviewPatientComponent } from '../../modules/patient/pages/overview-patient/overview-patient.component';
import { PlanExaminationComponent } from '../../modules/patient/pages/plan-examination/plan-examination.component';
import { PatientListComponent } from './pages/patient-list/patient-list.component';
import { PatientRemittanceListComponent } from './pages/patient-remittance-list/patient-remittance-list.component';
import { RemittanceListComponent } from './pages/remittance-list/remittance-list.component';

export const routes: Routes = [
  { path: 'overview-patient/:patientId', component: OverviewPatientComponent, canActivate: [AuthGuard] },
  { path: 'plan-examination/:remissid/:patientId', component: PlanExaminationComponent, canActivate: [AuthGuard] },
  { path: 'patient-list', component: PatientListComponent, canActivate: [AuthGuard] },
  //{ path: 'plan-examination/:patientId', component: PlanExamination, canActivate: [AuthGuard] }
  { path: 'overview-patient-page/:patientId', component: OverviewPatientComponent, canActivate: [AuthGuard] },
  { path: 'patient-remittance-list/:patientId', component: PatientRemittanceListComponent, canActivate: [AuthGuard] },
  //{ path: '', redirectTo: 'remittance-list', component: RemittanceListComponent, pathMatch: 'full' },
  { path: 'remittance-list', component: RemittanceListComponent, canActivate: [AuthGuard] },
  { path: 'patient-remittance-list/:patientId', component: PatientRemittanceListComponent, canActivate: [AuthGuard] },
  ]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PatientRoutingModule { }
