<style>
  .last {
    display: none;
  }
</style>
<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title" id="add-examinationtype-modal">Skapa ny undersökningsvariant</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
  </div>
  <form [formGroup]="newExaminationTypeForm" (ngSubmit)="submitForm()">
    <!--<input type="hidden" formControlName="typeId" id="typeId" name="typeId" [value]="1">-->
    <div class="modal-body">
      <!--<div class="form-group">
    <div class="row">
      <label class="col-form-label col-3 text-right pt-0">Ny/utgå från befintlig (kopia)</label>
      <div class="col-9">
        <div class="form-check">
          <input class="form-check-input" type="radio" id="newOrCopyRadio_New" formControlName="newOrCopy" value="new">
          <label class="form-check-label" for="newOrCopyRadio_New">
            Ny
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="newOrCopyRadio_Copy" formControlName="newOrCopy" value="copy">
          <label class="form-check-label" for="newOrCopyRadio_Copy">
            Befintlig (kopia)
          </label>
        </div>
      </div>
    </div>
  </div>-->
      <!--<div *ngIf="newOrCopy.value=='copy'">
  <div class="form-group row">
    <div class="col-3 text-right">
      <label for="examinationTypeOption" class="col-form-label">Vilken undersökning vill du utgå ifrån:</label>
    </div>
    <div class="col-7">
      <select formControlName="examinationType" class="form-control" id="examinationTypeOption" (change)="getDataFromExistingEchoTypes($event.target.value)">
        <ng-container *ngFor="let examinationType of examinationTypesList | async; index as i;">
          <option [value]="examinationType.id">{{examinationType.variantName}}</option>-->
      <!--<option *ngIf="examinationType.visible" [value]="examinationType.id">{{examinationType.variantName}}<span *ngIf="examinationType.default" [ngbTooltip]="'Skapa en ny undersökningstyp och gör en kopia av denna, om du vill det.'"> (Systemtyp)</span></option>-->
      <!--</ng-container>
        </select>
      </div>
    </div>
  </div>-->

      <div class="form-group row">
        <div class="col-3 text-right">
          <label class="col-form-label">
            Namn:
          </label>
        </div>
        <div class="col-7">
          <input type="text" class="form-control" formControlName="variantName" required>
          <span class="text-danger"
                *ngIf="(registerFormControl.variantName.touched || submitted) && registerFormControl.variantName.errors?.required">
            Namn är obligatoriskt
          </span>
        </div>
      </div>
      <div class="form-group row mt-2">
        <div class="col-3 text-right">
          <label class="col-form-label">
            Beskrivning:
          </label>
        </div>
        <div class="col-7">
          <textarea class="form-control" formControlName="variantDescription" required></textarea>
          <span class="text-danger"
                *ngIf="(registerFormControl.variantDescription.touched || submitted) && registerFormControl.variantDescription.errors?.required">
            Beskrivningen är obligatorikt
          </span>
          <small class="form-text text-muted">Du kan göra ändringar av valda variabler vid undersökningen av patienten.</small>
        </div>
      </div>
      <!--<div class="form-group row">
    <div class="col-3 text-right">
      <label for="svarighetsgrad">
        Svårghetsgrad:
      </label>
    </div>
    <div class="col-7">
      <select class="form-select form-control" formControlName="svarighetsgrad">
        <option [selected]="true">Välj svårighetsgrad</option>
        <option *ngFor="let svarighetsgrad of svarighetsgradList" [value]="svarighetsgrad.value">{{svarighetsgrad.text}}</option>
      </select>
    </div>
  </div>-->
      <div class="col-7 offset-3">
        <div class="form-group">
          <div class="form-check">
            <label class="form-check-label" for="typeActiveFromDatum">
              <input formControlName="typeActiveFromDatum" id="typeActiveFromDatum" class="form-check-input" type="checkbox">
              Sätt giltighetstid start
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row mb-2" *ngIf="typeActiveFromDatum.value">
        <div class="col-3 text-right">
          <label class="col-form-label" for="giltigFromDatum">
            Giltighet från:
          </label>
        </div>
        <div class="col-7">
          <div class="input-group">
            <input type="text"
                   placeholder="Giltig från"
                   class="form-control"
                   formControlName="validFromDatum" #d="ngbDatepicker" ngbDatepicker>
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
          </div>
        </div>
      </div>
      <div class="col-7 offset-3">
        <div class="form-group">
          <div class="form-check">
            <input formControlName="typeActiveToDatum" class="form-check-input" type="checkbox" id="typeActiveToDatum">
            <label class="form-check-label" for="typeActiveToDatum">
              Sätt giltighetstid slut:
            </label>
          </div>
        </div>
      </div>
      <div class="form-group row mb-2" *ngIf="typeActiveToDatum.value">
        <div class="col-3 text-right">
          <label class="col-form-label" for="giltigToDatum">
            Giltighet till:
          </label>
        </div>
        <div class="col-7">
          <div class="input-group">
            <input type="text"
                   placeholder="Giltig till"
                   name="giltigToDatum"
                   class="form-control"
                   formControlName="validToDatum" #d="ngbDatepicker" ngbDatepicker>
            <button class="btn btn-outline-secondary" (click)="d.toggle()" type="button"><fa-icon [icon]="faCalendar"></fa-icon></button>
          </div>
        </div>
      </div>
      <!--<div class="col-7">
    <mat-form-field appearance="fill" class="w-100">
      <mat-label>Ange giltighetsperiod</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Giltig from" formControlName="validFrom">
        <input matEndDate placeholder="Giltig till" formControlName="validTo">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>-->
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info cancel" (click)="modal.dismiss()">{{'common.close' | translate}}</button>
      <button type="submit" class="btn btn-lg btn-success">{{'common.save' | translate}}</button>
    </div>
  </form>
</ng-template>
