
<canvas #drawingCanvas class="w-100" height="200"></canvas>

<div class="clearfix">
    <div class="drawing-alternatives">
        <div class="color-alternatives">
            <div class="color-alternative" [class.active]="color == 'black'" style="background:black" (click)="setColor('black')"></div>
            <div class="color-alternative" [class.active]="color == 'silver'" style="background:silver" (click)="setColor('silver')"></div>
            <div class="color-alternative" [class.active]="color == 'orange'" style="background:orange" (click)="setColor('orange')"></div>
            <div class="color-alternative" [class.active]="color == 'red'" style="background:red" (click)="setColor('red')"></div>
            <div class="color-alternative" [class.active]="color == 'purple'" style="background:purple" (click)="setColor('purple')"></div>
            <div class="color-alternative" [class.active]="color == 'dodgerblue'" style="background:dodgerblue" (click)="setColor('dodgerblue')"></div>
            <div class="color-alternative" [class.active]="color == 'forestgreen'" style="background:forestgreen" (click)="setColor('forestgreen')"></div>
            <div class="color-alternative" [class.active]="color == 'white'" style="border:1px solid #ccc" (click)="setColor('white')"></div>
        </div>

        <button type="button" class="btn btn-xs btn-outline-secondary clear-btn" (click)="clear()">&times; {{'components.drawable-canvas.clear' | translate}}</button>
    </div>
</div>
