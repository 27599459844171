<ng-template #content let-modal size="lg">
  <div class="modal-header">
    <h5 class="modal-title" id="add-image-modal">{{'pages.overview.images.add' | translate}}</h5>
    <!--<button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <span aria-hidden="true">&times;</span>
  </button>-->
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <ng-container *ngIf="!imageToDrawOn">
      <h6 class="text-center">{{'pages.overview.images.choose-image' | translate}}:</h6>
      <div class="image-collage">
        <div class="row no-gutters">
          <div class="col-auto" *ngFor="let imageName of imageLib" (click)="imageToDrawOn = imageName">
            <img [src]="'/assets/img/' + imageName + '.png'" class="img-fluid" />
          </div>
        </div>
      </div>
    </ng-container>

    <div class="row justify-content-center justify-content-lg-start" *ngIf="imageToDrawOn">
      <div class="col-lg-3 mb-3">
        <button type="button" class="btn btn-outline-info" (click)="imageToDrawOn = null">
          {{'pages.overview.images.change-image' | translate}}
        </button>
      </div>
      <div class="col-11 col-md-10 col-lg-6 border">
        <div>
          <drawable-canvas [backgroundImageName]="imageToDrawOn"
                           [triggerSaveImage]="saveDrawedImage"
                           (getImgDataUrl)="receiveImgDataUrl($event)"></drawable-canvas>
        </div>
      </div>

    </div>
    <div class="row justify-content-center justify-content-lg-start" *ngIf="imageToDrawOn">
      <div class="form-group col-12">
        <label class="form-label">Kommentar</label>
        <textarea class="form-control" rows="3"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info cancel" (click)="closeModal()">{{'common.cancel' | translate}}</button>
    <button *ngIf="imageToDrawOn"
            type="button" class="btn btn-success save" (click)="addImage()">
      <fa-icon [icon]="faPlus"></fa-icon>&ensp;{{'common.add' | translate}}
    </button>
  </div>
</ng-template>
