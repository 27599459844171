import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef} from '@angular/core';
import { DynamicSortService } from '@core/services/dynamic-sort.service';
import { faCaretDown, faCaretUp, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FormControl } from '@angular/forms';
import { SelectListItem } from '@model/select-list-item';
import { EditExaminationModalService } from '../components/add-examination-modal/add-examination-modal.component';
import { ExaminationsListDto, UndersokningService } from '../../../core/services/undersokning.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'examinations-list',
  templateUrl: './examinations-list.component.html',
  styleUrls: ['./examinations-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ExaminationsListComponent implements OnInit, OnDestroy {
  errorMessage = '';
  $subscriptions: Subscription[] = [];

  constructor(
    private uService: UndersokningService,
    public translate: TranslateService,
    private toastr: ToastrService,
    private http: HttpClient,
    private detector: ChangeDetectorRef,
    private dynamicSort: DynamicSortService,
    private examinationmodal: EditExaminationModalService
  ) { }
  //Ikoner
  public faCaretDown = faCaretDown;
  public faCaretUp = faCaretUp;
  public faTrash = faTrash;

  examinations: any[] = [];
  ngOnInit(): void {
    this.getData();
    this.initVisibleTableColumns();
  }
  //////////
  ///
  ngOnDestroy(): void {
    this.$subscriptions.forEach(_ => _.unsubscribe());
  }
  /* Edit remiss*/
  public openAddExaminationModal(id: number) {
    const modalRef = this.examinationmodal.show(id, true);
    this.$subscriptions.push(
      modalRef.subscribe({
        next: () => {
          //this.examination = result;
          this.toastr.success("Undersökningen har sparats.");
          this.getData();
        },
        error: (error) => {
          this.toastr.error(error.error);
        }
      })
    );
  }
  getData() {
    this.uService.getExaminations().subscribe(_ => this.examinations = _);
  }
  /* Sorting */
  private defaultOrderColumn = 'pnr';
  public orderBy: string = this.defaultOrderColumn;
  public sortAscending: boolean = true;
  public setOrderBy(column: string, reload: boolean = false) {
    if (!reload) {
      this.sortAscending = this.orderBy != column ? true : !this.sortAscending;
    }
    this.orderBy = column;
  }
  /* Show/Hide table columns */
  public possibleTableColumns: SelectListItem[] = [];
  public visibleTableColumnsFC = new FormControl();
  private initVisibleTableColumns() {
    this.possibleTableColumns = [
      new SelectListItem('Skapad', 'creatde'),
      new SelectListItem('PNR', 'pnr'),
      new SelectListItem('Namn', 'name'),
    ];
    this.visibleTableColumnsFC.setValue(['pnr', 'created', 'name']);
    this.detector.markForCheck();
  }
  public columnIsVisible(column: string): boolean {
    let visibleColumns: string[] = this.visibleTableColumnsFC.value;
    return visibleColumns.indexOf(column) > -1;
  }
  confirmDelete(examinaitionId: number) {
      this.uService.deleteExamination(examinaitionId).subscribe({
        next: () => {
          this.getData();
        },
        error: (errorResponse) => {
          //  this.errorMessage = errorResponse;
        },
    });
  }
}
