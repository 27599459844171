import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicSortService {
  public sort(property, sortAscending: boolean = true) {
    let sortOrder = -1;
    if (sortAscending)
      sortOrder = 1;

    return function (a, b) {
      let result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}
