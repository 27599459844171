import { AbstractControl, ValidatorFn } from '@angular/forms';
import { RangeError } from '@model/range-error';

export class RefValidatorService 
{  
  validator(ref): ValidatorFn {
    return (c: AbstractControl): { [key: string]: any } | null => {
      if (ref == undefined || c.value == '')
        return null;

      if (c.value < ref.lowerExtreme)
        return { 'rangeError': RangeError.LowerExtreme };
      if (c.value > ref.upperExtreme)
        return { 'rangeError': RangeError.UpperExtreme };
      if (c.value < ref.lowerNormal)
        return { 'rangeError': RangeError.LowerNormal, 'errorText': ref.lowerNormal };
      if (c.value > ref.upperNormal)
        return { 'rangeError': RangeError.UpperNormal, 'errorText': ref.upperNormal };
      else
        return null;
    }
  }
}
