import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../../_helpers/auth.guards';
import { DefiniatorComponent } from './echo/definiator/definiator.component';
import { ExaminationInProgressComponent } from './echo/examination-in-progress/examination-in-progress.component';
import { OverviewPageComponent } from './echo/overview-page/overview-page.component';
import { EchoTestbaddComponent } from './echo-testbadd/echo-testbadd.component';
import { ExaminationsListComponent } from './examinations-list/examinations-list.component';
//import { OverviewPatient } from '../patient/pages/overview-patient/overview-patient.component';

export const routes: Routes = [
  { path: 'echo', component: ExaminationInProgressComponent, canActivate: [AuthGuard] },
  { path: 'examination-in-progress/:remissid/:undersokningstypId/:patientId', component: ExaminationInProgressComponent, canActivate: [AuthGuard] },
  { path: 'overview', component: OverviewPageComponent, canActivate: [AuthGuard] },
  { path: 'definiator', component: DefiniatorComponent, canActivate: [AuthGuard] },
  { path: 'examinationslist', component: ExaminationsListComponent, canActivate: [AuthGuard] },
  { path: 'echo-testbadd/:id', component: EchoTestbaddComponent, canActivate: [AuthGuard] },
]
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExaminationsRoutingModule { }
