import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, Pipe, PipeTransform, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription, combineLatest, from } from 'rxjs';
/*import { PatientLakemedel } from '@model/data-patientlakemedel';*/
import { VariablesService } from '@core/services/variabler.service';
import { EchoVariablesDto } from '@model/undersokningsvarianter/echo-variables-dto';
import { SysEchoGroupDto } from '@model/undersokningsvarianter/sys-echo-group-dto';
import { SysInfUnitDto } from '@model/undersokningsvarianter/sys-inf-unit-dto';
import { SysInfFormatDto } from '@model/undersokningsvarianter/sys-inf-format-dto';
import { SysInfEchoValueTypeListDto } from '@model/undersokningsvarianter/sys-inf-echo-value-type-list-dto';
import { SysInfEchoValueTypeDto } from '@model/undersokningsvarianter/sys-inf-echo-value-type-dto';
import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { CalculationService, Formel, VariabelFormler } from '../../../../core/services/calculation.service';
import { EditVariableFormulaModalService } from '../formula-echovariable-modal/formula-echovariable-modal.component';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { groupBy, map, mergeMap, tap, toArray } from 'rxjs/operators';
import { SysInfEchoMeasuremenMethodDto } from '../../../../model/inf/sys-inf-measuremen-method-dto';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSelectChange } from '@angular/material/select';
import { MatOptionSelectionChange } from '@angular/material/core';
import { filter } from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'add-echovariable-modal',
  templateUrl: './add-echovariable-modal.component.html',
  styleUrls: ['./add-echovariable-modal.component.scss']
})
export class AddEchoVariableModalComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private dataService: VariablesService,
    private activeModal: NgbActiveModal,
    private editFormulaModal: EditVariableFormulaModalService,
    private service: CalculationService,
    private toastr: ToastrService
  ) { }

  cVariableId: number;
  cVariable: EchoVariablesDto;
  variableName: string;
  graderingJsonData: VariabelFormler;
  $subscriptions: Subscription[] = [];
  groupList: Observable<SysEchoGroupDto[]>;
  listSysInfEchoValueType: Observable<SysInfEchoValueTypeDto[]>;
  public get sysInfEchoValueTypeList(): Observable<SysInfEchoValueTypeListDto[]> { return this._sysInfEchoValueTypeList };
  private _sysInfEchoValueTypeList: Observable<SysInfEchoValueTypeListDto[]> = null!;
  unitList: Observable<SysInfUnitDto[]>;
  formatList: Observable<SysInfFormatDto[]>;
  undersokningsVyGrupp: Observable<SysEchoGroupDto[]>;
  anatomiskFysiologiskVyGrupp: Observable<SysEchoGroupDto[]>;
  sysInfMeasuremenMethods: Observable<SysInfEchoMeasuremenMethodDto[]>;
  addedMeasuremenMethods: SelectedMeasuremenMethod[] = [];
 /* public loading = new BehaviorSubject<boolean>(false);*/
  processSubmit = false;
  submitted = false;
  isLoading: boolean = true;

  //Ikoner
  faTrash = faTrash;

  ngOnInit(): void {
    this.getVariableData();
    this.getUnits();
    this.getFormats();
    this.getMeasuremenMethods();
    this.getGroups();
    this.getGroupTypes();
    this.getVariableOptions();
    this.setForm();
    this.isLoading = false;
  }
  //////////
  ///
  ngOnDestroy(): void {
    this.$subscriptions.forEach(_ => _.unsubscribe());
  }
  get id() { return this.saveAddVariableForm.get('id'); }
  get sysDescription() { return this.saveAddVariableForm.get('sysDescription'); }
  get name() { return this.saveAddVariableForm.get('name'); }
  get description() { return this.saveAddVariableForm.get('description'); }
  get sysEchoGroupsUnd() { return this.saveAddVariableForm.get('sysEchoGroupsUnd') }
  get sysEchoGroupsAnFys() { return this.saveAddVariableForm.get('sysEchoGroupsAnFys'); }
  get shortName() { return this.saveAddVariableForm.get('shortName'); }
  get short() { return this.saveAddVariableForm.get('short'); }
  get sysInfEchoValueType() { return this.saveAddVariableForm.get('sysInfEchoValueType'); }
  get sysInfUnit() { return this.saveAddVariableForm.get('sysInfUnit'); }
  get sysInfFormat() { return this.saveAddVariableForm.get('sysInfFormat'); }
  get impossibleValueMin() { return this.saveAddVariableForm.get('impossibleValueMin'); }
  get impossibleValueMax() { return this.saveAddVariableForm.get('impossibleValueMax'); }
  get durabilityOfMeasurment() { return this.saveAddVariableForm.get('durabilityOfMeasurment'); }
  get timeLimitMeasurment() { return this.saveAddVariableForm.get('timeLimitMeasurment'); }
  get internalComment() { return this.saveAddVariableForm.get('internalComment'); }
  
  ///* Modal */
  //private openModal() {
  //  this.modalService.open(this.modalHtml, { ariaLabelledBy: 'add-echovariable-modal', size: 'lg' })
  //    .result.then((reason) => this.modalClosed.emit(reason), (reason) => this.modalClosed.emit(reason));
  //}
  showData: boolean = false;
  getVariableData() {
    if (this.cVariableId > 0) {
      this.dataService.getVariable(this.cVariableId).subscribe(_ => {
        this.cVariable = _;
        if (_.calculationJson.formler) {
          let formler: Formel[] = [];
          //Gruppera på kön
          of(..._.calculationJson.formler)
            .pipe(
              groupBy((item) => item.kon),
              mergeMap((group) => group.pipe(toArray()))
          )
            .subscribe((groupedItems) => {
              //Sortera så att graderingen hamnar i ordning
              groupedItems.sort((a, b) => (a.graderingstyp > b.graderingstyp) ? 1 : -1).forEach(f => {
                let graderingstext = f.graderingsText.replaceAll("{variabel}", _.shortName);

                let formel: Formel = {
                  id: f.id,
                  kon: f.kon,
                  graderingstyp: f.graderingstyp,
                  formel: f.formel,
                  format: f.format,
                  graderingsText: graderingstext,
                  graderingsTecken: this.service.filterTecken(f.graderingstyp),
                  konBeskrivning: this.service.filterKon(f.kon),
                  visaEjISvar: f.visaEjISvar,
                  startValue: f.startValue
                }
                formler.push(formel);
              })
            });

          //TODO: Sortera formler på kön
          let calculationJson: VariabelFormler = {
            intervalltyp: _.calculationJson.intervalltyp,
            kontyp: _.calculationJson.kontyp,
            format: _.calculationJson.format,
            formler: formler
          }
          this.graderingJsonData = calculationJson;
        }
      });
    }
  }
  public getGroups() {
    this.groupList = this.dataService.getAllGroups();
  }
  public getVariableOptions() {
    this.listSysInfEchoValueType = this.dataService.getInfoSysInfEchoValueType();
  }
  public getUnits() {
    this.unitList = this.dataService.getAllVariableUnits();
  }
  public getFormats() {
    this.formatList = this.dataService.getAllVariableFormats();
  }
  public getMeasuremenMethods() {
    this.sysInfMeasuremenMethods = this.dataService.getAllMeasuremenMethods();
  }
  public getGroupTypes() {
    this.undersokningsVyGrupp = this.dataService.getEchoGroupsByType(1);
    this.anatomiskFysiologiskVyGrupp = this.dataService.getEchoGroupsByType(2);
  }
  public getInfoSysInfEchoValueType(typId: number) {
    // Om variabel något annat än variabel är valt ska inte format och enhet sparas - nollställ
    if (typId == null) {
      this.sysInfUnit.reset();
      this.sysInfFormat.reset();
      this.impossibleValueMin.reset();
      this.impossibleValueMax.reset(); 
    }
    //Nollställer metoderna om det inte är uträknat värde som är valt för valuetype
    if (typId != 7) {
      this.addedMeasuremenMethods = [];
      this.saveAddVariableForm.get('sysInfMeasuremenMethods').reset();
    }
    this._sysInfEchoValueTypeList = this.dataService.getInfEchoValueTypeList(typId);
  }
  public saveAddVariableForm: FormGroup;
  private setForm() {
    this.saveAddVariableForm = new FormGroup({
      //TODO: maxLength borde väl styras från DB? Kolla och uppdatera
      sysDescription: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      description: new FormControl('', [Validators.required, Validators.maxLength(500)]),
      name: new FormControl('', [Validators.required, Validators.maxLength(50)]),
      shortName: new FormControl('', [Validators.required, Validators.maxLength(30)]),
      short: new FormControl('', [Validators.maxLength(10)]),
      sysEchoGroupsUnd: new FormControl(''),
      sysEchoGroupsAnFys: new FormControl('0', Validators.required),
      sysInfEchoValueType: new FormControl(null),
      sysInfUnit: new FormControl(),
      sysInfFormat: new FormControl(),
      id: new FormControl(),
      impossibleValueMin: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(3), Validators.minLength(1)]),
      impossibleValueMax: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(3), Validators.minLength(1)]),
      durabilityOfMeasurment: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(3), Validators.minLength(1)]),
      timeLimitMeasurment: new FormControl('', [Validators.pattern(/^-?(0|[1-9]\d*)?$/), Validators.maxLength(3), Validators.minLength(1)]),
      sysInfMeasuremenMethods: new FormControl(),
      internalComment: new FormControl()
    });
    /*Redigera befintlig variabel*/
    // TODO: Fortsätt här!! Lägg in värden i formuläret.
    if (this.cVariableId > 0) {
      console.log('Redigera befintlig variabel', this.cVariableId);
      this.dataService.getVariable(this.cVariableId).subscribe((prevUppg) => {
        console.log(prevUppg.sysEchoGroupIds);
        //let varIds: string[] = prevUppg.sysEchoGroupIds.map(_ => { return String(_) });
        //console.log(varIds);
        //prevUppg.sysEchoGroupIds.forEach(id => {
        //  this.saveAddVariableForm.get('sysEchoGroupsUnd').value(prevUppg.sysEchoGroupIds);
        //});

        //this.saveAddVariableForm.get('sysEchoGroupsUnd').patchValue(varIds);
        //  prevUppg.sysEchoGroupIds.forEach(_ => {
        //    this.saveAddVariableForm.get(_.toString()).setValue(_.toString());
        //});
        //let varIds = prevUppg.sysEchoGroupIds.map(str => String(str));
        //varIds.forEach(_ => this.saveAddVariableForm.get('sysEchoGroupsUnd').setValue(_));

        //  for (var i = 0; i < varIds.length; i++) {
        //    this.saveAddVariableForm.get('sysEchoGroupsUnd').patchValue(varIds[i]);
        //  }
        this.saveAddVariableForm.patchValue({
          id: prevUppg.id,
          sysDescription: prevUppg.sysDescription,
          name: prevUppg.name,
          description: prevUppg.description,
          shortName: prevUppg.shortName,
          short: prevUppg.short,
          sysEchoGroupsUnd: prevUppg.sysEchoGroupIds,
          sysEchoGroupsAnFys: prevUppg.sysEchoGroupTypeAnatomiskId,
          sysInfFormat: prevUppg.sysInfFormatId,
          sysInfUnit: prevUppg.sysInfUnitId,
          sysInfEchoValueType: prevUppg.sysInfEchoValueTypeId,
          impossibleValueMax: prevUppg.impossibleValueMax,
          impossibleValueMin: prevUppg.impossibleValueMin,
          durabilityOfMeasurment: prevUppg.durabilityOfMeasurment,
          timeLimitMeasurment: prevUppg.timeLimitMeasurment,
          sysEchoGroupTypeUndersökningsvyIds: prevUppg.sysEchoGroupTypeUndersökningsvyIds,
          sysEchoGroupTypeAnatomiskId: prevUppg.sysEchoGroupTypeAnatomiskId,
          sysInfMeasuremenMethods: prevUppg.measurementMethods.map(_ => _.id),
          internalComment: prevUppg.internalComment
        });
        // Om det finns metoder ska dessa läggas till i sorteringslistan
        if (prevUppg.measurementMethods.length > 0) {
          prevUppg.measurementMethods.map(me => {
            this.addedMeasuremenMethods.push({
              id: me.id,
              name: me.name,
              sortorder: me.sortorder
            });
          });
        }
        this.getInfoSysInfEchoValueType(prevUppg.sysInfEchoValueTypeId);
      });
    }
    else {
      this.saveAddVariableForm.reset();
      this.getInfoSysInfEchoValueType(null);
    }
  }

  public submitForm() {
    //TODO: Save
    this.processSubmit = true;
    console.log('submitForm', this.saveAddVariableForm.value);
    this.submitted = true;
    if (!this.saveAddVariableForm.valid) {
      //Något felaktigt i formuläret, visa alla felmeddelanden, stäng ej modal
      this.saveAddVariableForm.markAllAsTouched();
      this.processSubmit = false;
      return;
    }
    if (this.saveAddVariableForm.valid) {
      let selectedgroups: Array<any> = this.saveAddVariableForm.get('sysEchoGroupsUnd').value;
      //selectedgroups.push(this.saveAddVariableForm.get('sysEchoGroupsAnFys').value);

      let v: EchoVariablesDto = {
        id: this.id.value,
        name: this.name.value,
        shortName: this.shortName.value,
        short: this.short.value,
        sysDescription: this.sysDescription.value,
        description: this.description.value,
        sysInfEchoValueTypeId: this.sysInfEchoValueType.value,
        sysInfUnitId: this.sysInfUnit.value,
        sysInfFormatId: this.sysInfFormat.value,
        sysEchoGroupIds: selectedgroups,
        active: true,
        impossibleValueMin: this.impossibleValueMin.value,
        impossibleValueMax: this.impossibleValueMax.value,
        durabilityOfMeasurment: this.durabilityOfMeasurment.value,
        timeLimitMeasurment: this.timeLimitMeasurment.value,
        measurementVariable: true, // TODO: Fixa så att det inte är hårdkodat
        sysEchoGroupTypeUndersökningsvyIds: selectedgroups,
        sysEchoGroupTypeAnatomiskId: Number(this.sysEchoGroupsAnFys.value),
        measurementMethods: this.addedMeasuremenMethods,
        internalComment: this.internalComment.value
        //  calculationJson: new VariabelFormler()//todo:
      };
      console.log(v);
      this.dataService.saveVariable(v).subscribe({
        next: () => {
          this.activeModal.close();
          if (this.id.value > 0)
            this.toastr.success(this.name.value + " har ändrats", "", { positionClass: 'toast-top-center' });
          else
            this.toastr.success(this.name.value + " har lagts till", "", { positionClass: 'toast-top-center' });
        },
        error: (errorResponse: any) => {
          this.processSubmit = false;
          this.toastr.warning("Något gick fel", "", { positionClass: 'toast-top-center' });
        }
      })
    }
  }
  get registerFormControl() {
    return this.saveAddVariableForm.controls;
  }
  //////////
  ///
  public onCancel(): void {
    this.activeModal.dismiss();
  }
  //////////
  ///
  public openEditGraderingar(): void {
    this.activeModal.dismiss();
    //Formatera tillbaka formatet.
    let format = this.cVariable?.format?.replace(/X/g, '#');
    const modalRef = this.editFormulaModal.show(this.cVariableId, this.variableName, format);
    this.$subscriptions.push(
      modalRef.subscribe(() => {
      })
    );
  }
  //////////
  ///
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.addedMeasuremenMethods, event.previousIndex, event.currentIndex);
  }
  //////////
  ///
  changeSelectedMethods(event: MatOptionSelectionChange) {
    let metod: SelectedMeasuremenMethod = this.addedMeasuremenMethods.filter(_ => _.id == event.source.value)[0] ?? null;
    let metodIndex: number = this.addedMeasuremenMethods.findIndex(_ => _.id === event.source.value);
    if (event.isUserInput) {
      if (metod == null && event.source.selected === true) {
        this.addedMeasuremenMethods.push({
          id: event.source.value,
          name: event.source.getLabel()
        });
      }
      if (metod != null && !event.source.selected) {
        //Ta bort
        this.addedMeasuremenMethods.splice(metodIndex, 1);
      }
    }
  }
  //////////
  ///
  setIndex(metod: SelectedMeasuremenMethod, i) {
    metod.sortorder = i
    return metod.sortorder = i;
  }
}

@Injectable({
  providedIn: "any"
})

export class EditVariableModalService {
  constructor(private modalService: NgbModal) { }

  public show(id: number, variableName: string): Observable<unknown> {
    const modalRef = this.modalService.open(AddEchoVariableModalComponent, { backdrop: "static", size: "xl" });
    modalRef.componentInstance.cVariableId = id;
    modalRef.componentInstance.variableName = variableName;
    return modalRef.closed;
  }
}
export class SelectedMeasuremenMethod {
  id: number;
  name: string;
  sortorder?: number;
  value?: string | "";
  selected?: boolean;
}
