import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'edit-image-modal',
  templateUrl: './edit-image-modal.component.html',
  styleUrls: ['./edit-image-modal.component.scss']
})
export class EditImageModalComponent implements OnChanges {
  constructor(
    private modalService: NgbModal
  ) { }

  @Input() imageName: string;
  @Input() modalIsOpen: boolean;
  @Output() modalClosed = new EventEmitter();

  @ViewChild("content") modalHtml;

  ngOnChanges(): void {
    if (this.modalIsOpen && this.imageName)
      this.openModal();
  }

  private openModal() {
    this.modalService.open(this.modalHtml, {ariaLabelledBy: 'edit-image-modal', size: 'lg'})
      .result.then((result) => this.modalClosed.emit(), (reason) => this.modalClosed.emit());
  }
  public closeModal() {
    this.modalService.dismissAll();
    this.modalClosed.emit();
  }

}
