import { AfterContentInit, AfterViewInit, Directive, ElementRef, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
@Directive({
  selector: '[customAutofocus]',
  standalone: true
})
export class AutofocusDirective implements OnChanges {
  @Input() public autofocusBool: boolean;
  @Input() public vIndex: number;
  @Input() public gIndex: number;
  @Input() public style: number;
  constructor(private element: ElementRef<HTMLInputElement>) { }

  ngOnChanges(change: SimpleChanges) {
    //if (change.autofocusBool) {
    //  //  console.log('input changed');
    //  console.log("Bool" + this.autofocusBool + "Variabel" + this.variableId);
    //  this.element.nativeElement.focus();
    //}
    if (change.autofocusBool.currentValue) {
      //  console.log('input changed');

      this.element.nativeElement.focus();
    }
  }
  //ngAfterViewInit() {
  //  if(this.autofocusBool) {
  //    //  console.log('input changed');
  //    this.element.nativeElement.focus();
  //  }
  //}
  //ngOnInit() {
  //  this.run();
  //}
  //run() {
    
  //}
}
