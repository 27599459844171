<nav class="navbar navbar-expand-sm navbar-light bg-light border-bottom shadow-sm navbar-fixed-top sticky-top">
  <div class="container-fluid">
    <span class="navbar-brand mb-0 h1">KlinFys</span>

    <button class="navbar-toggler" type="button" (click)="toggle()" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarContent" [attr.aria-expanded]="isExpanded" aria-label="Toggle menu">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" [class.show]="isExpanded" id="navbarContent">
      <ul class="navbar-nav me-auto">
        <!--<li class="nav-item">
    <a class="nav-link" [routerLink]="''">{{'pages.input.title' | translate}}</a>
  </li>-->
        <!--<li class="nav-item">
    <a class="nav-link" [routerLink]="'overview'">{{'pages.overview.title' | translate}}</a>
  </li>-->
        <!--<li class="nav-item">
    <a class="nav-link" [routerLink]="'overview-vers2'">{{'pages.overview-vers2.title' | translate}}</a>
  </li>-->
        <!--<li class="nav-item">
    <a class="nav-link" [routerLink]="'plan-examination'">{{'pages.plan-examination.title' | translate}}</a>
  </li>-->
        <li class="nav-item">
          <a class="nav-link" [routerLink]="'examinationslist'" [routerLinkActive]="['active']">Undersökningar</a>
        </li>
        <!-- Kommentera fram nedan-->
  <!--<li class="nav-item">
    <a class="nav-link" [routerLink]="'remittance-list'" [routerLinkActive]="['active']">Remissbedömning</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="'patient-list'" [routerLinkActive]="['active']">Patienter</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLinkActive]="['active']">Snabblista <i class="fas fa-star"></i></a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLinkActive]="['active']">Historik</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="'remittance-list'">[Remitent]</a>
  </li>
        <li class="nav-item">
          <form class="form-inline my-2 my-lg-0">
            <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Ny undersökning</button>
          </form>
        </li>-->
      </ul>
      <!--Kommentera fram nedan-->
      <!--<div class="navbar-nav border p-1">
      <div class="input-group d-flex mt-2 mb-0 my-sm-0">
        <mat-form-field class="pr-1 col-4">
          <mat-select [formControl]="searchtype" multiple class="form-control-sm form-control">
            <mat-option *ngFor="let searchtype of searchTypeList" [value]="searchtype" class="p-2">{{searchtype}}</mat-option>
          </mat-select>
        </mat-form-field>
        <input type="text" class="form-control-sm form-control col-6" aria-label="Text input with dropdown button" placeholder="Sök">
        <button class="btn btn-outline-primary btn-sm ml-1">Sök</button>
      </div>
    </div>-->
      <div class="d-flex my-2 my-sm-0 mr-2">
        <button mat-button [matMenuTriggerFor]="menu" class="btn btn-sm">{{'pages.settings.title' | translate}} <fa-icon [icon]="faChevronDown" class="ml-3"></fa-icon></button>
        <mat-menu #menu="matMenu">
          <a mat-menu-item [routerLink]="'examination-variants'">Undersökningsvarianter</a>
          <!--<a mat-menu-item [routerLink]="'examinationstypes'">{{'pages.examinationstypes.title' | translate}}</a>-->
          <a mat-menu-item [routerLink]="'editechovariables'">{{'pages.editechovariables.title' | translate}}</a>
          <a mat-menu-item [routerLink]="'manage-table'">{{'pages.manage-table.title'| translate}}</a>
          <a mat-menu-item [routerLink]="'calculation'">{{'pages.calculation.title'| translate}}</a>
        </mat-menu>
      </div>
      <!--<div class="d-flex my-2 my-sm-0">
      <div class="input-group input-group-sm">
        <div class="input-group-prepend">
          <span class="input-group-text"><fa-icon [icon]="faGlobe"></fa-icon></span>
        </div>
        <select class="form-control" [(ngModel)]="selectedLang" (change)="changeLanguage()">
          <option value="sv" [translate]="'languages.sv'"></option>
          <option value="en" [translate]="'languages.en'"></option>
        </select>
      </div>
    </div>-->
      <div class="d-flex my-2 my-sm-0 pl-2">
        <a class="btn btn-link btn-sm text-nowrap" (click)="logout()">Logga ut</a>
      </div>
    </div>
  </div>
</nav>
