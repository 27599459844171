
<div class="h-100">
  <div class="d-flex flex-nowrap h-100">
    <app-side-menu></app-side-menu>
    <div class="container-fluid">
      <div class="row bg-dark p-2 text-light border-bottom border-secondary sticky-top sticky-offset" *ngIf="remittance$ | async as remittance">
        <div class="col-lg-1 my-auto text-center">
          <span class="fa-stack fa-2x">
            <i class="fas fa-circle fa-stack-2x" style="color: #FFF;"></i>
            <i class="fas fa-user fa-stack-1x fa-inverse" style="color: #343a40;"></i>
          </span>
        </div>
        <div class="col-lg-3 my-auto">
          {{remittance.name}} {{remittance.lastname}}<br>{{remittance.personalIdentityNumber}}<br>72 år
        </div>
        <div class="col-lg-4 my-auto">
          <ng-container *ngIf="(remittance.weight != null || remittance.height != null); else addwh">
            <div class="row"><div class="col-6 d-flex justify-content-end">Vikt: </div><div class="col-6"><span [ngbTooltip]="'Från journalsystem'">{{remittance.weight}} kg <i>(2022-05-02)</i></span></div></div>
            <div class="row"><div class="col-6 d-flex justify-content-end">Längd:  </div><div class="col-6"><span [ngbTooltip]="'Från journalsystem'">{{remittance.height}} cm <i>(2022-05-02)</i></span></div></div>
            <div class="row"><div class="col-6 offset-6"><a href="#">Ändra</a></div></div>
          </ng-container>
          <ng-template #addwh><p><i>Vikt och längd saknas</i><br><button class="btn btn-sm btn-outline-light">Lägg till vikt och längd</button></p></ng-template>
</div>
        <div class="col-lg-4 d-flex justify-content-end">
          <button (click)="openShowRemissModal(remittance)" class="btn btn-sm btn-outline-light">Visa remiss</button>
        </div>
        <!--<div class="col-lg-3" *ngIf="dataRemissWithId.warningText || dataRemissWithId.warningTextLakemedel">-->
        <!--<div class="alert alert-danger" role="alert">
          <h4 class="alert-heading"><i class="fas fa-exclamation-circle"></i> Att tänka på</h4>
          <hr>
          {{dataRemissWithId.warningRemissText}}
          {{dataRemissWithId.warningTextLakemedel}}
        </div>-->
        <!--<patient-lakemedel [remiss]="dataRemissWithId"
         [patient]="dataPatientWithId"></patient-lakemedel>-->
        <!--/div>-->
        <!--<div class="col-lg-4 my-1">

          <patient-lakemedel [remiss]="dataRemissWithId"
                             [patient]="dataPatientWithId"></patient-lakemedel>

        </div>-->
      </div>
      <div class="border">
        <mat-horizontal-stepper [linear]="isLinear" #stepper (selectionChange)="onStepChange()">
          <mat-step [stepControl]="firstFormGroup" class="pb-0" #active>
            <ng-template matStepLabel>Undersökning</ng-template>
            <input-table (currentExamination)="onChangeTab($event)"></input-table>
          </mat-step>
          <mat-step [stepControl]="FormGroup3" class="pb-0">
            <ng-template matStepLabel>Granska</ng-template>
            <examination-result [examination]="examination" [activeTab]="activestep"></examination-result>
</mat-step>
          <mat-step [stepControl]="FormGroup4" class="pb-0">
            <ng-template matStepLabel>Klassificera</ng-template>
          </mat-step>
          <mat-step [stepControl]="FormGroup5" class="pb-0">
            <ng-template matStepLabel>Signera</ng-template>
          </mat-step>
          <mat-step [stepControl]="FormGroup6" class="pb-0">
            <ng-template matStepLabel>Svar</ng-template>
          </mat-step>
        </mat-horizontal-stepper>
      </div>

    </div>
  </div>
</div>

<add-infoUndersokning-modal [modalIsOpen]="showPatientinfoModalIsOpen | async"
                            [patientRemiss]="remittance$ | async"
                            (onSubmit)="newPatientInfoSubmitted($event)"
                            (modalClosed)="showPatientinfoModalClosed()"></add-infoUndersokning-modal>

<show-remiss-modal [modalIsOpen]="showRemissModalIsOpen | async"
                   [patientRemiss]="remittance$ | async"
                   (openRemissInfoModal)="openShowRemissModal()"
                   (modalClosed)="showRemissModalClosed()"></show-remiss-modal>

