
<div class="container-fluid">
  <div class="row mb-2">
    <div class="col">
      <div class="card">
        <div class="card body">
          <div class="row  align-items-center">
            <div class="col-11">
              <div class="row">
                <div *ngFor="let image of drawedImages" class="col-lg-1">
                  <div class="zoomable-image p-1" (click)="openPreviewImageModal(image)">
                    <img [src]="'assets/img/' + image + '.png'" class="img-fluid" />
                    <fa-icon [icon]="faSearchPlus" class="zoom-icon" size="2x"></fa-icon>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-1">
              <a class="link " (click)="openAddImageModal()" [ngbTooltip]="'pages.overview.images.add' | translate"><fa-icon [icon]="faPlusCircle" class="fa-3x"></fa-icon>&ensp;</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-6 order-lg-1 order-1">
      <div *ngFor="let itemGroup of dataItemGroups | async; index as i">
        <div class="row">
          <div class="col-lg-6">
            <table class="table table-sm table-bordered table-hover">
              <thead>
                <tr>
                  <th colspan="5" class="clearfix">
                    <span class="float-left">{{itemGroup.group}}</span>
                    <span class="float-right" [ngbTooltip]="'common.edit' | translate" placement="left">
                      <a (click)="openEditGroupModal(itemGroup)" class="px-2"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of itemGroup.items" class="clickable" (click)="showDiagramForItem(i, item)"
                    [class.selected]="diagramItems[i] && diagramItems[i].caption == item.caption">
                  <td class="text-center" style="width:10%">
                    <span [class.text-info]="item.caption" [class.text-transparent]="!item.caption" class="mr-1" [ngbTooltip]="item.caption" placement="right">
                      <fa-icon [icon]="faInfoCircle"></fa-icon>
                    </span>
                  </td>
                  <td class="text-center" style="width:10%">
                    {{item.symbol}}
                  </td>
                  <td style="width:40%">{{item.shortCaption}}</td>
                  <td style="width:20%">{{item.value ? item.value : '[value]'}}</td>
                  <td style="width:20%">{{item.unit}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-lg-6 px-0 pt-2" *ngIf="diagramItems[i]">
            <button class="float-right btn btn-xs btn-outline-info rounded-0" (click)="showDiagramForItem(i, null)">&times;</button>
            <div class="dataitem-info-box mt-4">
              <diagram [dataItem]="diagramItems[i]"
                       [series]="[{name: diagramItems[i].shortCaption, data: randomSerieData}]"></diagram>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6 order-2">
      <app-definiator></app-definiator>
    </div>
  </div>
</div>


<edit-itemgroup-modal [itemGroup]="itemGroupToEdit | async"
                      [modalIsOpen]="editItemGroupModalIsOpen | async"
                      (modalClosed)="editItemGroupModalClosed($event)"></edit-itemgroup-modal>

<preview-image-modal [imageName]="imageToPreview | async"
                     [modalIsOpen]="previewImageModalIsOpen | async"
                     (modalClosed)="previewImageModalClosed($event)"
                     (removeImageClick)="removePreviewedImage()"></preview-image-modal>

<add-image-modal [modalIsOpen]="addImageModalIsOpen | async"
                 (modalClosed)="addImageModalClosed($event)"></add-image-modal>
