
<form [formGroup]="variabeFormulaForm">
  <h3>{{refTyp.beskrivning}}</h3>
   <div class="mb-3" formArrayName="constantsArray">
    <ng-container *ngFor="let konst of constantsArray.controls; let i = index">
      <label for="constant_{{konstanter[i].id}}" class="form-label">Konstant {{konstanter[i].beskrivning}}: </label>
      <input type="text" id="constant_{{konstanter[i].id}}" class="form-control" formControlName="{{i}}">
    </ng-container>
  </div>
  <div class="row" *ngIf="variabeFormulaForm.valid">
    <div class="col-12">
      <button class="btn btn primary" (click)="generateFormula()">Förhandsgranska formel</button>
    </div>
    <!--<div class="col-12">{{selectedMetod.formelMedKonst}}</div>-->
  </div>
  <div class="row" *ngIf="variabeFormulaForm.valid">
    <div class="col-12">
      <button class="btn btn primary" (click)="calculateFormula()">Beräkna formel</button>
    </div>
  </div>
  <!--<div class="row" *ngIf="calculatedFormel | async">
    <div class="col-12">
      Beräknad: {{calculatedFormel | async}}
    </div>
  </div>-->
</form>
<hr>
